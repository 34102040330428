var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bg-white py-16 min-h-96"},[_c('div',{staticClass:"max-w-container px-container mx-auto"},[_c('div',{staticClass:"flex justify-between mb-12 items-center"},[_c('h2',{staticClass:"text-6xl text-gray font-aldine italic"},[_vm._v("\n                News & Updates\n            ")]),_vm._v(" "),_c('BaseSelect',{attrs:{"dropdown-options":_vm.types,"has-selected-option":null},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_vm._v("\n                Show News and Updates\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-3 gap-4"},[(_vm.featuredNews.length)?_c('div',{staticClass:"col-span-2 grid grid-rows-2 gap-y-8"},_vm._l((_vm.featuredNews),function(entry){return _c(entry.type.data.slug === 'news'
                            ? 'FeaturedNews'
                            : 'StandardNews',{key:entry.id,tag:"Component",staticClass:"p-8",attrs:{"entry":entry}})}),1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-span-1 rounded-lg bg-blue-lightest p-8"},[_c('h3',{staticClass:"text-2xl font-aldine italic mb-8"},[_vm._v("\n                    Show items related to...\n                ")]),_vm._v(" "),_vm._l((_vm.categories),function(category){return _c('label',{class:[
                        'bg-transparent',
                        { 'bg-yellow': _vm.isCategorySelected(category) },
                        'border-2',
                        'border-yellow',
                        'cursor-pointer',
                        'font-bold',
                        'inline-block',
                        'mb-2',
                        'mr-2',
                        'px-4',
                        'py-2',
                        'rounded-full',
                        'text-blue-darkest',
                        'text-gray',
                        'text-sm' ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCategories),expression:"selectedCategories"}],staticClass:"sr-only",attrs:{"type":"checkbox"},domProps:{"value":category,"checked":Array.isArray(_vm.selectedCategories)?_vm._i(_vm.selectedCategories,category)>-1:(_vm.selectedCategories)},on:{"change":function($event){var $$a=_vm.selectedCategories,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=category,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedCategories=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedCategories=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedCategories=$$c}}}}),_vm._v("\n                    "+_vm._s(category.title)+"\n                    "),(_vm.isCategorySelected(category))?_c('span',{staticClass:"rounded-full border border-blue-darkest"},[_c('i',{staticClass:"far fa-fw fa-minus fa-sm fa-bold"})]):_vm._e()])})],2),_vm._v(" "),_vm._l((_vm.filteredEntries),function(entry){return (_vm.entries !== null)?_c('div',{key:entry.id,class:[
                    'p-8',
                    {
                        'col-span-1': entry.type.data.slug === 'updates',
                    },
                    {
                        'col-span-2': entry.type.data.slug === 'news',
                    } ]},[_c(entry.type.data.slug === 'news'
                            ? 'FeaturedNews'
                            : 'StandardNews',{tag:"Component",attrs:{"entry":entry}})],1):_vm._e()})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }