<template>
    <div class="flex gap-4 w-full flex-col">
        <div
            class="relative border rounded-lg px-4 py-3 bg-white border-blue-medium w-full mb-2"
            v-if="headingEditor">
            <label
                for="entryHeading"
                class="absolute top-2 left-4 text-xs text-gray"
                >Heading<span class="text-danger-dark">*</span></label
            >
            <div class="flex w-full my-4">
                <ToolbarButton
                    class="font-aldine"
                    @click="
                        headingEditor
                            .chain()
                            .focus()
                            .setFontFamily('Aldine, serif')
                            .run()
                    "
                    :is-active="
                        headingEditor.isActive('textStyle', {
                            fontFamily: 'Aldine, serif',
                        })
                    ">
                    Aldine
                </ToolbarButton>
                <ToolbarButton
                    class="font-swiss"
                    @click="
                        headingEditor
                            .chain()
                            .focus()
                            .setFontFamily('Swiss721, sans-serif')
                            .run()
                    "
                    :is-active="
                        headingEditor.isActive('textStyle', {
                            fontFamily: 'Swiss721, sans-serif',
                        })
                    ">
                    Swiss721
                </ToolbarButton>

                <ToolbarButton
                    @click="headingEditor.chain().focus().toggleBold().run()"
                    :disabled="
                        !headingEditor.can().chain().focus().toggleBold().run()
                    "
                    :is-active="headingEditor.isActive('bold')">
                    <strong>Bold</strong>
                </ToolbarButton>

                <ToolbarButton
                    @click="headingEditor.chain().focus().toggleItalic().run()"
                    :disabled="
                        !headingEditor
                            .can()
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    "
                    :is-active="headingEditor.isActive('italic')">
                    <em>Italic</em>
                </ToolbarButton>
                <ToolbarButton
                    @click="headingEditor.chain().focus().setHardBreak().run()"
                    class="border-r border-blue-light">
                    BR
                </ToolbarButton>
            </div>
            <div>
                <EditorContent
                    id="headingEditor"
                    class="w-full"
                    :editor="headingEditor" />
            </div>
        </div>

        <div>
            <EntryContent
                label="Content"
                class="w-full mb-2"
                id="content"
                v-model="entry.content" />
        </div>
        <div>
            <label
                for="topics"
                class="inline-block font-bold mb-2">
                Topics<span class="text-red-600">*</span>
            </label>

            <EntryArray
                id="topics"
                class="w-full"
                v-model="entry.extra.topics"
                new-item-label="Add Topic"
                :new-item-default="{}"
                v-slot="{ data, add, remove }">
                <template>
                    <li
                        v-for="(item, i) in data"
                        :key="i"
                        class="mb-2">
                        <div class="flex flex-row gap-4 items-center">
                            <input
                                v-model="item.title"
                                class="rounded w-1/2" />
                            <div class="w-1/2">
                                <EntryUsers
                                    class="w-full"
                                    v-model="item.contacts" />
                            </div>
                            <button
                                @click="remove(i)"
                                class="p-2 text-white bg-red-600 hover:bg-red-800">
                                <i class="far fa-fw fa-trash" />
                            </button>
                        </div>
                    </li>
                </template>
            </EntryArray>
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Heading from '@tiptap/extension-heading'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import HardBreak from '@tiptap/extension-hard-break'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'

import EntryContent from 'Components/Entries/EntryFields/EntryContent'
import EntryTitle from 'Components/Entries/EntryFields/EntryTitle'
import EntryArray from 'Components/Entries/EntryFields/EntryArray'
import EntryUsers from 'Components/Entries/EntryFields/EntryUsers'
import ToolbarButton from 'Components/Entries/EntryFields/EntryContent/ToolbarButton.vue'

const HeadingDocument = Document.extend({
    content: 'heading',
})

export default {
    components: {
        EditorContent,
        EntryArray,
        EntryContent,
        EntryTitle,
        ToolbarButton,
        EntryUsers,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            headingEditor: null,
        }
    },
    computed: {
        entry: {
            get() {
                return this.value
            },
        },
    },

    watch: {
        entry: {
            handler(newValue, oldvalue) {
                this.$emit('input', newValue)
            },
            deep: true,
        },
    },
    created() {
        if (Array.isArray(this.value?.extra)) {
            this.value.extra = {}
            this.value.topics = []
        }
    },
    mounted() {
        this.headingEditor = new Editor({
            content:
                this.value.extra.heading ?? `<h2>Content Section Title</h2>`,
            extensions: [
                Text,
                HeadingDocument,
                Bold,
                Italic,
                Heading.configure({
                    levels: [2],
                    HTMLAttributes: {
                        class: 'text-blue-darkest text-6xl pb-12',
                    },
                }),
                FontFamily,
                HardBreak,
                TextStyle,
                Placeholder.configure({
                    placeholder: (node) => {
                        console.log(node)
                        return 'Contact section title'
                    },
                }),
            ],
            onUpdate: () => {
                // HTML
                // this.$emit('input', this.editor.getHTML())

                // JSON
                this.value.extra.heading = this.headingEditor.getJSON()
                this.$emit('input', this.value)
            },
        })
    },
    beforeDestroy() {
        this.headingEditor.destroy()
    },
}
</script>
