<template>
    <div
        class="grid grid-cols-1 gap-4 bg-white/80 rounded-2xl px-8 py-4 col-span-full xl:col-span-1">
        <div class="pr-8">
            <h3
                class="font-aldine italic text-blue-darkest font-light antialiased text-3xl leading-tight xl:leading-10 xl:text-[32px]"
                v-text="title" />

            <p
                v-if="message"
                v-html="message.message"
                class="line-clamp-3" />

            <div class="flex flex-col md:flex-row gap-4 mt-4">
                <ButtonPrimarySmall
                    v-for="(link, i) in links"
                    :key="i"
                    :href="link.url"
                    v-text="link.text" />

                <ButtonOutline
                    v-if="notificationCount > 0"
                    type="submit"
                    text-size="text-xs"
                    href="/notifications"
                    v-text="notificationCountText">
                </ButtonOutline>
            </div>
        </div>
        <!-- <img
            src="/sup/build/images/welcome-banner.png"
            class="max-w-72"
            alt=""
        /> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ButtonOutline from '../Button/ButtonOutline'
import ButtonPrimarySmall from '../Button/ButtonPrimarySmall'

export default {
    name: 'HomepageWelcomeBanner',
    components: {
        ButtonOutline,
        ButtonPrimarySmall,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        message: {
            type: Object,
            required: false,
            default() {
                return {
                    title: 'Hello and Welcome, %first_name%!',
                    message: null,
                }
            },
        },
    },
    computed: {
        ...mapGetters('spotlight', ['notificationCount']),
        notificationCountText() {
            if (this.notificationCount === 1) {
                return "You've got 1 New Notification"
            }

            return `You've Got ${this.notificationCount} New Notifications`
        },
        title() {
            let title = this.message?.title

            if (!title) {
                title = 'Hello and Welcome, %first_name%!'
            }

            return title.replace('%first_name%', this.user.first_name)
        },
        links() {
            return this.message?.links
        },
    },
}
</script>
