<template>
    <nav class="bg-blue-darkest rounded-md p-4">
        <div v-for="(links, section) in items">
            <span
                class="text-gray-200 font-bold inline-block w-full text-sm"
                v-text="section"
            />
            <ul class="inline-block w-full mb-4">
                <li v-for="(link, i) in links">
                    <router-link
                        v-if="link.routerLink"
                        class="inline-block rounded text-blue-lighter py-2 hover:underline"
                        :to="link.routerLink"
                    >
                        <i
                            v-if="link.icon"
                            :class="[
                                'inlin-block',
                                'mr-2',
                                'far',
                                'fa-fw',
                                link.icon,
                            ]"
                        /><span
                            class="w-full"
                            v-text="link.text"
                        />

                        <span
                            v-if="link.help"
                            v-text="link.help"
                            class="text-xs italic inline-block ml-6"
                        />
                    </router-link>
                </li>
            </ul>
        </div>

        <a
            href="/sitemanager"
            class="inline-block text-sm rounded bg-coral px-4 py-2 mb-2 text-white font-bold hover:underline w-full"
        >
            <i class="far fa-fw fa-chevron-left inline-block mr-2"></i>
            Back to SiteManager
        </a>
    </nav>
</template>
<script>
export default {
    props: {
        items: { required: true, type: Object },
    },
    data() {
        return {}
    },
}
</script>
