<template>
<div
    class="toggle-switch--wrapper"
    role="switch"
    aria-controls="select-option"
    tabindex="0"
    aria-checked="false"
    @keyup.enter="toggleValue()"
>
    <input
        :id="id"
        v-model="model"
        class="toggle-switch"
        type="checkbox"
        :name="name"
        :value="inputValue"
    >
    <label
        class="toggle-switch--label"
        :for="id"
    >
        <i class="fal fa-check" />
    </label>
    <span
        v-if="text"
        class="toggle-switch--text leading-tight"
        @click.prevent="toggleValue()"
        v-text="text"
    />
</div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: null,
            required: false,
        },
        value: {
            required: true,
            default: null,
            validator: (p) => {
                return [
                    'string',
                    'number',
                    'boolean',
                    'array',
                ].indexOf(typeof p) !== -1 || p === null;
            },
        },
        inputValue: {
            type: [String, Array, Boolean, Object],
            required: true,
            default: null,
            validator: (p) => {
                return [
                    'string',
                    'number',
                    'boolean',
                    'array',
                    'object',
                ].indexOf(typeof p) !== -1 || p === null;
            },
        },
        uncheckedValue: {
            type: [String, Array, Boolean, Object],
            required: false,
            default: null,
            validator: (p) => {
                return [
                    'string',
                    'number',
                    'boolean',
                    'array',
                    'object',
                ].indexOf(typeof p) !== -1 || p === null;
            },
        },
        id: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            // value: null,
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                if (value) {
                    value = this.inputValue;
                } else {
                    value = this.uncheckedValue;
                }

                this.$emit('input', value);
            }
        },
    },
    methods: {
        toggleValue() {
            if (this.value) {
                this.model = false;
            } else {
                this.model = this.inputValue;
            }
        }
    }
};
</script>
