<template>
    <div
        class="flex items-center border border-blue-medium rounded-md px-6 py-2 bg-white shadow-lg"
    >
        <label class="text-gray-500">
            Show FAQs that contain:
            <input
                type="text"
                :value="value"
                placeholder="Start typing"
                class="flex-grow outline-none text-blue-darkest font-bold border-none"
                @input="$emit('input', $event.target.value)"
            />
        </label>
    </div>
</template>

<script>
export default {
    name: 'FormInputSearch',
    props: {
        value: {
            type: String,
            required: true,
        },
    },
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>

