<template>
    <div class="px-container py-16">
        <div class="max-w-screen-xl mx-auto">
            <h2 class="text-6xl text-gray font-aldine italic">
                Work Orders
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-9 mt-12">
                <div class="col-span-3 self-start h-auto bg-blue-lightest shadow rounded-tl-xl rounded-bl-xl p-4 mt-8">
                    <BaseSidebar
                        title="Work Order"
                        :items="items"
                        :selected-item="selectedItem"
                        @item-selected="selectItem"
                    />
                </div>

                <div class="md:col-start-4 col-span-full grid gap-4">
                    <BasePanel class="flex flex-col gap-8">
                        <div class="text-lg">
                            <div>
                                <h3 class="text-gray-darkest font-swiss font-bold text-2xl leading-tight mb-4">{{ selectedItem }}</h3>
                            </div>

                            <div 
                                v-if="selectedItem === 'Submit a Work Order'" 
                                class="grid gap-8 mt-4">
                                <div class="">
                                    <h3 class="text-xl text-blue font-swiss font-bold mb-4">Work Orders Information</h3>
                                    <WorkOrdersForm
                                        :work-orders="workOrders" />
                                </div>
                            </div>

                            <div
                                v-if="selectedItem === 'Your Work Orders'">
                                <span class="font-black text-sm uppercase">Filter by:</span>
                                <WorkOrderStatus 
                                    :work-orders="workOrders" />
                            </div>
                        </div>
                    </BasePanel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import endpoints from 'Root/endpoints';
import { SET_USER_WORK_ORDERS }  from 'Stores/Frontend/mutation-types';
import BasePanel from 'Components/Redesign/Base/BasePanel.vue'
import BaseSidebar from 'Components/Redesign/Base/BaseSidebar.vue'
import WorkOrdersForm from 'Components/Redesign/WorkOrders/WorkOrdersForm.vue'
import WorkOrderStatus from 'Components/Redesign/WorkOrders/WorkOrderStatus.vue'
export default {
    name: 'WorkOrders',
    components: {
        BasePanel,
        BaseSidebar,
        WorkOrdersForm,
        WorkOrderStatus,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        workOrder: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [
                { name: 'Submit a Work Order' }, 
                { name: 'Your Work Orders' },
                { name: 'Photo Upload' },
                { name: 'Video Upload' },
                { name: 'Submit to Hotel' },
                { name: 'Add Asset Version' },
                { name: 'Add Best Practice' },
            ],
            selectedItem: '',
            selectedType: '',
            workOrders: {},
        }
    },
    mounted() {
        this.selectItem(this.items[0], 'tab');
        this.fetchWorkOrders();
    },
    methods: {
        selectItem(item) {
            if (this.items.some(i => i.name === item.name)) {
                this.selectedType = 'tab';
            }
            this.selectedItem = item.name;
        },
        fetchWorkOrders() {
            if (this.workOrders.length > 0) {
                return;
            }

            this.isLoading = true;

            axios.get(
                endpoints.USER_WORK_ORDERS_LIST,
                {
                    params: {
                        status: [
                            'open',
                            'closed',
                        ],
                        includes: [
                            'property',
                            'brand',
                            'request_type',
                            'submittedby',
                            'notes',
                        ]
                    }
                }
            ).then(res => {
                this.workOrders = res.data;
                this.$store.commit('user/' + SET_USER_WORK_ORDERS, res.data.data);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
}
</script>
