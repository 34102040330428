<template>
<div class="searchInputGroup">
    <div class="">
        <label
            :for="id"
            :class="[
                'label',
                {'sr-only': !showLabel}
            ]"
        >{{ label }}</label>

        <input
            :id="id"
            v-model="text"
            type="text"
            :name="label"
            class="border
                border-slate-lighter
                w-full
                text-slate-light
                focus:ring-0
                focus:outline-none
                focus:inset-1
                focus:border-coral-dark
                rounded"
            @keyup.enter="addSelection(text)"
            :placeholder="placeholder ? placeholder : null"
        >

        <button
            v-if="text"
            class="my-2 underline w-full text-right"
            @click="addSelection(text)"
        >
            Add {{ text }} <i class="far fa-plus" />
        </button>

        <div
            v-if="selected.length"
            class="searchInputGroup__textGroup selected"
        >
            <label
                v-for="(item, i) in selected"
                :key="i"
                class="button --rounded --surf --normal --xs mb-1.5 mr-1.5"
            >
                <input
                    v-model="selected"
                    type="checkbox"
                    :value="item"
                    class="sr-only"
                >
                {{ item }}
                <i class="far fa-times" />
            </label>
        </div>
    </div>
</div>
</template>

<script>
import {v4 as uuid} from 'uuid';

export default {
    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
            required: false,
        },
        name: {
            type: String,
            default: '',
            required: false,
        },
        id: {
            type: String,
            default: uuid(),
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: true,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: true,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            text: null,
            internalSelected: [],
        };
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(selected) {
                this.internalSelected = selected;
                this.$emit('input', this.internalSelected);
            }
        },
    },
    methods: {
        addSelection(text) {
            let selected = this.selected;
            selected.push(text);
            this.selected = selected;
            this.text = null;
        }
    },
};
</script>

<style>

</style>
