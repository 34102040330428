<template>
    <div class="flex flex-wrap">
        <EntryTitle
            id="title"
            v-model="entry.title"
            class="w-full mb-8" />

        <EntryExcerpt
            class="w-full"
            v-model="entry.extra.excerpt"
            placeholder="A brief excerpt">
            <template #help>
                The exceprt will appear in the Spoltight section of the homepage
            </template>
        </EntryExcerpt>

        <EntryContent
            label="Content"
            class="w-full"
            :required="true"
            id="content"
            v-model="entry.content" />
    </div>
</template>

<script>
import EntryContent from 'Components/Entries/EntryFields/EntryContent'
import EntryTitle from 'Components/Entries/EntryFields/EntryTitle'
import EntryExcerpt from 'Components/Entries/EntryFields/EntryExcerpt'

export default {
    components: {
        EntryContent,
        EntryTitle,
        EntryExcerpt,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {}
    },
    computed: {
        entry: {
            get() {
                return this.value
            },
            set(newEntry) {
                this.$emit('input', newEntry)
            },
        },
    },

    created() {},
}
</script>
