<template>
    <div :class="wrapperClasses">
        <label
            :for="id"
            class="absolute top-2 left-4 text-xs text-gray"
            >{{ label }}
            <i
                v-if="icon"
                :class="iconClasses" />
            <span
                v-if="required"
                class="text-danger-dark"
                >*</span
            ></label
        >
        <input
            :id="id"
            type="text"
            :class="inputClasses"
            :name="name"
            :value="value"
            :disabled="disabled"
            :required="required"
            :placeholder="placeholder"
            @input="updateValue($event.target.value)" />
    </div>
</template>

<script>
export default {
    name: 'FormInput',
    props: {
        value: {
            type: String,
            required: false,
        },
        id: {
            required: false,
            type: String,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default:
                'w-full h-full font-bold text-blue-darkest pt-4 pb-0 px-0 outline-none border-none',
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
    },
    computed: {
        wrapperClasses() {
            let classes = ['relative', 'border', 'rounded-lg', 'px-4', 'py-3']

            if (this.disabled) {
                classes.push('bg-blue-lighter', 'border-blue-lighter')
            } else {
                classes.push('bg-white', 'border-blue-medium')
            }

            return classes
        },
        inputClasses() {
            let classes = this.inputClass.split(' ')

            if (this.disabled) {
                classes.push('bg-blue-lighter')
            }

            return classes
        },
        iconClasses() {
            return ['far', 'fa-sm', 'fa-fw', `fa-${this.icon}`]
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },
    },
}
</script>
