<template>
    <svg 
        class="drag-handle hover:text-gray-dark transition-colors duration-200 ease-in-out cursor-pointer" 
        :class="iconClasses"
        width="12" 
        height="20" 
        viewBox="0 0 12 20" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="currentColor"/>
        <circle cx="2" cy="10" r="2" fill="currentColor"/>
        <circle cx="2" cy="18" r="2" fill="currentColor"/>
        <circle cx="10" cy="2" r="2" fill="currentColor"/>
        <circle cx="10" cy="10" r="2" fill="currentColor"/>
        <circle cx="10" cy="18" r="2" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'IconDrag',
    props: {
        iconColor: {
            type: String,
            default: 'blue',
            // Add more if needed
            validator: (val) =>
                ['white', 'blue', 'lightblue', 'mediumblue', 'gray', 'darkblue'].includes(val),
        },
    },
    computed: {
        iconClasses() {
            let classes = [];

            if (this.iconColor === 'white') {
                classes.push('text-white');
            } else if (this.iconColor === 'blue') {
                classes.push('text-blue');
            } else if (this.iconColor === 'lightblue') {
                classes.push('text-blue-light');
            } else if (this.iconColor === 'darkblue') {
                classes.push('text-blue-darkest');
            } else if (this.iconColor === 'gray') {
                classes.push('text-gray');
            } else if (this.iconColor === 'mediumblue') {
                classes.push('text-blue-medium');
            } else {
                classes.push('text-blue');
            }

            return classes;
        },
    }
}
</script>

<style>

</style>