<script>
import CollectionBlock from './collectionBlock.vue';

export default {
    components: {
        CollectionBlock
    },
    props: {
        collections: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        },
        stats: {
            type: [Array, Object],
            required: false,
            default: function() {
                return [];
            }
        },
        commsPageId: {
            type: String,
            required: true
        },
        currentHash: {
            type: [Number,String],
            required: true
        },
        keyword: {
            type: String,
            required: false,
            default: ''
        },
        assetTypeId: {
            type: [Number,null],
            required: false,
            default: null
        },
        isAdmin: {
            type: Boolean,
            required: true
        },
        downloadLimit: {
            type: Number,
            required: false,
            default: 0
        },
    },
    data() {
        return {
        };
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        getStats(id) {
            if (this.stats.by_collection[id]) {
                return this.stats.by_collection[id];
            }
            return [];
        },
        initialDisplay(id) {
            if (id == parseInt(this.currentHash)) {
                return 'open';
            }
            return 'closed';
        },
        open(id) {
            this.$emit('open', id);
        }
    }
};
</script>

<template>
<div class="pt-4">
    <collection-block 
        v-for="c in collections" 
        :key="c.collection_id"
        :comms-page-id="commsPageId"
        :collection="c"
        :initial-display="initialDisplay(c.collection_id)"
        :stats="getStats(c.collection_id)"
        :keyword="keyword"
        :asset-type-id="assetTypeId"
        :is-admin="isAdmin"
        :download-limit="downloadLimit"
        @open="open"
    />
</div>
</template>