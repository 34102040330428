<template>
    <label
        :class="[
            'text-xs py-2 pl-6 pr-8 rounded-full bg-transparent border-2 font-medium text-center no-underline flex gap-4 items-center max-w-full relative cursor-pointer',
            borderColorClass,
        ]">
        <input
            type="checkbox"
            :checked="isSelected"
            @change="toggleSelect"
            class="sr-only" />
        <span>{{ transformedText }}</span>
        <i
            class="far fa-times absolute right-4"
            @click="removeItem" />
    </label>
</template>

<script>
export default {
    name: 'SearchTagInput',
    props: {
        item: {
            type: [Object, String],
            required: true,
        },
        selected: {
            type: Array,
            required: true,
        },
        selectedTextTransform: {
            required: false,
            type: Function,
            default(item) {
                if (typeof item === 'object') {
                    return item[this.selectedDisplayKey]
                }
                return item
            },
        },
        selectedDisplayKey: {
            required: true,
            type: String,
        },
        type: {
            type: String,
            required: true,
        },
    },
    computed: {
        borderColorClass() {
            switch (this.type) {
                case 'brands':
                    return 'border-coral'
                case 'asset_types':
                    return 'border-green-500'
                case 'properties':
                    return 'border-gold'
                default:
                    return 'border-blue'
            }
        },
        transformedText() {
            if (typeof this.item === 'object') {
                return this.selectedTextTransform(this.item)
            }
            return this.item
        },
        isSelected() {
            const selected = this.selected.some((i) =>
                typeof i === 'object'
                    ? i[this.selectedDisplayKey] ===
                      this.item[this.selectedDisplayKey]
                    : i === this.item
            )
            // console.debug(`isSelected for item "${this.item}": ${selected}`)
            return selected
        },
    },
    methods: {
        toggleSelect() {
            // console.debug(`Toggling select for item:`, this.item)
            let updatedSelected
            if (this.isSelected) {
                updatedSelected = this.selected.filter((i) =>
                    typeof i === 'object'
                        ? i[this.selectedDisplayKey] !==
                          this.item[this.selectedDisplayKey]
                        : i !== this.item
                )
            } else {
                updatedSelected = [...this.selected, this.item]
            }
            this.$emit('update:selected', updatedSelected)
        },
        removeItem() {
            // console.debug(`Removing item:`, this.item)
            const updatedSelected = this.selected.filter((i) =>
                typeof i === 'object'
                    ? i[this.selectedDisplayKey] !==
                      this.item[this.selectedDisplayKey]
                    : i !== this.item
            )
            this.$emit('update:selected', updatedSelected)
        },
    },
}
</script>
