<template>
    <multiselect
        class="absolute"
        v-model="data"
        id="entry_properties"
        label="name"
        track-by="marsha_code"
        placeholder="Enter MARSHA code"
        open-direction="bottom"
        :options="properties"
        :multiple="true"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="8"
        :limit-text="limitText"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="findProperties"
    >
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import debounce from 'lodash/debounce'

import { getProperties } from 'Composables/useProperties.js'

export default {
    components: {
        Multiselect,
    },
    props: {
        value: {
            required: false,
            type: Array,
            default: () => [],
        },
    },
    data() {
        return { filter: null, properties: [], isLoading: false }
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValues) {
                this.$emit('input', newValues)
            },
        },
    },
    methods: {
        limitText(count) {
            return `${count} addtional properties`
        },
        findProperties: debounce(async function (filter) {
            this.filter = filter
            this.isLoading = true

            try {
                const response = await getProperties({ search: filter })

                if (response.data.data) {
                    this.properties = response.data.data
                }
            } catch (error) {
                this.properties = []
                console.error(error)
            }

            this.isLoading = false
        }, 300),
    },
    async mounted() {},
}
</script>
