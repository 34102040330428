import router from 'Root/router.js'
import {
    createEntry,
    getEntries,
    getEntry,
    getEntryBySlug,
    updateEntry,
    deleteEntry,
    createCategory,
    updateCategory,
    deleteCategory,
} from 'Composables/useEntries.js'

import NotificationModel from 'Components/Redesign/SystemNotifications/NotificationModel.js'

// @var VueRouter
export default {
    namespaced: true,
    state: {
        entry: null,
        entries: [],
        type: null,
        pagination: {
            page: 1,
            limit: 25,
            orderby: 'created_at',
            direction: 'desc',
        },
        meta: {},
        filters: {
            status: 'active',
        },
        isLoading: null,
    },
    mutations: {
        SET_FILTERS(state, filter) {
            state.filter = filter
        },
        SET_ENTRY(state, entry) {
            if (Array.isArray(entry.content)) {
                entry.content = { type: 'doc', content: [] }
            }

            state.entry = entry
        },
        SET_ENTRIES(state, entries) {
            state.entries = entries
        },
        SET_TYPE(state, type) {
            state.type = type
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading
        },
        SET_META(state, meta) {
            state.meta = meta
        },
    },
    getters: {
        types(state) {
            return state.meta?.types?.data
        },
        categories(state) {
            return state.meta?.categories?.data
        },
        statuses(state) {
            return state.meta?.statuses?.data
        },
        currentPage(state) {
            return state.pagination.page
        },
        totalPages(state) {
            return state.meta?.pagination?.total_pages
        },
        totalEntries(state) {
            return state.meta?.pagination?.total
        },
        nextPage(state, getters) {
            if (!getters.totalPages) {
                return null
            }

            if (getters.totalPages === getters.currentPage) {
                return null
            }

            return getters.currentPage + 1
        },
        prevPage(state, getters) {
            if (!getters.totalPages) {
                return null
            }

            if (getters.totalPages === 1) {
                return null
            }

            return getters.totalPages - 1
        },
    },
    actions: {
        async getEntries({ commit, state, dispatch }, type = null) {
            commit('SET_LOADING', true)

            try {
                const response = await getEntries({
                    type,
                    status: ['active', 'draft'],
                    ...state.pagination,
                    ...state.filters,
                })

                commit('SET_ENTRIES', response.data?.data)
                commit('SET_META', response.data?.meta)

                dispatch('handleTypes', {
                    types: response.data.meta?.types?.data,
                    type,
                })
            } catch (error) {
                console.error(error)
            }

            commit('SET_LOADING', false)
        },
        async getEntryById({ commit, dispatch }, id) {
            commit('SET_LOADING', true)

            try {
                const response = await getEntry(id)
                commit('SET_ENTRY', response.data.data)
                dispatch('handleTypes', response.data.meta?.types.data)
            } catch (error) {
                console.error(error)
            }

            commit('SET_LOADING', false)
        },
        async getEntryBySlug({ commit, dispatch }, slug) {
            commit('SET_LOADING', true)

            try {
                const response = await getEntryBySlug(slug)

                if (!response.data.data.length) {
                    throw new Error(`Could not find an entry with slug ${slug}`)
                }

                commit('SET_ENTRY', response.data.data[0])
                dispatch('handleTypes', response.data.meta?.types.data)
            } catch (error) {
                console.error(error)
            }

            commit('SET_LOADING', false)
        },
        handleTypes({ commit }, options = { types: null, type: null }) {
            const { type, types } = options

            if (type) {
                commit(
                    'SET_TYPE',
                    types?.find((item) => item.slug === type)
                )
            }
        },
        async createEntry({ commit, dispatch }, entry) {
            try {
                entry = await createEntry(entry)

                dispatch(
                    'addNotification',
                    new NotificationModel('Entry created'),
                    { root: true }
                )

                router.push({
                    name: 'entries.index',
                    props: { type: this.type },
                })

                return entry
            } catch (error) {
                let message = 'Unable to create entry'

                dispatch(
                    'addNotification',
                    new NotificationModel(message, 'danger'),
                    { root: true }
                )

                return error
            }
        },
        async updateEntry({ commit, dispatch }, entry) {
            try {
                const response = await updateEntry(entry)

                dispatch(
                    'addNotification',
                    new NotificationModel('Entry updated'),
                    { root: true }
                )

                // router.push({
                //     name: 'entries.index',
                //     props: { type: this.type },
                // })

                dispatch('setEntry', response.data.data)

                return entry
            } catch (error) {
                console.error(error)
                let message = 'Unable to update entry'

                dispatch(
                    'addNotification',
                    new NotificationModel(message, 'danger'),
                    { root: true }
                )

                return false
            }
        },
        clearFilters({ commit }) {
            commit('SET_FILTERS', { status: 'active' })
        },
        setFilters({ commit }, filters) {
            commit('SET_FILTERS', filters)
        },
        clearEntry({ commit }) {
            commit('SET_ENTRY', null)
        },
        setEntry({ commit }, entry) {
            commit('SET_ENTRY', entry)
        },
        editEntry({ commit, dispatch }, entry) {
            commit('SET_ENTRY', entry)

            router.push({
                name: 'entries.edit',
                params: { type: entry.type, id: entry.id },
            })
        },
        async deleteEntry({ dispatch }, entry) {
            if (
                confirm(
                    'Are you sure you wish to delete this entry? This CANNOT be undone!'
                )
            ) {
                try {
                    const response = await deleteEntry(entry)

                    dispatch(
                        'pushSuccessAlert',
                        { title: response.data.message },
                        { root: true }
                    )

                    dispatch('getEntries', entry.type.data.slug)
                } catch (error) {
                    console.error(error)
                }

                try {
                    await router.push({
                        name: 'entries.index',
                        params: { typeSlug: entry.type.data.slug },
                    })
                } catch (error) {}
            }
        },
        async createCategory({ dispatch }, category) {
            try {
                const response = await createCategory(category)

                dispatch(
                    'pushSuccessAlert',
                    { title: response.data.message },
                    { root: true }
                )
                dispatch('getEntries')
            } catch (error) {
                console.error(error)
            }
        },
        async updateCategory({ dispatch }, category) {
            try {
                const response = await updateCategory(category)

                dispatch(
                    'pushSuccessAlert',
                    { title: response.data.message },
                    { root: true }
                )
            } catch (error) {
                console.error(error)
            }
        },
        async deleteCategory({ dispatch }, category) {
            if (
                confirm(
                    'Are you sure you wish to delete this category? This CANNOT be undone!'
                )
            ) {
                try {
                    const response = await deleteCategory(category)

                    dispatch(
                        'pushSuccessAlert',
                        { title: response.data.message },
                        { root: true }
                    )

                    dispatch('getEntries')
                } catch (error) {
                    console.error(error)
                }

            }
        },
    },
}
