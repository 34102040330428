<template>
    <FormInput
        @input="$emit('input', $event)"
        label="Title"
        required
        placeholder="Title"
        :value="data" />
</template>

<script>
import FormInput from 'Components/Redesign/Form/FormInput'

export default {
    components: { FormInput },
    props: {
        value: {
            type: String,
            default: null,
        },
    },

    data() {
        return { data: null }
    },
    watch: {},

    mounted() {
        this.data = this.value
    },

    beforeDestroy() {},
}
</script>

<style lang="scss">
.tiptap.ProseMirror {
    height: 250px;
}
</style>
