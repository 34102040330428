import axios from 'axios'
import {
    USER_GET_ACCOUNT,
    USER_ACCOUNT_SETTINGS,
    USER_ASSOCIATIONS,
} from 'Root/endpoints'

export async function getAccount() {
    return axios.get(USER_GET_ACCOUNT)
}

export async function getSettings() {
    return axios.get(USER_ACCOUNT_SETTINGS)
}

export async function hideNotification(notification) {
    return axios.post(USER_ACCOUNT_SETTINGS, {
        name: 'hidden_notifications',
        notification,
    })
}

export async function setAssociationsOrder(order) {
    return axios.post(USER_ACCOUNT_SETTINGS, {
        name: 'associations_order',
        order,
    })
}

export async function getAssociations(force = false) {
    return axios.get(USER_ASSOCIATIONS, { params: { force } })
}

export default {
    getAccount,
    getAssociations,
    getSettings,
    hideNotification,
    setAssociationsOrder,
}
