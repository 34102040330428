<template>
    <div>
        <ul class="p-4 flex gap-2">
            <li
                v-for="link in links"
                :key="link.url"
                class="relative">
                <a
                    :class="[
                        'flex items-center gap-4 bg-white font-bold rounded-full shadow-sm p-4',
                        isActive(link)
                            ? 'text-blue'
                            : 'text-black hover:text-blue',
                    ]"
                    :href="link.url"
                    @mouseover="setHoveredLink(link)"
                    @mouseleave="clearHoverLink"
                    @click="handleClick(link)">
                    <i
                        v-if="link.icon === 'trash'"
                        :class="[
                            'text-danger',
                            'far',
                            'fa-fw',
                            `fa-${link.icon}`,
                        ]" />
                    <i
                        v-else
                        :class="[
                            'text-gray',
                            'far',
                            'fa-fw',
                            `fa-${link.icon}`,
                        ]" />
                </a>
                <BaseTooltip
                    :show-tooltip="isHovered(link)"
                    :message="capitalize(link.icon)"
                    class="-translate-x-1/2 left-1/2" />
            </li>
        </ul>

        <!-- Modal -->
        <Modal
            :visible="isRenameModalVisible"
            @update:visible="isRenameModalVisible = $event"
            @close="isRenameModalVisible = false">
            <div>
                <h3 class="font-aldine italic text-blue-darkest mb-4">
                    Rename Workspace
                </h3>
                <form @submit.prevent="submitForm">
                    <FormInput
                        v-model="newWorkspaceName"
                        label="New name"
                        name="name"
                        :required="true" />
                    <ButtonPrimary
                        type="submit"
                        class="mt-4">
                        Rename
                    </ButtonPrimary>
                    <ButtonPrimary
                        @click="isRenameModalVisible = false"
                        class="mt-2 bg-gray">
                        Cancel
                    </ButtonPrimary>
                </form>
            </div>
        </Modal>
    </div>
</template>

<script>
import axios from 'axios'
import BaseTooltip from 'Components/Redesign/Base/BaseTooltip.vue'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import FormInput from 'Components/Redesign/Form/FormInput.vue'
import Modal from 'Components/Modal/index.vue'
import { mapState } from 'vuex'

export default {
    name: 'LinkNav',
    components: {
        BaseTooltip,
        ButtonPrimary,
        FormInput,
        Modal,
    },
    props: {
        links: {
            required: true,
            type: Array,
        },
        isOpen: {
            required: false,
            default: true,
            type: Boolean,
        },
        workspaceId: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            hoveredLink: null,
            isRenameModalVisible: false,
            newWorkspaceName: '',
        }
    },
    methods: {
        ...mapState({
            workspaces: (state) => state.user.workspaces,
        }),
        isActive(link) {
            let url = new URL(window.location)
            return link.url === url.pathname
        },
        setHoveredLink(link) {
            this.hoveredLink = link
        },
        clearHoverLink() {
            return (this.hoveredLink = null)
        },
        isHovered(link) {
            return this.hoveredLink === link
        },
        capitalize(text) {
            if (!text) return ''
            return text.charAt(0).toUpperCase() + text.slice(1) + ' Workspace'
        },
        handleClick(link) {
            if (link.action === 'rename') {
                this.isRenameModalVisible = true
            } else if (link.url) {
                window.location.href = link.url
            }
        },
        async submitForm() {
            try {
                await axios.post(`/workspaces/${this.workspaceId}`, {
                    name: this.newWorkspaceName,
                })
                window.location.href = `/workspaces/${this.workspaceId}`
            } catch (error) {
                console.error('Error renaming workspace:', error)
            }
        },
    },
}
</script>
