<template>
    <div>
        <label
            v-if="inputLabel"
            :for="inputId"
            class="font-bold mb-2">
            {{ inputLabel }}
        </label>
        <v-select
            ref="formSelect"
            v-model="internalValue"
            :id="inputId"
            :class="[selectInputClasses, inputLabel ? 'mt-2' : 'mt-0']"
            :placeholder="placeholder"
            :options="options"
            :label="label"
            :taggable="taggable"
            :components="{ OpenIndicator }"
            :reduce="reduce"
            :filter-by="filterBy"
            :multiple="multiple"
            :searchable="searchable"
            :dropdown-should-open="dropdownShouldOpen"
            @click.native="toggleDropdown"
            @search="$emit('search', $event)"
            @option:created="$emit('option:created', $event)" />
        <input
            v-if="value"
            type="hidden"
            :value="hiddenInputLabel ? value[hiddenInputLabel] : value"
            :name="name" />
    </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
    name: 'FormSelect',
    components: {
        vSelect,
    },
    props: {
        label: {
            type: String,
            required: false,
            default: 'title',
        },
        inputLabel: {
            type: String,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            default: 'Select One...',
        },
        inputId: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            default() {
                return []
            },
            required: true,
        },
        selectInputClasses: {
            type: String,
            default:
                'w-full text-blue-darkest p-2 outline-none border-none rounded-md bg-white shadow-lg',
        },
        value: {
            type: [String, Object, Number, Array],
            default: null,
        },
        taggable: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: null,
            required: false,
        },
        hiddenInputLabel: {
            type: String,
            default: null,
            required: false
        },
        reduce: {
            type: Function,
            required: false,
            default: (option) => option,
        },
        filterBy: {
            type: Function,
            default(option, label, search) {
                return (
                    (label || '')
                        .toLocaleLowerCase()
                        .indexOf(search.toLocaleLowerCase()) > -1
                )
            },
        },
        multiple: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isDropdownOpen: false,
            OpenIndicator: {
                // Custom open indicator with SVG
                render: (createElement) =>
                    createElement('span', [
                        createElement(
                            'svg',
                            {
                                attrs: {
                                    width: '18',
                                    height: '10',
                                    viewBox: '0 0 18 10',
                                    fill: 'none',
                                },
                            },
                            [
                                createElement('path', {
                                    attrs: {
                                        d: 'M1 1L9 9L17 1',
                                        stroke: '#0F358A',
                                        'stroke-width': '2',
                                        'stroke-linecap': 'round',
                                        'stroke-linejoin': 'round',
                                    },
                                }),
                            ]
                        ),
                    ]),
            },
        }
    },
    computed: {
        internalValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        addOption(newOption) {
            this.$emit('option:created', newOption)
        },
        toggleDropdown() {
        // Toggles the dropdown state
        this.isDropdownOpen = !this.isDropdownOpen;
        },
        dropdownShouldOpen(VueSelect) {
        // Custom logic for opening/closing the dropdown
        return this.isDropdownOpen;
        },
    },
    watch: {
        value(newVal) {
            this.internalValue = newVal
        },
    },
    mounted() {
        this.internalValue = this.value
    },
}
</script>
