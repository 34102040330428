<template>
    <div
        class="js-notificationForm flex flex-col max-w-container px-container mx-auto pb-16 scroll-m-42">
        <RadioTab
            v-model="selectedNotificationType"
            :options="types"
            name="group"
            class="relative z-20" />
        <div>
            <form
                class="form bg-blue-lightest rounded-b-3xl rounded-tr-3xl -translate-y-8 relative"
                method="POST"
                @submit.prevent="submitForm">
                <fieldset class="flex flex-col gap-12 px-6 pb-6 pt-16">
                    <div :class="gridCols">
                        <div
                            class="flex flex-row flex-wrap content-start pr-4"
                            v-if="selectedNotificationType === 'Property'">
                            <span
                                class="font-bold text-xl leading-tight text-blue-darkest mb-4 inline-block w-full"
                                >Properties</span
                            >
                            <ButtonOutline
                                @click.prevent=""
                                v-for="property in current.properties"
                                :key="property.property_id"
                                text-color="text-black"
                                class="text-base rounded-full mb-4 mr-2">
                                {{ property.name }}
                                <input
                                    type="hidden"
                                    name="property_ids[]"
                                    :value="property.property_id" />
                                <button
                                    @click.prevent="
                                        remove(
                                            'properties',
                                            'property_id',
                                            property
                                        )
                                    "
                                    class="ml-4 inline-block rounded-full px-2 border hover:border-coral">
                                    -
                                </button>
                            </ButtonOutline>

                            <ButtonOutline
                                @click.prevent="showPropertyModal = true"
                                class="mb-4 mr-2 text-xl relative max-w-8 max-h-8"
                                padding="p-5"
                                text-color="text-blue"
                                border-color="border-blue">
                                <span
                                    class="absolute top-1/5 left-1/2 -translate-y-1/2 -translate-x-1/2"
                                    >+</span
                                >
                            </ButtonOutline>
                        </div>
                        <div
                            class="flex flex-row flex-wrap content-start pr-4"
                            v-if="selectedNotificationType === 'Brand'">
                            <span
                                class="font-bold text-xl leading-tight text-blue-darkest mb-4 inline-block w-full"
                                >Brands</span
                            >
                            <ButtonOutline
                                @click.prevent=""
                                v-for="brand in current.brands"
                                :key="brand.brand_id"
                                text-color="text-black"
                                class="text-base rounded-full mb-2 mr-4">
                                {{ brand.name }}
                                <input
                                    type="hidden"
                                    name="brand_ids[]"
                                    :value="brand.property_id" />

                                <button
                                    @click.prevent="
                                        remove('brands', 'brand_id', brand)
                                    "
                                    class="ml-4 inline-block rounded-full px-2 border hover:border-coral">
                                    -
                                </button>
                            </ButtonOutline>

                            <ButtonOutline
                                @click.prevent="showBrandModal = true"
                                class="text-xl relative max-w-8 max-h-8"
                                padding="p-5"
                                text-color="text-blue"
                                border-color="border-blue">
                                <span
                                    class="absolute top-1/5 left-1/2 -translate-y-1/2 -translate-x-1/2"
                                    >+</span
                                >
                            </ButtonOutline>
                        </div>
                        <div
                            class="flex flex-row flex-wrap content-start px-4">
                            <span
                                class="font-bold text-xl leading-tight text-blue-darkest mb-4 inline-block w-full"
                                >Asset Types</span
                            >
                            <ButtonOutline
                                @click.prevent=""
                                v-for="assetType in current.assetTypes"
                                :key="assetType.id"
                                text-color="text-black"
                                class="text-base rounded-full mb-2 mr-4">
                                {{ assetType.name }}
                                <input
                                    type="hidden"
                                    name="asset_type_ids[]"
                                    :value="assetType.asset_type_id" />
                                <button
                                    @click.prevent="
                                        remove(
                                            'assetTypes',
                                            'asset_type_id',
                                            assetType
                                        )
                                    "
                                    class="ml-4 inline-block rounded-full px-2 border hover:border-coral">
                                    -
                                </button>
                            </ButtonOutline>

                            <ButtonOutline
                                @click.prevent="showAssetTypeModal = true"
                                class="text-xl relative max-w-8 max-h-8"
                                padding="p-5"
                                text-color="text-blue"
                                border-color="border-blue">
                                <span
                                    class="absolute top-1/5 left-1/2 -translate-y-1/2 -translate-x-1/2"
                                    >+</span
                                >
                            </ButtonOutline>
                        </div>
                        <div 
                            class="flex flex-row flex-wrap content-start px-4"
                            v-if="selectedNotificationType === 'Brand'"
                        >
                            <span class="font-bold text-xl leading-tight text-blue-darkest mb-4 inline-block w-full">
                                Regions
                            </span>
                            <ButtonOutline
                                v-for="region in current.regions"
                                :key="region.id"
                                text-color="text-black"
                                class="text-base rounded-full mb-2 mr-4">
                                {{ region.name }}
                                <input
                                    type="hidden"
                                    name="region_ids[]"
                                    :value="region.id" />
                                <button
                                    @click.prevent="
                                        remove('regions', 'id', region)
                                    "
                                    class="ml-4 inline-block rounded-full px-2 border hover:border-coral">
                                    -
                                </button>
                            </ButtonOutline>

                            <ButtonOutline
                                @click.prevent="showRegionModal = true"
                                class="text-xl relative max-w-8 max-h-8"
                                padding="p-5"
                                text-color="text-blue"
                                border-color="border-blue">
                                <span
                                    class="absolute top-1/5 left-1/2 -translate-y-1/2 -translate-x-1/2"
                                    >+</span
                                >
                            </ButtonOutline>
                            <p class="mt-4 font-aldine italic text-gray w-full">
                                Unless specifying regions above, you’ll receive
                                notifications about asset updates in any region.
                            </p>
                        </div>
                    </div>

                    <div>
                        <label
                            class="font-bold text-xl leading-tight text-blue-darkest mb-4 inline-block">
                            Choose your notification frequency
                        </label>
                        <div class="flex gap-12 items-center">
                            <radio-tab
                                v-model="current.schedule"
                                :options="schedules"
                                name="schedule" />
                            <span
                                class="text-[13px] text-green-dark bg-blue-lighter rounded py-4 px-6 flex gap-2 items-center">
                                <svg
                                    width="16"
                                    height="19"
                                    viewBox="0 0 16 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 14.3125C9 14.9453 8.47266 15.4375 7.875 15.4375C7.24219 15.4375 6.75 14.9453 6.75 14.3125C6.75 13.7148 7.24219 13.1875 7.875 13.1875C8.47266 13.1875 9 13.7148 9 14.3125ZM8.71875 11.2188C8.71875 11.7109 8.33203 12.0625 7.875 12.0625C7.38281 12.0625 7.03125 11.7109 7.03125 11.2188V8.96875C7.03125 8.51172 7.38281 8.125 7.875 8.125C8.33203 8.125 8.71875 8.51172 8.71875 8.96875V11.2188ZM5.34375 2.5H10.4062V1.09375C10.4062 0.636719 10.7578 0.25 11.25 0.25C11.707 0.25 12.0938 0.636719 12.0938 1.09375V2.5H13.5C14.7305 2.5 15.75 3.51953 15.75 4.75V16C15.75 17.2656 14.7305 18.25 13.5 18.25H2.25C0.984375 18.25 0 17.2656 0 16V4.75C0 3.51953 0.984375 2.5 2.25 2.5H3.65625V1.09375C3.65625 0.636719 4.00781 0.25 4.5 0.25C4.95703 0.25 5.34375 0.636719 5.34375 1.09375V2.5ZM1.6875 16C1.6875 16.3164 1.93359 16.5625 2.25 16.5625H13.5C13.7812 16.5625 14.0625 16.3164 14.0625 16V7H1.6875V16Z"
                                        fill="#28C642" />
                                </svg>

                                Weekly notifications are sent Sundays after 5pm
                            </span>
                        </div>
                    </div>

                    <div class="max-w-full">
                        <div class="flex gap-4 items-center justify-between">
                            <FormInput
                                id="name"
                                name="name"
                                label="Name this notification"
                                :required="true"
                                v-model="current.name"
                                class="w-full" />
                            <ButtonPrimary
                                text-size="text-sm"
                                class="flex gap-4 items-center shrink-0"
                                type="submit --icon">
                                <Spinner v-if="creating" />
                                <span v-else>
                                    {{ buttonText }}
                                </span>
                                <IconArrowRight icon-color="white" />
                            </ButtonPrimary>

                            <button
                                v-if="notificationBeingUpdated"
                                class="button --red"
                                @click="cancelUpdateNotification">
                                Cancel
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <PropertyMultiselectModal
            v-if="showPropertyModal"
            v-model="current.properties"
            @close="showPropertyModal = false"
            :is-open="showPropertyModal" />

        <BrandMultiselectModal
            v-if="showBrandModal"
            v-model="current.brands"
            @close="showBrandModal = false"
            :is-open="showBrandModal" />

        <RegionMultiselectModal
            v-if="showRegionModal"
            v-model="current.regions"
            @close="showRegionModal = false"
            :is-open="showRegionModal" />

        <AssetTypeMultiselectModal
            v-if="showAssetTypeModal"
            v-model="current.assetTypes"
            @close="showAssetTypeModal = false"
            :is-open="showAssetTypeModal" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import store from 'Stores/Frontend'

import PropertyMultiselectModal from 'Components/Redesign/MultiselectModal/PropertyMultiselectModal'
import AssetTypeMultiselectModal from 'Components/Redesign/MultiselectModal/AssetTypeMultiselectModal'
import BrandMultiselectModal from 'Components/Redesign/MultiselectModal/BrandMultiselectModal'
import RegionMultiselectModal from 'Components/Redesign/MultiselectModal/RegionMultiselectModal'
import BackgroundBlueLight from 'Components/Redesign/Background/BackgroundBlueLight'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary'
import FormInput from 'Components/Redesign/Form/FormInput'
import IconArrowRight from 'Components/Redesign/Icons/IconArrowRight'
import RadioTab from 'Components/Redesign/RadioTab'
import SearchInput from 'Components/Redesign/Search/SearchInput'
import Spinner from 'Components/Redesign/Spinner'
import ButtonOutline from 'Components/Redesign/Button/ButtonOutline'

export default {
    name: 'AccountNotificationsForm',
    store,
    components: {
        AssetTypeMultiselectModal,
        PropertyMultiselectModal,
        BrandMultiselectModal,
        RegionMultiselectModal,
        SearchInput,
        RadioTab,
        Spinner,
        IconArrowRight,
        FormInput,
        BackgroundBlueLight,
        ButtonPrimary,
        ButtonOutline,
    },
    data() {
        return {
            selectedNotificationType: 'Brand',
            creating: false,
            current: {
                brands: [],
                properties: [],
                regions: [],
                assetTypes: [],
                schedule: 'daily',
                name: null,
            },
            types: [
                { label: 'Brand Level Notification', value: 'Brand' },
                { label: 'Property Level Notification', value: 'Property' },
            ],
            schedules: [
                { label: 'Daily Email', value: 'daily' },
                { label: 'Weekly Email', value: 'weekly' },
                { label: 'Biweekly', value: 'semi_monthly' },
                { label: 'Monthly Email', value: 'monthly' },
            ],
            showPropertyModal: false,
            showBrandModal: false,
            showRegionModal: false,
            showAssetTypeModal: false,
        }
    },
    computed: {
        ...mapState('accountNotifications', ['notificationBeingUpdated']),
        buttonText() {
            return this.notificationBeingUpdated
                ? 'Update Notification'
                : 'Save Notification'
        },
        gridCols() {
            if (this.selectedNotificationType == 'Property') {
                return 'grid grid-cols-2 divide-x divide-blue-lighter';
            }

            return 'grid grid-cols-3 divide-x divide-blue-lighter';
        }
    },
    watch: {
        selectedNotificationType(value) {
            if (value === 'Brand') {
                this.current.properties = []
            } else {
                this.current.brands = []
                this.current.regions = []
            }
        },
        notificationBeingUpdated(value) {
            if (value !== null) {
                this.current = {
                    brands: value.brands,
                    properties: value.properties,
                    regions: value.regions,
                    assetTypes: value.asset_types,
                    schedule: value.schedule,
                    name: value.name,
                }

                if (this.current.properties.length > 0) {
                    this.selectedNotificationType = 'Property'
                }
            }
        },
    },
    methods: {
        formattedPropertyName(property) {
            let formatted = property.marsha_code + ' - ' + property.name

            if (formatted.length > 30) {
                formatted =
                    property.marsha_code +
                    ' - ' +
                    property.name.substr(0, 25) +
                    '...'
            }

            return formatted
        },
        async createNotification() {
            const notification = Object.assign({}, this.current)

            this.creating = true

            notification.asset_type_ids = notification.assetTypes.map(
                (type) => type.asset_type_id
            )
            notification.brand_ids = notification.brands.map(
                (brand) => brand.brand_id
            )
            notification.property_ids = notification.properties.map(
                (property) => property.property_id
            )
            notification.region_ids = notification.regions.map(
                (region) => region.id
            )

            const success = await this.$store.dispatch(
                'accountNotifications/createNotification',
                notification
            )

            if (success) {
                this.$store.dispatch('pushSuccessAlert', {
                    title: 'Notification created',
                })
            } else {
                this.$store.dispatch('pushErrorAlert', {
                    title: 'Notification could not be created',
                })
            }

            this.creating = false
            this.resetForm()
            this.$store.dispatch('accountNotifications/fetchNotifications')
        },
        async updateNotification() {
            const notification = Object.assign({}, this.current)

            this.creating = true

            notification.asset_type_ids = notification.assetTypes.map(
                (type) => type.asset_type_id
            )

            notification.brand_ids = notification.brands.map(
                (brand) => brand.brand_id
            )

            notification.property_ids = notification.properties.map(
                (property) => property.property_id
            )

            notification.region_ids = notification.regions.map(
                (region) => region.id
            )

            const success = await this.$store.dispatch(
                'accountNotifications/updateNotification',
                {
                    notification,
                    id: this.notificationBeingUpdated.id,
                }
            )

            if (success) {
                this.$store.dispatch('pushSuccessAlert', {
                    title: 'Notification updated',
                })
            } else {
                this.$store.dispatch('pushErrorAlert', {
                    title: 'Notification could not be updated',
                })
            }

            this.creating = false
            this.resetForm()
            this.$store.dispatch(
                'accountNotifications/setNotificationToUpdate',
                null
            )

            this.$store.dispatch('accountNotifications/fetchNotifications')
        },
        cancelUpdateNotification() {
            this.resetForm()

            this.$store.dispatch(
                'accountNotifications/setNotificationToUpdate',
                null
            )
        },
        remove(field, key, remove) {
            this.current[field] = this.current[field].filter(
                (item) => item[key] !== remove[key]
            )
        },
        submitForm() {
            if (this.notificationBeingUpdated) {
                this.updateNotification()
            } else {
                this.createNotification()
            }
        },
        resetForm() {
            this.current.brands = []
            this.current.properties = []
            this.current.regions = []
            this.current.assetTypes = []
            this.current.schedule = 'daily'
            this.current.name = null
            this.selectedNotificationType = 'Brand'
        },
    },
}
</script>
