import {
    SET_SPOTLIGHT_BRAND_ASSETS,
    SET_SPOTLIGHT_PROPERTY_ASSETS,
    SET_SPOTLIGHT_ASSET_NOTIFICATIONS,
    SET_SPOTLIGHT_IS_LOADING,
    SET_SPOTLIGHT_UPDATES,
    SET_SPOTLIGHT_FEATURED_NEWS,
    SET_SPOTLIGHT_FEATURED_UPDATES,
} from 'Stores/Frontend/mutation-types.js'

import { getAssets } from 'Composables/useSearch.js'
import { getEntries } from 'Composables/useEntries.js'
import {
    getAssetNotifications,
    getReadAssetNotifications,
    getUnreadAssetNotifications,
    getUpdateNotifications,
    markSeen,
} from 'Composables/useUserNotifications.js'

const spotlight = {
    namespaced: true,
    state: {
        brandAssets: [],
        isLoading: false,
        featuredNews: [],
        featuredUpdates: [],
        assetNotifications: [],
        propertyAssets: [],
        updates: [],
        notifications: {
            read: [],
            unread: [],
            // tempRead is a place to put assets that we have seen
            // but do not want to be shown with the deactivated assets yet
            // we filter the notifications ids out from the read array
            tempRead: [],
        },
        pagination: {
            unread: {},
            read: {},
        },
    },
    getters: {
        hasPropertyAssets(state) {
            return state.propertyAssets.length > 0
        },
        hasBrandAssets(state) {
            return state.brandAssets.length > 0
        },
        hasAssetNotifications(state) {
            return state.assetNotifications.length > 0
        },
        hasUpdates(state) {
            return state.updates.length > 0
        },
        hasFeaturedNews(state) {
            return state.featuredNews.lenght > 0
        },
        hasFeaturedUpdates(state) {
            return state.featuredUpdates.length > 0
        },
        hasUnreadNotifications(state) {
            return state.notifications.unread.length > 0
        },
        hasReadNotifications(state) {
            return state.notifications.read.length > 0
        },
        unreadPagination(state) {
            return state.pagination.unread
        },
        readPagination(state) {
            return state.pagination.read
        },
        unreadNotifications(state) {
            return state.notifications.unread
        },
        readNotifications(state) {
            return state.notifications.read
        },
        notificationCount(state) {
            let total = state.pagination.unread?.total ?? 0

            return total - state.notifications.tempRead.length
        },
        hasMoreRead(state) {
            const current = state.pagination.read.current_page ?? 0
            const total = state.pagination.read.total_pages ?? 1

            return current < total
        },
        hasMoreUnread(state) {
            const current = state.pagination.unread.current_page ?? 0
            const total = state.pagination.unread.total_pages ?? 1

            return current < total
        },
    },
    mutations: {
        [SET_SPOTLIGHT_BRAND_ASSETS](state, assets) {
            state.brandAssets = assets
        },
        [SET_SPOTLIGHT_PROPERTY_ASSETS](state, assets) {
            state.propertyAssets = assets
        },
        [SET_SPOTLIGHT_ASSET_NOTIFICATIONS](state, assetNotifications) {
            state.assetNotifications = assetNotifications
        },
        [SET_SPOTLIGHT_IS_LOADING](state, isLoading) {
            state.isLoading = isLoading
        },
        [SET_SPOTLIGHT_UPDATES](state, updates) {
            state.updates = updates
        },
        [SET_SPOTLIGHT_FEATURED_NEWS](state, news) {
            state.featuredNews = news
        },
        [SET_SPOTLIGHT_FEATURED_UPDATES](state, update) {
            state.featuredUpdates = update
        },
        SET_SPOTLIGHT_READ(state, read) {
            state.notifications.read = read
        },
        SET_SPOTLIGHT_READ_PAGINATION(state, pagination) {
            state.pagination.read = pagination
        },
        SET_SPOTLIGHT_UNREAD(state, unread) {
            state.notifications.unread = unread
        },
        SET_SPOTLIGHT_UNREAD_PAGINATION(state, pagination) {
            state.pagination.unread = pagination
        },
        SET_SPOTLIGHT_TEMP_READ(state, temp) {
            state.notifications.tempRead = temp
        },
    },
    actions: {
        setIsLoading({ commit }, isLoading) {
            commit(SET_SPOTLIGHT_IS_LOADING, isLoading)
        },
        async init({ dispatch, getters }) {
            return await dispatch('loadUnread')
        },
        async loadRead({ commit, dispatch, state }, { page = 1 } = {}) {
            dispatch('setIsLoading', true)

            try {
                const response = await getReadAssetNotifications({ page })

                if (response.data.data) {
                    let read = state.notifications.read

                    read.push(...response.data.data)

                    commit('SET_SPOTLIGHT_READ', read)
                    commit(
                        'SET_SPOTLIGHT_READ_PAGINATION',
                        response.data.meta.pagination
                    )
                }
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async loadUnread({ commit, dispatch }, { page = 1 } = {}) {
            dispatch('setIsLoading', true)

            try {
                const response = await getUnreadAssetNotifications({ page })

                if (response.data.data) {
                    commit('SET_SPOTLIGHT_UNREAD', response.data.data)

                    commit(
                        'SET_SPOTLIGHT_UNREAD_PAGINATION',
                        response.data.meta.pagination
                    )
                }
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async loadUpdates({ dispatch, commit }) {
            dispatch('setIsLoading', true)

            try {
                const response = await getUpdateNotifications('1 year ago')

                if (response.data.data.length) {
                    commit(SET_SPOTLIGHT_UPDATES, response.data.data)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async loadBrandAssets({ commit, dispatch }, brandIds) {
            const params = {
                order_by: 'date_created_desc',
                brand_ids: brandIds,
                per_page: 100,
                brand_only: true,
            }

            dispatch('setIsLoading', true)

            try {
                const response = await getAssets(params)

                if (response.data.data.assets.data.length) {
                    commit(
                        SET_SPOTLIGHT_BRAND_ASSETS,
                        response.data.data.assets.data
                    )
                }
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async loadPropertyAssets({ commit, dispatch }, propertyIds) {
            const params = {
                order_by: 'date_created_desc',
                property_ids: propertyIds,
                per_page: 100,
            }

            dispatch('setIsLoading', true)

            try {
                const response = await getAssets(params)

                if (response.data.data.assets.data.length) {
                    commit(
                        SET_SPOTLIGHT_PROPERTY_ASSETS,
                        response.data.data.assets.data
                    )
                }
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async loadNews({ commit, dispatch }) {
            try {
                const newsResponse = await getEntries({
                    type: 'news',
                    limit: 3,
                    status: 'active',
                    order_by: 'created_at',
                    includes: [
                        'brands',
                        'collections',
                        'properties',
                        'collections.thumbnails',
                        'properties.links',
                    ],
                })

                commit(SET_SPOTLIGHT_FEATURED_NEWS, newsResponse.data.data)
            } catch (error) {
                console.error(error)
            }

            try {
                const updateResponse = await getEntries({
                    type: 'updates',
                    limit: 3,
                    order_by: 'created_at',
                    status: 'active',
                })
                commit(
                    SET_SPOTLIGHT_FEATURED_UPDATES,
                    updateResponse.data.data
                )
            } catch (error) {
                console.error(error)
            }
        },
        markSeen({ commit, state }, notification) {
            let read = state.notifications.tempRead

            read.push(notification)

            commit('SET_SPOTLIGHT_TEMP_READ', read)

            markSeen(notification)
        },
    },
}

export default spotlight
