<template>
    <div class="relative select-wrapper">
        <!-- Container for positioning the arrow -->
        <div class="relative">
            <!-- Native select element -->
            <select
                v-model="selected"
                :class="dropdownClasses"
                @change="handleDropdownChange"
                ref="select"
                class="w-full pr-10"
                @focus="openDropdown"
                @blur="closeDropdown">
                <!-- Placeholder option -->
                <option
                    v-if="!selected"
                    value=""
                    disabled
                    selected>
                    <slot
                        v-if="!hasSelectedOption"
                        name="default"
                        >Select an option</slot
                    >
                </option>
                <!-- Options -->
                <option
                    v-for="option in dropdownOptions"
                    :key="option.value"
                    :value="option.value">
                    {{ option.label }}
                </option>
            </select>
            <!-- Custom dropdown arrow -->
            <svg
                width="21"
                height="13"
                viewBox="0 0 23 13"
                fill="none"
                class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer z-10 pointer-events-none"
                @click.stop="toggleDropdown"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 12.5C11.0781 12.5 10.7031 12.3594 10.4219 12.0781L1.42188 3.07812C0.8125 2.51562 0.8125 1.53125 1.42188 0.96875C1.98438 0.359375 2.96875 0.359375 3.53125 0.96875L11.5 8.89062L19.4219 0.96875C19.9844 0.359375 20.9688 0.359375 21.5312 0.96875C22.1406 1.53125 22.1406 2.51562 21.5312 3.07812L12.5312 12.0781C12.25 12.3594 11.875 12.5 11.5 12.5Z"
                    :fill="arrowFill" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseSelect',
    props: {
        dropdownOptions: {
            type: Array,
            default: () => [],
        },
        hasSelectedOption: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Number, String],
            default: '',
        },
        dropdownClasses: {
            type: Array,
            default: () => [
                'rounded-full',
                'bg-gold-lighter',
                'text-blue-darkest',
                'border-none',
                'shadow-[0_1px_1px_0_rgba(192,183,161,0.6)]',
                'font-normal',
                'pl-8',
                'pr-12',
                'w-full md:w-auto',
                'text-center',
                'no-underline',
                'hover:bg-gray-light',
                'transition-colors',
            ],
        },
        arrowFill: {
            type: String,
            default: '#928D82',
        },
    },
    data() {
        return {
            selectedOption: this.value || '',
            isOpen: false,
        }
    },
    watch: {
        value(newValue) {
            this.selectedOption = newValue || ''
        },
    },
    computed: {
        selected: {
            get() {
                return this.selectedOption
            },
            set(newValue) {
                this.selectedOption = newValue
                this.$emit('input', newValue)
                this.$emit('change', newValue)
            },
        },
    },
    methods: {
        handleDropdownChange(event) {
            this.selectedOption = event.target.value
            this.$emit('change', this.selectedOption)
            this.$emit('input', this.selectedOption)
            this.closeDropdown()
        },
        openDropdown() {
            this.isOpen = true
        },
        closeDropdown() {
            this.isOpen = false
        },
        toggleDropdown(event) {
            event.stopPropagation()
            // Toggle dropdown
            this.isOpen = !this.isOpen
            this.$refs.select.focus()
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.closeDropdown()
            }
        },
    },
    mounted() {
        document.addEventListener('mousedown', this.handleClickOutside)
    },
    beforeDestroy() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    },
}
</script>

<style scoped>
.select-wrapper {
    position: relative;
    display: inline-block;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: none;
    padding-right: 3.5em; /* Space for the custom arrow */
}
</style>
