<template>
    <div class="relative">
        <span v-html="svgContent" />
        <span
            v-if="notification"
            class="absolute -top-2 -right-2 bg-coral text-blue-darkest rounded-full border-2 border-blue-darkest w-auto min-w-6 h-6 px-1 flex justify-center items-center">
            {{ notificationText }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'BaseIcon',
    props: {
        svg: {
            type: String,
            required: true,
        },
        notification: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        svgContent() {
            return this.svg
        },
        notificationText() {
            return this.notification > 99 ? '99+' : this.notification
        },
    },
    methods: {
        iconClick(icon) {
            if (icon.action) {
                icon.action()
            }
        },
    },
}
</script>
