<template>
    <div class="radioContainer--pill">
        <label
            v-for="option in options"
            :key="option.value"
            class="radio--pill cursor-pointer">
            <input
                :id="name"
                v-model="selected"
                type="radio"
                :name="name"
                :value="option.value" />
            <span class="flex gap-2.5 items-center">
                <i
                    v-if="option.value === selected"
                    class="far fa-check" />
                {{ option.label }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            required: false,
            type: String,
        },
        options: {
            required: true,
            type: Array,
        },
        value: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            selected: this.value,
        }
    },
    watch: {
        selected() {
            this.$emit('input', this.selected)
        },
        value() {
            this.selected = this.value
        },
    },
}
</script>
