<template>
<div
    :class="[
        'flex rounded-md w-auto flex-col justify-end py-4',
    ]"
>
    <div
        class="md:w-full flex justify-start items-center cursor-pointer"
    >
        <span
            v-if="icon"
            class="pr-2 leading-8"
        >
            <i :class="['far', 'fa-fw', `fa-${icon}`]" />
        </span>

        <h3
            v-if="label && opened"
            class="bold text-2xl mr-2 font-swiss"
            v-text="label"
        />
    </div>

    <div
        class="h-full w-full"
    >
        <slot
            name="content"
            :isOpen="opened"
        />
    </div>
</div>
</template>

<script>
export default {
    props: {
        label: {
            required: false,
            type: String,
            default: null,
        },
        remember: {
            required: false,
            type: String,
            default: null,
        },
        icon: {
            required: false,
            type: String,
            default: null,
        },
        closedClasses: {
            required: false,
            type: Array,
            default() {
                return [];
            }
        },
        openedClasses: {
            required: false,
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            isOpen: true,
        };
    },
    computed: {
        opened: {
            get() {
                return this.isOpen;
            },
            set(open) {
                this.isOpen = open;
                this.rememberOpen(open);
            }
        }
    },
    mounted() {
        this.opened = this.getRememberOpen();
    },
    methods: {
        rememberOpen(open) {
            if (this.remember) {
                window.localStorage.setItem(this.remember, open);
            }
        },
        getRememberOpen() {
            let open = true;

            if (this.remember) {
                open = window.localStorage.getItem(this.remember);

                if (open === null) {
                    open = true;
                } else {
                    open = (open === 'true');
                }
            }

            return open;
        },
    }
};
</script>
