<template>
    <header class="my-3 text-white">
        <nav class="relative">
            <div
                v-if="user"
                class="flex flex-row-reverse justify-between items-center w-full px-container">
                <div class="flex items-center">
                    <ButtonStripped
                        class="hidden xl:inline-block text-white font-normal hover:text-blue-light"
                        href="/brandmaker-sso">
                        <span class="flex gap-4 items-center">
                            <BaseIcon
                                :svg="MIStudioIcon"
                                size="w-[18px] h-[18px]"
                                color="text-blue-light" />
                            <span class="text-lg">MI Studio</span>
                        </span>
                    </ButtonStripped>
                    <span class="hidden xl:inline-block text-blue-light"
                        >|</span
                    >
                    <ButtonStripped
                        class="text-white font-normal hover:text-blue-light"
                        padding="pl-6 pr-0 py-2"
                        @click="openMenu">
                        <span class="flex gap-4 items-center">
                            <span class="text-lg hidden xl:inline-block">
                                Open Menu
                            </span>
                            <BaseIcon
                                :svg="menuIcon"
                                size="w-[18px] h-[15px]"
                                color="text-blue-light" />
                        </span>
                    </ButtonStripped>
                </div>
                <NavigationUserMenu
                    :user="user"
                    :is-admin="isAdmin" />
            </div>
            <transition name="fade">
                <NavigationMenu
                    v-show="menuOpen"
                    :is-open="menuOpen"
                    @close-menu="closeMenu" />
            </transition>
        </nav>
    </header>
</template>

<script>
import BaseIcon from '../Base/BaseIcon.vue'
import ButtonStripped from '../Button/ButtonStripped.vue'
import NavigationUserMenu from '../Navigation/NavigationUserMenu.vue'
import NavigationMenu from '../Navigation/NavigationMenu.vue'

export default {
    name: 'NavigationBase',
    components: {
        BaseIcon,
        ButtonStripped,
        NavigationUserMenu,
        NavigationMenu,
    },
    props: {
        user: {
            type: Object,
            default: null,
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menuIcon:
                '<svg width="18" height="15" viewBox="0 0 18 15" fill="currentColor"><path d="M0 1.4375C0 0.929688 0.390625 0.5 0.9375 0.5H16.5625C17.0703 0.5 17.5 0.929688 17.5 1.4375C17.5 1.98438 17.0703 2.375 16.5625 2.375H0.9375C0.390625 2.375 0 1.98438 0 1.4375ZM0 7.6875C0 7.17969 0.390625 6.75 0.9375 6.75H16.5625C17.0703 6.75 17.5 7.17969 17.5 7.6875C17.5 8.23438 17.0703 8.625 16.5625 8.625H0.9375C0.390625 8.625 0 8.23438 0 7.6875ZM16.5625 14.875H0.9375C0.390625 14.875 0 14.4844 0 13.9375C0 13.4297 0.390625 13 0.9375 13H16.5625C17.0703 13 17.5 13.4297 17.5 13.9375C17.5 14.4844 17.0703 14.875 16.5625 14.875Z" fill="#9DB6EB"/></svg>',
            MIStudioIcon:
                '<svg height="17.9" viewBox="0 0 18 17.9" width="18" xmlns="http://www.w3.org/2000/svg"><g fill="#9db6ea"><path d="m17.94 9.62c-.08 1.15-.39 2.24-.91 3.28-.03.05-.05.12-.1.12-.08 0-.09-.08-.12-.14-.67-1.43-1.34-2.87-2.01-4.31-.43-.92-.86-1.85-1.29-2.76-.84-1.8-1.69-3.6-2.53-5.4-.03-.06-.12-.14-.04-.22.06-.06.14 0 .21.01 1.71.44 3.18 1.28 4.39 2.57 1.32 1.41 2.12 3.07 2.36 4.99.02.14.01.28.1.41v.22c-.08.07-.05.17-.05.25v.99z"/><path d="m8.16 17.89s0-.07-.01-.1c.6.07 1.2.06 1.79 0-.02.04-.03.07-.05.11h-1.73z"/><path d="m17.94 9.62v-.99c0-.08-.03-.18.05-.25v1.24h-.06z"/><path d="m2.92 11.58c-.92.02-1.76-.22-2.52-.75-.15-.1-.23-.22-.26-.41-.32-1.67-.11-3.28.51-4.84.63-1.61 1.67-2.91 3.05-3.93.1-.08.15-.1.22.04.75 1.62 1.5 3.23 2.24 4.85.45 1 .57 2.03.08 3.05-.66 1.39-1.83 1.97-3.32 1.97z"/><path d="m9.94 17.79c-.6.06-1.2.08-1.79 0-.95-.03-1.85-.3-2.71-.67-1.22-.52-2.26-1.29-3.15-2.27-.08-.08-.16-.17-.21-.28 2.87-.21 4.82-1.65 5.79-4.42.23.5.46.97.69 1.45.48 1.04.96 2.07 1.45 3.11.41.87.81 1.74 1.23 2.61.08.16.07.24-.12.28-.39.08-.77.19-1.17.18v.02z"/><path d="m7.7 0c.13-.03.15.08.19.16.56 1.19 1.11 2.38 1.67 3.57.44.95.89 1.91 1.34 2.86 1.34 2.87 2.69 5.75 4.04 8.63.11.23.08.37-.13.51-.25.17-.5.36-.73.55-.18.15-.28.15-.39-.09-.5-1.1-1.02-2.19-1.52-3.28-1.39-2.96-2.77-5.93-4.16-8.9-.53-1.14-1.06-2.28-1.61-3.42-.1-.21-.06-.28.15-.33.39-.08.77-.17 1.16-.26z"/></g></svg>',
            menuOpen: false,
        }
    },
    methods: {
        openMenu() {
            // console.log('Menu Opened');
            this.menuOpen = true // Toggle menu state
        },
        closeMenu() {
            // console.log('Menu Closed');
            this.menuOpen = false // Close the menu
        },
    },
}
</script>
