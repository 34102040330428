<template>
    <svg
        width="646"
        height="722"
        viewBox="0 0 646 722"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g
            opacity="0.3"
            clip-path="url(#clip0_4388_1439)">
            <path
                d="M774.055 223.105C802.556 162.349 701.517 3.53789 634.082 60.3983C566.646 117.259 519.913 139.47 445.907 98.5468C371.901 57.6231 350.625 11.7225 294.121 36.5471C237.617 61.3716 306.048 72.8321 251.488 119.651C188.249 173.918 131.784 52.1866 81.3714 132.055C30.9588 211.924 56.0378 283.437 104.504 312.572C269.386 411.689 82.2811 469.759 66.289 533.496C50.297 597.232 -7.68859 647.663 84.6301 648.276C209.841 603.813 280.35 630.085 344.881 657.872C409.413 685.659 467.967 714.962 569.267 676.561C757.35 609.714 866.355 564.125 846.801 338.304C829.402 251.577 729.611 317.849 774.055 223.105Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M834.761 338.992C865.924 556.777 747.217 600.673 565.264 667.428C466.588 703.63 410.144 676.249 347.522 649.289C284.886 622.325 222.084 618.476 95.0067 639.889C6.70405 654.768 61.6693 590.337 77.1602 528.5C92.6377 466.639 274.055 410.264 114.166 314.085C67.1653 285.768 42.8557 216.357 91.7636 138.848C140.67 61.3439 195.458 179.336 256.826 126.673C309.757 81.2514 243.427 70.1199 298.249 46.0331C353.087 21.9392 373.772 66.4354 445.596 106.116C517.44 145.794 562.826 124.27 628.275 69.1606C693.749 14.0633 791.784 168.13 764.182 227.125C721.106 319.047 822.603 254.028 834.761 338.992Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M822.72 339.681C852.926 550.872 737.792 593.589 561.262 658.296C465.605 693.358 410.876 666.839 350.163 640.707C289.421 614.565 228.521 610.801 105.383 631.502C19.8096 645.889 73.0412 583.442 88.0311 523.506C102.994 463.519 278.725 408.839 123.828 315.599C78.2924 288.1 54.7523 220.79 102.156 145.643C149.556 70.5023 202.666 184.754 262.164 133.696C313.465 89.6716 249.236 78.8692 302.377 55.5201C355.548 32.1568 375.642 75.2487 445.286 113.687C514.966 152.119 559.005 131.282 622.468 77.924C685.98 24.5896 781.012 173.912 754.309 231.146C712.601 320.247 810.94 257.316 822.72 339.681Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M810.68 340.369C839.929 544.966 728.367 586.505 557.259 649.163C464.622 683.085 411.607 657.428 352.803 632.123C293.957 606.805 234.958 603.124 115.759 623.115C32.9151 637.009 84.4133 576.547 98.902 518.511C113.35 460.398 283.394 407.414 133.491 317.112C89.4197 290.431 66.6489 225.223 112.548 152.436C158.442 79.6596 209.875 190.172 267.502 140.717C317.174 98.0909 255.046 87.6176 306.505 65.0062C358.01 42.3735 377.513 84.061 444.975 121.257C512.493 158.443 555.184 138.293 616.661 86.6863C678.211 35.115 770.24 179.693 744.435 235.166C704.096 321.446 799.277 260.603 810.68 340.369Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M798.64 341.057C826.932 539.06 718.942 579.421 553.256 640.03C463.638 672.812 412.338 648.017 355.444 623.54C298.493 599.045 241.394 595.448 126.136 614.728C46.0206 628.13 95.7852 569.652 109.773 513.516C123.707 457.278 288.064 405.989 143.153 318.625C100.547 292.762 78.5455 229.656 122.939 159.229C167.328 88.817 217.083 195.589 272.84 147.739C320.882 106.51 260.855 96.3659 310.633 74.4923C360.472 52.5901 379.383 92.8733 444.664 128.826C510.019 164.766 551.363 145.304 610.855 95.4486C670.442 45.6404 759.468 185.475 734.562 239.186C695.591 322.645 787.613 263.89 798.64 341.057Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M786.599 341.747C813.934 533.155 709.518 572.338 549.254 630.898C462.655 662.54 413.07 638.607 358.085 614.957C303.028 591.285 247.831 587.773 136.512 606.342C59.1261 619.251 107.157 562.758 120.644 508.522C134.063 454.158 292.733 404.564 152.815 320.139C111.674 295.095 90.4421 234.089 133.331 166.023C176.214 97.9754 224.291 201.008 278.178 154.762C324.591 114.93 266.665 105.115 314.762 83.9793C362.934 62.8078 381.254 101.687 444.353 136.397C507.545 171.091 547.542 152.316 605.048 104.212C662.673 56.1668 748.696 191.257 724.688 243.207C687.087 323.845 775.95 267.178 786.599 341.747Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M774.559 342.435C800.937 527.249 700.093 565.254 545.251 621.765C461.672 652.268 413.801 629.196 360.726 606.374C307.564 583.525 254.268 580.097 146.888 597.955C72.2317 610.371 118.529 555.863 131.515 503.526C144.42 451.037 297.204 403.467 162.477 321.652C122.742 297.522 102.339 238.522 143.723 172.817C185.1 107.133 231.5 206.426 283.515 161.783C328.299 123.35 272.475 113.864 318.89 93.4654C365.396 73.0245 383.124 110.499 444.042 143.966C505.072 177.415 543.721 159.327 599.241 112.974C654.904 66.6923 738.031 197.087 714.815 247.227C678.748 325.121 764.287 270.465 774.559 342.435Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M762.518 343.124C787.939 521.344 690.668 558.171 541.248 612.633C460.689 641.996 414.533 619.786 363.366 597.792C312.1 575.766 260.705 572.422 157.265 589.569C85.3373 601.493 129.901 548.969 142.386 498.532C154.776 447.918 301.841 402.097 172.14 323.166C133.86 299.871 114.235 242.956 154.115 179.611C193.985 116.291 238.708 211.844 288.853 168.806C332.008 131.77 278.284 122.613 323.018 102.952C367.857 83.2422 384.995 119.312 443.731 151.537C502.598 183.74 539.9 166.339 593.434 121.738C647.135 77.2187 727.277 202.878 704.941 251.248C670.27 326.333 752.623 273.753 762.518 343.124Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M750.478 343.812C774.942 515.438 681.243 551.087 537.246 603.5C459.706 631.723 415.264 610.376 366.007 589.208C316.635 568.005 267.141 564.745 167.641 581.182C98.4428 592.613 141.273 542.073 153.256 493.537C165.132 444.797 306.477 400.725 181.802 324.679C144.977 302.218 126.132 247.388 164.507 186.404C202.871 125.449 245.916 217.262 294.191 175.828C335.716 140.189 284.094 131.361 327.146 112.439C370.319 93.4589 386.865 128.125 443.42 159.107C500.125 190.064 536.08 173.35 587.627 130.5C639.366 87.7441 716.523 208.667 695.068 255.268C661.793 327.544 740.96 277.04 750.478 343.812Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M738.438 344.5C761.945 509.532 671.819 544.004 533.243 594.367C458.723 621.45 415.996 600.965 368.648 580.625C321.172 560.245 273.579 557.069 178.018 572.795C111.549 583.733 152.646 535.178 164.128 488.542C175.489 441.676 311.114 399.354 191.465 326.193C156.095 304.565 138.029 251.821 174.9 193.197C211.758 134.606 253.125 222.679 299.529 182.849C339.425 148.609 289.904 140.11 331.275 121.925C372.781 103.676 388.736 136.937 443.11 166.676C497.651 196.387 532.259 180.361 581.821 139.262C631.598 98.2696 705.769 214.456 685.195 259.288C653.316 328.755 729.297 280.327 738.438 344.5Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M726.398 345.189C748.947 503.627 662.394 536.921 529.241 585.235C457.74 611.179 416.727 591.555 371.289 572.043C325.707 552.486 280.015 549.394 188.394 564.409C124.654 574.855 164.11 528.307 174.999 483.548C185.938 438.579 315.75 397.983 201.127 327.707C167.212 306.913 149.926 256.255 185.291 199.992C220.643 143.765 260.334 228.098 304.867 189.872C343.134 157.029 295.713 148.859 335.403 131.412C375.243 113.893 390.607 145.75 442.799 174.247C495.178 202.712 528.438 187.373 576.014 148.026C623.829 108.796 695.015 220.246 675.322 263.309C644.838 329.968 717.634 283.615 726.398 345.189Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M714.357 345.878C735.95 497.721 652.97 529.837 525.238 576.102C456.757 600.906 417.458 582.144 373.929 563.46C330.243 544.725 286.452 541.718 198.77 556.022C137.76 565.975 175.491 521.414 185.87 478.553C196.304 435.461 320.386 396.611 210.789 329.22C178.329 309.26 161.822 260.688 195.683 206.785C229.529 152.922 267.542 233.516 310.205 196.894C346.842 165.448 301.523 157.607 339.531 140.898C377.705 124.11 392.477 154.563 442.488 181.817C492.704 209.036 524.533 194.281 570.207 156.788C615.975 119.218 684.26 226.035 665.448 267.329C636.36 331.178 705.971 286.902 714.357 345.878Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M702.317 346.566C722.952 491.815 643.545 522.753 521.235 566.969C455.774 590.634 418.19 572.734 376.57 554.876C334.779 536.965 292.889 534.042 209.146 547.635C150.866 557.095 186.872 514.521 196.74 473.558C206.67 432.342 325.023 395.239 220.451 330.733C189.446 311.607 173.719 265.12 206.075 213.579C238.415 162.079 274.75 238.933 315.543 203.916C350.551 173.868 307.332 166.356 343.659 150.384C380.166 134.327 394.348 163.375 442.177 189.386C490.231 215.36 520.704 201.283 564.4 165.551C608.199 129.734 673.506 231.824 655.575 271.35C627.883 332.389 694.307 290.189 702.317 346.566Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M690.276 347.254C709.955 485.909 634.121 515.67 517.233 557.836C454.791 580.362 418.921 563.323 379.211 546.293C339.314 529.205 299.326 526.366 219.523 539.248C163.971 548.216 198.253 507.628 207.611 468.563C217.035 429.224 329.659 393.866 230.113 332.246C200.563 313.954 185.615 269.553 216.467 220.372C247.301 171.237 281.958 244.351 320.881 210.938C354.259 182.287 313.142 175.104 347.787 159.87C382.628 144.544 396.218 172.188 441.866 196.956C487.757 221.684 516.876 208.285 558.593 174.313C600.423 140.25 662.752 237.613 645.701 275.37C619.404 333.6 682.644 293.477 690.276 347.254Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M678.236 347.944C696.957 480.004 624.696 508.587 513.23 548.705C453.808 570.091 419.653 553.913 381.851 537.711C343.85 521.446 305.763 518.691 229.899 530.862C177.077 539.337 209.634 500.736 218.482 463.569C227.401 426.106 334.295 392.494 239.776 333.761C211.679 316.302 197.512 273.987 226.859 227.166C256.187 180.396 289.167 249.77 326.218 217.96C357.968 190.708 318.951 183.854 351.915 169.358C385.09 154.762 398.089 181.001 441.555 204.527C485.283 228.009 513.048 215.288 552.787 183.077C592.646 150.767 651.998 243.402 635.828 279.391C610.926 334.811 670.981 296.765 678.236 347.944Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M666.195 348.632C683.959 474.098 615.272 501.504 509.227 539.572C452.825 559.819 420.384 544.503 384.492 529.128C348.385 513.686 312.2 511.014 240.275 522.475C190.182 530.458 221.015 493.843 229.353 458.574C237.766 422.987 338.931 391.12 249.438 335.274C222.796 318.649 209.409 278.42 237.251 233.96C265.073 189.553 296.375 255.188 331.556 224.982C361.676 199.127 324.761 192.602 356.043 178.844C387.552 164.978 399.959 189.814 441.244 212.097C482.81 234.333 509.22 222.29 546.98 191.839C584.87 161.283 641.244 249.191 625.954 283.411C602.448 336.022 659.317 300.052 666.195 348.632Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M654.155 349.32C670.962 468.192 605.848 494.421 505.225 530.439C451.842 549.547 421.115 535.092 387.133 520.544C352.921 505.925 318.637 503.338 250.652 514.088C203.288 521.578 232.396 486.95 240.224 453.579C248.132 419.869 343.568 389.746 259.1 336.787C233.913 320.995 221.305 282.853 247.643 240.753C273.959 198.711 303.583 260.605 336.894 232.004C365.385 207.546 330.57 201.351 360.171 188.33C390.014 175.195 401.786 198.708 440.933 219.667C480.292 240.739 505.392 229.291 541.173 200.602C577.094 171.799 630.489 254.979 616.081 287.431C593.97 337.231 647.654 303.339 654.155 349.32Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M642.115 350.009C657.964 462.287 596.424 487.338 501.222 521.307C450.86 539.276 421.847 525.682 389.773 511.962C357.457 498.166 325.074 495.663 261.028 505.702C216.394 512.699 243.777 480.057 251.095 448.584C258.497 416.75 348.204 388.371 268.762 338.301C245.029 323.342 233.202 287.286 258.035 247.547C282.844 207.869 310.792 266.024 342.232 239.027C369.093 215.967 336.38 210.1 364.299 197.817C392.475 185.413 403.654 207.526 440.622 227.237C477.816 247.068 501.564 236.294 535.366 209.365C569.319 182.316 619.735 260.768 606.207 291.452C585.491 338.442 635.991 306.627 642.115 350.009Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M630.075 350.697C644.967 456.381 587.001 480.255 497.22 512.174C449.878 529.005 422.578 516.271 392.414 503.379C361.993 490.405 331.511 487.986 271.405 497.315C229.5 503.819 255.158 473.164 261.966 443.589C268.863 413.631 352.841 386.995 278.425 339.814C256.146 325.688 245.099 291.719 268.427 254.34C291.731 217.026 318.001 271.441 347.57 246.048C372.802 224.386 342.19 218.848 368.428 207.303C394.937 195.629 405.522 216.343 440.312 234.807C475.34 253.397 497.737 243.295 529.56 218.127C561.543 192.832 608.981 266.555 596.334 295.472C577.012 339.651 624.328 309.914 630.075 350.697Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M618.034 351.386C631.969 450.476 577.577 473.173 493.217 503.042C448.895 518.734 423.31 506.861 395.055 494.796C366.528 482.646 337.948 480.311 281.781 488.929C242.605 494.94 266.538 466.271 272.837 438.595C279.228 410.513 357.477 385.619 288.087 341.328C267.262 328.035 256.995 296.153 278.819 261.135C300.616 226.185 325.209 276.86 352.908 253.071C376.511 232.806 347.999 227.598 372.556 216.79C397.399 205.847 407.39 225.161 440.001 242.377C472.864 259.727 493.91 250.298 523.753 226.891C553.768 203.348 598.227 272.344 586.46 299.493C568.532 340.86 612.664 313.202 618.034 351.386Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M605.994 352.075C618.971 444.57 568.153 466.09 489.214 493.909C447.913 508.463 424.041 497.45 397.695 486.213C371.064 474.886 344.385 472.634 292.157 480.542C255.711 486.06 277.918 459.377 283.708 433.6C289.593 407.394 362.113 384.241 297.749 342.841C278.377 330.381 268.892 300.586 289.211 267.928C309.502 235.342 332.417 282.277 358.245 260.093C380.219 241.226 353.809 236.346 376.684 226.276C399.861 216.064 409.258 233.978 439.69 249.947C470.388 266.056 490.083 257.299 517.946 235.653C545.993 213.864 587.473 278.131 576.587 303.513C560.052 342.068 601.001 316.489 605.994 352.075Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M593.953 352.763C605.973 438.664 558.73 459.008 485.211 484.776C446.931 498.193 424.772 488.04 400.336 477.63C375.6 467.126 350.822 464.958 302.533 472.155C268.817 477.18 289.298 452.484 294.578 428.605C299.958 404.275 366.749 382.861 307.411 344.354C289.493 332.727 280.788 305.018 299.603 274.721C318.388 244.5 339.625 287.695 363.583 267.114C383.927 249.645 359.618 245.095 380.812 235.762C402.322 226.281 411.127 242.796 439.378 257.517C467.913 272.385 486.256 264.301 512.139 244.416C538.218 224.379 576.718 283.917 566.713 307.534C551.572 343.275 589.337 319.776 593.953 352.763Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M581.913 353.451C592.975 432.759 549.308 451.926 481.209 475.643C445.949 487.923 425.504 478.629 402.977 469.047C380.136 459.366 357.26 457.282 312.91 463.768C281.923 468.3 300.679 445.59 305.45 423.61C310.324 401.155 371.386 381.479 317.074 345.868C300.608 335.072 292.685 309.451 309.995 281.515C327.274 253.657 346.834 293.113 368.921 274.136C387.636 258.065 365.428 253.843 384.94 245.249C404.784 236.498 412.995 251.613 439.068 265.087C465.437 278.714 482.43 271.303 506.332 253.178C530.444 234.895 565.964 289.703 556.84 311.554C543.091 344.482 577.674 323.063 581.913 353.451Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M569.872 354.14C579.977 426.853 539.886 444.845 477.206 466.511C444.968 477.654 426.235 469.219 405.618 460.464C384.671 451.606 363.697 449.605 323.286 455.382C295.029 459.421 312.058 438.696 316.32 418.615C320.688 398.036 376.022 380.095 326.736 347.381C311.723 337.417 304.582 313.885 320.387 288.309C336.16 262.815 354.042 298.531 374.259 281.159C391.345 266.484 371.238 262.592 389.068 254.735C407.246 246.715 414.864 260.43 438.757 272.657C462.962 285.043 478.604 278.305 500.525 261.941C522.67 245.411 555.21 295.489 546.967 315.575C534.609 345.687 566.011 326.35 569.872 354.14Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M557.832 354.829C566.979 420.948 530.464 437.764 473.204 457.378C443.987 467.386 426.967 459.809 408.258 451.881C389.207 443.846 370.135 441.929 333.663 446.995C308.135 450.541 323.438 431.802 327.191 413.621C331.053 394.916 380.659 378.708 336.398 348.895C322.837 339.761 316.478 318.318 330.779 295.103C345.046 271.973 361.251 303.949 379.597 288.181C395.053 274.904 377.047 271.341 393.197 264.222C409.708 256.932 416.733 269.248 438.446 280.227C460.487 291.372 474.779 285.307 494.719 270.704C514.897 255.927 544.455 301.273 537.093 319.595C526.125 346.892 554.347 329.638 557.832 354.829Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M545.792 355.517C553.98 415.042 521.044 430.684 469.201 448.246C443.007 457.119 427.698 450.398 410.899 443.298C393.743 436.086 376.573 434.252 344.039 438.608C321.241 441.661 334.817 424.907 338.062 408.626C341.418 391.795 385.296 377.315 346.061 350.408C333.951 342.103 328.375 322.751 341.171 301.896C353.932 281.131 368.459 309.367 384.935 295.203C398.762 283.324 382.857 280.089 397.325 273.708C412.17 267.149 418.602 278.064 438.135 287.797C458.012 297.7 470.955 292.308 488.912 279.467C507.125 266.441 533.701 307.056 527.22 323.615C517.641 348.093 542.684 332.925 545.792 355.517Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M533.751 356.206C540.981 409.136 511.624 423.605 465.198 439.113C442.027 446.853 428.429 440.987 413.54 434.715C398.278 428.326 383.011 426.575 354.415 430.222C334.347 432.78 346.194 418.012 348.933 403.631C351.782 388.674 389.933 375.917 355.723 351.922C345.063 344.445 340.272 327.184 351.562 308.69C362.817 290.289 375.667 314.785 390.272 302.225C402.47 291.743 388.666 288.838 401.453 283.195C414.631 277.366 420.472 286.881 437.824 295.367C455.537 304.029 467.132 299.31 483.105 288.229C499.354 276.956 522.945 312.837 517.346 327.636C509.153 349.292 531.02 336.212 533.751 356.206Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M521.711 356.894C527.981 403.23 502.206 416.528 461.196 429.98C441.048 436.589 429.161 431.577 416.18 426.132C402.814 420.565 389.45 418.898 364.791 421.835C347.454 423.9 357.571 411.115 359.804 398.636C362.145 385.552 394.572 374.509 365.385 353.435C356.174 346.784 352.168 331.617 361.954 315.483C371.703 299.446 382.875 320.203 395.61 309.246C406.179 300.163 394.476 297.587 405.581 292.681C417.093 287.583 422.342 295.697 437.513 302.936C453.063 310.357 463.311 306.312 477.298 296.992C491.585 287.471 512.19 318.615 507.473 331.656C500.662 350.487 519.357 339.499 521.711 356.894Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M509.671 357.583C514.982 397.325 492.791 409.454 457.193 420.848C440.071 426.329 429.892 422.167 418.821 417.55C407.35 412.806 395.891 411.221 375.168 413.449C360.562 415.019 368.946 404.219 370.675 393.642C372.508 382.429 399.213 373.087 375.048 354.949C367.283 349.121 364.065 336.05 372.347 322.277C380.59 308.605 390.084 325.621 400.949 316.269C409.888 308.583 400.286 306.336 409.709 302.168C419.555 297.8 424.214 304.513 437.203 310.507C450.59 316.685 459.493 313.316 471.491 305.755C483.82 297.986 501.434 324.389 497.6 335.677C492.165 351.678 507.694 342.787 509.671 357.583Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M497.63 358.271C501.98 391.42 483.378 402.384 453.191 411.715C439.096 416.072 430.624 412.756 421.462 408.966C411.885 405.046 402.332 403.542 385.544 405.062C373.67 406.137 380.318 397.319 381.546 388.647C382.868 379.303 403.857 371.64 384.709 356.462C378.389 351.452 375.961 340.483 382.738 329.071C389.475 317.762 397.292 331.039 406.286 323.291C413.596 317.002 406.095 315.084 413.837 311.654C422.017 308.017 426.086 313.327 436.891 318.077C448.119 323.012 455.68 320.319 465.684 314.517C476.059 308.501 490.675 330.157 487.726 339.697C483.658 352.858 496.03 346.074 497.63 358.271Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M485.59 358.96C488.976 385.514 473.973 395.322 449.188 402.582C438.125 405.823 431.362 403.329 424.103 400.383C416.428 397.269 408.777 395.861 395.921 396.675C386.78 397.253 391.685 390.416 392.417 383.652C393.226 376.173 408.514 370.151 394.372 357.976C389.49 353.773 387.858 344.916 393.131 335.864C398.361 326.92 404.501 336.457 411.624 330.313C417.305 325.422 411.905 323.833 417.966 321.14C424.479 318.234 427.961 322.139 436.581 325.647C445.65 329.337 451.877 327.326 459.878 323.28C468.308 319.017 479.911 335.914 477.853 343.717C475.134 354.024 484.366 349.361 485.59 358.96Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M473.549 359.649C475.968 379.609 464.577 388.28 445.185 393.45C437.16 395.59 432.092 393.92 426.743 391.801C420.963 389.51 415.23 388.179 406.297 388.289C399.894 388.367 403.04 383.509 403.288 378.658C403.574 373.037 413.201 368.575 404.034 359.49C400.586 356.073 399.755 349.35 403.522 342.658C407.247 336.078 411.709 341.875 416.962 337.335C421.013 333.842 417.714 332.582 422.093 330.627C426.94 328.452 429.84 330.946 436.27 333.217C443.185 335.659 448.09 334.345 454.071 332.043C460.575 329.541 469.132 341.653 467.979 347.738C466.572 355.166 472.701 352.648 473.549 359.649Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M461.509 360.337C462.946 373.704 455.206 381.296 441.183 384.318C436.206 385.39 432.822 384.513 429.384 383.218C425.498 381.753 421.71 380.491 416.673 379.902C413.025 379.475 414.364 376.592 414.159 373.663C413.894 369.89 418.007 366.788 413.696 361.003C411.691 358.313 411.652 353.783 413.914 349.452C416.133 345.236 418.917 347.293 422.3 344.357C424.721 342.262 423.524 341.331 426.222 340.114C429.402 338.669 431.729 339.74 435.959 340.787C440.73 341.968 444.34 341.404 448.264 340.806C452.88 340.102 459.178 347.485 458.106 351.759C457.008 356.135 461.036 355.934 461.509 360.337Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
            <path
                d="M437.18 375.185C428.484 375.81 423.745 369.287 423.358 362.517C422.972 355.746 426.936 348.727 435.648 348.357C444.343 347.733 449.082 354.255 449.468 361.026C449.855 367.797 445.891 374.816 437.18 375.185Z"
                stroke="#9DB6EB"
                stroke-miterlimit="10" />
        </g>
        <defs>
            <clipPath id="clip0_4388_1439">
                <rect
                    width="674.789"
                    height="829.707"
                    fill="white"
                    transform="translate(828.355) rotate(86.7289)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'BackgroundBlueLight',
}
</script>

<style></style>
