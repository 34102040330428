<template>
    <section
        class="w-full mx-auto relative bg-blue-lighter overflow-hidden mt-2">
        <!-- Static SVG Background -->
        <svg
            class="absolute top-1/2 -translate-y-1/2 -left-1/4 w-full max-h-96 z-0"
            viewBox="0 0 688 492"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g
                opacity="0.5"
                clip-path="url(#clip0_2415_4303)">
                <path
                    d="M-233.193 387.062C-271.725 453.474 -160.898 638.339 -75.712 578.802C9.47378 519.264 67.3236 497.316 153.693 548.033C240.062 598.75 262.541 651.672 332.346 627.416C402.151 603.16 320.471 585.894 389.448 536.809C469.398 479.915 529.062 620.271 595.318 533.798C661.573 447.324 636.275 365.39 579.874 329.554C387.995 207.639 617.482 154.425 641.145 83.8621C664.807 13.2993 738.162 -39.6227 626.947 -46.2379C472.988 -4.33528 389.824 -38.3706 313.969 -73.7242C238.113 -109.078 169.566 -145.75 44.8399 -109.12C-186.431 -46.1115 -320.938 -1.90281 -312.927 252.998C-297.932 351.529 -173.105 283.497 -233.193 387.062Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-298.372 252.998C-320.927 6.37836 -174.845 -35.3052 49.0321 -98.6052C170.444 -132.934 236.581 -98.5545 310.193 -64.2529C383.821 -29.9453 459.241 -21.5904 613.862 -37.4837C721.304 -48.5276 650.625 20.3141 627.697 88.7749C604.784 157.265 382.267 208.94 568.331 327.234C623.023 362.057 647.539 441.582 583.259 525.5C518.981 609.415 461.082 473.367 383.497 528.579C316.579 576.2 395.75 592.961 328.022 616.496C260.276 640.038 238.412 588.732 154.586 539.55C70.7378 490.372 14.5589 511.635 -68.1129 569.332C-150.813 627.015 -258.348 447.672 -221.02 383.18C-162.775 282.697 -289.572 349.211 -298.372 252.998Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-283.812 252.997C-305.668 13.8455 -163.973 -26.744 53.2288 -88.0906C170.923 -121.332 235.053 -88.0318 306.421 -54.7823C377.823 -21.5208 450.957 -13.3825 600.781 -28.7303C704.9 -39.396 636.447 27.3278 614.254 93.6867C592.09 160.105 376.543 210.24 556.793 324.913C609.776 358.723 633.509 435.838 571.206 517.202C508.906 598.558 452.77 466.818 377.55 520.349C312.692 566.504 389.353 582.76 323.703 605.575C258.015 628.404 236.767 578.712 155.484 531.067C74.1564 483.426 19.6486 504.004 -60.5092 559.862C-140.723 615.691 -244.966 441.869 -208.842 379.297C-152.441 281.895 -275.288 346.267 -283.812 252.997Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-269.254 252.997C-290.411 21.3136 -153.102 -18.1822 57.4236 -77.5755C171.401 -109.731 233.524 -77.5086 302.648 -45.3111C371.822 -13.0956 442.671 -5.17385 587.699 -19.976C688.495 -30.2637 622.268 34.3422 600.809 98.5992C579.394 162.945 370.818 211.541 545.253 322.593C596.527 355.39 619.477 430.095 559.15 508.904C498.828 587.701 444.456 460.27 371.601 512.119C308.803 556.809 382.954 572.561 319.381 594.655C255.752 616.77 235.119 568.694 156.38 522.585C77.573 476.482 24.7362 496.374 -52.9076 550.393C-130.635 604.367 -231.586 436.067 -196.666 375.416C-142.109 281.094 -261.005 343.324 -269.254 252.997Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-254.696 252.996C-275.154 28.7805 -142.231 -9.62155 61.6184 -67.0615C171.879 -98.1302 231.994 -66.9863 298.874 -35.8408C365.822 -4.67141 434.385 3.03372 574.616 -11.2229C672.089 -21.1324 608.088 41.3559 587.364 103.511C566.698 165.784 365.092 212.842 533.713 320.272C583.278 352.057 605.446 424.352 547.094 500.606C488.75 576.844 436.142 453.722 365.651 503.888C304.913 547.113 376.556 562.361 315.06 583.734C253.489 605.135 233.472 558.675 157.277 514.101C80.9898 469.537 29.8239 488.744 -45.3059 540.922C-120.548 593.042 -218.205 430.264 -184.49 371.533C-131.777 280.293 -246.723 340.379 -254.696 252.996Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-240.138 252.996C-259.897 36.2486 -131.361 -1.05974 65.8132 -56.5464C172.356 -86.5285 230.465 -56.463 295.101 -26.3695C359.821 3.7538 426.1 11.2425 561.534 -2.46855C655.684 -12 593.908 48.3705 573.919 108.424C554.003 168.624 359.366 214.143 522.173 317.952C570.029 348.724 591.414 418.609 535.038 492.308C478.672 565.988 427.828 447.174 359.702 495.659C301.024 537.418 370.157 552.161 310.738 572.814C251.226 593.502 231.825 548.656 158.173 505.619C84.4065 462.593 34.9116 481.114 -37.7041 531.453C-110.46 581.719 -204.825 424.461 -172.314 367.651C-121.444 279.492 -232.441 337.436 -240.138 252.996Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-225.58 252.996C-244.64 43.7166 -120.491 7.50202 70.0081 -46.0312C172.834 -74.9269 228.935 -45.9396 291.327 -16.8981C353.82 12.1792 417.814 19.451 548.451 6.28558C639.278 -2.8678 579.729 55.3851 560.475 113.336C541.307 171.465 353.903 215.088 510.633 315.632C556.857 345.286 577.383 412.866 522.983 484.011C468.594 555.131 419.514 440.626 353.753 487.429C297.135 527.723 363.759 541.962 306.417 561.894C248.963 581.868 230.178 538.638 159.069 497.136C87.8233 455.648 39.9994 473.484 -30.1024 521.984C-100.372 570.395 -191.571 418.597 -160.138 363.77C-111.307 278.594 -218.158 334.492 -225.58 252.996Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-211.024 252.996C-229.384 51.1846 -109.622 16.0637 74.2009 -35.5161C173.309 -63.3254 227.404 -35.4163 287.552 -7.42686C347.818 20.6044 409.526 27.6597 535.367 15.0399C622.871 6.26463 565.547 62.3996 547.028 118.249C528.609 174.305 348.22 216.33 499.091 313.312C543.619 341.936 563.349 407.123 510.925 475.713C458.514 544.275 411.199 434.078 347.802 479.2C293.244 518.029 357.358 531.763 302.093 550.974C246.698 570.234 228.529 528.62 159.963 488.654C91.238 448.704 45.0851 465.854 -22.5026 512.514C-90.2863 559.071 -178.213 412.785 -147.964 359.888C-101.009 277.777 -203.878 331.549 -211.024 252.996Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-196.466 252.995C-214.127 58.6525 -98.7517 24.6253 78.3958 -25.001C173.786 -51.7239 225.875 -24.8931 283.778 2.04431C341.817 29.0295 401.24 35.8683 522.284 23.7939C606.465 15.3969 551.367 69.4141 533.583 123.161C515.914 177.145 342.538 217.573 487.551 310.992C530.383 338.586 549.317 401.381 498.869 467.415C448.436 533.419 402.885 427.53 341.853 470.97C289.355 508.334 350.959 521.563 297.772 540.054C244.435 558.601 226.882 518.601 160.86 480.171C94.6548 441.76 50.1728 458.225 -14.9009 503.045C-80.1985 547.748 -164.854 406.972 -135.788 356.006C-90.7087 276.961 -189.595 328.606 -196.466 252.995Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-181.908 252.994C-198.87 66.1196 -87.8814 33.1861 82.5906 -14.4868C174.264 -40.1234 224.345 -14.3707 280.005 11.5147C335.817 37.4538 392.954 44.0761 509.202 32.5473C590.06 24.5283 537.188 76.4279 520.138 128.073C503.218 179.984 336.856 218.815 476.011 308.672C517.147 335.235 535.286 395.637 486.814 459.117C438.359 522.561 394.571 420.981 335.904 462.739C285.465 498.638 344.561 511.363 293.45 529.133C242.172 546.966 225.235 508.582 161.756 471.688C98.0716 434.814 55.2606 450.594 -7.29914 493.575C-70.1107 536.423 -151.495 401.159 -123.612 352.124C-80.4085 276.144 -175.313 325.661 -181.908 252.994Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-167.35 252.994C-183.613 73.5875 -77.0112 41.7475 86.7854 -3.97181C174.741 -28.5222 222.816 -3.8476 276.231 20.9858C329.816 45.8789 384.669 52.2847 496.12 41.3015C573.654 33.6606 522.898 83.411 506.693 132.986C490.412 182.793 331.174 220.058 464.471 306.352C503.911 331.885 521.254 389.894 474.758 450.819C428.281 511.705 386.257 414.433 329.955 454.509C281.576 488.943 338.162 501.164 289.129 518.213C239.909 535.332 223.588 498.563 162.652 463.205C101.488 427.87 60.3483 442.964 0.302594 484.105C-60.0229 525.099 -138.136 395.347 -111.436 348.242C-70.1082 275.327 -161.03 322.718 -167.35 252.994Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-152.791 252.993C-168.354 81.0545 -66.139 50.308 90.9823 6.54232C175.22 -16.922 221.288 6.67483 272.46 30.4562C323.818 54.3032 376.385 60.4923 483.039 50.0546C557.251 42.7919 508.71 90.4216 493.25 137.897C477.707 185.629 325.494 221.3 452.933 304.031C490.677 328.534 507.224 384.15 462.704 442.521C418.205 500.848 377.946 407.884 324.008 446.279C277.689 479.247 331.765 490.963 284.809 507.292C237.648 523.697 221.943 488.544 163.55 454.722C104.907 420.925 65.5329 435.454 7.90632 474.635C-49.8384 513.896 -124.774 389.534 -99.2584 344.359C-59.8058 274.51 -146.746 319.773 -152.791 252.993Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-138.235 252.993C-153.099 88.5224 -55.2709 58.8694 95.1751 17.0574C175.696 -5.32093 219.757 17.198 268.684 39.9273C317.815 62.7283 368.097 68.701 469.955 58.8088C540.843 51.9243 494.517 97.4332 479.803 142.81C464.998 188.467 319.811 222.544 441.391 301.711C477.44 325.184 493.191 378.408 450.646 434.223C408.125 489.992 369.63 401.335 318.057 438.049C273.798 469.552 325.365 480.764 280.486 496.372C235.383 512.064 220.294 478.526 164.444 446.239C108.322 413.98 70.627 427.835 15.5061 465.165C-39.7443 502.583 -111.417 383.722 -87.0845 340.477C-49.5073 273.695 -132.465 316.83 -138.235 252.993Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-123.677 252.992C-137.841 95.9902 -44.4009 67.4305 99.37 27.5723C176.173 6.27985 218.227 27.721 264.911 49.3983C311.815 71.1533 359.811 76.9094 456.872 67.5627C524.438 61.0564 480.327 104.445 466.358 147.722C452.292 191.304 314.129 223.788 429.851 299.39C464.204 321.834 479.159 372.665 438.591 425.925C398.047 479.135 361.316 394.787 312.108 429.819C269.908 459.857 318.966 470.564 276.165 485.451C233.12 500.43 218.647 468.507 165.341 437.756C111.739 407.036 75.7229 420.216 23.1079 455.696C-29.6484 491.27 -98.0579 377.91 -74.9084 336.596C-39.2066 272.879 -118.183 313.886 -123.677 252.992Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-109.119 252.992C-122.584 103.458 -33.531 75.9916 103.565 38.0872C176.65 17.8805 216.698 38.2441 261.138 58.8694C305.814 79.5783 351.525 85.1181 443.79 76.3168C508.032 70.1887 466.137 111.456 452.914 152.635C439.585 194.141 308.447 225.033 418.311 297.07C450.968 318.484 465.127 366.922 426.535 417.628C387.969 468.279 353.002 388.239 306.159 421.589C266.019 450.162 312.568 460.365 271.843 474.531C230.857 488.796 216.999 458.489 166.237 429.274C115.155 400.091 80.8187 412.598 30.7095 446.226C-19.5527 479.958 -84.6989 372.099 -62.7326 332.714C-28.9057 272.063 -103.9 310.943 -109.119 252.992Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-94.5606 252.992C-107.327 110.926 -22.6611 84.5527 107.76 48.6022C177.127 29.4811 215.168 48.7673 257.364 68.3405C299.814 88.0033 343.239 93.3266 430.707 85.0708C491.626 79.3209 451.947 118.468 439.469 157.547C426.879 196.978 302.765 226.278 406.771 294.75C437.733 315.134 451.096 361.179 414.479 409.33C377.892 457.422 344.689 381.691 300.21 413.36C262.13 440.467 306.169 450.165 267.522 463.611C228.594 477.162 215.352 448.47 167.133 420.791C118.572 393.147 85.9145 404.979 38.3114 436.757C-9.45683 468.645 -71.3395 366.287 -50.5565 328.832C-18.6046 271.248 -89.6177 307.999 -94.5606 252.992Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-80.0027 252.991C-92.0693 118.394 -11.7915 93.1135 111.955 59.1171C177.604 41.0814 213.639 59.2904 253.591 77.8117C293.813 96.4284 334.953 101.535 417.625 93.8248C475.221 88.4531 437.756 125.479 426.024 162.459C414.172 199.816 297.083 227.524 395.231 292.43C424.498 311.784 437.064 355.436 402.424 401.032C367.814 446.565 336.375 375.143 294.261 405.13C258.241 430.772 299.77 439.966 263.2 452.691C226.332 465.528 213.764 438.363 168.029 412.308C122.048 386.113 91.0101 397.36 45.9132 427.287C0.638746 457.332 -57.9803 360.476 -38.3805 324.95C-8.3032 270.433 -75.3351 305.056 -80.0027 252.991Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-65.4447 252.99C-76.8119 125.861 -0.921933 101.674 116.15 69.6314C178.081 52.6809 212.109 69.8129 249.817 87.2821C287.813 104.853 326.667 109.743 404.542 102.578C458.815 97.5849 423.566 132.491 412.579 167.371C401.466 202.653 291.401 228.77 383.691 290.109C411.263 308.434 423.033 349.692 390.368 392.734C357.736 435.708 328.061 368.594 288.312 396.899C254.352 421.076 293.372 429.766 258.879 441.77C224.069 453.894 212.12 428.338 168.926 403.825C125.468 379.162 96.1055 389.74 53.515 417.817C10.7342 446.019 -44.6211 354.664 -26.2045 321.068C1.99835 269.618 -61.0525 302.111 -65.4447 252.99Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-50.8886 252.99C-61.5563 133.329 9.94544 110.234 120.342 80.1464C178.556 64.2808 210.578 80.3362 246.042 96.7533C281.811 113.278 318.379 117.952 391.458 111.332C442.407 106.717 409.375 139.503 399.132 172.284C388.757 205.49 285.718 230.018 372.149 287.789C398.026 305.086 408.999 343.95 378.311 384.436C347.657 424.852 319.746 362.046 282.361 388.67C250.461 411.381 286.971 419.566 254.556 430.85C221.804 442.26 210.475 418.314 169.82 395.343C128.886 372.213 101.199 382.122 61.115 408.348C20.8275 434.707 -31.2637 348.854 -14.0303 317.186C12.2984 268.804 -46.7719 299.168 -50.8886 252.99Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-36.3306 252.99C-46.2987 140.797 20.8146 118.795 124.537 90.6616C179.032 75.8807 209.049 90.8595 242.269 106.225C275.81 121.703 310.093 126.161 378.376 120.087C426.002 115.85 395.185 146.515 385.688 177.197C376.051 208.328 280.036 231.267 360.609 285.469C384.791 301.737 394.968 338.207 366.255 376.139C337.579 413.996 311.432 355.498 276.412 380.44C246.572 401.686 280.573 409.367 250.234 419.93C219.541 430.626 208.831 408.291 170.717 386.86C132.306 365.263 106.294 374.503 68.7168 398.878C30.9224 423.395 -17.9045 343.044 -1.85428 313.304C22.6008 267.991 -32.4893 296.225 -36.3306 252.99Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-21.7725 252.99C-31.041 148.265 31.6834 127.355 128.732 101.177C179.509 87.48 207.519 101.383 238.495 115.696C269.81 130.128 301.807 134.37 365.293 128.841C409.596 124.982 380.995 153.527 372.243 182.109C363.345 211.166 274.354 232.517 349.07 283.149C371.557 298.388 380.936 332.464 354.2 367.841C327.501 403.139 303.118 348.95 270.463 372.21C242.683 391.991 274.174 399.167 245.913 409.01C217.278 418.992 207.187 398.267 171.613 378.378C135.726 358.313 111.388 366.884 76.3186 389.409C41.017 412.082 -4.54534 337.234 10.3217 309.423C32.9038 267.179 -18.2066 293.281 -21.7725 252.99Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M-7.2144 252.989C-15.7831 155.732 42.5519 135.914 132.927 111.691C179.985 99.0779 205.99 111.905 234.722 125.166C263.809 138.553 293.52 142.578 352.211 137.594C393.19 134.114 366.806 160.539 358.798 187.021C350.639 214.004 268.672 233.768 337.53 280.828C358.323 295.039 366.905 326.72 342.144 359.542C317.423 392.282 294.805 342.401 264.515 363.979C238.794 382.295 267.776 388.967 241.592 398.089C215.016 407.358 205.543 388.242 172.509 369.894C139.146 351.362 116.483 359.264 83.9206 379.938C51.1114 400.769 8.81407 331.424 22.498 305.54C43.2074 266.366 -3.92391 290.337 -7.2144 252.989Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M7.34373 252.988C-0.525104 163.199 53.4201 144.473 137.122 122.206C180.461 110.676 204.461 122.428 230.949 134.637C257.809 146.977 285.234 150.787 339.129 146.348C376.785 143.247 352.617 167.551 345.354 191.933C337.933 216.842 262.989 235.022 325.99 278.508C345.089 291.691 352.873 320.977 330.089 351.245C307.346 381.425 286.491 335.852 258.566 355.749C234.904 372.6 261.377 378.767 237.271 387.168C212.753 395.724 203.899 378.219 173.406 361.411C142.566 344.412 121.577 351.645 91.5225 370.469C61.2051 389.457 22.1733 325.615 34.674 301.658C53.5119 265.555 10.3588 287.393 7.34373 252.988Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M21.9019 252.989C14.7331 170.668 64.2877 153.033 141.318 132.721C180.937 122.274 202.932 132.952 227.176 144.109C251.809 155.403 276.948 158.996 326.047 155.103C360.379 152.38 338.429 174.565 331.909 196.846C325.227 219.681 257.307 236.28 314.45 276.189C331.856 288.345 338.842 315.235 318.033 342.947C297.268 370.57 278.178 329.305 252.617 347.52C231.015 362.906 254.979 368.568 232.949 376.249C210.49 384.09 202.254 368.196 174.302 352.929C145.985 337.463 126.67 344.027 99.1245 361C71.2984 378.145 35.5328 319.808 46.8503 297.776C63.8174 264.747 24.6417 284.45 21.9019 252.989Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M36.4602 252.988C29.9917 178.135 75.1547 161.59 145.513 143.235C181.412 133.87 201.402 143.474 223.402 153.579C245.809 163.828 268.661 167.205 312.965 163.856C343.973 161.512 324.241 181.578 318.464 201.758C312.522 222.52 251.624 237.54 302.91 273.868C318.624 284.998 324.811 309.491 305.978 334.649C287.191 359.713 269.864 322.756 246.668 339.29C227.127 353.21 248.581 358.368 228.628 365.328C208.228 372.456 200.61 358.172 175.199 344.446C149.404 330.513 131.763 336.408 106.727 351.53C81.3908 366.833 48.8923 314.001 59.0265 293.894C74.1245 263.939 38.9246 281.506 36.4602 252.988Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M51.0185 252.987C45.2506 185.602 86.0207 170.146 149.708 153.749C181.887 145.465 199.873 153.996 219.629 163.049C239.809 172.252 260.374 175.413 299.882 172.609C327.567 170.644 310.053 188.591 305.02 206.669C299.817 225.359 245.94 238.806 291.371 271.547C305.393 281.652 310.779 303.748 293.922 326.351C277.113 348.855 261.551 316.207 240.719 331.059C223.238 343.514 242.183 348.168 224.307 354.407C205.965 360.821 198.965 348.148 176.095 335.962C152.823 323.562 136.854 328.788 114.329 342.059C91.482 355.52 62.252 308.195 71.2029 290.011C84.4335 263.132 53.2076 278.562 51.0185 252.987Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M65.575 252.987C60.5081 193.07 96.8837 178.703 153.901 164.265C182.359 157.059 198.342 164.52 215.854 172.521C233.807 180.677 252.085 183.624 286.798 181.364C311.159 179.778 295.865 195.606 291.574 211.583C287.11 228.2 240.254 240.079 279.829 269.227C292.161 278.31 296.746 298.005 281.865 318.053C267.034 337.999 253.236 309.66 234.769 322.83C219.347 333.82 235.783 337.969 219.984 343.487C203.701 349.188 197.318 338.126 176.99 327.48C156.24 316.614 141.942 321.169 121.929 332.59C101.57 344.209 75.6102 302.393 83.3774 286.13C94.7435 262.331 67.4889 275.619 65.575 252.987Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M80.1335 252.987C75.7682 200.538 107.747 187.257 158.097 174.78C182.833 168.65 196.814 175.043 212.082 181.992C227.807 189.102 243.797 191.833 273.717 190.118C294.753 188.911 281.68 202.621 278.129 216.495C274.407 231.041 234.568 241.363 268.29 266.907C278.933 274.969 282.715 292.262 269.81 309.756C256.957 327.143 244.922 303.111 228.821 314.6C215.459 324.125 229.385 327.769 215.664 332.567C201.438 337.554 195.672 328.103 177.887 318.997C159.658 309.664 147.03 313.55 129.531 323.121C111.657 332.897 88.9708 296.594 95.554 282.248C105.06 261.534 81.7723 272.675 80.1335 252.987Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M94.6904 252.986C91.0276 208.006 118.606 195.808 162.291 185.295C183.303 180.238 195.283 185.566 208.307 191.463C221.805 197.527 235.506 200.044 260.633 198.872C278.344 198.045 267.495 209.638 264.683 221.407C261.702 233.884 228.876 242.663 256.749 264.587C265.705 271.631 268.683 286.519 257.754 301.458C246.878 316.286 236.608 296.563 222.87 306.37C211.568 314.429 222.985 317.57 211.341 321.646C199.174 325.92 194.023 318.081 178.782 310.515C163.074 302.716 152.112 305.929 137.132 313.651C121.739 321.585 102.331 290.798 107.729 278.366C115.381 260.743 96.0541 269.732 94.6904 252.986Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M109.248 252.985C106.289 215.474 129.461 204.353 166.485 195.809C183.771 191.82 193.753 196.089 204.533 200.934C215.804 205.952 227.213 208.255 247.55 207.625C261.934 207.18 253.314 216.657 251.238 226.319C249 236.73 223.178 243.989 245.208 262.266C252.481 268.3 254.65 280.776 245.697 293.16C236.799 305.429 228.293 290.014 216.921 298.14C207.678 304.734 216.586 307.37 207.019 310.726C196.911 314.286 192.373 308.061 179.678 302.032C166.487 295.768 157.189 298.307 144.733 304.181C131.815 310.273 115.693 285.01 119.904 274.484C125.713 259.963 110.336 266.788 109.248 252.985Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M123.806 252.985C121.553 222.941 140.311 212.888 170.68 206.324C184.235 203.394 192.214 206.63 200.76 210.405C209.794 214.396 218.918 216.467 234.468 216.379C245.523 216.316 239.14 223.681 237.793 231.232C236.303 239.58 217.464 245.362 233.668 259.946C239.262 264.98 240.619 275.033 233.641 284.862C226.722 294.573 219.979 283.466 210.972 289.91C203.789 295.039 210.187 297.17 202.697 299.805C194.648 302.652 190.72 298.042 180.574 293.549C169.899 288.821 162.256 290.68 152.335 294.711C141.882 298.959 129.062 279.235 132.08 270.602C136.066 259.2 124.62 263.845 123.806 252.985Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M138.363 252.985C136.823 230.41 151.147 221.402 174.874 216.839C184.693 214.951 190.685 217.152 196.985 219.876C204.629 223.213 212.944 225.005 221.384 225.134C229.105 225.456 224.979 230.711 224.347 236.144C223.615 242.439 211.706 246.833 222.128 257.626C226.047 261.685 226.586 269.29 221.585 276.564C216.643 283.716 211.665 276.918 205.022 281.68C199.899 285.344 203.788 286.971 198.375 288.886C192.384 291.018 189.061 288.03 181.469 285.066C173.304 281.88 167.302 283.041 159.936 285.242C151.925 287.636 142.447 273.482 144.255 266.72C146.463 258.467 138.903 260.902 138.363 252.985Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M152.922 252.985C152.11 237.879 161.961 229.849 179.069 227.354C183.891 226.602 188.845 227.3 193.213 229.347C198.01 231.465 203.082 232.991 208.303 233.888C212.669 234.601 210.857 237.754 210.903 241.057C210.962 245.312 205.792 248.532 210.588 255.306C212.819 258.457 212.555 263.548 209.53 268.267C206.566 272.86 203.352 270.37 199.074 273.451C196.011 275.649 197.39 276.771 194.055 277.965C190.122 279.384 187.392 278.032 182.366 276.584C176.698 274.951 172.309 275.353 167.538 275.773C161.926 276.267 154.845 267.57 156.432 262.839C158.057 257.994 153.189 257.961 152.922 252.985Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
                <path
                    d="M183.265 237.869C193.788 237.725 199.05 245.356 199.049 252.985C199.049 260.615 193.788 268.245 183.264 268.101C172.742 268.244 167.48 260.614 167.48 252.984C167.481 245.354 172.742 237.724 183.265 237.869Z"
                    stroke="#9DB6EB"
                    stroke-miterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_2415_4303">
                    <rect
                        width="759.169"
                        height="1001.55"
                        fill="white"
                        transform="matrix(0 -1 1 0 -314 634.17)" />
                </clipPath>
            </defs>
        </svg>
        <div
            class="max-w-container w-full mx-auto px-container py-8 flex items-end flex-wrap z-10 relative">
            <div
                class="grid grid-cols-1 md:grid-cols-[max-content_1fr_1fr] xl:grid-cols-[max-content_400px_1fr] gap-6 lg:gap-8 w-full">
                <!-- Logo -->
                <BaseLogo />

                <HomepageFavorites
                    :user="user"
                    :favorites="favorites"
                    @remove-favorite="deleteFavorite"
                    class="md:col-span-2 xl:col-span-1" />

                <HomepageWelcomeBanner
                    :user="user"
                    :message="featured" />

                <BaseNotification
                    v-for="(notification, i) in filteredNotifications"
                    :key="i"
                    :notification="notification"
                    @hide="updateNotification" />
            </div>
        </div>
    </section>
</template>

<script>
import BaseLogo from '../Base/BaseLogo.vue'
import BaseNotification from '../Base/BaseNotification.vue'
import HomepageFavorites from '../Homepage/HomepageFavorites.vue'
import HomepageWelcomeBanner from '../Homepage/HomepageWelcomeBanner.vue'
import { hideNotification } from 'Composables/useAccount.js'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'HomepageWelcome',
    components: {
        BaseLogo,
        BaseNotification,
        HomepageFavorites,
        HomepageWelcomeBanner,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        notifications: {},
    },
    computed: {
        ...mapState('favorites', ['favorites']),
        isUserLoading() {
            return this.$store.getters['user/isLoading']
        },
        filteredNotifications() {
            return this.$store.getters.messages.filter(
                (message) =>
                    message.is_featured === false &&
                    this.hiddenNotifications.includes(message.id) === false
            )
        },
        hiddenNotifications() {
            const setting = this.$store.state.user?.settings?.find(
                (setting) => setting.name === 'hidden_notifications'
            )

            if (setting) {
                return setting.value
            }

            return []
        },
        featured() {
            const featured = this.$store.getters.messages.find(
                (message) => message.is_featured
            )

            return featured
        },
    },
    methods: {
        ...mapActions('favorites', ['deleteFavorite']),
        async updateNotification(notification) {
            const updated = await hideNotification(notification)
            this.$store.dispatch('user/loadSettings')
        },
    },
    mounted() {},
}
</script>
