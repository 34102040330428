import {
    ASSET_LIST,
    ASSET_UPDATE
} from 'Root/endpoints';

import types, { SET_IS_LOADING } from '../mutation-types';

import {
    uniqBy,
    reject
} from 'lodash';

import axios from 'axios';

import {
    SET_ASSET,
} from 'Stores/Frontend/mutation-types';

import Notification from 'Components/Redesign/SystemNotifications/NotificationModel';

const asset = {
    namespaced: true,
    state: {
        asset: null,
    },
    mutations: {
        // User Stuff
        [SET_ASSET](state, asset) {
            state.asset = asset;
        },
    },
    actions: {
        updateAsset({state, commit}, asset) {
            commit(SET_ASSET, asset);
        },
        async getAsset({state, commit, dispatch}, assetId) {

            commit(SET_IS_LOADING, true, { root: true });

            return axios.get(ASSET_LIST, assetId)
                .then((response) => {
                    dispatch('asset/updateAsset', response.data.data);
                }).catch(error => {

                    dispatch(
                        'setNotification',
                        new Notification(error.response.statusText, 'emergency')
                    );

                }).finally(() => {
                    commit(SET_IS_LOADING, false, { root: true });
                });
        },
        async newComment({state, commit}, comment) {
            axios.get(ASSET_COMMENT_CREATE, {
                user: state.user.user,
                comment
            }).then(response => {

            });
        },
        async setApiVisibility({state, commit, dispatch}, payload) {
            return axios.post(
                ASSET_UPDATE + '/' + payload.asset_id,
                {
                    visible_to_api: payload.visible_to_api
                }
            ).then(response => {

                commit(SET_ASSET, response.data.data);

                dispatch(
                    'addNotification',
                    new Notification(`Updated Asset ${payload.asset_id} API visibility`, 'success'),
                    { root: true }
                );
            }).catch(error => {
                dispatch(
                    'addNotification',
                    new Notification(error.response.statusText, 'emergency'),
                    { root: true }
                );
            });
        }
    },
};

export default asset;
