<template>
    <multiselect
        class="absolute"
        v-model="data"
        id="entry_collections"
        label="name"
        track-by="collection_id"
        placeholder="Enter Collection Name"
        open-direction="bottom"
        :options="collections"
        :multiple="true"
        :searchable="false"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="8"
        :limit-text="limitText"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
    >
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { getCollections } from 'Composables/useCollections.js'

export default {
    components: {
        Multiselect,
    },
    props: {
        value: {
            required: false,
            type: Array,
            default: [],
        },
    },
    data() {
        return { filter: null, allCollections: [], isLoading: false }
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValues) {
                this.$emit('input', newValues)
            },
        },
        collections() {
            if (!this.filter) {
                return this.allCollections
            }

            return this.allCollections.filter(
                (collection) => collection.name.includes(this.filter) !== -1
            )
        },
    },
    methods: {
        limitText(count) {
            return `${count} addtional Collections`
        },
        async findBrands(filter) {
            this.filter = filter
        },
    },
    async mounted() {
        this.isLoading = true

        try {
            const response = await getCollections()

            if (response.data.data) {
                this.allCollections = response.data.data?.collections?.data
            }
        } catch (error) {
            this.allCollections = []
            console.error(error)
        }

        this.isLoading = false
    },
}
</script>
