import {
    SET_OPTIONS_APPROVAL_CATEGORIES,
    SET_OPTIONS_APPROVAL_DOCUMENT_TYPES,
    SET_OPTIONS_BRANDS,
    SET_OPTIONS_COUNTRIES,
    SET_OPTIONS_DIVISIONS,
    SET_OPTIONS_LANGUAGES,
    SET_OPTIONS_REGIONS,
    SET_OPTIONS_STATES,
    SET_OPTIONS_TEMPLATE_TYPES,
    SET_OPTIONS_TEMPLATE_TYPE_CATEGORIES,
    SET_OPTIONS_TIME_ZONES,
    SET_COLLATERAL_LINK_OPENED,
} from 'Stores/Frontend/mutation-types.js'

import {
    getApprovalCategories,
    getAssetTypes,
    getCountries,
    getDivisions,
    getLanguages,
    getRegions,
    getStates,
    getTemplateTypeCategories,
    getTemplateTypes,
    getTimeZones,
} from 'Composables/useOptions.js'

import { getBrands } from 'Composables/useBrands.js'
import { SET_OPTIONS_ASSET_TYPES } from '../mutation-types'

const options = {
    namespaced: true,
    state: {
        assetActiveStatus: null,
        assetStatuses: [],
        assetTypes: [],
        brands: [],
        colors: [],
        countries: [],
        divisions: [],
        languages: [],
        regions: [],
        states: [],
        subjects: [],
        templateTypeCategories: [],
        templateTypes: [],
        timeZones: [],
        approvalCategories: [],
        approvalDocumentTypes: [],
        openCollateralAccordion:
            localStorage.getItem('openCollateralAccordion') || null,
    },
    mutations: {
        SET_DATA(state, data) {
            state.brands = data.brands ?? null
            state.assetTypes = data.assetTypes ?? null
        },
        [SET_OPTIONS_ASSET_TYPES](state, types) {
            state.assetTypes = types
        },
        [SET_OPTIONS_COUNTRIES](state, countries) {
            state.countries = countries
        },
        [SET_OPTIONS_STATES](state, states) {
            state.states = states
        },
        [SET_OPTIONS_LANGUAGES](state, languages) {
            state.languages = languages
        },
        [SET_OPTIONS_BRANDS](state, brands) {
            state.brands = brands
        },
        [SET_OPTIONS_TIME_ZONES](state, zones) {
            state.timeZones = zones
        },
        [SET_OPTIONS_TEMPLATE_TYPES](state, types) {
            state.templateTypes = types
        },
        [SET_OPTIONS_TEMPLATE_TYPE_CATEGORIES](state, categories) {
            state.templateTypeCategories = categories
        },
        [SET_OPTIONS_DIVISIONS](state, divisions) {
            state.divisions = divisions
        },
        [SET_OPTIONS_APPROVAL_CATEGORIES](state, categories) {
            state.approvalCategories = categories
        },
        [SET_OPTIONS_APPROVAL_DOCUMENT_TYPES](state, types) {
            state.approvalTypes = types
        },
        [SET_OPTIONS_REGIONS](state, regions) {
            state.regions = regions
        },
        [SET_COLLATERAL_LINK_OPENED](state, payload) {
            state.openCollateralAccordion = payload
        },
    },
    getters: {},
    actions: {
        async init({ dispatch, commit }) {},
        async loadAssetTypes({ commit }) {
            try {
                const response = await getAssetTypes()
                commit(SET_OPTIONS_ASSET_TYPES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadBrands({ commit }) {
            try {
                const response = await getBrands()
                commit(SET_OPTIONS_BRANDS, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadCountries({ commit }) {
            try {
                const response = await getCountries()
                commit(SET_OPTIONS_COUNTRIES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadStates({ commit }) {
            try {
                const response = await getStates()
                commit(SET_OPTIONS_STATES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadLanguages({ commit }) {
            try {
                const response = await getLanguages()
                commit(SET_OPTIONS_LANGUAGES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadTimeZones({ commit }) {
            try {
                const response = await getTimeZones()
                commit(SET_OPTIONS_TIME_ZONES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadTemplateTypes({ commit }) {
            try {
                const response = await getTemplateTypes()
                commit(SET_OPTIONS_TEMPLATE_TYPES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },

        async loadTemplateTypeCategories({ commit }) {
            try {
                const response = await getTemplateTypeCategories()
                commit(SET_OPTIONS_TEMPLATE_TYPE_CATEGORIES, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadDivisions({ commit }) {
            try {
                const response = await getDivisions()
                commit(SET_OPTIONS_DIVISIONS, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadApprovalCategories({ commit }) {
            try {
                const response = await getApprovalCategories()

                if (response.data.data) {
                    commit(SET_OPTIONS_APPROVAL_CATEGORIES, response.data.data)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async loadRegions({ commit }) {
            try {
                const response = await getRegions()
                commit(SET_OPTIONS_REGIONS, response.data.data)
            } catch (error) {
                console.error(error)
            }
        },
        async setCollateralLinkOpened({ commit }, payload) {
            try {
                // console.log(
                //     'Action: setCollateralLinkOpened called with payload:',
                //     payload
                // )

                commit(SET_COLLATERAL_LINK_OPENED, payload)
                localStorage.setItem('openCollateralAccordion', payload)
            } catch (error) {
                console.error(error)
            }
        },
    },
}

export default options
