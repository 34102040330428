<template>
    <article class="prose">
        <BaseTitleTag
            variant="info"
            :title="titleTag"
            :tag-link="tagLink"
            class="font-normal" />
        <div
            class="mt-4 leading-5 featured-update"
            v-html="title"></div>
        <div class="mt-4">
            <BasePerson
                :thumbnail="personThumbnail"
                :name="personName"
                :title="`News posted on ${formattedDate}`" />
        </div>
    </article>
</template>

<script>
import moment from 'moment'

import { renderContent } from 'Composables/useEntries.js'

import BasePerson from '../Base/BasePerson.vue'
import BaseTitleTag from '../Base/BaseTitleTag.vue'

export default {
    name: 'StandardNews',
    components: {
        BasePerson,
        BaseTitleTag,
    },
    props: {
        entry: {
            type: Object,
            required: true,
            default: null,
        },
    },
    computed: {
        categories() {
            return this.entry.categories?.data ?? []
        },
        collections() {
            return this.entry.collections?.data ?? []
        },
        audience() {
            if (this.categories.length > 0) {
                return this.categories[0].plural
            }
            return null
        },
        title() {
            return renderContent(this.entry.content)
        },
        personName() {
            return `${this.entry.author.data?.first_name} ${this.entry.author.data?.last_name}`
        },
        personThumbnail() {
            return `/img/avatars/${this.entry?.author_id}`
        },
        link() {
            return this.entry.links.data.view
        },
        tagLink() {
            return null
        },
        formattedDate() {
            return moment(this.entry.created_at).format('MMMM, Do YYYY')
        },
        titleTag() {
            let title = 'Update for'

            if (this.audience) {
                title = title + ` ${this.audience}`
            }

            return title
        },
        images() {
            if (!this.collections) {
                return null
            }

            if (this.entry.featured_image) {
                return [this.entry.featured_image]
            }

            if (!this.collections.length) {
                return null
            }

            return this.collections.data[0].thumbnails?.data.map(
                (thumbnail) => thumbnail.url
            )
        },
    },
}
</script>

<style lang="scss">
.featured-update {
    p {
        font-family: Swiss721, sans-serif;
    }
}
</style>
