<template>
    <div class="flex items-center cursor-pointer">
        <div class="relative">
            <Drawer class="-nav">
                <template #trigger="{ toggleDrawer }">
                    <div
                        class="flex items-center gap-4"
                        @click="toggleDrawer"
                        @keyup.esc="toggleDrawer">
                        <div
                            class="flex items-center px-1.5 bg-blue rounded-full h-[50px]">
                            <div
                                class="flex align-middle items-center rounded-full h-10 w-10 bg-white text-blue overflow-hidden">
                                <img
                                    v-if="user.avatar"
                                    class="rounded-full h-10"
                                    :src="user.avatar"
                                    :alt="user.full_name" />
                                <span
                                    v-else
                                    class="text-blue w-full text-center font-bold">
                                    {{ user.first_name }} {{ user.full_name }}
                                </span>
                            </div>

                            <!-- Arrow -->
                            <button
                                class="focus:ring-2 ring-blue-400 px-4 h-full">
                                <svg
                                    width="16"
                                    height="9"
                                    viewBox="0 0 16 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.3125 1.63281L8.59766 8.06641C8.38672 8.24219 8.17578 8.3125 8 8.3125C7.78906 8.3125 7.57812 8.24219 7.40234 8.10156L0.652344 1.63281C0.300781 1.31641 0.300781 0.753906 0.617187 0.4375C0.933594 0.0859375 1.49609 0.0859375 1.8125 0.402344L8 6.30859L14.1523 0.402344C14.4687 0.0859375 15.0312 0.0859375 15.3477 0.4375C15.6641 0.753906 15.6641 1.31641 15.3125 1.63281Z"
                                        fill="#ECF0F8" />
                                </svg>
                            </button>
                        </div>

                        <div>
                            <div class="font-bold tracking-[.32px]">
                                {{ user.full_name }}
                            </div>
                            <div class="text-xs text-blue-medium">
                                {{ user.email }}
                            </div>
                        </div>

                        <div
                            v-if="isAdmin"
                            class="hidden md:inline-block">
                            <ButtonPrimarySmall href="/sitemanager">
                                Admin
                            </ButtonPrimarySmall>
                        </div>
                    </div>
                </template>

                <template #drawer>
                    <ul class="p-4">
                        <li>
                            <a
                                href="/account"
                                class="link inline-block text-gray-900 my-1">
                                Your Account
                            </a>
                        </li>
                        <li v-if="isAdmin">
                            <a
                                href="/account/notifications"
                                class="link inline-block text-gray-900 my-1">
                                Notification Preferences
                            </a>
                        </li>

                        <li>
                            <a
                                href="/logout"
                                class="link inline-block text-gray-900 my-1">
                                Logout
                            </a>
                        </li>
                    </ul>
                </template>
            </Drawer>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import Drawer from '../Drawer/index.vue'
import ButtonPrimarySmall from '../Button/ButtonPrimarySmall'

export default {
    name: 'NavigationUserAccount',
    components: {
        Drawer,
        ButtonPrimarySmall,
    },
    props: {
        user: {
            type: Object,
            default: null,
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            avatarUrl: null,
        }
    },
    computed: {
        ...mapState(['urls']),
    },
    created() {
        // Call the method when the component is created to fetch the avatar
    },
    methods: {},
}
</script>
