<template>
<div :class="classes">
    <div class="systemMessage--icon">
        <i :class="iconClasses" />
    </div>
    <div
        class="systemMessage--message"
        v-html="message.message"
    />
    <button
        class=""
        @click.prevent="$emit('hide', message)"
    >
        <i class="far fa-fw fa-times" />
    </button>
    <slot />
</div>
</template>

<script>
export default {
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        classes() {
            let classes = [
                'systemMessage',
            ];

            switch(this.message.severity) {
            case 0:
                classes.push('--emergency');
                break;

            case 1:
                classes.push('--alert');
                break;

            case 2:
                classes.push('--critical');
                break;

            case 3:
                classes.push('--error');
                break;

            case 4:
                classes.push('--warning');
                break;

            case 5:
                classes.push('--notice');
                break;

            case 6:
                classes.push('--info');
                break;

            case 7:
                classes.push('--debug');
                break;
            };

            return classes.join(' ');
        },
        iconClasses() {
            let classes = [
                'systemMessage--icon',
                'far',
                'fa-fw',
                'fa-2x',
            ];

            switch(this.message.severity) {
            case 0:
                classes.push('--emergency');
                classes.push('fa-times-octagon');
                break;

            case 1:
                classes.push('--alert');
                classes.push('fa-times-circle');
                break;

            case 2:
                classes.push('--critical');
                classes.push('fa-exclamation-triangle');
                break;

            case 3:
                classes.push('--error');
                classes.push('fa-exclamation-circle');
                break;

            case 4:
                classes.push('--warning');
                classes.push('fa-exclamation');
                break;

            case 5:
                classes.push('--notice');
                classes.push('fa-exclamation');
                break;

            case 6:
                classes.push('--info');
                classes.push('fa-exclamation');
                break;

            case 7:
                classes.push('--debug');
                classes.push('fa-question');
                break;
            };

            return classes.join(' ');
        }
    }
};
</script>

<style lang="scss" scoped>
.systemMessage {
    @apply w-full p-4 flex items-center;

    &.--emergency {
        @apply bg-red-600 text-slate-text;
    }

    &.--alert {
        @apply bg-red-600 text-slate-text;

    }

    &.--critical {
        @apply bg-coral-dark text-slate-text;

    }

    &.--error {
        @apply bg-coral text-slate-text;

    }

    &.--warning {
        @apply bg-coral text-slate-text;

    }

    &.--notice {
        @apply bg-coral text-slate-text;

    }

    &.--info {
        @apply bg-surf-dark text-gray-100;

    }

    &.--debug {
        @apply bg-seafoam-dark text-gray-100;

    }

    &.--icon {
        @apply bg-seafoam-dark text-gray-100;
    }
}
</style>
