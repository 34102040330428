<template>
    <div
        class="relative border rounded-lg px-4 py-3 bg-white border-blue-medium">
        <label
            for="editor"
            class="absolute top-2 left-4 text-xs text-gray">
            {{ label }} <span v-if="required" class="text-danger-dark">*</span>
        </label>
        <div
            v-if="editor"
            class="my-4">
            <ToolbarButton
                class="font-aldine"
                @click="
                    editor.chain().focus().setFontFamily('Aldine, serif').run()
                "
                :is-active="
                    editor.isActive('textStyle', {
                        fontFamily: 'Aldine, serif',
                    })
                ">
                Aldine
            </ToolbarButton>

            <ToolbarButton
                class="font-swiss"
                @click="
                    editor
                        .chain()
                        .focus()
                        .setFontFamily('Swiss721, sans-serif')
                        .run()
                "
                :is-active="
                    editor.isActive('textStyle', {
                        fontFamily: 'Swiss721, sans-serif',
                    })
                ">
                Swiss721
            </ToolbarButton>

            <ToolbarButton
                @click="editor.chain().focus().toggleBold().run()"
                :disabled="!editor.can().chain().focus().toggleBold().run()"
                :is-active="editor.isActive('bold')">
                <i class="far fa-bold fa-fw" />
            </ToolbarButton>

            <ToolbarButton
                @click="editor.chain().focus().toggleItalic().run()"
                :disabled="!editor.can().chain().focus().toggleItalic().run()"
                :is-active="editor.isActive('italic')">
                <i class="far fa-italic fa-fw" />
            </ToolbarButton>

            <ToolbarButton
                @click="editor.chain().focus().toggleStrike().run()"
                :disabled="!editor.can().chain().focus().toggleStrike().run()"
                :is-active="editor.isActive('strike')"
                class="border-r border-blue-light">
                <i class="far fa-strikethrough fa-fw" />
            </ToolbarButton>

            <!-- <ToolbarButton -->
            <!--     @click="editor.chain().focus().toggleCode().run()" -->
            <!--     :disabled="!editor.can().chain().focus().toggleCode().run()" -->
            <!--     :class="{ 'is-active': editor.isActive('code') }" -->
            <!-- > -->
            <!--     code -->
            <!-- </ToolbarButton> -->

            <!-- <ToolbarButton -->
            <!--     @click="editor.chain().focus().unsetAllMarks().run()" -->
            <!-- > -->
            <!--     clear marks -->
            <!-- </ToolbarButton> -->

            <!-- <ToolbarButton @click="editor.chain().focus().clearNodes().run()"> -->
            <!--     clear nodes -->
            <!-- </ToolbarButton> -->

            <ToolbarButton
                @click="editor.chain().focus().setParagraph().run()"
                :is-active="editor.isActive('paragraph')">
                <i class="far fa-fw fa-paragraph" />
            </ToolbarButton>

            <ToolbarButton
                @click="
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                "
                :is-active="editor.isActive('heading', { level: 1 })">
                H1
            </ToolbarButton>

            <ToolbarButton
                @click="
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                "
                :is-active="editor.isActive('heading', { level: 2 })">
                H2
            </ToolbarButton>
            <ToolbarButton
                @click="
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                "
                :is-active="editor.isActive('heading', { level: 3 })"
                class="border-r border-blue-light">
                H3
            </ToolbarButton>

            <!-- <ToolbarButton -->
            <!--     @click=" -->
            <!--         editor.chain().focus().toggleHeading({ level: 4 }).run() -->
            <!--     " -->
            <!--     :class="{ -->
            <!--         'is-active': editor.isActive('heading', { level: 4 }), -->
            <!--     }" -->
            <!-- > -->
            <!--     h4 -->
            <!-- </ToolbarButton> -->
            <!-- <ToolbarButton -->
            <!--     @click=" -->
            <!--         editor.chain().focus().toggleHeading({ level: 5 }).run() -->
            <!--     " -->
            <!--     :class="{ -->
            <!--         'is-active': editor.isActive('heading', { level: 5 }), -->
            <!--     }" -->
            <!-- > -->
            <!--     h5 -->
            <!-- </ToolbarButton> -->
            <!-- <ToolbarButton -->
            <!--     @click=" -->
            <!--         editor.chain().focus().toggleHeading({ level: 6 }).run() -->
            <!--     " -->
            <!--     :class="{ -->
            <!--         'is-active': editor.isActive('heading', { level: 6 }), -->
            <!--     }" -->
            <!-- > -->
            <!--     h6 -->
            <!-- </ToolbarButton> -->

            <ToolbarButton
                @click="editor.chain().focus().toggleBulletList().run()"
                :is-active="editor.isActive('bulletList')">
                <i class="far fa-list fa-fw" />
            </ToolbarButton>
            <ToolbarButton
                @click="editor.chain().focus().toggleOrderedList().run()"
                :is-active="editor.isActive('orderedList')"
                class="border-r border-blue-light">
                <i class="far fa-list-ol fa-fw" />
            </ToolbarButton>

            <!-- <ToolbarButton -->
            <!--     @click="editor.chain().focus().toggleCodeBlock().run()" -->
            <!--     :class="{ 'is-active': editor.isActive('codeBlock') }" -->
            <!-- > -->
            <!--     code block -->
            <!-- </ToolbarButton> -->

            <ToolbarButton
                @click="editor.chain().focus().toggleBlockquote().run()"
                :is-active="editor.isActive('blockquote')">
                <i class="far fa-fw fa-quote-left" />
            </ToolbarButton>
            <ToolbarButton
                @click="editor.chain().focus().setHorizontalRule().run()">
                <i class="far fa-minus fa-fw" />
            </ToolbarButton>
            <ToolbarButton
                @click="editor.chain().focus().setHardBreak().run()"
                class="">
                <i class="far fa-quote fa-fw" />
            </ToolbarButton>
            <!-- <ToolbarButton -->
            <!--     @click="editor.chain().focus().undo().run()" -->
            <!--     :disabled="!editor.can().chain().focus().undo().run()" -->
            <!-- > -->
            <!--     Undo -->
            <!-- </ToolbarButton> -->
            <!-- <ToolbarButton -->
            <!--     @click="editor.chain().focus().redo().run()" -->
            <!--     :disabled="!editor.can().chain().focus().redo().run()" -->
            <!-- > -->
            <!--     Redo -->
            <!-- </ToolbarButton> -->
        </div>
        <editor-content
            id="content"
            class="overflow-y-scroll"
            :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'

import ToolbarButton from './ToolbarButton.vue'

export default {
    components: {
        EditorContent,
        ToolbarButton,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },
        label: {
            required: true,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
            default: true
        }

    },

    data() {
        return {
            editor: null,
        }
    },

    watch: {
        value(value) {
            // HTML
            // const isSame = this.editor.getHTML() === value

            // JSON
            const isSame =
                JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [StarterKit, FontFamily, TextStyle],
            onUpdate: () => {
                // HTML
                // this.$emit('input', this.editor.getHTML())

                // JSON
                this.$emit('input', this.editor.getJSON())
            },
        })
    },

    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>

<style lang="scss">
.tiptap.ProseMirror {
    height: 250px;
}
</style>
