<template>
<div class="systemMessages">
    <message
        v-for="message in filtered"
        :key="message.id"
        :message="message"
        @hide="hideMessage(message)"
    />
</div>
</template>

<script>
import Message from './Message';
import {SET_SYSTEM_MESSAGES} from 'Stores/Frontend/mutation-types';

export default {
    components: {
        Message,
    },
    data() {
        return {
            hidden: [],
        };
    },
    computed: {
        messages: {
            get() {
                return this.$store.state.messages.data;
            },
            set(messages) {
                this.$store.commit(SET_SYSTEM_MESSAGES, messages);
            }
        },
        filtered() {
            return this.messages.filter(message => {
                return this.hidden.indexOf(message.id) === -1;
            });
        }
    },
    mounted() {
        this.$store.dispatch('getMessages');
    },
    methods: {
        hideMessage(message) {
            if (this.hidden.indexOf(message.id) === -1) {
                this.hidden.push(message.id);
            }
        }
    }
};
</script>

<style scoped lang="scss">

.systemMessages {
    @apply w-full;
}

</style>
