<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start top-12 z-50">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition-group
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <div
                    v-for="notification in notifications"
                    :key="notification.getId()"
                    :class="['notification', `--${notification.getLevel()}`]">
                    <div class="notification--body">
                        <div class="notification--bodyWrapper">
                            <div class="notification--iconWrapper">
                                <i :class="getIconClasses(notification)" />
                            </div>
                            <div class="notification--message">
                                <p class="notification--mainMessage">
                                    {{ notification.getMessage() }}
                                </p>
                                <p
                                    v-show="notification.getSubMessage()"
                                    class="notification--subMessage">
                                    {{ notification.getSubMessage() }}
                                </p>
                            </div>
                            <div class="notification--controls">
                                <button
                                    class="notification--close"
                                    @click.prevent="close(notification)">
                                    <span class="sr-only">Close</span>
                                    <i class="far fa-fw fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import NotificationModel from 'Components/Redesign/SystemNotifications/NotificationModel'

export default {
    props: {},
    data() {
        return {}
    },
    computed: {
        notifications: {
            get() {
                return this.$store.state.notifications
            },
        },
    },
    methods: {
        close(notification) {
            this.$store.dispatch('clearNotification', notification)
        },
        getIconClasses(notification) {
            let classes = ['notification--icon', 'far', 'fa-fw']

            let level = notification.getLevel()

            switch (level) {
                case 'info':
                case 'primary':
                case 'success':
                    classes.push('fa-check')
                    break
                case 'danger':
                    classes.push('fa-exclamation-triangle')
                    break
                default:
                    classes.push('fa-exclamation')
                    break
            }

            return classes.join(' ')
        },
    },
}
</script>

<style></style>
