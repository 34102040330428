<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import AssetListing from 'Components/Redesign/Asset/AssetListing'
import Loading from 'Components/Redesign/Loading'

export default {
    components: {
        AssetListing,
        Loading,
    },
    props: {
        commsPageId: {
            type: String,
            required: true,
        },
        keyword: {
            type: String,
            required: false,
            default: null,
        },
        assetTypeId: {
            type: [Number, null],
            required: false,
            default: null,
        },
        collectionIds: {
            type: Array,
            required: false,
            default: function () {
                return []
            },
        },
        isAdmin: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            assets: [],
            isLoading: false,
            meta: [],
        }
    },
    computed: {},
    watch: {
        assetTypeId: {
            handler() {
                this.fetchAssets()
            },
        },
        keyword: debounce(function (newVal) {
            this.fetchAssets()
        }, 350),
    },
    mounted() {
        this.fetchAssets()
    },
    methods: {
        fetchAssets() {
            this.isLoading = true
            let form = new FormData()

            this.collectionIds.forEach((c) => {
                form.append('collection_ids[]', c)
            })
            if (this.keyword) {
                form.append('keyword', this.keyword)
            }
            if (this.assetTypeId) {
                form.append('asset_type_ids[]', this.assetTypeId)
            }

            let url = '/api/comms-pages/' + this.commsPageId + '/asset-search'

            axios
                .post(url, form)
                .then((response) => {
                    this.assets = response.data.data.assets.data
                    this.meta = response.data.meta
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    },
}
</script>

<template>
    <div
        id="all"
        class="pt-4">
        <loading
            :is-loading="isLoading"
            message="Loading..."
            loading-message="!relative top-0.5 left-0.5" />
        <div v-if="!isLoading">
            <asset-listing
                :assets="assets"
                :is-admin="isAdmin"
                :active-status="{ id: 5, name: 'Active', value: 'active' }"
                :show-controls="false"
                :show-messages="false" />
            <div
                v-if="assets.length == 0"
                class="p-4 mb-4 bg-blue-lightest border-2 border-white text-coral-darker rounded-md relative"
                role="alert">
                Unable to display assets.
            </div>
        </div>
    </div>
</template>
