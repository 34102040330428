<template>
    <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.6875 8.8125C11.0938 9.1875 11.0938 9.84375 10.6875 10.2188C10.5 10.4062 10.25 10.5 10 10.5C9.71875 10.5 9.46875 10.4062 9.28125 10.2188L6 6.9375L2.6875 10.2188C2.5 10.4062 2.25 10.5 2 10.5C1.71875 10.5 1.46875 10.4062 1.28125 10.2188C0.875 9.84375 0.875 9.1875 1.28125 8.8125L4.5625 5.5L1.28125 2.21875C0.875 1.84375 0.875 1.1875 1.28125 0.8125C1.65625 0.40625 2.3125 0.40625 2.6875 0.8125L6 4.09375L9.28125 0.8125C9.65625 0.40625 10.3125 0.40625 10.6875 0.8125C11.0938 1.1875 11.0938 1.84375 10.6875 2.21875L7.40625 5.53125L10.6875 8.8125Z"
            fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: 'IconX',
}
</script>

<style></style>
