<template>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0">
        <div
            v-if="isOpen"
            class="bg-blue-darkest fixed w-full h-full inset-0 bg-opacity-75 z-50 overflow-scroll md:overflow-auto px-4">
            <div
                class="bg-blue-darkest max-w-screen-xl mx-auto rounded-3xl p-6 grid grid-rows-[min-content_446px_min-content] my-20"
                @keyup.esc="close">
                <div
                    class="flex flex-col lg:flex-row items-start justify-between">
                    <div>
                        <h4
                            class="text-blue-lightest text-base font-swiss font-bold leading-tight">
                            <slot name="eyebrow"></slot>
                        </h4>
                        <h3
                            class="text-blue-medium text-4xl lg:text-5xl italic mb-4 mt-3 md:mt-0 leading-tight">
                            <slot name="title"></slot>
                        </h3>
                    </div>

                    <div class="flex flex-col md:flex-row gap-4">
                        <ButtonOutline
                            text-color="text-white"
                            @click.prevent="close">
                            Cancel
                        </ButtonOutline>
                        <ButtonPrimary
                            @click.prevent="save"
                            class="flex gap-4 items-center">
                            Select
                            <IconX />
                        </ButtonPrimary>
                    </div>
                </div>
                <div
                    class="rounded-2xl overflow-y-auto grid grid-cols-5 mt-4 lg:mt-0">
                    <div
                        class="col-span-full md:col-span-2 bg-blue-lightest p-6 overflow-y-scroll">
                        <BaseMultiselect
                            v-model="internalValue"
                            :options="options"
                            :label="label"
                            :clear-search-on-select="false"
                            :filterable="false"
                            :reduce="reduce"
                            :dropdown-should-open="() => true"
                            :deselect-from-dropdown="deselectFromDropdown"
                            @search="$emit('search', $event)">
                            <slot #option="option">
                                {{ option }}
                            </slot>
                        </BaseMultiselect>
                    </div>
                    <div class="col-span-full md:col-span-3 bg-blue-medium p-6">
                        <div
                            class="text-blue-darkest font-aldine text-3xl italic">
                            <slot name="currentTitle">
                                Current Properties
                            </slot>
                        </div>
                        <slot
                            name="current"
                            :value="value" />
                    </div>
                </div>
                <div>
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import ButtonOutline from 'Components/Redesign/Button/ButtonOutline.vue'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import ButtonSecondary from 'Components/Redesign/Button/ButtonSecondary.vue'
import BaseMultiselect from 'Components/Redesign/Base/BaseMultiselect'
import IconX from 'Components/Redesign/Icons/IconX.vue'

export default {
    name: 'MultiselectModal',
    components: {
        ButtonOutline,
        ButtonPrimary,
        ButtonSecondary,
        BaseMultiselect,
        IconX,
    },
    props: {
        value: {
            required: true,
            default: null,
        },
        label: {
            required: false,
            type: String,
            default: 'title',
        },
        options: {
            required: true,
            type: Array,
            default: () => [],
        },
        trackBy: {
            required: false,
            type: String,
            default: 'id',
        },
        isOpen: {
            required: false,
            default: false,
            type: Boolean,
        },
        reduce: {
            type: Function,
            default: (option) => option,
        },
        deselectFromDropdown: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {}
    },
    computed: {
        internalValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            this.$emit('save', this.value)
        },
    },
    created() {},
    mounted() {},
}
</script>
