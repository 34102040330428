<template>
    <form
        class="submit_work_order default"
        method="post"
        action=""
        enctype="multipart/form-data">
        <fieldset class="grid grid-cols-1 md:grid-cols-6 gap-4 items-center">
            <div
                v-if="showProperties"
                class="col-span-3">
                <FormMultiselect
                    v-model="editing.properties.data"
                    id="entry_properties"
                    label="Properties"
                    labelKey="name"
                    trackByKey="marsha_code"
                    placeholder="Enter MARSHA code"
                    :searchable="true"
                />
            </div>
            <div
                v-if="!showProperties"
                class="col-span-3">
                <FormMultiselect
                    v-model="editing.brands.data"
                    id="entry_brands"
                    label="Brands"
                    labelKey="name"
                    trackByKey="brand_id"
                    placeholder="Enter brand"
                    :searchable="true"
                />
            </div>
            <div class="col-span-3">
                <ButtonPrimary @click.native="toggleOption">
                    My approval is for {{ showProperties ? 'a brand' : 'a property' }}
                </ButtonPrimary>
            </div>
            <div class="col-span-3">
                <AccountFormSelect
                    v-model="request_type"
                    v-validate="'required'"
                    :options="requestTypeOptions"
                    name="request_type"
                    placeholder="Select One..."
                    label=" Request Type"
                    required
                />
            </div>
            <div class="col-span-3">
                <AccountFormSelect
                    v-model="urgency"
                    v-validate="'required'"
                    :options="urgencyOptions"
                    name="urgency"
                    placeholder="Select One..."
                    label="Urgency"
                    class="font-normal"
                    required
                />
            </div>
            <div class="col-span-full">
                <label class="form__label" for="metadata">File</label>
                <input type="file">
            </div>
            <div class="col-span-full">
                <AccountFormTextArea
                    v-model="editing.extra"
                    name="notes"
                    input-id="notes"
                    placeholder="Tell us more..."
                    label="Notes"
                />
            </div>
            <div class="col-span-full">
                <FormMultiselect
                    v-model="secondary_contact"
                    id="secondary_contact"
                    label="Secondary Contact"
                    labelKey="name"
                    trackByKey=""
                    placeholder="Search..."
                    :searchable="true"
                />
                <span class="text-xs">The Secondary Contact will be copied on all communications for this ticket.</span>
            </div>

            <div class="col-span-full flex gap-4 items-center">
                <span class="font-bold text-sm">Status Notifications</span>
                <label for="notify_submitter_by_email" class="text-sm">
                    <input type="hidden" name="notify_submitter_by_email" value="0" />
                    <input type="checkbox" class="checkbox" id="notify_submitter_by_email" name="notify_submitter_by_email" value="1" />
                    Notify me
                </label>
                <label for="notify_secondary_contact_by_email" class="text-sm">
                    <input type="hidden" name="notify_secondary_contact_by_email" value="0" />
                    <input type="checkbox" class="checkbox" id="notify_secondary_contact_by_email" name="notify_secondary_contact_by_email" value="1"/>
                    Notify secondary contact
                </label>
            </div>

            <div class="col-span-full -center">
                <ButtonPrimary>
                    Submit Work Order
                </ButtonPrimary>
            </div>

        </fieldset>
    </form>
</template>

<script>
import { mapState } from 'vuex'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import FormMultiselect from 'Components/Redesign/Form/FormMultiselect.vue'
import AccountFormSelect from 'Components/Redesign/Form/AccountFormSelect.vue'
import AccountFormTextArea from 'Components/Redesign/Form/AccountFormTextArea.vue'

export default {
    name: 'WorkOrdersForm',
    components: {
        ButtonPrimary,
        FormMultiselect,
        AccountFormSelect,
        AccountFormTextArea,
    },
    props: {
        workOrders: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showProperties: true,
            selectedCategory: null,
            request_type: null,
            urgency: null,
            secondary_contact: null,
        }
    },
    computed: {
        ...mapState('content', ['entry', 'type']),
        editing: {
            get() {
                return this.entry
            },
            set(entry) {
                this.$store.dispatch('content/setEntry', entry)
            },
        },
        requestTypeOptions() {
            if (this.isDataSourceInvalid(this.workOrders)) {
                return [];
            }

            const uniqueNames = new Set();
            return this.workOrders.data.map((item) => {
                if (item.request_type && item.request_type.data) {
                const name = item.request_type.data.name;
                if (!uniqueNames.has(name)) {
                    uniqueNames.add(name);
                    return {
                        id: item.id,
                        title: name,
                    };
                }
                }
                return null;
            }).filter(item => item !== null); // Filter out any null values
        },
        urgencyOptions() {
            if (this.isDataSourceInvalid(this.workOrders)) {
                return [];
            }

            const uniqueUrgencies = new Set();
            return this.workOrders.data.map((item) => {
                if (item.urgency) {
                const urgency = item.urgency;
                if (!uniqueUrgencies.has(urgency)) {
                        uniqueUrgencies.add(urgency);
                        return {
                            id: urgency,
                            title: urgency,
                        };
                    }
                }
                return null;
            }).filter(item => item !== null); // Filter out any null values
        },

    },
    methods: {
        toggleOption(event) {
            event.preventDefault();
            this.showProperties = !this.showProperties;
        },
        isDataSourceInvalid(dataSource) {
            return !dataSource || !dataSource.data;
        },
    },
    async created() {
        if (this.id) {
            await this.$store.dispatch('content/getEntryById', this.id)
        }

        if (this.entrySlug) {
            await this.$store.dispatch('content/getEntryBySlug', this.entrySlug)
        }

        if (!this.editing) {
            this.editing = {
                title: null,
                type: null,
                category_ids: [],
                brands: { data: [] },
                request_type: null,
                urgency: null,
                properties: { data: [] },
                collections: { data: [] },
                content: {
                    type: 'doc',
                    content: [],
                },
                extra: null,
            }
        }
    },
}
</script>
