<template>
    <div
        class="relative bg-white border border-blue-medium rounded-lg px-4 py-3">
        <label class="absolute top-2 left-4 text-xs text-gray z-20">
            {{ label }}
            <span
                v-if="required"
                class="text-danger-dark">
                *
            </span>
        </label>
        <multiselect
            v-model="data"
            :id="id"
            class="custom-select"
            :label="labelKey"
            :track-by="trackByKey"
            :placeholder="placeholder"
            open-direction="bottom"
            :options="options"
            :multiple="true"
            :searchable="searchable"
            :loading="isLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="300"
            :limit="8"
            :limit-text="limitText"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="handleSearchChange"
        />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import debounce from 'lodash/debounce'
import { getBrands } from 'Composables/useBrands.js'
import { getProperties } from 'Composables/useProperties.js'

export default {
    components: {
        Multiselect,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        trackByKey: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            filter: null,
            options: [],
            isLoading: false,
        }
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValues) {
                this.$emit('input', newValues)
            },
        },
    },
    methods: {
        limitText(count) {
            return `${count} additional options`
        },
        handleSearchChange: debounce(async function (filter) {
            this.filter = filter
            this.isLoading = true

            try {
                let response
                if (this.id === 'entry_brands') {
                    response = await getBrands()
                } else if (this.id === 'entry_properties') {
                    response = await getProperties({ search: filter })
                }

                if (response.data.data) {
                    this.options = response.data.data
                }
            } catch (error) {
                this.options = []
                console.error(error)
            }

            this.isLoading = false
        }, 300),
    },
    async mounted() {
        try {
            let response
            if (this.id === 'entry_brands') {
                response = await getBrands()
            } else if (this.id === 'entry_properties') {
                response = await getProperties()
            }

            if (response.data.data) {
                this.options = response.data.data
            }
        } catch (error) {
            this.options = []
            console.error(error)
        }
    },
}
</script>

<style scoped>
/deep/ .custom-select .multiselect__tags {
    border: none !important;
    padding-left: 0 !important;
}

/deep/ .custom-select .multiselect__placeholder {
    padding-top: 0.75rem !important;
    margin-bottom: 0 !important;
}
</style>
