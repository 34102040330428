var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"p-4 flex gap-2"},_vm._l((_vm.links),function(link){return _c('li',{key:link.url,staticClass:"relative"},[_c('a',{class:[
                    'flex items-center gap-4 bg-white font-bold rounded-full shadow-sm p-4',
                    _vm.isActive(link)
                        ? 'text-blue'
                        : 'text-black hover:text-blue' ],attrs:{"href":link.url},on:{"mouseover":function($event){return _vm.setHoveredLink(link)},"mouseleave":_vm.clearHoverLink,"click":function($event){return _vm.handleClick(link)}}},[(link.icon === 'trash')?_c('i',{class:[
                        'text-danger',
                        'far',
                        'fa-fw',
                        ("fa-" + (link.icon)) ]}):_c('i',{class:[
                        'text-gray',
                        'far',
                        'fa-fw',
                        ("fa-" + (link.icon)) ]})]),_vm._v(" "),_c('BaseTooltip',{staticClass:"-translate-x-1/2 left-1/2",attrs:{"show-tooltip":_vm.isHovered(link),"message":_vm.capitalize(link.icon)}})],1)}),0),_vm._v(" "),_c('Modal',{attrs:{"visible":_vm.isRenameModalVisible},on:{"update:visible":function($event){_vm.isRenameModalVisible = $event},"close":function($event){_vm.isRenameModalVisible = false}}},[_c('div',[_c('h3',{staticClass:"font-aldine italic text-blue-darkest mb-4"},[_vm._v("\n                Rename Workspace\n            ")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('FormInput',{attrs:{"label":"New name","name":"name","required":true},model:{value:(_vm.newWorkspaceName),callback:function ($$v) {_vm.newWorkspaceName=$$v},expression:"newWorkspaceName"}}),_vm._v(" "),_c('ButtonPrimary',{staticClass:"mt-4",attrs:{"type":"submit"}},[_vm._v("\n                    Rename\n                ")]),_vm._v(" "),_c('ButtonPrimary',{staticClass:"mt-2 bg-gray",on:{"click":function($event){_vm.isRenameModalVisible = false}}},[_vm._v("\n                    Cancel\n                ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }