<template>
<section class="assetListing flex flex-wrap">
    <template v-if="assets.length">
        <AssetListingControls
            v-if="showControls"
            :active-status="activeStatus"
            :all-selected="allSelected"
            @select-all="selectAll"
        />

        <lazy-component
            v-for="(chunk, i) in chunked"
            class="assetListing__contentRow gap-4"
            :key="i"
        >
            <div
                v-for="asset in chunk"
                :key="asset.asset_id"
                class="assetListing__content"
            >
                <AssetCard
                    :is-admin="isAdmin"
                    :selected="isSelected(asset)"
                    :asset="asset"
                    @input="updateSelected($event, asset)"
                />
            </div>
        </lazy-component>

        <AssetListingControls
            v-if="showControls"
            :active-status="activeStatus"
            :all-selected="allSelected"
            @select-all="selectAll"
        />
    </template>

    <template v-if="!hasSearched && !assets.length && showMessages">
        <div>
            <span class="text-lg">Search for assets using the form above and/or the filters to the left.</span>
        </div>
    </template>

    <template v-if="hasSearched && !assets.length && showMessages">
        <div class="card">
            <span class="text-lg">Your search did not match any results</span>
        </div>
    </template>
</section>
</template>

<script>
import AssetCard from 'Components/Redesign/Asset/AssetCard';
import AssetListingControls from 'Components/Redesign/Asset/AssetListingControls';
import { reject, find, differenceWith, isEqual, chunk } from 'lodash';
import {
    SET_SEARCH_STATUS,
    SET_USER_SELECTED_ASSETS,
} from 'Stores/Frontend/mutation-types';
import LazyComponent from 'v-lazy-component';

export default {
    name: 'AssetListing',
    components: {
        AssetCard,
        AssetListingControls,
        LazyComponent,
    },
    props: {
        assets: {
            required: false,
            default: () => [],
            type: Array,
        },
        activeStatus: {
            required: true,
            type: Object,
        },
        isAdmin: {
            required: false,
            default: false,
            type: Boolean,
        },
        showControls: {
            required: false,
            default: true,
            type: Boolean,
        },
        showMessages: {
            required: false,
            default: true,
            type: Boolean,
        }
    },
    data() {
        return {};
    },
    computed: {
        allSelected() {
            return this.assets.every((asset) => find(this.selected, asset));
        },
        selected: {
            get() {
                return this.$store.state.user.selectedAssets;
            },
            set(assets) {
                this.$store.dispatch('user/setSelectedAssets', assets);
            }
        },
        hasSearched() {
            return this.$store.state.search.hasSearched;
        },
        chunked() {
            return chunk(this.assets, 3);
        }
    },
    mounted() {},
    methods: {
        updateSelected(isChecked, asset) {
            if (isChecked) {
                let selected = this.selected;
                selected.push(asset);
                this.selected = selected;
            } else {
                this.selected = reject(this.selected, asset);
            }
        },
        selectAll(event) {
            if (event.target.checked) {
                let selected = this.selected;
                selected.push(...this.assets);
                this.selected = selected;
            } else {
                this.selected = differenceWith(this.selected, this.assets, isEqual);
            }
        },
        isSelected(asset) {
            let matches = this.selected.filter((a) => a.asset_id == asset.asset_id);

            return (matches.length > 0);
        },
    }
};
</script>
