<template>
<transition
    v-if="isLoading"
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
>
    <div
        class="loading"
    >
        <div class="loading--mask" />
        <span class="loading--message pill --green --rounded">
            <i :class="`far fa-fw fa-spin fa-${icon} fa-sync mr-2`" />
            {{ message }}
        </span>
    </div>
</transition>
</template>

<script>
export default {
    props: {
        isLoading: {
            required: true,
            type: Boolean,
        },
        message: {
            required: false,
            type: String,
            default: 'Updating Results',
        },
        icon: {
            required: false,
            type: String,
            default: 'fa-refresh',
        }
    },
    data() {
        return {};
    },
};
</script>
