<template>
    <a
        :href="tagLink"
        class="py-2 px-6 font-aldine cursor-pointer no-underline"
        :class="[colorClasses, hasIcon ? 'flex gap-2 items-center' : '']">
        <svg
            v-if="hasIcon"
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none">
            <path
                d="M16.8125 13.0312C17.3125 13.9062 16.6875 15 15.6562 15H2.3125C1.28125 15 0.65625 13.9062 1.15625 13.0312L7.84375 1.65625C8.34375 0.78125 9.625 0.78125 10.1562 1.65625L16.8125 13.0312ZM8.25 5.25V9.25C8.25 9.6875 8.625 10 9 10C9.40625 10 9.75 9.6875 9.75 9.25V5.25C9.75 4.84375 9.40625 4.5 9 4.5C8.5625 4.5 8.25 4.84375 8.25 5.25ZM9 13C9.53125 13 9.96875 12.5625 9.96875 12.0312C9.96875 11.5 9.53125 11.0625 9 11.0625C8.4375 11.0625 8 11.5 8 12.0312C8 12.5625 8.4375 13 9 13Z"
                fill="#611B07" />
        </svg>
        {{ title }}
    </a>
</template>

<script>
export default {
    name: 'BaseTitleTag',
    props: {
        title: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: 'notification',
            validator: (val) =>
                ['notification', 'info'].includes(val),
        },
        hasIcon: {
            type: Boolean,
            default: false,
        },
        tagLink: {
            type: String,
            default: null,
        },
    },
    computed: {
        colorClasses() {
            const classes = [];

            // bg-color and text-color
            classes.push(
                {
                    notification: 'text-black bg-coral-medium',
                    info: 'text-blue-darkest bg-blue-light',
                }[this.variant]
            )

            return classes.join(' ')
        },
    },
};
</script>
