<template>
<div class="newsSlider">
    <div class="newsSlider--controls">
        <button
            class="newsSlider--previous"
            @click="prev()"
        >
            <i class="far fa-chevron-left" />
        </button>

        <span class="newsSlider--position">{{ current }} of {{ slides.length }}</span>

        <button
            class="newsSlider--next"
            @click="next()"
        >
            <i class="far fa-chevron-right" />
        </button>
    </div>

    <div class="newsSlider--slides ">
        <div
            class="newsSlider--slide"
        >
            <figure
                v-if="currentSlide.image"
                class="newsSlider--slideImage"
            >
                <img
                    :src="currentSlide.image"
                    :alt="currentSlide.link.altText"
                >
            </figure>
            <div
                v-else
                class="newsSlider--slideImage newsSlider--slideImage--placeholder"
            >
                {{ currentSlide.link.altText }}
            </div>

            <div class="newsSlider--slideContent">
                <span class="newsSlider--slideTitle">{{ currentSlide.title }}</span>

                <div class="newsSlider--slideSummary">
                    {{ currentSlide.summary }}
                </div>

                <a
                    v-if="currentSlide.link.url"
                    class="newsSlider--slideLink button --coral"
                    :href="currentSlide.link.url"
                >
                    {{ currentSlide.link.text || 'Read More' }}
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            current: 1,
        };
    },
    computed: {
        currentSlide() {
            return this.slides[this.current - 1];
        }
    },
    methods: {
        prev() {
            if (this.current === 1) {
                this.current = this.slides.length;
                return;
            }

            this.current--;
        },
        next() {
            if (this.slides.length === this.current) {
                this.current = 1;
                return;
            }

            this.current++;
        }
    }
};
</script>

<style>

</style>
