<template>
    <div class="w-10/12 mx-auto flex flex-wrap py-8">
        <EntryNavigation
            class="w-2/12"
            :items="navItems" />

        <section class="w-10/12 px-8">
            <div
                v-if="type"
                class="w-full border-b flex items-center pb-4 mb-4">
                <h2 class="text-4xl text-gray font-aldine italic mr-4">
                    {{ type.plural }}
                </h2>
                <ButtonPrimarySmall
                    @click="create(type.slug)"
                    text-size="text-xs">
                    Add New {{ type.singular }}
                </ButtonPrimarySmall>
            </div>
            <router-view />
        </section>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import { getEntries } from 'Composables/useEntries.js'

import ButtonPrimarySmall from 'Components/Redesign/Button/ButtonPrimarySmall'
import EntryNavigation from 'Components/Entries/EntryNavigation'

export default {
    components: {
        ButtonPrimarySmall,
        EntryNavigation,
    },
    props: {
        typeSlug: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            navItems: {
                Main: [
                    {
                        routerLink: '/sitemanager/content',
                        text: 'Dashboard',
                        icon: 'fa-globe',
                    },
                ],
                Entries: [
                    {
                        routerLink: '/sitemanager/content/entries/news',
                        text: 'News',
                        icon: 'fa-newspaper',
                        help: 'Inform users about happenings within the Stacks.',
                    },
                    {
                        routerLink: '/sitemanager/content/entries/updates',
                        text: 'Updates',
                        icon: 'fa-exclamation',
                        help: 'Updates are brief messages displayed under the latest news in the "Spotlight" section of the hoamgepage.',
                    },
                    {
                        routerLink: '/sitemanager/content/entries/notifications',
                        text: 'Notifications',
                        icon: 'fa-bolt',
                        help: 'Notifications are displayed in the "Spotlight" section of the homepage.',
                    },
                    {
                        routerLink: '/sitemanager/content/entries/resources',
                        text: 'Resources',
                        icon: 'fa-life-ring',
                        help: 'Longer form help articles.',
                    },
                    {
                        routerLink: '/sitemanager/content/entries/faqs',
                        text: 'FAQs',
                        icon: 'fa-question',
                    },
                ],
                Sections: [
                    // {
                    //     routerLink: '/sitemanager/content/entries/sections',
                    //     text: 'Welcome',
                    //     icon: 'fa-star',
                    //     help: null,
                    // },
                    // {
                    //     routerLink: '/sitemanager/content/entries/sections',
                    //     text: 'Marketing Tools',
                    //     icon: 'fa-paper-plane',
                    //     help: null,
                    // },
                    {
                        routerLink: '/sitemanager/content/entries/sections/contact',
                        text: 'Contact',
                        icon: 'fa-envelope',
                        help: null,
                    },
                ],
                Meta: [
                    {
                        routerLink: '/sitemanager/content/entry-categories',
                        text: 'Categories',
                        icon: 'fa-tag',
                    },
                ],
            },
        }
    },
    computed: {
        ...mapGetters('content', ['types']),
        ...mapState('user', ['user']),
        type() {
            return this.types?.find((type) => this.typeSlug == type.slug)
        },
    },
    methods: {
        async create(type) {
            await this.$store.dispatch('content/setEntry', {
                title: null,
                type: this.typeSlug,
                category_ids: [],
                brands: { data: [] },
                categories: { data: [] },
                properties: { data: [] },
                collections: { data: [] },
                author: { data: this.user },
                status_id: 1,
                content: {
                    type: 'doc',
                    content: [],
                },
                extra: { excerpt: null,},
            })

            try {
                await this.$router.push({ name: 'entries.create', props: { type } })
            } catch (error) {

            }
        },
    },
    mounted() {},
    created() {
        this.$store.dispatch('content/getEntries', this.typeSlug)
    },
}
</script>
