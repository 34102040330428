<template>
    <MultiselectModal
        v-model="tempProperties"
        :is-open="isOpen"
        :options="options"
        label="name"
        @search="updateOptions"
        @save="
            $emit('input', tempProperties)
            $emit('close', true)
        "
        @close="$emit('close', true)">
        <template #title> Select Properties </template>

        <template #option="option">
            {{ option }}
        </template>

        <template #current>
            <div
                class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-4 min-h-24">
                <MultiselectModalItem
                    v-for="(property, i) in tempProperties"
                    :key="i"
                    :name="property.name"
                    :title="property.marsha_code"
                    :truncate-title="true"
                    :truncate-name="true"
                    :reverseOrder="true"
                    @remove="removeTempProperty(property)">
                    <template #icon>
                        <button class="ml-auto">
                            <IconRemoveUser />
                        </button>
                    </template>
                </MultiselectModalItem>
            </div>
        </template>
    </MultiselectModal>
</template>

<script>
import debounce from 'lodash/debounce'

import { getProperties } from 'Composables/useProperties.js'

import MultiselectModal from 'Components/Redesign/MultiselectModal'
import MultiselectModalItem from 'Components/Redesign/MultiselectModal/MultiselectModalItem'
import IconRemoveUser from 'Components/Redesign/Icons/IconRemoveUser'

export default {
    components: {
        IconRemoveUser,
        MultiselectModal,
        MultiselectModalItem,
    },
    props: {
        value: {
            required: true,
            type: [Array],
            default: () => [],
        },
        isOpen: {
            required: true,
            default: false,
            type: Boolean,
        },
    },
    data() {
        return { options: [], tempProperties: [] }
    },
    methods: {
        updateOptions: debounce(async function (filter) {
            if (!filter || filter.length < 3) {
                this.options = []
                return
            }

            try {
                const response = await getProperties({
                    search: filter,
                })

                if (!response.data.data) {
                    throw new Error('Unable to find properties')
                }

                this.options = response.data.data
            } catch (error) {
                console.error(error)
            }
        }, 250),
        removeTempProperty(remove) {
            this.tempProperties = this.tempProperties.filter(
                (property) => property.property_id !== remove.property_id
            )
        },
    },
    mounted() {
        this.tempProperties = this.value
    },
}
</script>
