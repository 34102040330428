<template>
    <div>
        <transition name="fade">
            <div
                v-show="isOpen"
                class="bg-gray-darkest fixed h-full inset-0 opacity-75 w-full z-5"
                @click="closeMenu" />
        </transition>

        <div
            v-show="isOpen"
            class="bg-gold-lightest shadow-xl rounded-2xl text-blue max-w-[1137px] w-full absolute top-0 right-0 py-16 px-12 m-4">
            <div
                class="container mx-auto grid grid-cols-1 md:grid-cols-[max-content_max-content_max-content_max-content] gap-12 mt-6">
                <div
                    class="flex flex-col md:border-r border-blue-medium/50 pr-12">
                    <h3
                        class="text-2xl tracking-[.48px] italic font-normal flex items-center gap-2">
                        Search Assets
                        <IconArrowRight
                            icon-color="blue"
                            class="mt-1" />
                    </h3>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a
                            href="/search?asset_type_ids%5B0%5D=1&order_by=date_created_desc&per_page=72&page=1"
                            >Photos</a
                        >
                        <a
                            href="/search?asset_type_ids%5B0%5D=11&order_by=date_created_desc&per_page=72&page=1"
                            >Videos</a
                        >
                        <a
                            href="/search?asset_type_ids%5B0%5D=3&order_by=date_created_desc&per_page=72&page=1"
                            >Templates</a
                        >
                        <a
                            href="/search?asset_type_ids%5B0%5D=5&order_by=date_created_desc&per_page=72&page=1"
                            >Graphics</a
                        >
                        <a
                            href="/search?asset_type_ids%5B0%5D=12&asset_type_ids%5B1%5D=13&order_by=date_created_desc&per_page=72&page=1"
                            >Maps and Floorplans</a
                        >
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/assets">My Assets</a>
                        <a href="/workspaces">My Groups</a>
                        <a href="/notifications">Comms Pages</a>
                        <a
                            href="#collections-toolkits"
                            @click="handleRedirect">
                            Collections and Toolkits
                        </a>
                    </div>
                </div>
                <div
                    class="flex flex-col md:border-r border-blue-medium/50 pr-12">
                    <h3
                        class="text-2xl tracking-[.32px] italic font-normal flex items-center gap-2">
                        Marketing Tools
                        <IconArrowRight
                            icon-color="blue"
                            class="mt-1" />
                    </h3>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/brandmaker-sso">MI Studio</a>
                        <a
                            href="https://mi.bookmarriott.com/"
                            target="_blank"
                            >Sales Companion</a
                        >
                        <!-- <a href="/">Ad Builder</a> -->
                        <a
                            href="https://marriottintl-digital.lightning.force.com/lightning/page/home"
                            target="_blank"
                            >Marriott Bonvoy Offers</a
                        >
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/assets/collateral/ACH"
                            >Collateral Templates</a
                        >
                        <a href="/assets/guidelines">View Guidelines</a>
                        <a
                            href="https://mi.bookmarriott.com/brandsataglance/"
                            target="_blank"
                            >Brands at a Glance</a
                        >
                        <a
                            href="https://author-marriott-assets-prod.adobecqms.net/"
                            target="_blank"
                            >Submit to DAC</a
                        >
                    </div>
                </div>
                <div
                    class="flex flex-col md:border-r border-blue-medium/50 pr-12">
                    <h3
                        class="text-2xl tracking-[.48px] italic font-normal flex items-center gap-2">
                        Resources
                        <IconArrowRight
                            icon-color="blue"
                            class="mt-1" />
                    </h3>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/resources/faqs">FAQs</a>
                        <a href="#">Articles</a>
                        <a href="#">Tutorials</a>
                        <a href="#">Job Aids</a>
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/notifications">Your Notifications</a>
                        <a href="/news">News and Updates</a>
                        <a
                            href="/search?status=deactivated&order_by=association&per_page=72&page=1"
                            >Deactivated Assets</a
                        >
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/resources">Vendors</a>
                        <a href="/">Matterport</a>
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/resources/terms-of-use">Terms of Use</a>
                        <a href="/contact">Contact Us</a>
                    </div>
                </div>
                <div class="flex flex-col">
                    <h3
                        class="text-2xl tracking-[.48px] italic font-normal flex items-center gap-2">
                        Your Account
                        <IconArrowRight
                            icon-color="blue"
                            class="mt-1" />
                    </h3>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/sitemanager/assets/stored_searches"
                            >Saved Searches</a
                        >
                        <a href="/account/notifications"
                            >Notification Settings</a
                        >
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/workspaces">Workspaces</a>
                        <a href="/work_orders">Tickets</a>
                        <a href="/approvals">Approvals</a>
                        <a
                            href="https://author-marriott-assets-prod.adobecqms.net/"
                            target="_blank"
                            >DAC Submissions</a
                        >
                        <a
                            href="/sup/build/images/MarriottAssetLibrary_REMEDIATIONS_V1.pdf"
                            >Remediations</a
                        >
                    </div>
                    <div class="my-4 flex flex-col gap-2 font-light">
                        <a href="/sitemanager/">Admin (Sitemanager)</a>
                        <a href="/">3rd Party Vendor Management</a>
                        <a href="/">Your Comms Pages</a>
                        <a href="/">User Groups</a>
                        <a href="/assets/logos">Bundler</a>
                    </div>
                </div>
            </div>
            <ButtonStripped
                variant="link"
                size="large"
                class="absolute top-0 right-0 text-gray-dark font-light flex items-center gap-2 px-8 py-6"
                @click="closeMenu">
                Close Menu
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none">
                    <path
                        d="M13.3047 13.0938C13.0703 13.3281 12.6406 13.3281 12.4062 13.0938L7.25 7.89844L2.05469 13.0938C1.82031 13.3281 1.39062 13.3281 1.15625 13.0938C0.921875 12.8594 0.921875 12.4297 1.15625 12.1953L6.35156 7L1.15625 1.84375C0.921875 1.60938 0.921875 1.17969 1.15625 0.945312C1.39062 0.710938 1.82031 0.710938 2.05469 0.945312L7.25 6.14062L12.4062 0.945312C12.6406 0.710938 13.0703 0.710938 13.3047 0.945312C13.5391 1.17969 13.5391 1.60938 13.3047 1.84375L8.10938 7L13.3047 12.1953C13.5391 12.4297 13.5391 12.8594 13.3047 13.0938Z"
                        fill="#0F358A" />
                </svg>
            </ButtonStripped>
        </div>
    </div>
</template>

<script>
import ButtonStripped from '../Button/ButtonStripped.vue'
import IconArrowRight from '../Icons/IconArrowRight.vue'

export default {
    name: 'NavigationMenu',
    components: {
        ButtonStripped,
        IconArrowRight,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isOpen(newValue) {
            if (newValue) {
                this.disableScroll()
            } else {
                this.enableScroll()
            }
        },
    },
    mounted() {
        if (this.isOpen) {
            this.disableScroll()
        }
    },
    beforeDestroy() {
        this.enableScroll()
    },
    methods: {
        closeMenu() {
            this.$emit('close-menu') // Emit event to close the menu
        },
        disableScroll() {
            document.body.style.overflow = 'hidden'
        },
        enableScroll() {
            document.body.style.overflow = ''
        },
        handleRedirect(event) {
            event.preventDefault()

            this.closeMenu()

            const href = event.target.getAttribute('href')
            const isHomepage = window.location.pathname === '/'

            if (!isHomepage) {
                window.location.href = `${
                    window.location.origin
                }/#${href.substring(1)}`
            } else {
                this.scrollToSection(href)
            }
        },
        scrollToSection(href) {
            const element = document.querySelector(href)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

a:hover {
    @apply hover:text-blue-darkest underline transition-all;
}
</style>
