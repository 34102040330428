<script>
import debounce from 'lodash/debounce'
import AssetList from './assetList.vue'
import ByCollection from './byCollection.vue'
import AccountFormSelect from 'Components/Redesign/Form/AccountFormSelect.vue'
import FormInput from 'Components/Redesign/Form/FormInput.vue'
import RadioTab from 'Components/Redesign/RadioTab'

export default {
    // router,
    components: {
        AccountFormSelect,
        AssetList,
        ByCollection,
        FormInput,
        RadioTab,
    },
    props: {
        commsPageId: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        collections: {
            type: Array,
            required: false,
            default: function () {
                return []
            },
        },
        stats: {
            type: [Array, Object],
            required: false,
            default: function () {
                return []
            },
        },
        isAdmin: {
            type: Boolean,
            required: true,
        },
        downloadLimit: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            assetTypeId: null,
            assetTypes: [{ id: null, name: 'Show All Asset Types' }],
            initialUrl: '',
            display: 'by-collection',
            keyword: '',
            selectedNotificationType: 'by-collection',
        }
    },
    computed: {
        totalAssets() {
            return this.stats.total + 0
        },
        byCollection() {
            return this.display == 'by-collection'
        },
        byAssetList() {
            return this.display == 'asset-list'
        },
        collectionIds() {
            let ids = []

            this.collections.forEach((c) => {
                ids.push(c.collection_id)
            })

            return ids
        },
        initialHash() {
            return this.parseHash(this.initialUrl)
        },
    },
    watch: {
        assetTypeId: {
            handler() {
                let hash = this.assetTypeId
                    ? 'asset-type-' + this.assetTypeId
                    : 'all'
                this.pushHistory(hash)
            },
        },
        keyword: debounce(function (newVal) {
            // Wait for user to finish typing keyword before updating browser history
            this.pushCurrentState()
        }, 2000),
    },
    mounted() {
        this.initialUrl = new URL(window.location.href)
        this.keyword = this.initialUrl.searchParams.get('keyword')

        this.stats.by_type.forEach((t) => {
            this.assetTypes.push({ id: t.asset_type_id, name: t.asset_type })
        })

        window.addEventListener('popstate', (event) => {
            this.handleChangedState()
        })

        this.setDisplay(this.initialHash)
    },
    methods: {
        clear() {
            this.keyword = ''
        },
        parseHash(urlObject) {
            let hash = ''

            if (urlObject && urlObject.hash) {
                hash = urlObject.hash.replace('#', '')
            }

            return hash
        },
        toggleDisplay(d) {
            this.display = d

            if (d === 'asset-list') {
                this.pushHistory('all')
            } else {
                this.pushHistory('')
            }

            this.pushHistory('')
        },
        pushCurrentState() {
            let url = new URL(window.location.href)

            let hash = this.parseHash(url)

            this.pushHistory(hash)
        },
        pushHistory(hash) {
            let url = this.initialUrl.pathname

            // querystring must come before hash
            if (this.keyword) {
                url = url + '?keyword=' + encodeURIComponent(this.keyword)
            }

            if (hash) {
                url = url + '#' + hash
            }

            history.pushState({ display: this.display }, this.title, url)
        },
        handleChangedState() {
            // This method is invoked by the listener after the URL has been changed
            let url = new URL(window.location.href)
            let hash = this.parseHash(url)

            this.setDisplay(hash)
        },
        setDisplay(hash) {
            if (hash == 'all') {
                this.display = 'asset-list'
                return
            }
            if (hash.startsWith('asset-type-')) {
                this.display = 'asset-list'
                this.assetTypeId = parseInt(hash.replace('asset-type-', ''))
                return
            }

            this.display = 'by-collection'
        },
    },
}
</script>

<template>
    <div>
        <hr class="my-5 mt-8 -mx-8 border-t-0 border-b-2 border-gray-200" />
        <div class="flex items-center">
            <div class="w-2/3 flex items-center gap-4">
                <div class="mr-6">
                    <RadioTab
                        :name="'displayTab'"
                        :options="[
                            {
                                value: 'by-collection',
                                label: 'View by Collection',
                            },
                            { value: 'asset-list', label: 'View All Assets' },
                        ]"
                        v-model="display"
                        @input="toggleDisplay" />
                </div>
                <div
                    v-if="byAssetList"
                    class="mr-3 w-1/3">
                    <AccountFormSelect
                        v-model="assetTypeId"
                        :name="'asset_type_id'"
                        :options="assetTypes"
                        input-label="Asset Type"
                        placeholder="Show All Asset Types"
                        label="name"
                        :reduce="(option) => option.id"
                        :required="false"
                        class="!text-sm !mt-1 !border-blue-medium" />
                </div>
            </div>
            <div
                v-if="byAssetList"
                class="w-1/3">
                <div class="keyword w-full relative">
                    <FormInput
                        v-model="keyword"
                        type="text"
                        label="Filter assets by keyword" />
                    <div class="clear-link absolute w-auto right-4 top-5">
                        <a
                            v-if="keyword"
                            class="cursor-pointer text-coral-dark text-sm"
                            @click="clear">
                            clear
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr class="mt-5 mb-8 -mx-8 border-t-0 border-b-2 border-gray-200" />
        <asset-list
            v-if="byAssetList"
            :comms-page-id="commsPageId"
            :keyword="keyword"
            :asset-type-id="assetTypeId"
            :collection-ids="collectionIds"
            :is-admin="isAdmin" />
        <by-collection
            v-if="byCollection"
            :comms-page-id="commsPageId"
            :collections="collections"
            :current-hash="initialHash"
            :stats="stats"
            :keyword="keyword"
            :asset-type-id="assetTypeId"
            :is-admin="isAdmin"
            :download-limit="downloadLimit"
            @open="pushHistory" />
    </div>
</template>
