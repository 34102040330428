<template>
    <div>
        <div class="w-full">
            <BaseTitleTag
                variant="info"
                :title="titleTag"
                :tag-link="tagLink" />
        </div>
        <div class="flex flex-col md:flex-row items-start gap-12 mt-6">
            <div :class="{ 'w-7/12': images }">
                <h3
                    class="leading-10 text-gray-darkest"
                    v-html="title" />
                <div
                    class="flex justify-between flex-wrap gap-4 items-center justify-start mt-6">
                    <BasePerson
                        :thumbnail="personThumbnail"
                        :name="personName"
                        :title="`News posted on ${formattedDate}`" />
                    <ButtonPrimarySmall :href="link">
                        Read More
                    </ButtonPrimarySmall>
                </div>
            </div>
            <!-- Image composition -->
            <div
                v-if="images"
                class="relative w-full md:max-w-72 md:pb-4 mt-16 md:mt-0">
                <img
                    v-if="images[0]"
                    :src="images[0]"
                    alt=""
                    class="w-full max-w-64 max-h-52 relative inset-0 transition-all hover:scale-105 z-30 hover:shadow-xl" />
                <img
                    v-if="images[1]"
                    :src="images[1]"
                    alt=""
                    class="max-w-48 max-h-52 absolute inset-0 translate-x-1/2 -translate-y-8 transition-all hover:scale-105 z-20 hover:shadow-xl hover:z-30 object-contain" />
                <img
                    v-if="images[2]"
                    :src="images[2]"
                    alt=""
                    class="max-w-36 max-h-52 absolute inset-0 translate-x-8 -translate-y-16 transition-all hover:scale-105 z-10 hover:shadow-xl hover:z-30 object-contain" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import BasePerson from '../Base/BasePerson.vue'
import BaseTitleTag from '../Base/BaseTitleTag.vue'
import ButtonPrimarySmall from '../Button/ButtonPrimarySmall.vue'

export default {
    name: 'FeaturedNews',
    components: {
        BasePerson,
        BaseTitleTag,
        ButtonPrimarySmall,
    },
    props: {
        entry: {
            type: Object,
            required: true,
            default: null,
        },
    },
    computed: {
        categories() {
            return this.entry.categories?.data ?? []
        },
        collections() {
            return this.entry.collections?.data ?? []
        },
        audience() {
            if (this.categories.length > 0) {
                return this.categories[0].plural
            }

            return null
        },
        title() {
            return this.entry.extra.excerpt
        },
        personName() {
            return `${this.entry.author.data?.first_name} ${this.entry.author.data?.last_name}`
        },
        personThumbnail() {
            return `/img/avatars/${this.entry.author.data?.user_id}`
        },
        link() {
            return this.entry.links.data.view
        },
        tagLink() {
            return this.entry.links.data.view
        },
        titleTag() {
            let title = 'News'

            if (this.audience) {
                title = title + ` About "${this.audience}"`
            }

            return title
        },
        images() {
            if (!this.collections) {
                return null
            }

            if (this.entry.featured_image) {
                return [this.entry.featured_image]
            }

            if (!this.collections.length) {
                return null
            }

            return this.collections[0].thumbnails?.data.map(
                (thumbnail) => thumbnail.url
            )
        },
        formattedDate() {
            return moment(this.entry.created_at).format('MMMM, Do YYYY')
        },
    },
}
</script>
