import VueRouter from 'vue-router'

import { castIdToNumber } from 'Composables/useRouterUtils.js'

import ContentEditor from 'Views/Content/Editor'
import EntryList from 'Components/Entries/EntryList'
import EntryForm from 'Components/Entries/EntryForm'
import CategoryEditor from 'Components/Entries/CategoryEditor'

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            name: 'entries.dashboard',
            path: '/sitemanager/content',
            component: ContentEditor,
            props: true,
            children: [
                {
                    name: 'entries.index',
                    path: '/sitemanager/content/entries/:typeSlug',
                    component: EntryList,
                    props: (route) => {
                        return castIdToNumber(route.params)
                    },
                    children: [],
                },
                {
                    name: 'entries.create',
                    path: '/sitemanager/content/entries/:typeSlug/new',
                    component: EntryForm,
                    props: true,
                },
                {
                    name: 'sections.contact',
                    path: '/sitemanager/content/entries/sections/:entrySlug',
                    component: EntryForm,
                    props: (route) => {
                        return {
                            typeSlug: 'sections',
                            ...route.params,
                        }
                    },
                },
                {
                    name: 'entries.edit',
                    path: '/sitemanager/content/entries/:typeSlug/:id',
                    component: EntryForm,
                    props: (route) => {
                        return castIdToNumber(route.params)
                    },
                },
                {
                    name: 'categories.view',
                    path: '/sitemanager/content/entry-categories',
                    component: CategoryEditor,
                    props: (route) => {
                        return castIdToNumber(route.params)
                    },
                },
            ],
        },
    ],
})
