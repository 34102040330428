<template>
    <transition name="fade">
        <div
            v-if="showTooltip"
            class="absolute -top-full mb-4 w-36 bg-gray text-white text-center py-1 px-1.5 rounded-lg shadow-lg text-xs">
            <span
                class="absolute -bottom-1 left-1/2 -translate-x-1/2 rotate-45 bg-gray h-2.5 w-2.5"></span>
            {{ message }}
        </div>
    </transition>
</template>

<script>
export default {
    name: 'BaseTooltip',
    props: {
        showTooltip: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            required: true,
            default: 'Tooltip text',
        },
    },
}
</script>
