var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseButton',{class:[
        'rounded-full',
        'bg-transparent',
        'border-2',
        'font-medium',
        'md:w-auto',
        'text-center',
        'cursor-pointer',
        'no-underline',
        'transition-colors',
        _vm.textSize,
        _vm.textColor,
        _vm.padding,
        _vm.borderColor ],attrs:{"href":_vm.href},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }