<template>
    <section class="relative h-screen lg:h-[500px] z-20">
        <video
            autoplay
            loop
            muted
            playsinline
            class="absolute inset-0 w-full h-full object-cover">
            <source
                src="/sup/build/images/-12fd-4f71-8f56-ed111d418f3d.mp4"
                type="video/mp4" />
            <source
                src="/sup/build/images/-12fd-4f71-8f56-ed111d418f3d.webm"
                type="video/webm" />
            Your browser does not support the video tag.
        </video>
        <div
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-container px-container mx-auto">
            <div
                class="bg-gray-darkest/60 backdrop-blur text-white rounded-2xl p-8 w-full">
                <div class="flex items-end gap-6 mb-6">
                    <div class="text-6xl leading-none font-light">Search</div>
                    <ButtonStripped
                        href="/search/"
                        :has-arrow="true"
                        arrow-color="white"
                        class="text-white font-heavy">
                        Advanced Search
                    </ButtonStripped>
                </div>
                <div
                    class="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_1fr_max-content] grid-rows-[95px_min-content] items-start gap-4 w-full">
                    <SearchInput
                        id="brand_ids"
                        v-model="brands"
                        :endpoint="endpoints.BRANDS_LIST"
                        label="Brand"
                        filter-by="name"
                        selected-display-key="name"
                        search-display-key="name"
                        search-type="brands"
                        label-plural="Brands"
                        placeholder="Add brands to your search"
                        @input="search" />

                    <SearchInput
                        id="property_ids"
                        v-model="properties"
                        :endpoint="endpoints.PROPERTIES_LIST"
                        label="Property"
                        filter-by="name"
                        selected-display-key="name"
                        search-display-key="name"
                        search-type="properties"
                        :option-text-transform="
                            (property) =>
                                `${property.marsha_code} - ${property.name}`
                        "
                        :selected-text-transform="formattedPropertyName"
                        label-plural="Properties"
                        placeholder="Add properties to your search"
                        :live-search="true"
                        search-param="search"
                        search-help-text="Please enter MARSHA code, property name or city"
                        @input="search" />

                    <SearchInput
                        id="asset_type_Ids"
                        v-model="assetTypes"
                        :endpoint="endpoints.ASSET_TYPES_LIST"
                        label="Asset Type"
                        filter-by="name"
                        selected-display-key="name"
                        search-display-key="name"
                        search-type="asset_types"
                        label-plural="Asset Types"
                        placeholder="Add asset types to your search"
                        @input="search">
                    </SearchInput>

                    <SearchKeywordInput
                        id="keywords"
                        v-model="keywords"
                        label="Keywords"
                        label-plural="Keywords"
                        selected-display-key="name"
                        search-display-key="name"
                        search-type="keywords"
                        placeholder="Add keywords to your search"
                        :live-search="true"
                        search-param="search"
                        @input="search" />

                    <form
                        v-if="!liveSearch"
                        method="GET"
                        action="/search"
                        class="self-end">
                        <input
                            v-for="(property, i) in properties"
                            :key="property.property_id"
                            type="hidden"
                            :name="`property_ids[${i}]`"
                            :value="property.property_id" />

                        <input
                            v-for="(brand, i) in brands"
                            :key="brand.brand_id"
                            type="hidden"
                            :name="`brand_ids[${i}]`"
                            :value="brand.brand_id" />

                        <input
                            v-for="(assetType, i) in assetTypes"
                            :key="assetType.asset_type_id"
                            type="hidden"
                            :name="`asset_type_ids[${i}]`"
                            :value="assetType.asset_type_id" />

                        <input
                            v-for="(keyword, i) in keywords"
                            :key="i"
                            type="hidden"
                            :name="`keywords[${i}]`"
                            :value="keyword" />

                        <ButtonSecondary type="submit">
                            Search
                        </ButtonSecondary>
                    </form>

                    <div
                        class="flex flex-row md:flex-col justify-between md:justify-end gap-2 text-center h-full">
                        <ButtonStripped
                            v-if="liveSearch"
                            @click.prevent="clearSearch"
                            class="text-white text-sm font-normal cursor-pointer">
                            Clear Search
                        </ButtonStripped>

                        <ButtonSecondary
                            v-if="liveSearch"
                            @click.prevent="search">
                            Search
                        </ButtonSecondary>

                        <form
                            v-if="!liveSearch"
                            method="GET"
                            action="/search"
                            class="assetSearch--form">
                            <input
                                v-for="(property, i) in properties"
                                :key="property.property_id"
                                type="hidden"
                                :name="`property_ids[${i}]`"
                                :value="property.property_id" />

                            <input
                                v-for="(brand, i) in brands"
                                :key="brand.brand_id"
                                type="hidden"
                                :name="`brand_ids[${i}]`"
                                :value="brand.brand_id" />

                            <input
                                v-for="(assetType, i) in assetTypes"
                                :key="assetType.asset_type_id"
                                type="hidden"
                                :name="`asset_type_ids[${i}]`"
                                :value="assetType.asset_type_id" />

                            <input
                                v-for="(keyword, i) in keywords"
                                :key="i"
                                type="hidden"
                                :name="`keywords[${i}]`"
                                :value="keyword" />
                        </form>
                    </div>
                </div>
            </div>

            <div class="assetSearch--actions">
                <!-- <div class="assetSearch--recentSearches">
                    <drawer>
                        <template #trigger="{ toggleDrawer }">
                            <button
                                class="button --outline --normal text-sm"
                                @click="toggleDrawer"
                                @keyup.esc="toggleDrawer"
                            >
                                Recent <span v-if="isAdmin">and Saved</span> Searches <i class="far fa-fw fa-angle-down text-coral" />
                            </button>
                        </template>

                        <template
                            #drawer
                        >
                            <recent-stored-searches :is-admin="isAdmin" />
                        </template>
                    </drawer>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
import Drawer from '../Drawer'
import SearchInput from '../Search/SearchInput'
import SearchKeywordInput from '../Search/SearchKeywordInput'
import ButtonSecondary from '../Button/ButtonSecondary'
import ButtonStripped from '../Button/ButtonStripped'
import RecentStoredSearches from 'Components/Redesign/RecentStoredSearches'
import store from 'Stores/Frontend'
import endpoints from 'Root/endpoints'
import {
    SET_SEARCH_PROPERTIES,
    SET_SEARCH_BRANDS,
    SET_SEARCH_KEYWORDS,
    SET_SEARCH_ASSET_TYPES,
    SET_SEARCH_PAGE,
} from 'Stores/Frontend/mutation-types'

export default {
    store,
    name: 'Search',
    components: {
        Drawer,
        RecentStoredSearches,
        SearchInput,
        SearchKeywordInput,
        ButtonSecondary,
        ButtonStripped,
    },
    props: {
        /**
         * Whether or not to perform the search via ajax or send a request to /search directly
         */
        liveSearch: {
            type: Boolean,
            default: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Object,
            required: false,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            endpoints,
            recentSearches: [],
            savedSearches: [],
        }
    },
    mounted() {
        //
    },
    computed: {
        properties: {
            get() {
                return this.$store.state.search.query.properties
            },
            set(properties) {
                this.$store.commit(SET_SEARCH_PROPERTIES, properties)
            },
        },
        brands: {
            get() {
                return this.$store.state.search.query.brands
            },
            set(brands) {
                this.$store.commit(SET_SEARCH_BRANDS, brands)
            },
        },
        keywords: {
            get() {
                return this.$store.state.search.query.keywords
            },
            set(keywords) {
                this.$store.commit(SET_SEARCH_KEYWORDS, keywords)
            },
        },
        assetTypes: {
            get() {
                return this.$store.state.search.query.asset_types
            },
            set(assetTypes) {
                this.$store.commit(SET_SEARCH_ASSET_TYPES, assetTypes)
            },
        },
        inputPropertyIds() {
            return this.properties
                .map((property) => {
                    return property.property_id
                })
                .join(',')
        },
        inputBrandIds() {
            return this.brands
                .map((brand) => {
                    return brand.brand_id
                })
                .join(',')
        },
        inputAssetTypeIds() {
            return this.assetTypes
                .map((assetType) => {
                    return assetType.asset_type_id
                })
                .join(',')
        },
        inputKeywords() {
            return this.keywords
                .map((keyword) => {
                    return keyword.phrase
                })
                .join(',')
        },
        countQueryParams() {
            let i = 0
            let c = 0
            let qry = this.$store.state.search.query

            let arrays = [
                'asset_type_ids',
                'brand_ids',
                'cities',
                'collection_ids',
                'color_ids',
                'containers',
                'destination_ids',
                'division_ids',
                'file_type_ids',
                'groups',
                'keywords',
                'property_ids',
                'region_ids',
                'states',
                'subjects',
                'template_type_category_ids',
                'template_type_ids',
                'user_group_ids',
            ]

            for (i = 0; i < arrays.length; i++) {
                if (qry[arrays[i]] !== undefined) {
                    c += qry[arrays[i]].length
                }
            }

            let not_nulls = [
                'brand_only',
                'date_created_start',
                'date_created_end',
                'date_deactivated_start',
                'date_deactivated_end',
                'destination_controls',
                'from_dac',
                'from_mdam',
                'has_thumbnails',
                'is_offer',
                'is_stock',
                'last_modified_start',
                'last_modified_end',
                'limited_rights',
                'metadata',
                'name',
                'property_only',
                'rights_managed',
                'travel_advisor_only',
                'usage_rights',
            ]

            for (i = 0; i < not_nulls.length; i++) {
                if (qry[not_nulls[i]] !== undefined) {
                    if (qry[not_nulls[i]]) {
                        c += 1
                    }
                }
            }

            return c
        },
    },
    methods: {
        search() {
            if (this.liveSearch) {
                this.$store.commit(SET_SEARCH_PAGE, 1)
                this.$store.dispatch('search')
            } else {
                const queryParams = new URLSearchParams({
                    property_ids: this.inputPropertyIds,
                    brand_ids: this.inputBrandIds,
                    asset_type_ids: this.inputAssetTypeIds,
                    keywords: this.inputKeywords,
                }).toString()

                this.$router.push(`/search/?${queryParams}`)
            }
        },
        clearSearch() {
            this.$store.commit(SET_SEARCH_PROPERTIES, [])
            this.$store.commit(SET_SEARCH_BRANDS, [])
            this.$store.commit(SET_SEARCH_ASSET_TYPES, [])
            this.$store.commit(SET_SEARCH_KEYWORDS, [])
        },
        formattedPropertyName(property) {
            let formatted = property.marsha_code + ' - ' + property.name

            if (formatted.length > 30) {
                formatted =
                    property.marsha_code +
                    ' - ' +
                    property.name.substr(0, 25) +
                    '...'
            }

            return formatted
        },
    },
}
</script>
