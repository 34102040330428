<template>
<div
    :class="[
        'assetListingControls',
    ]">
    <label class="assetListingControls--selectAll">
        <input
            type="checkbox"
            :checked="allSelected"
            @input="$emit('select-all', $event)"
        >
        Select All
    </label>

    <toggle-switch
        id="activeAssetsOnly"
        v-model="activeAssetsOnly"
        name="activeAssetsOnly"
        :unchecked-value="{id: 0, name: 'All', value: 'all'}"
        :input-value="{id: 5, name: 'Active', value: 'active'}"
        text="Active assets only"
    />

    <span
        v-if="total"
        class="assetListingControls__results inline-block">
        {{ total }}{{ total === 10000 ? '+' : null }} matching results
    </span>

    <label class="assetListingControls--limit">
        Results per page
        <v-select
            v-model="perPage"
            :options="options"
        />
    </label>

    <paginate
        v-if="pages"
        v-model="currentPage"
        container-class="pagination"
        :page-count="pages"
        :click-handler="changePage"
        :page-range="5"
        break-view-class="pagination--breakView"
        class="ml-auto">
        <template v-slot:prev>
            <i class="pagination--prev far fa-fw fa-chevron-left" />
        </template>

        <template v-slot:breakViewContent>
            <div class="">
                <input
                    v-if="viewGoToPage"
                    ref="goToPageInput"
                    v-model.lazy="currentPage"
                    type="text"
                    class="pagination--goToPage"
                    @blur="viewGoToPage = false"
                >
                <span
                    class="cursor-pointer text-slate-text"
                    @click="showGoToPage()"
                >...</span>
            </div>
        </template>

        <template v-slot:next>
            <i class="pagination--next text-coral far fa-fw fa-chevron-right" />
        </template>
    </paginate>
</div>
</template>

<script>
import ToggleSwitch from 'Components/Redesign/ToggleSwitch';
import Paginate from 'vuejs-paginate';
import vSelect from 'vue-select';
import debounce from 'lodash/debounce';

import {
    SET_SEARCH_PER_PAGE,
    SET_SEARCH_PAGE,
    SET_SEARCH_STATUS,
} from 'Stores/Frontend/mutation-types';

export default {
    name: 'AssetListingControls',
    components: {
        ToggleSwitch,
        Paginate,
        vSelect
    },
    props: {
        allSelected: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            selected: [],
            originalStatus: null,
            viewGoToPage: false,
            options: [24,48,72,96,120] // This array needs to match .../Api/Structure/SearchController.php
        };
    },
    computed: {
        isLoading() {
            return this.$store.state.search.isLoading;
        },
        perPage: {
            get() {
                return this.$store.state.search.query.per_page;
            },
            set(value) {
                this.$store.commit(SET_SEARCH_PER_PAGE, value);
                this.$store.dispatch('search');
            }
        },
        pages: {
            get() {
                return this.$store.state.search.meta.pagination.total_pages;
            },
        },
        currentPage: {
            get() {
                return this.$store.state.search.meta.pagination.current_page;
            },
            set(value) {
                this.changePage(value);
            }
        },
        total: {
            get() {
                return this.$store.state.search.meta.pagination.total;
            }
        },
        activeAssetsOnly: {
            get() {
                return this.$store.state.search.query.status.value === 'active';
            },
            set(value) {
                this.$store.commit(SET_SEARCH_STATUS, value);
                this.$store.dispatch('search');
            },
        },
    },
    methods: {
        changePage: debounce(function(page) {
            this.$store.commit(SET_SEARCH_PAGE, page);
            this.$store.dispatch('search');

            this.viewGoToPage = false;

            let assetSearch = document.querySelector('.assetSearch');

            if (assetSearch) {
                assetSearch.scrollIntoView({
                    behavior: 'smooth',
                });
            }
            
        }, 200, {leading:false}),
        showGoToPage() {
            this.viewGoToPage = true;

            this.$nextTick(() => {
                this.$refs.goToPageInput.focus();
            });
        }
    }
};
</script>
