<template>
    <div>
        <h2
            v-if="title"
            class="text-2xl font-normal font-aldine text-blue-darkest">
            {{ title }}
        </h2>
        <ul class="flex flex-col gap-4 pt-4">
            <li
                v-for="(item, i) in items"
                :key="i"
                class="p-4 shadow-blue-double rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out font-bold"
                :class="
                    isSelected(item)
                        ? 'bg-white text-blue-darkest'
                        : 'bg-blue-lightest text-blue'
                "
                @click="selectItem(item)">
                <a
                    v-if="item.href"
                    :href="item.href"
                    v-text="text(item)" />
                <span
                    v-else
                    v-text="text(item)" />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BaseSidebar',
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        selectedItem: {
            type: [Object, String],
            required: false,
        },
    },
    computed: {
        text: () => (item) => {
            return typeof item === 'object' ? item.name : item
        },
    },
    methods: {
        selectItem(item) {
            this.$emit('item-selected', item)
        },
        isSelected(item) {
            if (typeof this.selectedItem === 'string') {
                return item.name === this.selectedItem
            }
            if (typeof this.selectedItem === 'object') {
                return item.name === this.selectedItem.name
            }
            return false
        },
    },
}
</script>
