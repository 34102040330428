<template>
    <!-- Conditional rendering based on the presence of href prop -->
    <button
        v-if="!href"
        class="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
        @click="$emit('click', $event)"
    >
        <slot />
    </button>
    <a
        v-else
        :href="href"
        :target="target"
    >
        <slot />
    </a>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        href: {
            // Render the button as an anchor tag
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: null,
            validator: (val) =>
                ['_blank', '_parent', '_self', '_top'].includes(val),
        },
    },
    methods: {
        mouseEnter(event) {
            this.$emit('mouseenter', event)
        },
        mouseLeave(event) {
            this.$emit('mouseleave', event)
        },
    },
}
</script>

