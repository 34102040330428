let FormMixins = {
    props: {
        csrfToken: {
            required: true,
            type: String,
        },
        csrfName: {
            type: String,
            default: 'csrf_token',
            required: false,
        }
    }
};

export default FormMixins;
