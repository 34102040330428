<template>
    <div>
        <EntryInput
            id="excerpt"
            v-model="data"
            :label="label"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :required="true" />

        <span class="font-aldine italic inline-block py-2 mb-2"
            ><slot name="help"
                >The excerpt will be displayed in the notifications list
                entry</slot
            ></span
        >
    </div>
</template>

<script>
import EntryInput from 'Components/Entries/EntryFields/EntryInput'

export default {
    components: {
        EntryInput,
    },
    props: {
        value: {
            required: false,
            default: null,
        },
        showCount: {
            required: false,
            default: true,
            type: Boolean,
        },
        maxlength: {
            required: false,
            default: 50,
            type: Number,
        },
        placeholder: {
            required: false,
            type: String,
            default: null,
        },
    },

    data() {
        return {}
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
        label() {
            let label = 'Excerpt'

            if (this.showCount) {
                label += ` ${this.count}/${this.maxlength}`
            }

            return label
        },
        count() {
            if (!this.data) {
                return 0
            }

            return this.data.length
        },
    },
    watch: {},
}
</script>
