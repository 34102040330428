<template>
    <div>
        <label
            :for="inputId"
            class="font-bold">
            {{ label }}
            <span
                v-if="optionalText"
                class="font-normal">
                {{ optionalText }}
            </span>
        </label>
        <textarea
            :id="inputId"
            :name="name"
            :placeholder="placeholder"
            :value="value"
            :class="classes"
            :required="required"
            @input="$emit('input', $event.target.value)"
        />
    </div>
</template>

<script>
export default {
    name: 'FormTextArea',
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Object],
            default: null,
        },
        inputId: {
            type: String,
            default: null,
        },
        optionalText: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
            required: false,
        },
        classes: {
            type: String,
            default: 'border-none shadow-lg p-4 rounded-md w-full min-h-40 mt-2',
        },
        required: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

