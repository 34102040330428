<template>
    <div class="assetMetadata">
        <form
            :action="action"
            method="POST">
            <input
                type="hidden"
                name="asset_id"
                :value="asset.asset_id" />

            <input
                type="hidden"
                :name="csrfName"
                :value="csrfToken" />

            <div class="assetMetadata--actions">
                <h2>Asset Information</h2>
                <ButtonPrimary> Update Asset Data </ButtonPrimary>
            </div>

            <div class="assetMetadata__formGroup -bg-none form__grid">
                <div class="form__inputGroup -spanTwo">
                    <label
                        class="form__label"
                        for="elevate_in_gallery">
                        Elevate in Gallery
                        <input
                            id="elevate_in_gallery"
                            v-model="elevateInGallery"
                            class="ml-2"
                            type="checkbox"
                            name="elevate_in_gallery" />
                    </label>
                </div>

                <div class="form__inputGroup -spanTwo">
                    <label
                        class="form__label inline-block"
                        for="travel_advisor_only">
                        Travel Advisor Only
                        <input
                            id="travel_advisor_only"
                            v-model="travelAdvisorOnly"
                            class="ml-2"
                            type="checkbox"
                            name="travel_advisor_only" />
                    </label>
                </div>

                <div
                    v-if="asset.property_id"
                    class="form__inputGroup -spanTwo">
                    <label
                        class="form__label inline-block"
                        for="primary_property_image">
                        Primary Property Image
                        <input
                            id="primary_property_image"
                            v-model="isPrimaryPropertyImage"
                            class="ml-2"
                            type="checkbox"
                            name="primary_property_image" />
                    </label>
                </div>

                <div class="form__inputGroup -spanThree">
                    <label
                        class="form__label"
                        for="keywords">
                        Keywords
                    </label>

                    <input
                        type="hidden"
                        name="keywords"
                        v-model="metadata" />

                    <textarea
                        id="keywords"
                        v-model="keywords"
                        :disabled="!isAdmin"
                        class="form__textarea"
                        name="keywords" />
                </div>

                <div class="form__inputGroup -spanThree">
                    <label
                        class="form__label"
                        for="metadata">
                        Metadata
                        <span class="form__help"
                            >Add terms and keywords to help in keyword search
                            below</span
                        >
                    </label>

                    <input
                        name="metadata"
                        type="hidden"
                        v-model="metadata" />

                    <textarea
                        id="metadata"
                        v-model="metadata"
                        :disabled="!isAdmin"
                        class="form__textarea"
                        name="metadata" />
                </div>

                <div class="form__inputGroup -spanThree">
                    <label
                        class="form__label"
                        for="marketing_caption">
                        Marketing Caption
                        <span class="form__help"
                            >Add marketing caption below (300 character
                            limit)</span
                        >
                    </label>

                    <input
                        name="marketing_caption"
                        :value="marketingCaption"
                        type="hidden" />

                    <textarea
                        id="marketing_caption"
                        :disabled="!isAdmin"
                        class="form__textarea"
                        name="marketing_caption"
                        :value="marketingCaption" />
                </div>

                <!-- <div
                v-if="isSuperAdmin"
                class="form__inputGroup"
            >
                <label
                    class="form__label"
                    for="asset_comment"
                >
                    Asset Comments
                    <span
                        v-if="!comments"
                        class="form__help"
                    >
                        There are currently no asset comments
                    </span>
                </label>

            </div> -->

                <div
                    v-if="isSuperAdmin"
                    class="form__inputGroup -spanThree">
                    <ul
                        v-if="comments"
                        class="assetMetadata--comments">
                        <li
                            v-for="(comment, i) in comments"
                            :key="comment.comment_id"
                            :class="['assetMetadata--comment']">
                            <span class="assetMetadata--commentUser">
                                {{ i + 1 }}. {{ comment.user.data.full_name }}
                                {{
                                    comment.user.data.is_admin
                                        ? '(Admin)'
                                        : null
                                }}
                            </span>
                            <span class="assetMetadata--commentDate text-xs"
                                >on {{ comment.date_created }}:</span
                            >
                            <p class="assetMetadata--commentText">
                                {{ comment.comment }}
                            </p>
                            <div class="assetMetadata--comentActions flex">
                                <label class="text-sm ml-auto inline-block">
                                    Delete Comment
                                    <input
                                        :id="`delete_asset_comment_ids_${comment.asset_comment_id}`"
                                        type="checkbox"
                                        class="ml-2"
                                        :value="comment.asset_comment_id"
                                        name="delete_asset_comment_ids[]" />
                                </label>
                            </div>
                        </li>
                    </ul>

                    <label
                        class="form__label"
                        for="comments">
                        Asset Comment
                        <span
                            v-if="!comments"
                            class="form__help">
                            There are currently no asset comments
                        </span>
                    </label>
                    <textarea
                        id="comments"
                        v-model="newComment"
                        class="form__textarea"
                        name="comment" />
                </div>

                <div
                    v-if="isAdmin && asset.asset_type.data.name === 'Photo'"
                    class="form__inputGroup">
                    <label
                        class="form__label"
                        for="report_card_notes">
                        Report Card Notes
                    </label>
                    <textarea
                        id="report_card_notes"
                        class="form__textarea"
                        name="report_card_notes"
                        :value="reportCardNotes" />
                </div>

                <div
                    v-if="isAdmin && asset.asset_type.data.name === 'Photo'"
                    class="form__inputGroup mb-8">
                    <span class="form__label">Report Card Grade</span>

                    <div class="form__inputGroup--radioButtons">
                        <label class="flex items-center gap-4">
                            <span class="radio--input">
                                <input
                                    v-model="reportCardGrade"
                                    name="report_card_grade"
                                    type="radio"
                                    value="pass" />
                                <span class="radio--control" />
                            </span>
                            <span class="radio--label">Pass</span>
                        </label>

                        <label class="flex items-center gap-4">
                            <span class="radio--input">
                                <input
                                    v-model="reportCardGrade"
                                    name="report_card_grade"
                                    type="radio"
                                    value="missing" />
                                <span class="radio--control" />
                            </span>
                            <span class="radio--label">Missing</span>
                        </label>

                        <label class="flex items-center gap-4">
                            <span class="radio--input">
                                <input
                                    v-model="reportCardGrade"
                                    name="report_card_grade"
                                    type="radio"
                                    value="aged" />
                                <span class="radio--control" />
                            </span>
                            <span class="radio--label">Old</span>
                        </label>

                        <label class="flex items-center gap-4">
                            <span class="radio--input">
                                <input
                                    v-model="reportCardGrade"
                                    name="report_card_grade"
                                    type="radio"
                                    value="quality" />
                                <span class="radio--control" />
                            </span>
                            <span class="radio--label"
                                >Doesn't Meet Quality Standards</span
                            >
                        </label>

                        <label class="flex items-center gap-4">
                            <span class="radio--input">
                                <input
                                    v-model="reportCardGrade"
                                    name="report_card_grade"
                                    type="radio"
                                    value="brand" />
                                <span class="radio--control" />
                            </span>
                            <span class="radio--label"
                                >Doesn't Meet Brand Standards</span
                            >
                        </label>

                        <label class="flex items-center gap-4">
                            <span class="radio--input">
                                <input
                                    v-model="reportCardGrade"
                                    name="report_card_grade"
                                    type="radio"
                                    value="other" />
                                <span class="radio--control" />
                            </span>
                            <span class="radio--label">Other</span>
                        </label>
                    </div>
                </div>

                <div
                    v-if="isAdmin"
                    class="assetMetadata--actions">
                    <ButtonPrimary> Update Asset Data </ButtonPrimary>
                </div>
            </div>
        </form>

        <div
            v-if="isAdmin"
            class="assetMetadata--assetHistory">
            <h2>Asset History</h2>
            <div v-if="historyNotes">
                <ul>
                    <li
                        v-for="(note, i) in historyNotes"
                        :key="note.asset_history_note_id"
                        :class="['assetMetadata--comment']">
                        <span class="assetMetadata--commentUser">
                            {{ i + 1 }}.
                            {{
                                note.user.data.full_name
                                    ? note.user.data.full_name
                                    : 'USER DELETED'
                            }}
                            {{ note.user.data.is_admin ? '(Admin)' : null }}
                        </span>
                        <span class="assetMetadata--commentDate text-xs"
                            >on {{ note.date_created }}:</span
                        >
                        <p class="assetMetadata--commentText">
                            {{ note.note }}
                        </p>
                    </li>
                </ul>
            </div>
            <div v-else>
                <span>There is currently no asset history</span>
            </div>
        </div>
    </div>
</template>

<script>
import AssetMixins from 'Components/Redesign/Mixins/AssetMixins'
import ButtonPrimary from '../Button/ButtonPrimary.vue'
import FormMixins from 'Components/Redesign/Mixins/FormMixins'
export default {
    name: 'AssetMetadata',
    components: {
        ButtonPrimary,
    },
    mixins: [AssetMixins, FormMixins],
    props: {
        isSuperAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
        action: {
            required: false,
            type: String,
            default() {
                return `/search/view?asset_id=${this.asset.asset_id}`
            },
        },
    },
    data() {
        return {}
    },
    computed: {},
}
</script>

<style></style>
