import axios from 'axios'

import { USER_WORKSPACES_UPDATE, USER_WORKSPACES_LIST } from 'Root/endpoints.js'

export async function createTag(tag) {}

export async function getTags(workspace) {
    return await axios.get(
        `${USER_WORKSPACES_LIST}/${workspace.workspace_id}/tags`
    )
}

export async function deleteTag(tag) {}

export async function updateTag(workspace, tag) {
    return await axios.put(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}/${tag.id}`,
        { tag }
    )
}

export async function tagAsset({ workspace, tag, asset }) {
    return await axios.post(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}/tags/asset`,
        {
            tag,
            asset,
        }
    )
}

export async function untagAsset({ workspace, tag, asset }) {
    return await axios.delete(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}/tags/asset`,
        {
            data: {
                tag,
                asset,
            },
        }
    )
}

export async function getWorkspace({ id, includes = [] }) {
    return await axios.get(`${USER_WORKSPACES_LIST}/${id}`, {
        params: { includes },
    })
}

export async function getComments({ workspace }) {
    return await axios.get(`${USER_WORKSPACES_LIST}/${workspace.workspace_id}`)
}

export async function postComment({ workspace, comment }) {
    return await axios.post(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}/comments`,
        { comment }
    )
}

export async function updateUsers({ workspace, users }) {
    return await axios.put(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}`,
        {
            user_ids: users.map((user) => user.user_id),
        }
    )
}

export async function rename({ workspace, name }) {
    return await axios.put(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}`,
        { name }
    )
}

export async function sendContactSheet({ workspace }) {
    return await axios.post(
        `${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}/pdf`
    )
}

export function assetHasTag({ asset, tag }) {
    return (
        asset.workspace_tags.data.find(
            (tagged) => tagged.name.toLowerCase() === tag.name.toLowerCase()
        ) !== undefined
    )
}

export default {
    createTag,
    deleteTag,
    getComments,
    getTags,
    getWorkspace,
    postComment,
    tagAsset,
    untagAsset,
    updateTag,
    updateUsers,
}
