import {
    SET_USER_NOTIFICATIONS,
    SET_USER_NOTIFICATION_TO_UPDATE,
} from 'Stores/Frontend/mutation-types'

import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'

Vue.use(Vuex)

const notifications = {
    namespaced: true,
    state: {
        notifications: null,
        notificationBeingUpdated: null,
    },
    actions: {
        async fetchNotifications({ commit }) {
            const response = await axios.get('/api/account/notifications')
            commit(SET_USER_NOTIFICATIONS, response.data)
        },
        async createNotification({ commit }, notification) {
            const response = await axios.post(
                '/api/account/notifications/save',
                notification
            )

            if (response.data.success) {
                return true
            }

            return false
        },
        async updateNotification({ commit }, { notification, id }) {
            const response = await axios.post(
                `/api/account/notifications/${id}/save`,
                notification
            )

            if (response.data.success) {
                return true
            }

            return false
        },
        async deleteNotification({ commit }, id) {
            const response = await axios.delete(
                `/api/account/notifications/${id}/delete`
            )

            if (response.data.success) {
                return true
            }

            return false
        },
        setNotificationToUpdate({ commit }, notification) {
            commit(SET_USER_NOTIFICATION_TO_UPDATE, notification)
        },
    },
    mutations: {
        [SET_USER_NOTIFICATIONS](state, notifications) {
            state.notifications = notifications
        },
        [SET_USER_NOTIFICATION_TO_UPDATE](state, notification) {
            state.notificationBeingUpdated = notification
        },
    },
}

export default notifications
