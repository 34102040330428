<template>
    <section>
        <strong v-if="!hasEntries"
            >Sorry, no {{ singularType }} entries are available</strong
        >

        <div
            v-if="hasEntries"
            class="w-full mb-4 flex items-center gap-4">
            <span class="inline-block mr-auto"
                >{{ totalEntries }} entries found</span
            >
            <BaseSelect
                id="status"
                :dropdown-options="statusOptions"
                v-model="selectedFilters.status"
                >Status</BaseSelect
            >
        </div>

        <table
            v-if="hasEntries"
            class="min-w-full divide-y divide-blue-dark">
            <thead>
                <!-- <th class="px-4 py-2 text-left text-sm font-semibold"> -->
                <!--     <input -->
                <!--         class="rounded" -->
                <!--         type="checkbox" -->
                <!--         @change="toggleSelected($event.target.checked)" /> -->
                <!-- </th> -->

                <th
                    scope="col"
                    class="px-4 py-2 text-left text-sm font-semibold "
                    @click="sortBy('title')">
                    Title
                </th>

                <th
                    scope="col"
                    class="px-4 py-2 text-left text-sm font-semibold sm:pl-0"
                    @click="sortBy('title')">
                    Status
                </th>

                <th
                    scope="col"
                    class="px-4 py-2 text-left text-sm font-semibold sm:pl-0"
                    @click="sortBy('author')">
                    Categories
                </th>

                <th
                    scope="col"
                    class="px-4 py-2 text-left text-sm font-semibold sm:pl-0"
                    @click="sortBy('author')">
                    Author
                </th>
                <th
                    scope="col"
                    class="px-4 py-2 text-left text-sm font-semibold sm:pl-0"
                    @click="sortBy('date')">
                    Date
                </th>
            </thead>
            <tbody class="divide-y divide-blue-light">
                <tr
                    v-for="entry in entries"
                    class="w-full hover:bg-blue-lightest">
                    <!-- <td class="px-4 py-2"> -->
                    <!--     <input -->
                    <!--         class="rounded" -->
                    <!--         type="checkbox" -->
                    <!--         v-model="selected" -->
                    <!--         :value="entry" /> -->
                    <!-- </td> -->

                    <td class="px-4 py-2 font-medium pr-4">
                        <a
                            class="block text-lg text-gray-darkest hover:underline"
                            :href="itemLinks(entry).edit"
                            >{{ entry.title }}</a
                        >
                        <div>
                            <a
                                class="text-blue-dark hover:underline mr-4"
                                v-if="itemLinks(entry).view"
                                :href="itemLinks(entry).view"
                                ><i class="far fa-arrow-right" /> View</a
                            >

                            <button
                                @click="deleteEntry(entry)"
                                class="text-danger-dark hover:underline text-xs"
                                v-if="itemLinks(entry).delete"
                                ><i class="far fa-trash" /> Delete
                            </button>
                        </div>
                    </td>

                    <td class="pr-8">{{ entry.status.data.title }}</td>

                    <td class="pr-8">
                        {{
                            entry.categories.data
                                .map((category) => category.title)
                                .join(', ')
                        }}
                    </td>

                    <td class="pr-8">
                        {{ entry.author.data.email }}
                    </td>

                    <td class="py-4 pr-2">
                        <!-- <div mb-2> -->
                        <!--     <span class="text-sm text-gray-darkest" -->
                        <!--         >Created</span -->
                        <!--     > -->
                        <!--     <span -->
                        <!--         class="" -->
                        <!--         v-text="formatDate(entry.created_at)" /> -->
                        <!-- </div> -->

                        <div>
                            <span class="text-sm text-gray-darkest"
                                >Last Updated</span
                            >
                            <br />
                            <span v-text="formatDate(entry.updated_at)" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'

import BaseSelect from 'Components/Redesign/Base/BaseSelect.vue'

import _get from 'lodash/get'

export default {
    props: { typeSlug: { required: true, type: String, default: null } },
    components: {
        BaseSelect,
    },
    data() {
        return {
            selected: [],
        }
    },
    computed: {
        ...mapState('content', [
            'entries',
            'type',
            'pagination',
            'isLoading',
            'filters',
            'meta',
        ]),
        ...mapGetters('content', [
            'types',
            'statuses',
            'categories',
            'totalEntries',
            'totalPages',
            'nextPage',
            'prevPage',
        ]),
        singularType() {
            if (this.type) {
                return this.type.singular
            }

            return ''
        },
        hasEntries() {
            if (!this.entries) {
                return false
            }

            return this.entries.length > 0
        },
        header() {
            if (!this.entries) {
                return null
            }

            if (this.entryFields) {
                return this.entryFields.map((field) => field.label)
            }

            return Object.keys(this.entries[0])
        },
        entryFields() {
            return [
                { label: 'Title', key: 'title' },
                { label: 'Status', key: 'status.data.title' },
                { label: 'Created At', key: 'created_at' },
                { label: 'Updated At', key: 'updated_at' },
                { label: 'Author', key: 'user.data.email' },
            ]
        },
        statusOptions() {
            return this.statuses.map((status) => {
                return {
                    label: status.title,
                    value: status.slug,
                }
            })
        },
        selectedFilters: {
            get() {
                return this.filters
            },
            set(newFilters) {
                this.setFilters(newFilters)
            },
        },
    },
    methods: {
        ...mapActions('content', ['setFilters', 'getEntries', 'deleteEntry']),
        toggleSelected(checked) {
            if (checked) {
                this.selected = this.entries
                return
            }

            this.selected = []
        },
        edit(entry) {
            this.$store.commit('content/SET_ENTRY', entry)

            this.$router.push({
                name: 'entries.edit',
                params: { type: entry.type, id: entry.id },
                props: { value: entry },
            })
        },
        columns(entry) {
            let columns = Object.values(entry)

            if (this.entryFields) {
                columns = this.entryFields.map((field) => {
                    if (field.key.includes('.') !== -1) {
                        return _get(entry, field.key)
                    }

                    return entry[field.key]
                })
            }

            return columns
        },
        itemLinks(entry) {
            return {
                view: entry.links.data.view,
                edit: entry.links.data.edit,
                delete: entry.links.data.delete,
            }
        },
        sortBy(column) {
            console.log(
                this.entryFields.find((field) => field.label === column)
            )
        },
        formatDate(date) {
            return moment(date).format('MMM, Do YYYY')
        },
    },
    watch: {
        filters: {
            handler(newFilters) {
                this.getEntries(this.typeSlug)
            },
            deep: true,
        },
    },
    async created() {
        await this.getEntries(this.typeSlug)
    },
    async beforeRouteUpdate(to, from, next) {
        this.$store.commit('content/SET_FILTERS', {})
        this.$store.commit('content/SET_ENTRIES', null)
        this.$store.dispatch('content/getEntries', to.params.typeSlug)
        next()
    },
}
</script>
