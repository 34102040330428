<template>
<modal
    :visible="isVisible"
    @close="isVisible = false"
>
    <template
        #default
    >
        <div class="p-8">
            <div class="inline-block w-full mb-4 text-lg">
                <slot
                    name="text"
                />
            </div>
            <div class="confirmActions text-center">
                <button
                    class="button --success"
                    @click="confirm"
                    v-text="confirmText"
                />
                <button
                    class="button --small"
                    @click="cancel"
                    v-text="cancelText"
                />
            </div>
        </div>
    </template>
</modal>
</template>

<script>
import Modal from 'Components/Modal';

export default {
    components: {
        Modal,
    },
    props: {
        confirmText: {
            type: String,
            required: false,
            default: 'Yes',
        },
        cancelText: {
            type: String,
            required: false,
            default: 'No',
        },
        visible: {
            type: Boolean,
            required: true,
            default: false,
        }
    },
    data() {
        return {
            value: false,
        };
    },
    computed: {
        isVisible: {
            get() {
                return this.visible;
            },
            set(visible) {
                this.$emit('update:visible', visible);
            }
        }
    },
    mounted() {
        if (this.visible) {
            this.isVisible = this.visible;
        }
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('confirmed', true);
        },
        cancel() {
            this.confirmed = false;
            this.$emit('cancelled', false);
        }
    }
};
</script>
