<template>
    <div class="assetDetails rounded-xl">
        <div class="assetDetails--header border-blue-light">
            <input
                v-model="isSelected"
                class="assetDetails--isSelected"
                type="checkbox"
                name="selectedAssets" />

            <span class="assetDetails--name text-blue-darkest"
                >Asset {{ asset.filename }}</span
            >

            <ButtonBookmark
                :item="asset"
                :category="'Asset'"
                :title="asset.filename"
                :link="asset.links.data.view"
                target="_self" />

            <span :class="['assetDetails--status ml-8', ...getStatusClasses()]">
                {{ statusText }}
            </span>

            <div class="assetDetails--actions flex items-center">
                <ButtonStripped
                    v-if="asset.studio_link"
                    :href="asset.studio_link"
                    :has-arrow="true"
                    arrow-color="mediumblue"
                    text-color="text-blue"
                    text-size="text-sm">
                    <span v-text="studioLinkText" />
                </ButtonStripped>
                <workspace-actions
                    class="assetDetails--actionLink relative border-blue-light"
                    :workspaces="$store.state.user.workspaces"
                    :asset="asset" />
                <AssetDownloads
                    class="assetDetails--actionLink relative border-blue-light"
                    :asset="asset" />
            </div>
        </div>
        <div class="assetDetails--body">
            <div class="assetDetails--files">
                <div class="assetDetails--image">
                    <AssetThumbnail
                        :asset="asset"
                        type="assetDetails"
                        image-size="full"
                        :slides-modal="true" />
                    <span class="assetDetails--timestamps">
                        Last Updated on {{ formattedDateUpdated }} - Created on
                        {{ formattedDateCreated }}
                    </span>
                    <span class="assetDetails--timestamps" v-if="formattedExpirationDate">
                        Expires on {{ formattedExpirationDate }}
                    </span>
                </div>

                <div class="assetDetails--rightsDocs">
                    <span class="assetDetails--rightsDocsHeading">
                        <i
                            class="far fa-fw fa-exclamation-triangle text-red-500" />
                        Usage Rights and License
                    </span>
                    <p v-if="asset.limited_rights == true">Limited Rights</p>
                    <p
                        v-if="usageDescription"
                        v-html="usageDescription" />
                    <ul
                        v-if="rightsDocs.length"
                        class="assetDetails--RightsDocsLinks">
                        <li
                            v-for="rightsDoc in rightsDocs"
                            :key="rightsDoc.rights_document_id">
                            <a
                                :href="rightsDoc.url"
                                class="link"
                                :aria-label="`Rights Document (${rightsDoc.name}) for asset ${asset.asset_id}`"
                                :alt="rightsDoc.name"
                                v-text="
                                    rightsDoc.name.length > 25
                                        ? rightsDoc.truncated_name
                                        : rightsDoc.name
                                " />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="assetDetails--meta">
                <dl class="assetDetails--metaList">
                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Caption</dt>

                        <dd class="assetDetails--metaValue font-bold">
                            {{ caption }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Association</dt>
                        <dd class="assetDetails--metaValue">
                            {{ asset.association_name }}
                        </dd>
                    </div>

                    <div
                        v-if="asset.property"
                        class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Property</dt>
                        <dd class="assetDetails--metaValue">
                            <a
                                v-if="asset.property.data.admin_links"
                                class="link"
                                :href="
                                    asset.property.data.admin_links.data.edit
                                "
                                >{{ asset.property.data.marsha_code }} -
                                {{ asset.property.data.name }}</a
                            >
                            <span v-else>
                                {{ asset.property.data.marsha_code }} -
                                {{ asset.property.data.name }}
                            </span>
                        </dd>
                    </div>

                    <div
                        v-if="asset.brand"
                        class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Brand</dt>
                        <dd class="assetDetails--metaValue">
                            <a
                                class="link"
                                href="#"
                                >{{ brand }}</a
                            >
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Description</dt>
                        <dd
                            class="assetDetails--metaValue"
                            v-html="description" />
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Subject</dt>
                        <dd class="assetDetails--metaValue">
                            {{ subject }}
                        </dd>
                    </div>
                </dl>

                <dl class="assetDetails--metaList">
                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Asset Name</dt>
                        <dd class="assetDetails--metaValue">
                            {{ name }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Asset Type</dt>
                        <dd class="assetDetails--metaValue">
                            {{ assetType }}
                        </dd>
                    </div>
                </dl>

                <dl class="assetDetails--metaList">
                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">File Name</dt>
                        <dd class="assetDetails--metaValue">
                            {{ filename }}
                            <a
                                v-if="isAdmin"
                                :href="asset.links.data.edit"
                                class="link"
                                >(Edit Asset)</a
                            >
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">DAC File Name</dt>
                        <dd class="assetDetails--metaValue">
                            {{ dacFileName }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">HWS Image Name</dt>
                        <dd class="assetDetails--metaValue">
                            {{ hwsImageName ? hwsImageName : 'N/A' }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">File Type</dt>
                        <dd class="assetDetails--metaValue">
                            {{ extension }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">File Size</dt>
                        <dd class="assetDetails--metaValue">
                            {{ fileSize }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Height</dt>
                        <dd
                            v-if="height"
                            class="assetDetails--metaValue">
                            {{ height }}px
                        </dd>
                        <dd v-else>N/A</dd>
                    </div>
                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Width</dt>
                        <dd
                            v-if="width"
                            class="assetDetails--metaValue">
                            {{ width }}px
                        </dd>
                        <dd v-else>N/A</dd>
                    </div>
                </dl>

                <dl
                    v-if="asset.asset_type_id === 3"
                    class="assetDetails--metaList">
                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Template Type</dt>
                        <dd class="assetDetails--metaValue">
                            {{ templateType }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Template Number</dt>
                        <dd class="assetDetails--metaValue">
                            {{ templateNumber }}
                        </dd>
                    </div>
                </dl>

                <dl class="assetDetails--metaList">
                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Created By</dt>
                        <dd class="assetDetails--metaValue">
                            {{ createdBy }}
                        </dd>
                    </div>

                    <div class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Collection(s)</dt>
                        <dd class="assetDetails--metaValue">
                            <span v-if="!collections.length">N/A</span>
                            <ul v-else>
                                <li
                                    v-for="collection in collections"
                                    :key="collection.collection_id">
                                    {{ collection.name }}
                                </li>
                            </ul>
                        </dd>
                    </div>

                    <div
                        v-if="fromMvwal"
                        class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">
                            Synced via MVWAL
                        </dt>
                        <dd
                            v-if="mvwalLink"
                            class="assetDetails--metaValue">
                            <a
                                class="link"
                                :href="mvwalLink"
                                >View on MVWAL</a
                            >
                        </dd>
                    </div>

                    <div
                        v-if="fromMdam"
                        class="assetDetails--metaItem">
                        <dt class="assetDetails--metaField">Synced via MDAM</dt>
                        <dd
                            v-if="mdamLink"
                            class="assetDetails--metaValue">
                            <a
                                class="link"
                                :href="mdamLink"
                                >View on MDAM</a
                            >
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
//import MiStudioLinks from 'Components/Redesign/MiStudioLinks';
import AssetDownloads from 'Components/Redesign/Asset/AssetDownloads'
import AssetMixins from 'Components/Redesign/Mixins/AssetMixins'
import AssetThumbnail from 'Components/Redesign/Asset/AssetThumbnail'
import ButtonBookmark from 'Components/Redesign/Button/ButtonBookmark'
import ButtonStripped from 'Components/Redesign/Button/ButtonStripped'
import WorkspaceActions from 'Components/Redesign/Asset/AssetWorkspaceActions'

import { find, reject } from 'lodash'

export default {
    name: 'AssetDetails',
    components: {
        AssetThumbnail,
        AssetDownloads,
        ButtonBookmark,
        ButtonStripped,
        //MiStudioLinks,
        WorkspaceActions,
    },
    mixins: [AssetMixins],
    props: {
        workspaces: {
            required: true,
            type: Array,
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        isSelected: {
            get() {
                return find(
                    this.$store.state.user.selectedAssets,
                    (asset) => asset.asset_id === this.asset.asset_id
                )
            },
            set(isSelected) {
                let assets = this.$store.state.user.selectedAssets

                if (isSelected) {
                    assets.push(this.asset)
                } else {
                    assets = reject(
                        assets,
                        (asset) => asset.asset_id === this.asset.asset_id
                    )
                }

                this.$store.dispatch('user/setSelectedAssets', assets)
            },
        },
        studioLinkText() {
            if (this.asset.studio_link_text) {
                return this.asset.studio_link_text
            }

            return 'Create on MI Studio'
        },
    },
    mounted() {
        if (!this.workspaces.length) {
            this.$store.dispatch('user/getWorkspaces')
        }
    },
    methods: {},
}
</script>

<style></style>
