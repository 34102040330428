<template>
    <div
        :class="theme"
        class="flex flex-col h-full align-stretch relative">
        <div class="mb-8">
            <h4 class="font-swiss font-bold pr-8 leading-tight">
                {{ collection.name }}
            </h4>
            <ButtonStripped
                :href="link"
                :class="buttonClass"
                :has-arrow="true"
                :arrow-color="arrowColor"
                :text-color="textColor"
                padding="px-0"
                class="mt-4 after:content-[''] after:absolute after:inset-0 after:w-full after:h-full">
                Explore
            </ButtonStripped>
        </div>
        <!-- image composition -->
        <div class="flex gap-1 mt-auto relative">
            <template v-for="(thumbnail, i) in thumbnails">
                <img
                    :key="i"
                    :src="thumbnail.url"
                    class="max-h-16"
                    :alt="link.name" />
            </template>
            <div
                v-if="thumbnails.length"
                :class="gradient"></div>
        </div>
    </div>
</template>

<script>
import ButtonStripped from '../Button/ButtonStripped.vue'
export default {
    components: {
        ButtonStripped,
    },
    props: {
        collection: {
            type: Object,
            default() {
                return {
                    title: 'Collection Title',
                }
            },
        },
        theme: {
            type: String,
            default:
                'bg-blue-darkest text-white py-6 pl-6 rounded-lg overflow-hidden',
        },
        buttonClass: {
            type: String,
            default: 'text-white ',
        },
        arrowColor: {
            type: String,
            default: 'white',
            validation: (value) =>
                ['white', 'blue', 'lightblue', 'gray', 'darkblue'].includes(
                    value
                ),
        },
        gradient: {
            type: String,
            default:
                'opacity-60 bg-gradient-to-r from-transparent via-transparent to-blue-darkest w-full h-full absolute',
        },
        textColor: {
            type: String,
            default: 'text-white',
        },
    },
    computed: {
        link() {
            return this.collection.links?.data?.view
        },
        thumbnails() {
            return this.collection.thumbnails?.data
        },
    },
}
</script>
