import axios from 'axios'
import moment from 'moment'

import {
    ACCOUNT_LIST_UPDATE_NOTIFICATIONS,
    ACCOUNT_LIST_ASSET_NOTIFICATIONS,
    ACCOUNT_LIST_DEACTIVATIONS,
    ACCOUNT_LIST_DOWNLOADS,
    ACCOUNT_UPDATE_ASSET_NOTIFICATIONS,
} from 'Root/endpoints'

export const DATE_FORMAT = moment.ISO_8601

export async function getAssetNotifications({ start, end, page }) {
    return axios.get(ACCOUNT_LIST_ASSET_NOTIFICATIONS, {
        params: { start, end, page },
    })
}

export async function getUpdateNotifications(from = '1 week ago') {
    return axios.get(ACCOUNT_LIST_UPDATE_NOTIFICATIONS, { params: { from } })
}

export async function getDeactivations(month = null) {
    return await axios.get(ACCOUNT_LIST_DEACTIVATIONS, { params: { month } })
}

export async function getDownloads() {
    return await axios.get(ACCOUNT_LIST_DOWNLOADS)
}

export async function markSeen(notification) {
    notification.seen = true

    return axios.put(
        `${ACCOUNT_UPDATE_ASSET_NOTIFICATIONS}/${notification.id}`,
        {
            notification: {
                id: notification.id,
                seen_at: moment().format('YYYY-MM-DD HH:mm'),
            },
        }
    )
}

export async function getUnreadAssetNotifications({ page = null } = {}) {
    return axios.get('/api/account/notifications/assets/unread', {
        params: { page },
    })
}

export async function getReadAssetNotifications({ page = 1 }) {
    return axios.get('/api/account/notifications/assets/read', {
        params: { page },
    })
}

export function formatNotificationDate(date) {
    const now = moment()

    date = moment(date, DATE_FORMAT)

    if (now.isSame(date, 'day')) {
        return 'Today'
    }

    if (date.isAfter(moment().subtract(2, 'day'))) {
        return 'Yesterday'
    }

    if (date.isAfter(moment().subtract(1, 'week'))) {
        return date.fromNow()
    }

    return date.format('MMMM Do, YYYY')
}

export function generateSearchParams({ entry }) {
    const notification = entry.notification.data

    const query = new URLSearchParams()

    query.append(
        'date_created_start',
        moment(entry.date_sent).format('YYYY-MM-DD')
    )
    query.append('date_created_end', notification.last_sent)

    notification.asset_types.forEach((type) =>
        query.append('asset_type_ids[]', type.asset_type_id)
    )

    notification.regions.forEach((region) =>
        query.append('region_ids[]', region.region_id)
    )

    notification.properties.forEach((property) =>
        query.append('property_ids[]', property.property_id)
    )

    notification.brands.forEach((brand) =>
        query.append('brand_ids[]', brand.brand_id)
    )

    console.log(query)

    return query
}

export default {
    formatNotificationDate,
    getAssetNotifications,
    getDeactivations,
    getDownloads,
    getReadAssetNotifications,
    getUnreadAssetNotifications,
    getUpdateNotifications,
    markSeen,
}
