<template>
    <div :class="cardClasses">
        <div :class="contentClasses">
            <BaseIcon
                :svg="icon"
                class="mb-4" />
            <h3
                :class="titleClass"
                class="font-swiss"
                v-html="title"></h3>
            <p
                v-if="isToolsPage"
                class="font-swiss mt-4 col-start-1"
                v-html="description"></p>
            <ButtonStripped
                v-if="link"
                :href="link"
                class="group text-white mt-6 after:content-[''] after:absolute after:inset-0 after:w-full after:h-full"
                text-size="text-lg"
                padding="p-0"
                :has-arrow="true"
                text-color="text-white"
                arrow-color="lightblue"
                arrow-size="large"
                :target="target">
                {{ linkText }}
            </ButtonStripped>
        </div>
        <img
            v-if="isFeatured"
            :src="featuredImage"
            :class="cardImageClasses"
            alt="" />
        <img
            v-else-if="isFeaturedSecondary"
            :src="featuredImageSecondary"
            :class="cardImageSecondaryClasses"
            alt="" />
        <div class="absolute top-3 right-3 z-20">
            <ButtonBookmark
                :item="card"
                category="Tool"
                :title="card.title"
                :link="card.link"
                :target="card.target" />
        </div>
    </div>
</template>

<script>
import BaseIcon from '../Base/BaseIcon.vue'
import ButtonStripped from '../Button/ButtonStripped.vue'
import ButtonBookmark from '../Button/ButtonBookmark.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'FeaturedToolCard',
    components: {
        BaseIcon,
        ButtonBookmark,
        ButtonStripped,
    },
    props: {
        card: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        link: {
            type: String,
            default: null,
        },
        linkText: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: '_self',
        },
        icon: {
            type: String,
            default: null,
        },
        isFeatured: {
            type: Boolean,
            default: false,
        },
        isFeaturedSecondary: {
            type: Boolean,
            default: false,
        },
        isToolsPageCard: {
            type: Boolean,
            default: false,
        },
        featuredImage: {
            type: String,
            default: null,
        },
        featuredImageSecondary: {
            type: String,
            default: null,
        },
        isToolsPage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cardClasses() {
            let baseClasses =
                'border-4 rounded-3xl px-6 py-8 font-light relative '
            if (this.isToolsPage) {
                if (this.isFeatured || this.isFeaturedSecondary) {
                    baseClasses +=
                        'grid md:grid-cols-[1fr_auto] gap-4 md:gap-12 pb-0 border-none overflow-hidden p-4 before:absolute before:inset-0 before:bg-gradient-to-br from-white via-white/40 to-white before:rounded-3xl before:z-0 after:absolute after:inset-0 after:bg-blue-darker after:z-10 after:m-1 after:rounded-3xl'
                } else {
                    baseClasses +=
                        'grid md:grid-cols-[1fr_auto] gap-4 md:gap-12 border-none before:absolute before:inset-0 before:bg-gradient-to-br from-white/40 via-white/20 to-white/20 before:rounded-3xl before:z-0 after:absolute after:inset-0 after:bg-blue-darker after:z-10 after:m-1 after:rounded-3xl'
                }
            } else {
                if (this.isFeatured) {
                    baseClasses +=
                        'grid grid-cols-1 md:grid-cols-[400px_1fr] md:col-span-2 md:row-span-2 border-none overflow-hidden p-4 before:absolute before:inset-0 before:bg-gradient-to-br from-white via-white/40 to-white before:rounded-3xl before:z-0 after:absolute after:inset-0 after:bg-blue-darker after:z-10 after:m-1 after:rounded-3xl'
                } else if (this.isFeaturedSecondary) {
                    baseClasses +=
                        'grid grid-rows-subgrid row-span-2 border-none overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-br from-white via-white/40 to-white before:rounded-3xl before:z-0 after:absolute after:inset-0 after:bg-blue-darker after:z-10 after:m-1 after:rounded-3xl'
                } else if (this.isToolsPageCard) {
                    baseClasses += 'border-blue-dark'
                } else {
                    baseClasses +=
                        'border-none before:absolute before:inset-0 before:bg-gradient-to-br from-white/40 via-white/20 to-white/20 before:rounded-3xl before:z-0 after:absolute after:inset-0 after:bg-blue-darker after:z-10 after:m-1 after:rounded-3xl'
                }
            }
            return baseClasses
        },
        contentClasses() {
            return this.isToolsPage &&
                (this.isFeatured || this.isFeaturedSecondary)
                ? 'relative z-20'
                : this.isToolsPage
                ? 'grid grid-cols-subgrid md:col-span-2 gap-4 z-20'
                : !this.isToolsPage && this.isFeaturedSecondary
                ? 'relative z-30'
                : 'relative z-20 flex flex-col items-stretch h-full'
        },
        cardImageClasses() {
            if (this.isToolsPage) {
                return 'w-full md:w-96 h-64 md:h-72 object-contain z-20'
            } else {
                return 'translate-x-6 translate-y-6 z-20 md:absolute bottom-6 right-6 md:w-1/2'
            }
        },
        cardImageSecondaryClasses() {
            if (this.isToolsPage) {
                return 'w-full md:w-96 h-64 md:h-72 object-contain z-20'
            } else {
                return 'absolute bottom-1 z-20'
            }
        },
        titleClass() {
            if (this.isToolsPage) {
                if (this.isFeatured || this.isFeaturedSecondary) {
                    return 'text-3xl leading-tight'
                } else {
                    return 'col-start-1 text-xl'
                }
            } else if (this.isFeaturedSecondary || this.isToolsPageCard) {
                return 'text-[28px] leading-tight mt-4 pr-16'
            } else if (this.isFeatured) {
                return 'text-4xl leading-tight mt-4'
            } else {
                return 'text-xl mt-auto'
            }
        },
    },
}
</script>

<style>
strong {
    font-weight: bold !important;
}
</style>
