<template>
    <button
        @click="$emit('click', $event)"
        :class="[
            'px-2',
            'py-1',
            'text-xs',
            { 'bg-blue-dark': isActive },
            { 'text-white': isActive },
        ]"
    >
        <i
            v-if="icon"
            :class="['far', 'fa-fw', icon]"
        />
        <slot />
    </button>
</template>
<script>
export default {
    props: {
        icon: {
            required: false,
            default: null,
            type: String,
        },
        isActive: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
}
</script>
