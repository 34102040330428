import {
    getFavorites,
    createFavorite,
    updateFavorite,
    deleteFavorite,
    bulkUpdateFavorites,
} from 'Composables/useFavorites'

import orderBy from 'lodash/orderBy'

import {
    SET_USER_FAVORITES,
    ADD_USER_FAVORITE,
    REMOVE_USER_FAVORITE,
    BULK_UPDATE_USER_FAVORITES,
    SET_USER_FAVORITES_IS_LOADING,
} from 'Stores/Frontend/mutation-types.js'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        favorites: [],
    },
    getters: {},
    mutations: {
        [SET_USER_FAVORITES](state, favorites) {
            state.favorites = favorites
        },
        [SET_USER_FAVORITES_IS_LOADING](state, isLoading) {
            state.isLoading = isLoading
        },
        [ADD_USER_FAVORITE](state, favorite) {
            state.favorites.push(favorite)
        },
        [REMOVE_USER_FAVORITE](state, id) {
            state.favorites = state.favorites.filter((fav) => fav.id !== id)
        },
    },
    actions: {
        async init({ dispatch }) {
            await dispatch('loadFavorites')
        },
        setIsLoading({ commit }, isLoading) {
            commit(SET_USER_FAVORITES_IS_LOADING, isLoading)
        },
        async setFavorites({ commit }, favorites) {
            commit(SET_USER_FAVORITES, orderBy(favorites, 'order'))
        },
        async loadFavorites({ commit, dispatch }) {
            dispatch('setIsLoading', true)

            try {
                const response = await getFavorites()
                commit(SET_USER_FAVORITES, response.data.data)
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async addFavorite({ commit, dispatch }, favorite) {
            dispatch('setIsLoading', true)

            try {
                const response = await createFavorite(favorite)

                if (!response.data.data) {
                    throw new Error('Unable to create favorite')
                }

                commit(ADD_USER_FAVORITE, response.data.data)
            } catch (error) {
                console.error(error)
            }
            dispatch('setIsLoading', false)
        },
        async updateFavorite({ commit, state, dispatch }, favorite) {
            dispatch('setIsLoading', true)

            try {
                const response = await updateFavorite(favorite)

                if (!response.data.data) {
                    throw new Error('Unable to create favorite')
                }

                let favorites = state.favorites

                const index = favorites.findIndex(
                    (fav) => fav.id == favorite.id
                )

                favorites[index] = response.data.data

                commit(SET_USER_FAVORITES, favorites)
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async deleteFavorite({ dispatch, commit }, id) {
            dispatch('setIsLoading', true)

            try {
                const response = await deleteFavorite({ id })

                if (!response.data.success) {
                    throw new Error('Unable to delete favorite')
                }

                commit(REMOVE_USER_FAVORITE, id)
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
        async updateOrder({ dispatch }, favorites) {
            dispatch('setIsLoading', true)

            try {
                favorites.forEach((favorite, i) => {
                    favorite.order = i
                })

                const response = await bulkUpdateFavorites(favorites)

                if (!response.data.success) {
                    throw new Error('Unable to update favorites')
                }

                dispatch('setFavorites', response.data.data)
            } catch (error) {
                console.error(error)
            }

            dispatch('setIsLoading', false)
        },
    },
}
