<template>
    <div class="flex flex-wrap">
        <EntryTitle
            id="title"
            v-model="entry.title"
            class="w-full mb-4"
        />


        <EntryExcerpt
            label="Excerpt"
            class="w-full"
            v-model="entry.extra.excerpt"
            placeholder="A brief excerpt">
            <template #help>
                The excerpt will appear in the Spoltight section of the homepage
            </template>
        </EntryExcerpt>


        <EntryContent
            label="Content"
            class="w-full mb-2"
            id="content"
            v-model="entry.content"
        />
    </div>
</template>

<script>
import EntryContent from 'Components/Entries/EntryFields/EntryContent'
import EntryExcerpt from 'Components/Entries/EntryFields/EntryExcerpt'
import EntryTitle from 'Components/Entries/EntryFields/EntryTitle'
import EntryInput from 'Components/Entries/EntryFields/EntryInput'

export default {
    components: {
        EntryContent,
        EntryExcerpt,
        EntryTitle,
        EntryInput,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            excerptMaxLength: 50,
        }
    },
    computed: {
        entry: {
            get() {
                return this.value
            },
            set(newEntry) {
                this.$emit('input', newEntry)
            },
        },
        excerpt: {
            get() {
                return this.entry.extra?.excerpt
            },
        },
        excerptCount() {
            if (!this.excerpt) {
                return 0
            }

            return this.excerpt.length
        },
    },

    watch: {
        entry: {
            handler(newValue, oldvalue) {
                this.$emit('input', newValue)
            },
            deep: true,
        },
    },
    created() {
        if (Array.isArray(this.value?.extra)) {
            this.value.extra = {}
        }
    },
}
</script>
