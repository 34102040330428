<template>
<div class="toggleBox pb-2 last:pb-0">
    <div
        class="toggleBox--header"
        @click.prevent="isOpen = !isOpen"
    >
        <span
            v-if="title"
            class="toggleBox--title"
            v-text="title"
        />
        <span
            v-if="meta"
            class="toggleBox--meta"
            v-text="meta"
        />
        <button
            class="toggleBox--control"
        >
            <i :class="getIconClasses()" />
        </button>
    </div>

    <div
        :class="getContentClasses()"
    >
        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <div v-show="isOpen">
                <slot
                    name="content"
                    :isOpen="isOpen"
                />
            </div>
        </transition>
    </div>
</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        meta: {
            type: String,
            required: false,
            default: null,
        },
        open: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            internalIsOpen: null
        };
    },
    mounted() {
        if (this.open !== this.isOpen) {
            this.isOpen = this.open;
        }
    },
    computed: {
        isOpen: {
            set(value) {
                this.internalIsOpen = value;
            },
            get() {
                if (this.internalIsOpen !== null) {
                    return this.internalIsOpen;
                }

                return this.open;
            }
        }
    },
    methods: {
        getIconClasses() {
            let classes = [
                'fal',
                'fa-fw',
            ];

            if (this.isOpen) {
                classes.push('fa-minus');
            } else {
                classes.push('fa-plus');
            }

            return classes.join(' ');
        },
        getContentClasses() {
            let classes = [];

            if (!this.isOpen) {
                classes.push('overflow-hidden max-h-0');
            }

            return classes.join(' ');
        }
    }
};
</script>

<style>

</style>
