<template>
<button
>{{ text }}</button>
</template>
<script>
export default {
    name: "apiButton",
    props: {
        text: {
            type: String
        },
        action: {
            required: true,
            type: String
        }
    }
}
</script>