<template>
    <CollectionTiles
        :collection="collection"
        theme="bg-blue-lighter border border-blue-light text-blue-darkest py-6 pl-6 rounded-lg overflow-hidden"
        button-class="text-blue"
        arrow-color="blue"
        text-color="text-blue"
        gradient="opacity-60 bg-gradient-to-r from-transparent via-transparent to-blue-lightest w-full h-full absolute" />
</template>

<script>
import CollectionTiles from './CollectionTiles.vue'

export default {
    name: 'CollectionTilesLight',
    components: {
        CollectionTiles,
    },
    props: {
        collection: {
            type: Object,
            default() {
                return {
                    title: 'Collection Title',
                }
            },
        },
    },
}
</script>
