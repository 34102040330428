<template>
<div class="searchInputGroup">
    <label
        :class="[
            'label',
            {'sr-only': !showLabel}
        ]"
        :for="label"
    >{{ label }}</label>

    <div class="searchInputGroup__checkboxGroup">
        <input
            v-if="search"
            :id="filterId"
            v-model="filter"
            class="input"
            type="text"
            @input="$emit('update:filter', $event.target.value)"
            :placeholder="placeholder ? placeholder : null"
        >

        <div
            v-if="selected.length"
            class="selected mb-5 max-h-60 overflow-y-scroll flex flex-wrap"
        >
            <span
                v-if="selected.length === options.length"
                class="button --tag --rounded --surf --normal --xs mb-1.5"
                @click="selected = []"
            >
                All {{ labelPlural }} selected
            </span>

            <label
                v-for="(item, i) in selected"
                v-else
                :key="i"
                class="text-sm border border-white p-1 rounded-md"
            >
                <input
                    v-model="selected"
                    type="checkbox"
                    :value="item"
                    class="sr-only"
                >
                {{ item[displayKey] }}
                <i class="far fa-times" />
            </label>
        </div>

        <ul
            class="searchInputGroup__list"
            role="list"
        >
            <li
                v-if="options.length === 0 && !selected"
            >
                No {{ labelPlural }}
            </li>

            <li
                v-if="options.length > 0"
                :class="[
                    'searchInputGroup__item',
                    {'text-slate-lightText': selected.length === options.length}
                ]"
                role="listitem"
                aria-controls="select-option"
                tabindex="0"
                aria-selected="false"
                @click="toggleOptions"
                @keyup.enter="toggleOptions()"
            >
                All {{ labelPlural }}
            </li>

            <li
                v-for="(item, i) in filteredOptions"
                :key="i"
                class="searchInputGroup__item"
            >
                <label
                    class="cursor-pointer hover:underline"
                    role="listitem"
                    aria-controls="select-option"
                    tabindex="0"
                    aria-selected="false"
                    @keyup.enter="toggleOption(item)"
                >
                    <span
                        :class="[
                            {'text-slate-lightText': isSelected(item)},
                        ]"
                        v-html="displayKeyFilter(item)"
                    />
                    <input
                        v-model="selected"
                        class="invisible sr-only"
                        type="checkbox"
                        :value="item"
                    >
                </label>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import { differenceBy, reject } from 'lodash';

export default {
    name: 'SearchInputCheckbox',
    props: {
        showLabel: {
            required: false,
            type: Boolean,
            default: true,
        },
        label: {
            required: true,
            type: String,
        },
        labelPlural: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
            default: null,
        },
        value: {
            type: Array,
            required: false,
            default() {
                return [];
            },
        },
        filterId: {
            required: false,
            type: String,
            default: '',
        },
        filterBy: {
            type: String,
            required: true,
        },
        filterResults: {
            type: Boolean,
            required: false,
            default: true,
        },
        displayKey: {
            type: String,
            required: true,
        },
        displayKeyFilter: {
            type: Function,
            required: false,
            default: function(item) {
                return item[this.displayKey];
            }
        },
        options: {
            type: Array,
            required: true,
        },
        search: {
            required: false,
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            internalFilter: null,
            internalSelected: [],
        };
    },
    computed: {
        filter: {
            get() {
                return this.internalFilter;
            },
            set(filter) {
                this.internalFilter = filter;
            }
        },
        selected: {
            get() {
                return this.value;
            },
            set(selected) {
                this.internalSelected = selected;
                this.$emit('input', this.internalSelected);
            }
        },
        filteredOptions() {
            let filtered = this.options;

            if (this.filter && this.filterResults) {
                return filtered.filter(option => {
                    if (option[this.filterBy] instanceof String) {
                        return option[this.filterBy].toLowerCase().includes(this.filter.toLowerCase());
                    }

                    return option[this.filterBy] == this.filter;
                });
            }

            return filtered;
        }
    },
    mounted() {
        if (this.value.length) {
            this.internalSelected = this.value;
        }
    },
    methods: {
        isSelected(item) {
            return this.selected.filter(selected => item[this.filterBy] === selected[this.filterBy]).length > 0 ? true : false;
        },
        toggleOptions() {
            if (this.selected.length === this.options.length) {
                this.selected = [];
            } else {
                this.selected = this.options;
            }
        },
        toggleOption(option) {
            if (this.isSelected(option)) {
                this.selected = reject(this.selected, option);
            }
            else {
                this.selected.push(option);
            }
        }
    }
};
</script>

<style>

</style>
