<template>
    <section class="bg-white py-16 min-h-96">
        <div class="max-w-container px-container mx-auto">
            <div class="flex justify-between mb-12 items-center">
                <h2 class="text-6xl text-gray font-aldine italic">
                    News & Updates
                </h2>

                <BaseSelect
                    v-model="type"
                    :dropdown-options="types"
                    :has-selected-option="null">
                    Show News and Updates
                </BaseSelect>
            </div>

            <div class="grid grid-cols-3 gap-4">
                <div
                    class="col-span-2 grid grid-rows-2 gap-y-8"
                    v-if="featuredNews.length">
                    <Component
                        v-for="entry in featuredNews"
                        :key="entry.id"
                        :is="
                            entry.type.data.slug === 'news'
                                ? 'FeaturedNews'
                                : 'StandardNews'
                        "
                        :entry="entry"
                        class="p-8" />
                </div>

                <div class="col-span-1 rounded-lg bg-blue-lightest p-8">
                    <h3 class="text-2xl font-aldine italic mb-8">
                        Show items related to...
                    </h3>
                    <label
                        v-for="category in categories"
                        :class="[
                            'bg-transparent',
                            { 'bg-yellow': isCategorySelected(category) },
                            'border-2',
                            'border-yellow',
                            'cursor-pointer',
                            'font-bold',
                            'inline-block',
                            'mb-2',
                            'mr-2',
                            'px-4',
                            'py-2',
                            'rounded-full',
                            'text-blue-darkest',
                            'text-gray',
                            'text-sm',
                        ]">
                        <input
                            type="checkbox"
                            class="sr-only"
                            v-model="selectedCategories"
                            :value="category" />
                        {{ category.title }}
                        <span
                            v-if="isCategorySelected(category)"
                            class="rounded-full border border-blue-darkest"
                            ><i class="far fa-fw fa-minus fa-sm fa-bold"
                        /></span>
                    </label>
                </div>

                <div
                    :class="[
                        'p-8',
                        {
                            'col-span-1': entry.type.data.slug === 'updates',
                        },
                        {
                            'col-span-2': entry.type.data.slug === 'news',
                        },
                    ]"
                    v-if="entries !== null"
                    v-for="entry in filteredEntries"
                    :key="entry.id">
                    <Component
                        :is="
                            entry.type.data.slug === 'news'
                                ? 'FeaturedNews'
                                : 'StandardNews'
                        "
                        :entry="entry" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { sortBy } from 'lodash'

import BaseSelect from '../Base/BaseSelect.vue'
import ButtonOutline from 'Components/Redesign/Button/ButtonOutline.vue'
import FeaturedNews from './FeaturedNews.vue'
import StandardNews from './StandardNews.vue'

export default {
    name: 'News',
    components: {
        BaseSelect,
        ButtonOutline,
        FeaturedNews,
        StandardNews,
    },
    props: {},
    data() {
        return {
            types: [
                { label: 'Show News & Updates', value: '' },
                { label: 'News', value: 'news' },
                { label: 'Updates', value: 'updates' },
            ],
            type: '',
            selectedCategories: [],
        }
    },
    computed: {
        ...mapState('content', ['entries', 'filters', 'isLoading']),
        ...mapGetters('content', [
            'totalPages',
            'currentPage',
            'categories',
            'nextPage',
            'prevPage',
        ]),
        featuredNews() {
            return this.entries
                .filter((entry) => entry.type.data.slug === 'news')
                .slice(0, 2)
        },
        filteredEntries() {
            let exclude = this.featuredNews.map((entry) => entry.id)
            return this.entries.filter((entry) => !exclude.includes(entry.id))
        },
    },
    methods: {
        ...mapActions('content', ['getEntries', 'setFilters']),
        isCategorySelected(category) {
            return (
                this.selectedCategories.find(
                    (selected) => selected.title === category.title
                ) !== undefined
            )
        },
    },
    watch: {
        type(newType, oldType) {
            let filters = this.filters
            if (!newType) {
                filters.type = ['news', 'updates']
            } else {
                filters.type = newType
            }

            this.setFilters(filters)

            this.getEntries()
        },
        selectedCategories(newCategories, oldCategories) {
            let filters = this.filters

            if (!newCategories) {
                filters.categories = []
            } else {
                filters.categories = newCategories.map(
                    (category) => category.slug
                )
            }

            this.setFilters(filters)
            this.getEntries()
        },
    },
    mounted() {
        let filters = this.filters

        filters.type = ['news', 'updates']

        this.setFilters(filters)
        this.getEntries()
    },
}
</script>
