<template>
    <svg
        width="718"
        height="587"
        viewBox="0 0 718 587"
        fill="none">
        <g clip-path="url(#clip0_2415_5072)">
            <path
                d="M275.54 -28.3664C157.218 121.938 74.0737 11.1901 104.619 126.867C135.165 242.544 186.59 118.033 212.218 242.119C237.846 366.205 65.9289 351.758 166.824 481.555C217.272 546.453 269.095 532.979 328.215 506.659C387.335 480.34 453.752 441.176 533.386 454.696C613.021 468.217 653.855 382.504 656.006 290.696C658.156 198.888 621.622 100.984 546.521 90.121C396.258 68.3871 393.863 -178.67 275.54 -28.3664Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M280.735 -21.5031C166.297 123.369 85.8071 17.6016 114.744 129.209C143.681 240.816 192.948 121.85 217.842 241.584C242.76 361.367 78.0819 347.738 175.454 472.389C224.199 534.719 274.322 521.738 331.531 496.406C358.109 484.625 386.216 470.173 416.424 459.386C451.212 446.979 488.695 439.405 529.756 446.286C606.929 459.024 646.004 376.367 647.859 287.817C649.662 199.166 614.2 104.6 543.071 93.7093C488.482 85.3798 453.923 44.9533 425.572 6.63445C380.26 -54.7673 351.639 -111.074 280.735 -21.5031Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M285.93 -14.6401C175.377 124.801 97.5404 24.0127 124.869 131.55C152.197 239.087 199.305 125.667 223.466 241.049C247.674 356.529 90.2347 343.718 184.084 463.223C231.127 522.986 279.549 510.498 334.846 486.152C360.498 474.836 387.626 460.956 416.783 450.59C450.427 438.655 486.549 431.439 526.125 437.875C600.762 450.013 638.153 370.229 639.712 284.938C641.169 199.444 606.777 108.216 539.621 97.2974C488.033 88.9681 454.785 49.5422 427.083 12.374C383.127 -46.9153 354.764 -101.084 285.93 -14.6401Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M291.123 -7.77688C184.454 126.233 109.272 30.4242 134.991 133.892C160.711 237.359 205.66 129.484 229.087 240.514C252.586 351.692 102.386 339.699 192.712 454.057C238.052 511.252 284.774 499.258 338.16 475.898C362.885 465.047 389.035 451.74 417.14 441.793C449.641 430.331 484.422 423.425 522.493 429.465C594.633 440.911 630.3 364.092 631.564 282.06C632.673 199.722 599.352 111.832 536.169 100.886C487.582 92.5566 455.644 54.1313 428.591 18.1138C385.992 -39.063 357.886 -91.0938 291.123 -7.77688Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M296.319 -0.914022C193.19 127.506 121.005 36.8352 145.116 136.233C169.227 235.631 212.017 133.301 234.711 239.978C257.5 346.854 114.539 335.678 201.343 444.89C244.98 499.517 290.001 488.017 341.475 465.644C365.274 455.258 390.446 442.523 417.5 432.996C448.857 422.008 482.298 415.409 518.862 421.054C588.508 431.808 622.449 357.954 623.417 279.181C624.179 199.999 591.93 115.448 532.719 104.474C487.133 96.1449 456.405 58.7784 430.102 23.8533C388.7 -31.1194 360.796 -81.2035 296.319 -0.914022Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M301.513 5.94899C202.188 128.899 132.738 43.2464 155.24 138.574C177.742 233.902 218.374 137.118 240.334 239.443C262.413 342.016 126.691 331.658 209.972 435.724C251.907 487.783 295.227 476.777 344.79 455.391C367.662 445.468 391.857 433.306 417.858 424.199C448.072 413.684 480.174 407.392 515.231 412.644C582.383 422.703 614.597 351.816 615.27 276.302C615.685 200.277 584.507 119.064 529.268 108.062C486.684 99.7331 457.243 63.3813 431.612 29.5928C391.531 -23.2462 363.868 -71.2382 301.513 5.94899Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M306.706 12.8117C211.188 130.292 144.47 49.6573 165.363 140.916C186.257 232.174 224.729 140.935 245.956 238.908C267.325 337.178 138.842 327.638 218.601 426.558C258.833 476.049 300.453 465.536 348.104 445.137C370.049 435.679 393.266 424.089 418.216 415.402C447.287 405.36 478.051 399.374 511.599 404.233C576.26 413.598 606.744 345.678 607.122 273.423C607.189 200.555 577.136 122.569 525.817 111.65C486.275 103.236 458.08 67.9837 433.121 35.3321C394.362 -15.3739 366.942 -61.2731 306.706 12.8117Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M311.902 19.675C220.194 131.687 156.204 56.0688 175.489 143.257C194.774 230.446 231.087 144.752 251.581 238.373C272.24 332.34 150.996 323.618 227.232 417.392C265.761 464.315 305.68 454.296 351.421 434.883C372.439 425.89 394.678 414.873 418.576 406.605C446.503 397.036 475.931 391.355 507.97 395.823C570.142 404.492 599.074 339.518 598.976 270.544C598.878 200.809 569.727 126.166 522.368 115.238C485.836 106.809 458.921 72.5864 434.633 41.072C397.198 -7.50208 370.019 -51.3074 311.902 19.675Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M317.096 26.5378C229.201 133.081 167.936 62.4798 185.612 145.598C203.289 228.717 237.443 148.569 257.204 237.837C277.153 327.502 162.86 319.776 235.86 408.225C272.543 452.671 310.941 443.13 354.735 424.629C374.843 416.134 396.074 405.616 418.934 397.808C445.702 388.665 473.81 383.334 504.338 387.412C564.023 395.384 591.244 333.376 590.828 267.665C590.407 201.083 562.318 129.761 518.916 118.826C485.397 110.381 459.76 77.188 436.143 46.8113C400.033 0.368239 373.095 -41.3422 317.096 26.5378Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M322.289 33.4006C238.211 134.477 179.667 68.8907 195.735 147.94C211.802 226.988 243.798 152.386 262.825 237.302C282.065 322.664 174.977 315.778 244.488 399.059C279.452 440.948 316.169 431.898 358.048 414.375C377.231 406.349 397.482 396.395 419.291 389.011C444.914 380.336 471.689 375.311 500.705 379.001C557.907 386.275 583.414 327.235 582.68 264.786C581.934 201.357 554.911 133.356 515.464 122.414C484.959 113.953 460.6 81.7893 437.651 52.5506C402.87 8.23739 376.173 -31.3768 322.289 33.4006Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M327.485 40.264C247.228 135.874 191.401 75.3022 205.86 150.281C220.319 225.26 250.156 156.203 268.449 236.767C286.979 317.826 187.098 311.781 253.119 389.893C286.363 429.225 321.4 420.666 361.365 404.122C379.622 396.563 398.892 387.174 419.651 380.214C444.129 372.007 469.574 367.287 497.075 370.591C551.797 377.165 575.585 321.093 574.534 261.907C573.464 201.631 547.509 136.95 512.015 126.002C484.527 117.524 461.444 86.3906 439.162 58.2905C405.713 16.1059 379.255 -21.4107 327.485 40.264Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M332.679 47.127C256.25 137.273 203.133 81.7135 215.984 152.623C228.834 223.532 256.512 160.02 274.072 236.232C291.892 312.988 199.218 307.783 261.748 380.727C293.274 417.503 326.629 409.434 364.679 393.868C382.011 386.777 400.301 377.954 420.009 371.418C443.343 363.679 467.458 359.26 493.444 362.18C545.689 368.051 567.753 314.952 566.386 259.028C564.99 201.905 540.11 140.544 508.564 129.591C484.096 121.095 462.288 90.991 440.672 64.0301C408.557 23.9726 382.338 -11.4446 332.679 47.127Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M337.873 53.9897C265.276 138.672 214.866 88.1243 226.107 154.964C237.349 221.803 262.868 163.837 279.695 235.696C296.805 308.15 211.34 303.785 270.377 371.56C300.185 405.78 331.857 398.2 367.993 383.614C384.4 376.991 401.71 368.733 420.367 362.62C442.556 355.35 465.345 351.23 489.812 353.769C539.584 358.935 559.919 308.81 558.238 256.149C556.516 202.178 532.717 144.137 505.112 133.178C483.669 124.665 463.133 95.5902 442.182 69.7693C411.404 31.8373 385.424 -1.47863 337.873 53.9897Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M343.069 60.8531C274.313 140.075 226.601 94.5358 236.233 157.306C245.866 220.075 269.227 167.654 285.32 235.161C301.721 303.313 223.466 299.787 279.009 362.394C307.099 394.058 337.088 386.967 371.311 373.36C386.791 367.205 403.122 359.513 420.728 353.824C441.773 347.023 463.237 343.197 486.183 345.359C533.488 349.816 552.087 302.668 550.094 253.271C548.042 202.451 525.333 147.732 501.664 136.767C483.25 128.236 463.983 100.189 443.694 75.5093C414.257 39.7008 388.517 8.48858 343.069 60.8531Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M348.264 67.7157C283.355 141.478 238.333 100.947 246.357 159.647C254.381 218.347 275.583 171.471 290.943 234.626C306.634 298.474 235.591 295.789 287.638 353.228C314.012 382.335 342.315 375.733 374.625 363.106C389.18 357.418 404.532 350.294 421.086 345.027C440.988 338.695 461.129 335.16 482.552 336.948C527.395 340.692 544.251 296.526 541.946 250.391C539.565 202.724 517.957 151.329 498.213 140.355C482.837 131.808 464.833 104.787 445.204 81.2484C417.113 47.5615 391.611 18.4558 348.264 67.7157Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M353.457 74.5788C292.406 142.886 250.065 107.358 256.481 161.988C262.896 216.618 281.939 175.288 296.565 234.091C311.546 293.637 247.719 291.79 296.267 344.062C320.926 370.612 347.541 364.498 377.94 352.852C391.568 347.631 405.941 341.075 421.444 336.23C440.202 330.368 459.024 327.118 478.92 328.538C521.307 331.562 536.411 290.384 533.798 247.513C531.085 202.997 510.594 154.932 494.762 143.943C482.432 135.385 465.685 109.383 446.714 86.9882C419.972 55.42 394.709 28.4245 353.457 74.5788Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M358.652 81.4419C301.469 144.296 261.798 113.769 266.605 164.33C271.411 214.89 288.296 179.105 302.189 233.556C316.46 288.799 259.85 287.791 304.896 334.895C327.842 358.89 352.768 353.263 381.255 342.599C393.956 337.844 407.352 331.856 421.803 327.433C439.417 322.041 456.923 319.07 475.289 320.127C515.228 322.426 528.57 284.242 525.651 244.634C522.603 203.27 503.246 158.548 491.311 147.531C482.039 138.972 466.54 113.978 448.224 92.7277C422.838 63.2755 397.814 38.3945 358.652 81.4419Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M363.846 88.305C310.546 145.712 273.53 120.18 276.728 166.671C279.926 213.162 294.651 182.922 307.811 233.02C321.372 283.961 271.986 283.792 313.525 325.729C334.759 347.167 357.992 342.026 384.569 332.345C396.343 328.056 408.762 322.639 422.161 318.636C438.632 313.716 454.826 311.015 471.657 311.717C509.157 313.279 520.724 278.1 517.503 241.755C514.117 203.542 495.908 162.192 487.86 151.119C481.653 142.58 467.399 118.571 449.733 98.4674C425.708 71.1277 400.926 48.3667 363.846 88.305Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M369.039 95.1677C319.637 147.134 285.261 126.591 286.851 169.012C288.44 211.433 301.007 186.739 313.433 232.485C326.284 279.123 284.126 279.79 322.153 316.563C341.679 335.444 363.215 330.788 387.882 322.091C398.729 318.267 410.172 313.422 422.518 309.839C437.847 305.391 452.733 302.951 468.024 303.306C503.096 304.12 512.872 271.958 509.355 238.876C505.626 203.815 488.559 165.88 484.408 154.707C481.256 146.222 468.261 123.16 451.242 104.207C428.586 78.9754 404.047 58.3415 369.039 95.1677Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M374.234 102.031C328.752 148.564 296.995 133.003 296.976 171.354C296.957 209.705 307.364 190.556 319.057 231.95C331.199 274.285 296.277 275.788 330.784 307.397C348.604 323.72 368.44 319.547 391.198 311.837C401.117 308.477 411.585 304.206 422.877 301.042C436.553 297.053 450.603 294.972 464.394 294.895C497.049 294.942 505.018 265.817 501.209 235.997C497.132 204.088 481.154 169.618 480.958 158.295C480.814 149.903 469.132 127.747 452.753 109.946C431.473 86.8185 407.183 68.3211 374.234 102.031Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M379.428 108.894C337.888 150.006 308.727 139.414 307.099 173.695C305.471 207.977 313.72 194.373 324.679 231.414C336.111 269.447 308.437 271.781 339.412 298.23C355.533 311.995 373.66 308.304 394.512 301.583C403.502 298.686 412.996 294.992 423.235 292.245C435.615 288.81 448.266 286.868 460.762 286.485C491.014 285.738 497.153 259.676 493.06 233.118C488.629 204.361 473.646 173.363 477.507 161.883C480.296 153.589 470.007 132.329 454.262 115.686C434.368 94.6553 410.331 78.3073 379.428 108.894Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M384.621 115.757C347.056 151.465 320.459 145.825 317.222 176.037C313.986 206.248 320.076 198.19 330.302 230.879C341.024 264.609 320.614 267.769 348.041 289.064C362.471 300.268 378.88 297.057 397.827 291.329C405.887 288.893 414.408 285.78 423.593 283.448C434.677 280.563 445.94 278.758 457.13 278.074C484.992 276.496 489.279 253.537 484.913 230.239C480.114 204.636 466.073 177.051 474.056 165.471C479.734 157.233 470.891 136.906 455.772 121.425C437.273 102.485 413.501 88.3046 384.621 115.757Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M389.817 122.62C356.264 152.948 332.193 152.236 327.348 178.378C322.502 204.52 326.434 202.007 335.926 230.344C345.939 259.771 332.821 263.743 356.672 279.898C369.424 288.535 384.1 285.804 401.143 281.076C408.273 279.097 415.822 276.57 423.953 274.651C433.742 272.309 443.628 270.641 453.501 269.663C478.983 267.194 481.392 247.401 476.767 227.36C471.587 204.914 458.509 180.671 470.606 169.059C479.183 160.827 471.788 141.474 457.284 127.165C440.193 110.305 416.701 98.3198 389.817 122.62Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M395.012 129.483C365.517 154.468 343.926 158.647 337.472 180.719C331.018 202.792 332.79 205.824 341.549 229.809C350.852 254.934 345.07 259.69 365.302 270.732C376.398 276.787 389.317 274.543 404.458 270.822C410.657 269.298 417.236 267.365 424.312 265.854C433.938 263.8 442.351 262.378 449.87 261.253C472.973 257.797 473.483 241.271 468.62 224.481C463.035 205.199 450.985 184.251 467.156 172.648C478.661 164.392 472.698 146.031 458.794 132.905C443.127 118.114 419.94 108.366 395.012 129.483Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M400.207 136.346C374.826 156.047 355.66 165.059 347.597 183.061C339.534 201.063 339.148 209.641 347.173 229.274C355.766 250.096 357.385 255.565 373.932 261.565C383.408 265.002 394.534 263.267 407.774 260.568C413.042 259.494 418.651 258.164 424.671 257.058C433.158 255.497 440.269 254.171 446.24 252.842C466.926 248.238 465.545 235.153 460.474 221.603C454.447 205.5 443.501 187.818 463.706 176.236C478.168 167.946 473.629 150.571 460.305 138.644C446.077 125.908 423.236 118.47 400.207 136.346Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
            <path
                d="M405.399 143.209C426.611 128.69 449.043 133.681 461.813 144.383C474.583 155.086 477.69 171.499 460.253 179.824C436.039 191.384 445.796 205.834 452.324 218.723C458.853 231.613 462.153 242.942 425.028 248.26C385.979 253.855 367.379 259.3 352.794 228.738C345.502 213.458 348.047 199.335 357.719 185.402C367.39 171.469 384.188 157.727 405.399 143.209Z"
                stroke="#CBC7BF"
                stroke-miterlimit="10" />
        </g>
        <defs>
            <clipPath id="clip0_2415_5072">
                <rect
                    width="556.859"
                    height="579.094"
                    fill="white"
                    transform="matrix(-0.94422 0.329314 0.329314 0.94422 526.543 -143.717)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'BackgroundGoldLight',
}
</script>
