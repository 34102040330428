<template>
    <div class="bg-white w-full rounded-2xl pt-4 pl-6 pb-1 pr-2 max-h-[201px]">
        <div class="overflow-x-hidden overflow-y-scroll scrollbar max-h-full">
            <div class="flex gap-2 items-center">
                <!-- Bookmark Icon -->
                <svg
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <mask
                        id="path-1-outside-1_4264_2573"
                        maskUnits="userSpaceOnUse"
                        x="-1"
                        y="-1"
                        width="14"
                        height="18"
                        fill="black"
                    >
                        <rect
                            fill="white"
                            x="-1"
                            y="-1"
                            width="14"
                            height="18"
                        />
                        <path
                            d="M12 1.5L12 16L6 12.5L-2.22277e-08 16L-3.26878e-09 1.5C-2.20642e-09 0.6875 0.65625 8.58054e-10 1.5 1.96127e-09L10.5 1.37289e-08C11.3125 1.47912e-08 12 0.6875 12 1.5Z"
                        />
                    </mask>
                    <path
                        d="M12 1.5L12 16L6 12.5L-2.22277e-08 16L-3.26878e-09 1.5C-2.20642e-09 0.6875 0.65625 8.58054e-10 1.5 1.96127e-09L10.5 1.37289e-08C11.3125 1.47912e-08 12 0.6875 12 1.5Z"
                        fill="#F68B6D"
                    />
                    <path
                        d="M12 16L11.8992 16.1728L12.2 16.3482L12.2 16L12 16ZM6 12.5L6.10077 12.3272L6 12.2685L5.89923 12.3272L6 12.5ZM-2.22277e-08 16L-0.2 16L-0.2 16.3482L0.100774 16.1728L-2.22277e-08 16ZM11.8 1.5L11.8 16L12.2 16L12.2 1.5L11.8 1.5ZM12.1008 15.8272L6.10077 12.3272L5.89923 12.6728L11.8992 16.1728L12.1008 15.8272ZM5.89923 12.3272L-0.100774 15.8272L0.100774 16.1728L6.10077 12.6728L5.89923 12.3272ZM0.2 16L0.2 1.5L-0.2 1.5L-0.2 16L0.2 16ZM0.2 1.5C0.2 0.795234 0.76941 0.2 1.5 0.2L1.5 -0.2C0.54309 -0.2 -0.2 0.579766 -0.2 1.5L0.2 1.5ZM1.5 0.2L10.5 0.2L10.5 -0.2L1.5 -0.2L1.5 0.2ZM10.5 0.2C11.202 0.2 11.8 0.797957 11.8 1.5L12.2 1.5C12.2 0.577043 11.423 -0.2 10.5 -0.2L10.5 0.2Z"
                        fill="white"
                        mask="url(#path-1-outside-1_4264_2573)"
                    />
                </svg>
                <span class="font-bold text-blue-darkest leading-5"
                    >Your Saved Items</span
                >
            </div>
            <ul class="mt-4 grid gap-3">
                <li
                    v-for="favorite in favorites"
                    :key="favorite.id"
                    class="relative"
                >
                    <a
                        :href="favorite.link"
                        class="link text-gray-900 grid gap-2 leading-tight hover:no-underline"
                        :target="favorite.target"
                    >
                        <div>
                            <div
                                class="font-bold truncate max-w-xs text-sm"
                                v-html="favorite.title"
                            />
                            <div
                                class="text-xs text-gold-light font-normal flex items-center gap-2"
                            >
                                {{ favorite.category }}
                                <span v-if="favorite.target === '_blank'">
                                    <svg
                                        width="9"
                                        height="10"
                                        viewBox="0 0 9 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5 1.5C5 1.16797 5.27344 0.875 5.625 0.875H8.10547C8.20312 0.875 8.28125 0.894531 8.35938 0.933594C8.41797 0.953125 8.49609 1.01172 8.55469 1.07031C8.67188 1.1875 8.73047 1.34375 8.75 1.5V4C8.75 4.35156 8.45703 4.625 8.125 4.625C7.77344 4.625 7.5 4.35156 7.5 4V3.02344L4.17969 6.32422C3.94531 6.57812 3.53516 6.57812 3.30078 6.32422C3.04688 6.08984 3.04688 5.67969 3.30078 5.44531L6.60156 2.125H5.625C5.27344 2.125 5 1.85156 5 1.5ZM0 2.75C0 2.06641 0.546875 1.5 1.25 1.5H3.125C3.45703 1.5 3.75 1.79297 3.75 2.125C3.75 2.47656 3.45703 2.75 3.125 2.75H1.25V8.375H6.875V6.5C6.875 6.16797 7.14844 5.875 7.5 5.875C7.83203 5.875 8.125 6.16797 8.125 6.5V8.375C8.125 9.07812 7.55859 9.625 6.875 9.625H1.25C0.546875 9.625 0 9.07812 0 8.375V2.75Z"
                                            fill="#928D82"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'HomepageFavorites',
    props: {
        favorites: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isHoveredMap: {},
            bookmarkPageIcon: 'bookmarkPageIcon',
            addIcon: 'addIcon',
            removeBookmarkPageHover: 'removeBookmarkPageHover',
        }
    },
    methods: {
        removeFavorite(favoriteId) {
            this.$emit('remove-favorite', favoriteId)
        },
    },
}
</script>

<style scoped>
.scrollbar {
    scrollbar-color: #0f358a #ecf0f8;
    scrollbar-width: thin;
}
</style>
