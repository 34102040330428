<template>
    <BaseButton
        :class="[
            'rounded-full',
            'bg-blue',
            'text-white',
            'font-medium',
            'w-full',
            'md:w-auto',
            'text-center',
            'no-underline',
            'inline-block',
            'hover:text-white',
            'hover:bg-blue-medium',
            'transition-colors',
            'text-base',
            padding,
        ]"
        :href="href"
        @click="$emit('click', $event)">
        <slot />
    </BaseButton>
</template>

<script>
import BaseButton from '../Base/BaseButton.vue'
export default {
    name: 'ButtonPrimary',
    components: {
        BaseButton,
    },
    props: {
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: null,
            validator: (val) =>
                ['_blank', '_parent', '_self', '_top'].includes(val),
        },
        padding: {
            type: String,
            default: 'px-8 py-3',
        },
    },
}
</script>
