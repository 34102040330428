<script>
import axios from 'axios'
import AssetListing from 'Components/Redesign/Asset/AssetListing'
import Loading from 'Components/Redesign/Loading'

export default {
    components: {
        AssetListing,
    },
    props: {
        commsPageId: {
            type: String,
            required: true,
        },
        collection: {
            type: [Array, Object],
            required: false,
            default: function () {
                return []
            },
        },
        initialDisplay: {
            type: String,
            required: false,
            default: 'closed',
        },
        stats: {
            type: [Array, Object],
            required: false,
            default: function () {
                return []
            },
        },
        keyword: {
            type: String,
            required: false,
            default: '',
        },
        assetTypeId: {
            type: [Number, null],
            required: false,
            default: null,
        },
        isAdmin: {
            type: Boolean,
            required: true,
        },
        downloadLimit: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            assets: [],
            display: 'closed',
            isLoading: false,
            meta: [],
            selectAll: false,
        }
    },
    computed: {
        countAssets() {
            let c = 0

            this.stats.forEach((s) => {
                c = c + s.count
            })

            return c
        },
        userHasAccessToCollectionAssets() {
            if (this.collection.thumbnails.length > 0) {
                return true
            }

            return this.collection.asset_stats.total === 0
        },
        thumbnails() {
            return this.collection.thumbnails
        },
    },
    watch: {
        initialDisplay: {
            handler() {
                this.init()
            },
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        fetchAssets() {
            this.isLoading = true
            let form = new FormData()

            form.append('collection_ids[]', this.collection.collection_id)
            /**
             * Commented out for now. If the client wants these filters we can uncomment them
             * If the client doesn't want them we should remove the code that passes the props
             * from the parent components down to this component
             */
            // if (this.keyword) {
            //     form.append('keyword', this.keyword);
            // }
            // if (this.assetTypeId) {
            //     form.append('asset_type_ids[]', this.assetTypeId);
            // }

            let url = '/api/comms-pages/' + this.commsPageId + '/asset-search'

            axios
                .post(url, form)
                .then((response) => {
                    this.assets = response.data.data.assets.data
                    this.meta = response.data.meta
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        img(i) {
            if (this.collection.thumbnails.length >= i + 1) {
                return this.collection.thumbnails[i]
            }
            return ''
        },
        alt(i) {
            if (this.collection.thumbnails.length >= i + 1) {
                return this.collection.thumbnails[i]
            }
            return ''
        },
        init() {
            if (this.initialDisplay == 'open') {
                this.open()
            }
        },
        async open() {
            if (this.assets.length == 0 && this.meta.length == 0) {
                await this.fetchAssets()
            }

            this.display = 'open'
            this.$emit('open', this.collection.collection_id)
        },
        close() {
            this.display = 'closed'
            this.$emit('open', null)
        },
        toggleSelectAll() {
            this.selectAll = !this.selectAll

            if (this.selectAll) {
                this.$store.dispatch('user/setSelectedAssets', this.assets)
            } else {
                this.$store.dispatch('user/setSelectedAssets', [])
            }
        },
    },
}
</script>

<template>
    <div :id="collection.collection_id">
        <div v-if="userHasAccessToCollectionAssets">
            <div
                class="mb-8 bg-blue-lightest py-4 px-6 rounded-xl border-2 border-blue-medium relative">
                <div class="coll--collection">
                    <div class="col-span-3">
                        <div
                            class="text-gray-darkest text-3xl font-bold font-swiss leading-9 flex items-center gap-4">
                            {{ collection.name }}
                            <a
                                :href="`/search?collection_ids[]=${collection.collection_id}`"
                                class="after:content-[''] after:absolute after:inset-0 after:w-full after:h-full">
                                <i class="far fa-link text-blue text-base" />
                            </a>
                        </div>
                        <div
                            v-for="stat in stats"
                            :key="stat.asset_type_id"
                            class="text-sm mb-3">
                            <span v-text="stat.count" />
                            <span v-text="stat.asset_type" />
                        </div>
                        <hr class="mb-3 border-0 h-0.5 bg-gray-300 w-2/3" />
                        <template v-if="countAssets <= downloadLimit">
                            <a
                                v-if="countAssets > 0"
                                class="text-sm flex gap-2 items-center"
                                :href="`/download/multiple?collection_id=${collection.collection_id}`"
                                target="_blank">
                                Download {{ countAssets }} assets
                                <svg
                                    width="12"
                                    height="14"
                                    viewBox="0 0 12 14"
                                    fill="none"
                                    class="rotate-180"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.34375 2.65625L10.8438 7.15625C11.0312 7.34375 11.0312 7.6875 10.8438 7.875C10.6562 8.0625 10.3125 8.0625 10.125 7.875L6.5 4.21875V13.5C6.5 13.7812 6.25 14 6 14C5.71875 14 5.5 13.7812 5.5 13.5V4.21875L1.84375 7.875C1.65625 8.0625 1.3125 8.0625 1.125 7.875C0.9375 7.6875 0.9375 7.34375 1.125 7.15625L5.625 2.65625C5.71875 2.5625 5.84375 2.5 6 2.5C6.125 2.5 6.25 2.5625 6.34375 2.65625ZM11.5 0C11.75 0 12 0.25 12 0.5C12 0.78125 11.75 1 11.5 1H0.5C0.21875 1 0 0.78125 0 0.5C0 0.25 0.21875 0 0.5 0H11.5Z"
                                        fill="#0F358A" />
                                </svg>
                            </a>
                        </template>
                        <div v-else>
                            <p class="text-sm mt-2">
                                There are too many assets in this collection for
                                bulk download.
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5 flex gap-4">
                        <img
                            v-for="(image, i) in thumbnails"
                            :key="i"
                            class="w-full h-full max-h-48 max-w-48 object-cover"
                            :src="`${image.url}`" />
                        <!-- <div class="coll--toggle">
                            <div>
                                <div
                                    class="coll--assets font-light text-coral-dark">
                                    <span v-text="countAssets" /> assets
                                </div>
                                <div v-if="display == 'closed'">
                                    <a
                                        v-if="countAssets > 0"
                                        class="button --coral --small"
                                        @click="open">
                                        Open To See More +
                                    </a>
                                </div>
                                <div v-else>
                                    <a
                                        class="button --coral --small --hide"
                                        @click="close">
                                        Close And Hide -
                                    </a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="self-center justify-self-end">
                        <span
                            class="bg-blue-lighter text-blue font-bold rounded-full h-24 w-24 text-xl flex items-center justify-center"
                            v-text="'+' + countAssets" />
                    </div>
                </div>
                <div v-if="display == 'open'">
                    <div class="cards">
                        <div>
                            <label
                                class="inline-block align-middle text-sm border border-2 border-gray-300 ml-2 p-2 text-gray-600">
                                Select All
                                <input
                                    @change="toggleSelectAll"
                                    type="checkbox"
                                    name="select-all"
                                    class="border-coral ml-2" />
                            </label>
                        </div>
                        <asset-listing
                            :assets="assets"
                            :active-status="{
                                id: 5,
                                name: 'Active',
                                value: 'active',
                            }"
                            :is-admin="isAdmin"
                            :show-controls="false"
                            :show-messages="false" />
                        <div
                            v-if="assets.length == 0"
                            class="p-4 mb-4 rounded-lg border border-yellow-300 bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                            role="alert">
                            Unable to display assets.
                        </div>
                    </div>
                    <div>
                        <a
                            class="button --coral --hide"
                            @click="close">
                            Close And Hide -
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="coll">
            <div class="coll--collection">
                <div class="coll--description">
                    <div class="text-3xl">
                        {{ collection.name }}
                    </div>
                    <div
                        v-for="stat in stats"
                        :key="stat.asset_type_id"
                        class="text-sm">
                        <span v-text="stat.count" />
                        <span v-text="stat.asset_type" />
                    </div>
                </div>
                <div class="coll--no-access">
                    <p class="important-message">
                        Unfortunately, you do not have proper permissions
                        enabled to view this collection.
                    </p>
                    <p>
                        Contact the
                        <a
                            href="/resources/contact"
                            class="link"
                            >site administrator</a
                        >
                        for assistance.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.coll--collection {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 2rem;
    &:last-of-type {
        margin-bottom: 0;
    }
    .coll--description {
        grid-column: span 1;

        h3 {
            line-height: 1.1em;
            text-transform: none;
        }
    }
    .coll--images {
        grid-column: span 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
    }
    .coll--img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        grid-column: span 1;
        grid-row: span 1;
    }
    .coll--toggle {
        align-items: center;
        border: 2px solid coral;
        color: orangered;
        display: flex;
        grid-column: span 2;
        grid-row: span 1;
        justify-content: center;
        padding: 1.4rem;

        .coll--assets {
            font-size: 1.6rem;
            text-align: center;
            margin-bottom: 1rem;
            font-weight: 200;
        }
    }
    .coll--no-access {
        border: 1px dashed #888;
        color: #888;
        grid-column: span 2;
        padding: 2rem 20%;
        text-align: center;

        .important-message {
            font-size: 1.8rem;
            font-style: italic;
            line-height: 1em;
            margin-bottom: 0.5em;
        }
    }
    .cards {
        margin: 2rem 0;
        display: block;
    }
    .button {
        &.--hide {
            @apply bg-coral-light;
        }
    }
}
</style>
