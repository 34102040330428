import axios from 'axios'

import { getEntryBySlug, renderContent } from 'Composables/useEntries.js'

import { CONTACT_FORM_SUBMIT } from 'Root/endpoints.js'
import {
    SET_CONTACT_FORM_CONTACTS,
    SET_CONTACT_FORM_CONTENT,
    SET_CONTACT_FORM_HAS_BEEN_SENT,
    SET_CONTACT_FORM_HEADING,
    SET_CONTACT_FORM_IS_LOADING,
    SET_CONTACT_FORM_RESPONSE,
    SET_CONTACT_FORM_TOPICS,
} from 'Stores/Frontend/mutation-types.js'

export default {
    namespaced: true,
    state: {
        contacts: [],
        content: null,
        heading: null,
        isLoading: false,
        response: null,
        hasBeenSent: false,
        topics: [],
    },
    mutations: {
        [SET_CONTACT_FORM_CONTACTS](state, contacts) {
            state.contacts = contacts
        },
        [SET_CONTACT_FORM_CONTENT](state, content) {
            state.content = content
        },
        [SET_CONTACT_FORM_HEADING](state, heading) {
            state.heading = heading
        },
        [SET_CONTACT_FORM_IS_LOADING](state, isLoading) {
            state.isLoading = isLoading
        },
        [SET_CONTACT_FORM_TOPICS](state, topics) {
            state.topics = topics
        },
        [SET_CONTACT_FORM_HAS_BEEN_SENT](state, hasBeenSent) {
            state.hasBeenSent= hasBeenSent
        },
        [SET_CONTACT_FORM_RESPONSE](state, response) {
            state.response = response
        },
    },
    getters: {},
    actions: {
        async init({ dispatch }) {
            await dispatch('loadSection')
        },
        async loadSection({ commit }) {
            commit(SET_CONTACT_FORM_IS_LOADING, true)

            try {
                const response = await getEntryBySlug('contact')
                const section = response.data.data[0]

                commit(SET_CONTACT_FORM_CONTENT, renderContent(section.content))
                commit(SET_CONTACT_FORM_CONTACTS, section.extra.contacts)
                commit(
                    SET_CONTACT_FORM_HEADING,
                    renderContent(section.extra.heading)
                )
                commit(SET_CONTACT_FORM_TOPICS, section.extra.topics)
            } catch (error) {
                console.error(error)
            }

            commit(SET_CONTACT_FORM_IS_LOADING, false)
        },
        async submit({ commit }, formData) {
            commit(SET_CONTACT_FORM_IS_LOADING, true)

            try {
                const response = await axios.post(CONTACT_FORM_SUBMIT, formData)

                commit(SET_CONTACT_FORM_HAS_BEEN_SENT, true)
                commit(SET_CONTACT_FORM_RESPONSE, response.data)
            } catch (error) {
                commit(SET_CONTACT_FORM_HAS_BEEN_SENT, true)
                commit(SET_CONTACT_FORM_RESPONSE, error.data)
                console.error(error)
            }

            commit(SET_CONTACT_FORM_IS_LOADING, false)
        },
    },
}
