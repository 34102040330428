<template>
    <div class="max-w-container px-container mx-auto py-16">
        <span
            class="absolute -top-16 right-0 z-0"
            width="718"
            height="587">
            <BackgroundBlueLight />
        </span>

        <div class="fullWidth__heading relative z-10">
            <h1 class="text-6xl text-gray font-aldine italic mb-8">
                Notifications
            </h1>
        </div>

        <div
            v-if="!isUpdatingNotification"
            class="fullWidth__body relative z-10">
            <div class="flex gap-8 items-center">
                <div class="col-span-2">
                    <h2
                        class="js-notificationForm text-gold-light font-swiss font-bold text-5xl leading-tight mb-4">
                        {{ setupNotificationText }}
                    </h2>
                    <p class="font-swiss text-lg">
                        Use this page to create email notifications alerting you
                        to new assets being added to The Stacks. These can be
                        controlled at either a brand or property level and you
                        can control the cadence of the emails. Notifications
                        will be sent to your primary email address:
                        <span class="font-bold text-gray-darkest">{{
                            userEmail
                        }}</span>
                    </p>

                    <p>
                        <a
                            class="link font-bold text-lg mt-4 inline-block"
                            href="/account">
                            Update or add an additional email in your account
                        </a>
                    </p>
                </div>

                <aside class="col-span-1 w-[411px] min-w-[411px]">
                    <div
                        class="border border-blue rounded-2xl px-24 py-6 text-center text-2xl flex flex-col gap-1 leading-[30px] text-black">
                        <p class="font-swiss leading-tight">
                            You have
                            <span
                                class="text-coral font-bold inline-block w-full"
                                v-text="`${notificationCount} notifications`" />
                            established
                        </p>

                        <ButtonStripped
                            class="text-lg flex center justify-center items-center gap-4 mt-4"
                            @click.prevent="scrollToList"
                            text-size="text-lg"
                            padding="px-0">
                            Manage Notifications
                            <svg
                                height="8"
                                viewBox="0 0 14 8"
                                width="14"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m1 1 6 6 6-6"
                                    fill="none"
                                    stroke="#9DB6EB"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2" />
                            </svg>
                        </ButtonStripped>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import BackgroundBlueLight from 'Components/Redesign/Background/BackgroundBlueLight.vue'
import ButtonStripped from 'Components/Redesign/Button/ButtonStripped.vue'

export default {
    components: {
        BackgroundBlueLight,
        ButtonStripped,
    },
    props: {
        userEmail: {
            type: String,
            required: true,
        },
        initialNotificationCount: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState('accountNotifications', [
            'notifications',
            'notificationBeingUpdated',
        ]),
        notificationCount() {
            if (this.notifications !== null) {
                return this.notifications.length
            }

            return this.initialNotificationCount
        },
        isUpdatingNotification() {
            return this.notificationBeingUpdated !== null
        },
        setupNotificationText() {
            return this.isUpdatingNotification
                ? `Update notification named ${this.notificationBeingUpdated.name}`
                : 'Set up a new notification'
        },
    },
    methods: {
        scrollToList() {
            const element = document.querySelector('#manage')

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                })
            }
        },
    },
}
</script>
