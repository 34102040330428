import VeeValidate from 'vee-validate'
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueObserveVisibility from 'vue-observe-visibility'

import router from './router.js'
import store from 'Stores/Frontend'

import AccountNotificationsForm from 'Components/Redesign/AccountNotifications/AccountNotificationsForm/AccountNotificationsForm.vue'
import AccountNotificationsHeader from 'Components/Redesign/AccountNotifications/AccountNotificationsForm/AccountNotificationsHeader.vue'
import AccountNotificationsList from 'Components/Redesign/AccountNotifications/AccountNotificationsForm/AccountNotificationsList.vue'
import ApiButton from 'Components/Generic/ApiButton'
import AssetDetails from 'Components/Redesign/Asset/AssetDetails'
import AssetListing from 'Components/Redesign/Asset/AssetListing'
import AssetMetadata from 'Components/Redesign/Asset/AssetMetadata'
import AssetSearchFilters from 'Components/Redesign/Asset/AssetSearchFilters'
import AssetSearchSelectedFilters from 'Components/Redesign/Asset/AssetSearchSelectedFilters'
import Assets from 'Components/Redesign/Asset/Assets'
import BaseButton from 'Components/Redesign/Base/BaseButton'
import BaseFooter from 'Components/Redesign/Base/BaseFooter'
import BaseLogo from 'Components/Redesign/Base/BaseLogo'
import BaseNotification from 'Components/Redesign/Base/BaseNotification'
import BpAccordion from 'Components/Redesign/BpAccordion'
import CollapsingMenu from 'Components/Redesign/CollapsingMenu'
import CommsPageDetail from 'Components/Redesign/CommsPageDetail'
import Drawer from 'Components/Redesign/Drawer'
import FaqList from 'Components/Redesign/Faq/FaqList'
import HomepageWelcome from 'Components/Redesign/Homepage/HomepageWelcome'
import HomepageWelcomeBanner from 'Components/Redesign/Homepage/HomepageWelcomeBanner'
import LinkNav from 'Components/Redesign/LinkNav'
import Loading from 'Components/Redesign/Loading'
import NavigationBase from 'Components/Redesign/Navigation/NavigationBase'
import NavigationMenu from 'Components/Redesign/Navigation/NavigationMenu'
import News from 'Components/Redesign/News'
import NewsSlider from 'Components/Redesign/NewsSlider'
import RelatedAssets from 'Components/Redesign/RelatedAssets'
import Search from 'Components/Redesign/Search'
import SearchInput from 'Components/Redesign/Search/SearchInput'
import SystemMessages from 'Components/Redesign/SystemMessages'
import Tabs from 'Components/Redesign/Tabs'
import FeaturedTools from 'Components/Redesign/FeaturedTools/FeaturedTools'
import WorkOrders from 'Components/Redesign/WorkOrders'

import EntryView from 'Components/Entries/EntryPage'

Vue.use(VueLazyLoad)
Vue.use(Vuex)
Vue.use(VeeValidate)
Vue.use(VueRouter)
Vue.use(VueObserveVisibility)

new Vue({
    el: document.querySelector('.mal'),
    components: {
        // Views
        AccountView: () => import('Views/Account'),
        ApprovalDetailsView: () => import('Views/Approvals/Details'),
        ApprovalsIndexView: () => import('Views/Approvals'),
        AssetGuidelinesView: () => import('Views/Assets/Guidelines'),
        HomeView: () => import('Views/Home'),
        NotificationsView: () => import('Views/Notifications'),
        ResourcesView: () => import('Views/Resources'),
        WorkspacesIndexView: () => import('Views/Workspaces'),
        WorkspaceView: () => import('Views/Workspaces/View'),
        LoginView: () => import('Views/Auth/Login'),

        AssetCollection: () =>
            import('Components/Redesign/Asset/AssetCollection'),
        LogoUpload: () => import('Components/Redesign/LogoUpload'),
        Remediations: () =>
            import('Components/Redesign/WorkOrders/Remediations'),
        AccountNotificationsForm,
        AccountNotificationsHeader,
        AccountNotificationsList,
        ApiButton,
        AssetDetails,
        AssetListing,
        AssetMetadata,
        AssetMetadata,
        AssetSearchFilters,
        AssetSearchSelectedFilters,
        Assets,
        BaseButton,
        BaseFooter,
        BaseLogo,
        BaseNotification,
        BpAccordion,
        BpAccordion,
        ButtonPrimary: () => import('Components/Redesign/Button/ButtonPrimary'),
        CollapsingMenu,
        CommsPageDetail,
        Drawer,
        EntryView,
        FaqList,
        FormInput: () => import('Components/Redesign/Form/FormInput'),
        FormPassword: () => import('Components/Redesign/Form/FormPassword'),
        HomepageWelcome,
        HomepageWelcomeBanner,
        LinkNav,
        Loading,
        NavigationMenu,
        NavigationBase,
        NavigationMinimal: () =>
            import('Components/Redesign/Navigation/NavigationMinimal'),
        News,
        NewsSlider,
        RelatedAssets,
        Search,
        SearchInput,
        SystemMessages,
        Tabs,
        Tabs,
        FeaturedTools,
        WorkOrders,
    },
    store,
    router,
    data: {},
    computed: {
        searchLoading() {
            return this.$store.state.search.isLoading
        },
    },
    methods: {
        goToPage(page) {
            window.location.href = page
        },
    },
    async created() {
        const body = document.querySelector('body')

        // We have a minimal layout for when people are not logged in.
        // Check that the data-minimal attribute has been added to the body tag
        if (body.dataset.minimal !== undefined) {
            return
        }

        await this.$store.dispatch('getMessages')
        await this.$store.dispatch('populateSearchFromParams')
        await this.$store.dispatch('user/init')
        await this.$store.dispatch('options/init')
        await this.$store.dispatch('favorites/init')
        await this.$store.dispatch('spotlight/init')
    },
})

// Handle tabs gracefully as per https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2å
function handleFirstTab(e) {
    if (e.keyCode === 9) {
        // console.log('Ive been pushed')
        document.body.classList.add('user-is-tabbing')
        window.removeEventListener('keydown', handleFirstTab)
        window.addEventListener('mousedown', handleMouseDownOnce)
    }
}

function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing')
    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', handleFirstTab)
}

window.addEventListener('keydown', handleFirstTab)
