<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#FFF3F1"/>
        <path d="M14.7568 17.4999C17.7568 17.4999 20.1943 19.9374 20.1943 22.9374C20.1943 23.5312 19.6943 23.9999 19.1005 23.9999H7.25678C6.66303 23.9999 6.19428 23.5312 6.19428 22.9374C6.19428 19.9374 8.60053 17.4999 11.6005 17.4999H14.7568ZM13.1943 15.9999C10.9755 15.9999 9.19428 14.2187 9.19428 11.9999C9.19428 9.81244 10.9755 7.99994 13.1943 7.99994C15.3818 7.99994 17.1943 9.81244 17.1943 11.9999C17.1943 14.2187 15.3818 15.9999 13.1943 15.9999ZM25.4443 14.2499C25.8505 14.2499 26.1943 14.5937 26.1943 14.9999C26.1943 15.4374 25.8505 15.7499 25.4443 15.7499H20.9443C20.5068 15.7499 20.1943 15.4374 20.1943 14.9999C20.1943 14.5937 20.5068 14.2499 20.9443 14.2499H25.4443Z" fill="#E53411"/>
    </svg>
</template>

<script>
export default {
    name: 'IconRemoveUser',
}
</script>
