import {v4 as uuid} from 'uuid';

class NotificationModel {
    constructor(message, level = 'success', ttl = 5) {
        this.id = uuid();
        this.message = message;
        this.level = level;
        this.ttl = ttl;
    }

    getId() {
        return this.id;
    }

    getMessage() {
        return this.message;
    }

    setSubMessage(subMessage) {
        this.subMessage = subMessage;
    }

    getSubMessage() {
        return this.subMessage;
    }

    getLevel() {
        return this.level;
    }

    getTtl() {
        return this.ttl;
    }

    valueOf() {
        return this.id;
    }
};

export default NotificationModel;
