var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseButton',{class:[
        'rounded-full',
        'bg-blue',
        'text-white',
        'font-medium',
        'w-full',
        'md:w-auto',
        'text-center',
        'no-underline',
        'inline-block',
        'hover:text-white',
        'hover:bg-blue-darkest',
        'transition-colors',
        'text-xs',
        'px-6 py-2' ],attrs:{"href":_vm.href},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }