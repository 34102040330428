import axios from 'axios'
import { COLLECTIONS_LIST } from 'Root/endpoints'

export async function getCollections(query) {
    return axios.get(COLLECTIONS_LIST, { params: query })
}

/**
 * Parses the filters the FilterBar component emits
 * @see FilterBar.vue
 */
export function parseCollectionFilters(filters) {
    const query = {}

    query.brand_ids = filters.filter.map((filter) => filter.brand_id)

    switch (filters.dropdown) {
        case null:
            query.types = ['collections', 'toolkits']
            break

        case 'collections':
            query.types = ['collections']
            break
        case 'toolkits':
            query.types = ['toolkits']
            break

        default:
            query.types = ['collections', 'toolkits']
            break
    }

    return query
}

export default { getCollections }
