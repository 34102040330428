<template>
    <section class="contactForm">
        <div class="contactForm--heading">
            <slot name="heading">
                <div
                    class="h-32 bg-blue-lighter rounded-md animate-pulse mb-8"
                />
            </slot>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 pb-8">
            <div class="contactForm--content">
                <slot />
                <div
                    v-if="topic"
                    class="flex gap-8"
                >
                    <BasePerson
                        v-for="(person, index) in topic.contacts"
                        :key="index"
                        :thumbnail="person.avatar"
                        title="Key Contact"
                        :name="person.full_name"
                        text-color="darkblue"
                    />
                </div>
            </div>

            <div
                v-if="!topics.length"
                class="w-full h-96 animate-pulse bg-blue-lighter"
            />

            <form
                v-if="topics.length"
                class="text-black grid gap-8 content-start"
                @submit.prevent="validateForm"
            >
                <div v-if="hasBeenSent">
                    <span
                        v-if="response.success"
                        class=""
                    >
                        Thank you, your inquiry has been sent. A confirmation email has been sent to your email address.
                    </span>

                    <span
                        v-if="!response.success"
                        class=""
                        >Sorry, we weren't able to submit your inquiry</span
                    >
                </div>
                <FormSelect
                    v-model="topic"
                    v-validate="'required'"
                    :options="topics"
                    name="topic"
                    input-label="Inquiry Topic"
                    required
                />
                <span
                    v-show="errors.has('topic')"
                    class="text-sm text-red-600"
                    >{{ errors.first('topic') }}</span
                >

                <FormTextArea
                    v-model="message"
                    v-validate="'required'"
                    name="message"
                    placeholder="Tell us more..."
                    label="How Can We Help?"
                    optional-text="(questions or comments)"
                    input-id="message"
                    required
                />
                <span
                    v-show="errors.has('message')"
                    class="text-sm text-red-600"
                    >{{ errors.first('message') }}</span
                >

                <ButtonPrimary class="justify-self-end">
                    Send My Inquiry
                </ButtonPrimary>
            </form>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BasePerson from 'Components/Redesign/Base/BasePerson.vue'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import FormSelect from 'Components/Redesign/Form/FormSelect.vue'
import FormTextArea from 'Components/Redesign/Form/FormTextArea.vue'

export default {
    components: {
        BasePerson,
        ButtonPrimary,
        FormSelect,
        FormTextArea,
    },
    props: {
        isLoading: {
            required: false,
            default: false,
            type: Boolean,
        },
        topics: {
            required: true,
            default: () => [],
            type: Array,
        },
        contacts: {
            required: true,
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            topic: null,
            message: null,
        }
    },
    computed: {
        ...mapState('contactForm', ['response', 'hasBeenSent']),
        ...mapState('user', ['user']),
    },
    methods: {
        ...mapActions('contactForm', ['submit']),
        async validateForm() {
            const isValid = await this.$validator.validateAll()

            if (!isValid) {
                return
            }

            const form = {
                topic: this.topic,
                message: this.message,
            }

            await this.submit(form)

            if (this.response.success) {
                this.topic = null
                this.message = null

                this.$validator.reset()
            }
        },
    },
}
</script>

<style lang="scss">
.contactForm--heading {
    h2 {
        @apply text-gray-darkest text-2xl md:text-9xl pb-20 leading-heading;
    }
}

.contactForm--content {
    p {
        @apply text-blue-darkest text-xl lg:max-w-sm mb-4;
    }
}
</style>
