var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-3 gap-8 py-20"},[_c('span',{staticClass:"absolute -top-16 right-0 z-0",attrs:{"width":"718","height":"587"}},[_c('BackgroundGoldLight')],1),_vm._v(" "),_c('div',{staticClass:"row-start-1 md:col-span-2 relative z-10"},[_c('div',{staticClass:"flex gap-4"},[_c('h2',{staticClass:"text-6xl text-gray-darkest mb-6"},[_vm._v("\n                    "+_vm._s(_vm.entry.title)+"\n                ")]),_vm._v(" "),_c('ButtonBookmark',{staticClass:"shrink-0",attrs:{"item":_vm.entry,"category":_vm.entry.categories.data.length > 0
                            ? _vm.entry.categories.data[0].title
                            : 'News',"title":_vm.entry.title,"link":_vm.entry.links.data.view,"target":"_self"}})],1),_vm._v(" "),_c('BasePerson',{attrs:{"thumbnail":_vm.entry.author.data.avatar,"title-type":'created',"title":_vm.entry.created_at,"name":_vm.entry.author.data.full_name}}),_vm._v(" "),_c('div',{staticClass:"mt-16"},[(
                        _vm.entry &&
                        _vm.entry.content &&
                        Array.isArray(_vm.entry.content.content)
                    )?_c('div',_vm._l((_vm.entry.content.content),function(el,i){return _c('article',{key:i,staticClass:"prose lg:prose-xl prose-strong:font-bold prose-headings:underline"},[(
                                el.content &&
                                Array.isArray(el.content) &&
                                el.content.length > 0
                            )?_c('p',[_vm._v("\n                            "+_vm._s(el.content[0].text)+"\n                        ")]):_c('p',[_vm._v("No content available")])])}),0):_c('div',[_vm._v("No entry or content available")])])],1),_vm._v(" "),[_c('div',{staticClass:"row-start-2 md:row-span-full md:col-start-3 grid gap-8 items-start"},[(_vm.entry.featured_image)?_c('img',{staticClass:"w-full h-96 object-cover relative z-10 border-none",attrs:{"src":_vm.entry.featured_image,"alt":""}}):_vm._e(),_vm._v(" "),(_vm.entry.brands.data.length > 0)?_c('div',{staticClass:"bg-white rounded-2xl px-8 py-4 flex flex-col gap-4 z-20"},[_c('div',{staticClass:"font-bold text-blue-darkest"},[_vm._v("Brands")]),_vm._v(" "),_vm._l((_vm.entry.brands.data),function(brand,i){return _c('div',{key:i},[_c('ButtonBrandDefault',{staticClass:"flex gap-4 items-center"},[_c('label',{staticClass:"cursor-pointer"},[_vm._v("\n                                "+_vm._s(brand.name)+"\n                            ")]),_vm._v(" "),_c('BaseIcon',{attrs:{"svg":_vm.bookmarkIcon}})],1)],1)})],2):_vm._e()])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }