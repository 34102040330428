<template>
    <div>
        <div
            class="flex flex-col md:flex-row justify-between items-center gap-4">
            <h3 class="font-swiss font-light text-6xl text-gold-light">
                Read & Filter Our FAQs
            </h3>

            <FormInputSearch v-model="params.keywords" />
        </div>

        <hr class="border border-gold-light mt-4" />

        <div class="grid grid-cols-1 gap-6 mt-12">
            <div v-if="faqs.length === 0">
                Sorry, no frequently asked questions were found.
            </div>

            <div
                v-if="displayCategories"
                class="flex gap-4">
                <ButtonPrimary
                    v-for="(cat, catIndex) in uniqueCategories"
                    :key="catIndex"
                    text-size="text-xs">
                    {{ cat }}
                </ButtonPrimary>
            </div>

            <BpAccordion
                v-for="(faq, i) in faqs"
                :key="i"
                bg-color-closed="bg-blue-lighter shadow-blue-faqs">
                <template #heading>
                    <h5
                        class="font-bold text-gray-darkest text-2xl flex items-center gap-2">
                        {{ faq.title }}
                        <ButtonBookmark
                            :item="faq"
                            category="FAQs"
                            :title="faq.title"
                            :link="faq.links.data.view"
                            target="_self" />
                    </h5>
                </template>

                <div
                    v-html="renderContent(faq.content)"
                    class="font-aldine text-lg flex flex-col space-y-4" />

                <div
                    v-if="nextSteps(faq)"
                    class="max-w-max">
                    <ButtonStripped
                        class="text-lg"
                        padding="p-0"
                        :href="nextSteps(faq).url">
                        <div class="flex items-center gap-1 mt-4">
                            <span
                                v-if="nextSteps(faq).label"
                                class="text-gold-light">
                                {{ nextSteps(faq).label }}
                            </span>
                            {{ nextSteps(faq).text }}
                        </div>
                        <hr class="w-full border border-blue-medium mt-0.5" />
                    </ButtonStripped>
                </div>
                <BasePerson
                    v-if="keyContact(faq)"
                    :thumbnail="keyContact(faq).avatar"
                    :title="keyContact(faq).title"
                    class="mt-4">
                    <template #name>
                        <a :href="`mailto:${keyContact(faq).email}`">
                            {{ keyContact(faq).name }}
                        </a>
                    </template>
                </BasePerson>
            </BpAccordion>
        </div>

        <div
            class="flex flex-col md:flex-row items-center justify-between gap-4 mt-12">
            <ButtonStripped
                padding="p-0"
                :has-arrow="true"
                arrow-color="blue"
                href="/resources/faqs">
                View All Frequently Asked Questions
            </ButtonStripped>

            <ButtonPrimary
                v-if="canLoadMore"
                @click="fetchFaqs(params.page + 1)">
                {{ loadMoreText }}
            </ButtonPrimary>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'

import { getEntries, renderContent } from 'Composables/useEntries.js'

import BaseIcon from 'Components/Redesign/Base/BaseIcon.vue'
import BasePerson from 'Components/Redesign/Base/BasePerson.vue'
import BpAccordion from 'Components/Redesign/BpAccordion/index.vue'
import ButtonBookmark from 'Components/Redesign/Button/ButtonBookmark.vue'
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import ButtonStripped from 'Components/Redesign/Button/ButtonStripped.vue'
import FormInputSearch from 'Components/Redesign/Form/FormInputSearch.vue'

export default {
    name: 'FaqList',
    components: {
        BaseIcon,
        BasePerson,
        ButtonBookmark,
        ButtonPrimary,
        ButtonStripped,
        BpAccordion,
        FormInputSearch,
    },
    props: {
        displayCategories: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            params: {
                type: 'faqs',
                keywords: '',
                orderby: 'updated_at',
                direction: 'desc',
                page: 1,
                limit: 4,
            },
            pagination: {},
            faqs: [],
            showLess: false,
        }
    },
    computed: {
        canLoadMore() {
            if (this.pagination.total_pages === 1) {
                return false
            }

            return this.params.page !== this.pagination.total_pages
        },
        loadMoreText() {
            let remaining = this.pagination.total - this.faqs.length

            if (remaining > this.params.limit) {
                remaining = this.params.limit
            }

            if (this.params.keywords) {
                return `Load ${remaining} More Containing "${this.params.keywords}"`
            }

            return `Load ${remaining} More`
        },
        uniqueCategories() {
            let allCategoryTitles = []

            if (Array.isArray(this.faqs)) {
                this.faqs.forEach((faq) => {
                    if (
                        faq.categories &&
                        faq.categories.data &&
                        Array.isArray(faq.categories.data)
                    ) {
                        faq.categories.data.forEach((cat) => {
                            if (
                                cat &&
                                cat.title &&
                                !allCategoryTitles.includes(cat.title.trim())
                            ) {
                                allCategoryTitles.push(cat.title.trim())
                            }
                        })
                    }
                })
            }

            // Sort alphabetically
            return allCategoryTitles.sort((a, b) => a.localeCompare(b))
        },
    },
    methods: {
        async fetchFaqs(page) {
            try {
                this.params.page = page ?? 1

                const response = await getEntries(this.params)

                this.faqs.push(...response.data.data)

                this.pagination = response.data.meta.pagination
            } catch (error) {
                console.error(error)
            }
        },
        renderContent,
        keyContact(faq) {
            return faq.extra?.keyContact
        },
        nextSteps(faq) {
            return faq.extra?.nextSteps
        },
    },
    watch: {
        'params.keywords': debounce(function () {
            this.faqs = []
            this.fetchFaqs(1)
        }, 250),
    },
    mounted() {
        this.fetchFaqs(1)
    },
}
</script>
