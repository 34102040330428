<template>
    <div
        class="col-span-full bg-blue-lightest border-2 border-white rounded-md relative overflow-hidden">
        <div class="flex justify-between items-center">
            <BaseTitleTag
                :title="severity"
                variant="notification"
                :has-icon="true" />
            <ButtonStripped
                class="pr-4 flex gap-2 items-center text-sm"
                @click="markAsSeen()">
                Mark as seen
                <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none">
                    <path
                        d="M0.957031 8C0.957031 3.59375 4.51953 0 8.95703 0C13.3633 0 16.957 3.59375 16.957 8C16.957 12.4375 13.3633 16 8.95703 16C4.51953 16 0.957031 12.4375 0.957031 8ZM12.5508 6.625C12.8945 6.28125 12.8945 5.75 12.5508 5.40625C12.207 5.0625 11.6758 5.0625 11.332 5.40625L7.95703 8.78125L6.55078 7.40625C6.20703 7.0625 5.67578 7.0625 5.33203 7.40625C4.98828 7.75 4.98828 8.28125 5.33203 8.625L7.33203 10.625C7.67578 10.9688 8.20703 10.9688 8.55078 10.625L12.5508 6.625Z"
                        fill="#0F358A" />
                </svg>
            </ButtonStripped>
        </div>
        <div class="flex items-end text-coral-darker text-sm py-3 px-6">
            <div class="max-w-prose md:max-w-screen-sm truncate" v-html="notification.message"/>
            <a
                v-for="(link, i) in notification.links"
                :key="i"
                class="font-bold">
                Keep Reading
            </a>
        </div>
    </div>
</template>

<script>
import ButtonStripped from '../Button/ButtonStripped.vue';
import BaseTitleTag from '../Base/BaseTitleTag.vue';
export default {
    name: 'BaseNotification',
    components: {
        ButtonStripped,
        BaseTitleTag,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        }
    },
    computed: {
        severity() {
            if (this.notification.severity < 5) {
                return 'Urgent Notification'
            }

            return 'Notification'
        }
    },
    methods: {
        markAsSeen() {
            this.$emit('hide', this.notification)
        },
    },
};
</script>
