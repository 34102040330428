<template>
    <div>
        <div
            v-if="editing && form"
            class="flex flex-wrap w-full">
            <div
                :class="{
                    'w-3/4': hasSidebar,
                    'w-full': !hasSidebar,
                }">
                <component
                    :is="form"
                    v-model="editing"
                    class="mb-4" />
            </div>
            <div
                v-if="hasSidebar"
                :class="[
                    { 'w-1/4': hasSidebar },
                    'pl-4',
                    'flex',
                    'flex-col',
                    'align-top',
                ]">
                <div class="w-full mb-4">
                    <label
                        for="status"
                        class="font-bold"
                        >Status <span class="text-danger-dark">*</span></label
                    >
                    <select
                        id="status"
                        class="w-full rounded"
                        v-model="editing.status_id"
                        required>
                        <option
                            v-for="status in statuses"
                            :value="status.id"
                            v-text="status.title" />
                    </select>
                </div>

                <div class="w-full mb-4">
                    <strong>Author</strong>
                    <EntryUsers
                        class="w-full"
                        :multiple="false"
                        v-model="editing.author.data" />
                </div>

                <div
                    class="w-full mb-4 w-full mb-4 pb-4 border-b border-blue-light flex flex-col">
                    <label class="flex flex-col">
                        <span class="font-bold mb-2">Audience</span>
                        <select
                            class="rounded"
                            v-model="editing.category_ids"
                            multiple>
                            <option
                                v-for="category in categories"
                                :value="category.id"
                                v-text="category.title" />
                        </select>
                    </label>
                </div>

                <div
                    class="w-full mb-4 pb-4 border-b border-blue-light flex flex-col">
                    <label
                        for="featured_image"
                        class="font-bold mb-2"
                        >Featured Image</label
                    >

                    <img
                        v-if="editing.featured_image"
                        :src="editing.featured_image" />
                    <span
                        class="mb-2"
                        v-else
                        v-text="'No featured image selected'" />

                    <input
                        @change="changeFeaturedImage"
                        id="featured_image"
                        type="file"
                        name="file" />

                    <p class="my-2 italic">
                        Select a featured image that will be displayed when
                        viewing the entry. <strong>Note</strong>, if set, this
                        will override the collection's imagery.
                    </p>
                </div>

                <div class="w-full mb-4">
                    <label class="flex flex-col">
                        <strong class="mb-2">Collections</strong>
                        <EntryCollections v-model="editing.collections.data" />
                        <p class="my-2 italic">
                            By associating the entry with a collection, it's
                            imagery may be used when displaying the entry.
                        </p>
                    </label>
                </div>

                <div class="w-full mb-4">
                    <label class="flex flex-col">
                        <strong class="mb-2">Brands</strong>
                        <EntryBrands v-model="editing.brands.data" />
                        <p class="my-2 italic">
                            Select the brands this entry should be associated
                            with.
                        </p>
                    </label>
                </div>

                <div class="w-full mb-4">
                    <label class="flex flex-col">
                        <strong class="mb-2">Properties</strong>
                        <EntryProperties v-model="editing.properties.data" />
                        <p class="my-2 italic">
                            Select the properties this entry should be
                            associated with.
                        </p>
                    </label>
                </div>

                <!-- <div class="w-full mb-4"> -->
                <!--     <strong>Tags</strong> -->
                <!-- </div> -->
            </div>

            <div class="w-full flex justify-end">
                <div class="grid grid-cols-1">
                    <ButtonPrimary
                        @click="save"
                        class="mb-12"
                        v-text="entry.id ? 'Update Entry' : 'Create Entry'" />

                    <button
                        class="text-sm text-danger-dark underline"
                        @click="deleteEntry(entry)">
                        <i class="far fa-trash" /> Delete
                    </button>
                </div>
            </div>
        </div>

        <span v-else="!form"> No form currently available </span>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import {
    updateEntry,
    createEntry,
    updateFeaturedImage,
} from 'Composables/useEntries.js'

import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'
import NotificationModel from 'Components/Redesign/SystemNotifications/NotificationModel.js'

import ContactSectionForm from 'Components/Entries/EntryForms/ContactSectionForm'
import FaqForm from 'Components/Entries/EntryForms/FaqForm'
import NewsForm from 'Components/Entries/EntryForms/NewsForm'
import NotificationForm from 'Components/Entries/EntryForms/NotificationForm'
import ResourceForm from 'Components/Entries/EntryForms/ResourceForm'

import EntryBrands from 'Components/Entries/EntryFields/EntryBrands'
import EntryCollections from 'Components/Entries/EntryFields/EntryCollections'
import EntryProperties from 'Components/Entries/EntryFields/EntryProperties'
import EntryUsers from 'Components/Entries/EntryFields/EntryUsers'

export default {
    components: {
        ButtonPrimary,
        ContactSectionForm,
        EntryBrands,
        EntryCollections,
        EntryProperties,
        EntryUsers,
        FaqForm,
        NewsForm,
        ResourceForm,
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        entrySlug: {
            type: String,
            required: false,
            default: null,
        },
        typeSlug: {
            required: true,
            type: String,
            default: null,
        },
        value: { required: false, type: Object, default: null },
    },
    data() {
        return {
            // editing: null,
            featuredImage: null,
        }
    },
    computed: {
        ...mapState('content', ['entry', 'type']),
        ...mapGetters('content', ['categories', 'statuses']),
        form() {
            if (this.typeSlug !== 'sections') {
                return this.getFormByType(this.typeSlug)
            }

            return this.getFormBySlug(this.entrySlug)
        },
        hasSidebar() {
            return this.typeSlug !== 'sections'
        },
        editing: {
            get() {
                return this.entry
            },
            set(entry) {
                this.$store.dispatch('content/setEntry', entry)
            },
        },
    },
    methods: {
        ...mapActions('content', ['deleteEntry']),
        getFormByType(type) {
            switch (type) {
                case 'news':
                    return NewsForm
                    break

                case 'notifications':
                    return NotificationForm
                    break
                case 'updates':
                    return NewsForm
                    break

                case 'resources':
                    return ResourceForm
                    break

                case 'faqs':
                    return FaqForm
                    break

                default:
                    return false
                    break
            }
        },
        getFormBySlug(slug) {
            switch (slug) {
                case 'contact':
                    return ContactSectionForm
                    break

                default:
                    return false
                    break
            }
        },
        save() {
            if (!this.editing.id) {
                this.$store.dispatch('content/createEntry', this.editing)
            } else {
                this.$store.dispatch('content/updateEntry', this.editing)
            }

            if (this.featuredImage) {
                try {
                    updateFeaturedImage(this.editing, this.featuredImage)

                    this.$store.dispatch(
                        'addNotification',
                        new NotificationModel('Updated featured image'),
                        { root: true }
                    )
                } catch (error) {
                    console.error(error)

                    this.$store.dispatch(
                        'addNotification',
                        new NotificationModel(
                            'Unable to update featured image',
                            'danger'
                        ),
                        { root: true }
                    )
                }

                this.featuredImage = null
            }
        },
        changeFeaturedImage(event) {
            this.featuredImage = event.target.files[0]
        },
    },
    mounted() {
        console.log('mounted')
    },
    async created() {
        if (this.id) {
            await this.$store.dispatch('content/getEntryById', this.id)
        }

        if (this.entrySlug) {
            await this.$store.dispatch('content/getEntryBySlug', this.entrySlug)
        }

        if (!this.editing) {
            this.editing = {
                title: null,
                type: this.typeSlug,
                status_id: 1,
                category_ids: [],
                brands: { data: [] },
                categories: { data: [] },
                properties: { data: [] },
                collections: { data: [] },
                author: { data: {} },
                content: {
                    type: 'doc',
                    content: [],
                },
                extra: {},
            }
        }
    },
    mounted() {},
}
</script>
