<template>
    <div>
        <h2
            class="text-4xl text-gray font-aldine italic mr-4 pb-4 mb-4 border-b">
            Categories
        </h2>
        <div class="grid grid-cols-12">
            <div class="col-span-4 pr-8">
                <ul class="divide-y divide-blue-light">
                    <li
                        v-for="category in categories"
                        class="py-2">
                        <button @click="editCategory(category)" class="text-gray-darkest text-lg mb-2 hover:underline">{{
                            category.title
                        }}</button>

                        <p class="mb-4">{{ category.description }}</p>

                        <button
                            @click="editCategory(category)"
                            class="text-blue-dark hover:underline mr-4">
                            <i class="far fa-arrow-right" /> Edit
                        </button>

                        <button
                            @click="deleteCategory(category)"
                            class="text-xs text-danger-dark hover:underline mr-4">
                            <i class="far fa-trash" /> Delete
                        </button>
                    </li>
                </ul>
            </div>

            <form
                @submit.prevent="save"
                class="col-span-8">
                <input
                    type="hidden"
                    v-model="selectedCategory.id" />

                <label
                    for="title"
                    class="font-bold text-gray-darkest mb-2">
                    Title <span class="text-danger-dark">*</span>
                </label>

                <input
                    class="p-2 rounded border-gray w-full shadow mb-4"
                    id="title"
                    placeholder="Please enter a category title"
                    required
                    v-model="selectedCategory.title" />

                <label
                    for="description"
                    class="font-bold text-gray-darkest mb-2">
                    Description
                </label>
                <textarea
                    id="description"
                    class="p-2 rounded border-gray w-full shadow mb-4 w-full"
                    placeholder="Feel free to give the category a description"
                    v-model="selectedCategory.description" />

                <button
                    class="hover:underline p-2"
                    @click.prevent="cancel">
                    Cancel
                </button>

                <ButtonPrimary
                    type="submit"
                    v-text="
                        selectedCategory.id
                            ? 'Update Category'
                            : 'Create Category'
                    " />
            </form>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary'

export default {
    components: {
        ButtonPrimary,
    },
    data() {
        return {
            selectedCategory: {
                id: null,
                title: null,
                description: null,
            },
        }
    },
    computed: {
        ...mapGetters('content', ['categories']),
    },
    methods: {
        ...mapActions('content', [
            'createCategory',
            'updateCategory',
            'deleteCategory',
        ]),
        editCategory(category) {
            this.selectedCategory = category
        },
        save(event) {
            const category = this.selectedCategory

            if (category.id) {
                this.updateCategory(category)
            } else {
                this.createCategory(category)
            }

            this.selectedCategory = { id: null, title: null, description: null }
        },
        cancel() {
            this.selectedCategory = {
                id: null,
                title: null,
                description: null,
            }
        },
    },
}
</script>
