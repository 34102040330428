import axios from 'axios'
import {
    USER_FAVORITES_LIST,
    USER_FAVORITES_CREATE,
    USER_FAVORITES_UPDATE,
    USER_FAVORITES_DELETE,
    USER_FAVORITES_BULK_UPDATE,
} from 'Root/endpoints'

export async function getFavorites() {
    return await axios.get(USER_FAVORITES_LIST)
}

export async function createFavorite(favorite) {
    return await axios.post(USER_FAVORITES_CREATE, { favorite })
}

export async function updateFavorite(favorite) {
    return await axios.put(`${USER_FAVORITES_UPDATE}/${favorite.id}`, {
        params: { favorite },
    })
}

export async function bulkUpdateFavorites(favorites) {
    return await axios.put(USER_FAVORITES_BULK_UPDATE, {
        favorites,
    })
}

export async function deleteFavorite(favorite) {
    return await axios.delete(`${USER_FAVORITES_DELETE}/${favorite.id}`)
}

export default {
    getFavorites,
    createFavorite,
    updateFavorite,
    bulkUpdateFavorites,
    deleteFavorite,
}
