<template>
    <div class="bg-blue text-white p-6 rounded-lg relative">
        <div class="flex gap-4 flex-row-reverse justify-end items-center">
            <h4 class="font-swiss font-bold pr-8 leading-tight line-clamp-3">
                {{ toolkit.title || toolkit.caption }}
            </h4>
            <template v-if="thumbnailUrls.length === 1">
                <img
                    :src="thumbnailUrls[0]"
                    class="h-[72px] w-14 object-cover"
                    alt="PDF Thumbnail" />
            </template>
            <div
                v-else
                class="bg-blue-medium h-[72px] p-4 rounded flex items-center">
                <BaseIcon :svg="folderDownloadIcon" />
            </div>
        </div>
        <div class="mt-4">
            <ButtonStripped
                v-if="toolkit.link"
                :href="toolkit.link"
                text-color="text-white"
                :has-arrow="true"
                arrow-color="white"
                padding="px-0"
                class="after:content-[''] after:absolute after:inset-0 after:w-full after:h-full">
                Download
                {{ toolkit.linkText ? toolkit.linkText : 'All Guidelines' }}
            </ButtonStripped>
            <ButtonStripped
                v-else
                :href="toolkit.links.data.view"
                text-color="text-white"
                :has-arrow="true"
                arrow-color="white"
                padding="px-0"
                class="after:content-[''] after:absolute after:inset-0 after:w-full after:h-full">
                Download Guideline
            </ButtonStripped>
        </div>
    </div>
</template>

<script>
import BaseIcon from '../Base/BaseIcon.vue'
import ButtonStripped from '../Button/ButtonStripped.vue'
export default {
    name: 'ToolkitTiles',
    components: {
        BaseIcon,
        ButtonStripped,
    },
    props: {
        toolkit: {
            type: Object,
            default() {
                return {
                    title: 'Toolkit Title',
                }
            },
        },
    },
    data() {
        return {
            folderDownloadIcon:
                '<svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 4C30.625 4 32 5.375 32 7V25C32 26.6875 30.625 28 29 28H3C1.3125 28 0 26.6875 0 25V3C0 1.375 1.3125 0 3 0H12.125C12.6875 0 13.1875 0.25 13.5625 0.625L17 4H29ZM31 25V7C31 5.9375 30.0625 5 29 5H16.5625L13.125 1.625C12.75 1.25 12.25 1 11.75 1H3C1.875 1 1 1.9375 1 3V25C1 26.125 1.875 27 3 27H29C30.0625 27 31 26.125 31 25ZM15.625 22.375L9.625 16.375C9.4375 16.1875 9.4375 15.875 9.625 15.6875C9.6875 15.5625 9.875 15.5 10 15.5C10.125 15.5 10.25 15.5625 10.3125 15.6875L15.5 20.8125V10C15.5 9.75 15.6875 9.5 16 9.5C16.25 9.5 16.5 9.75 16.5 10V20.8125L21.5625 15.75C21.75 15.5625 22.0625 15.5625 22.25 15.75C22.4375 15.9375 22.4375 16.25 22.25 16.4375L16.3125 22.375C16.25 22.5 16.125 22.5 16 22.5C15.8125 22.5 15.6875 22.5 15.625 22.375Z" fill="currentColor"/></svg>',
        }
    },
    computed: {
        thumbnailUrls() {
            if (this.toolkit.thumbnails && this.toolkit.thumbnails.length > 0) {
                return this.toolkit.thumbnails
            } else if (this.toolkit.asset_thumbnail_url) {
                return [this.toolkit.asset_thumbnail_url]
            } else {
                return []
            }
        },
    },
}
</script>
