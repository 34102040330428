<script>
export default {
    name: 'Modal',
    props: {
        visible: {
            default: false,
            required: false,
            type: Boolean,
        },
        contentClasses: {
            type: Array,
            required: false,
            default: () => {
                return []
            },
        },
        transparent: {
            type: Boolean,
            default: false,
            required: false,
        },
        overflowScroll: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {
            body: document.querySelector('body'),
            classes: ['modal', '--is-open'],
        }
    },
    computed: {
        isVisible() {
            if (this.visible) {
                this.addBodyListener()
                this.addBodyClasses()
                return true
            } else {
                this.removeBodyListener()
                this.removeBodyClasses()
                return false
            }
        },
    },
    mounted() {
        //
    },
    methods: {
        addBodyListener() {
            this.body.addEventListener('keyup', this.bodyListener)
        },
        removeBodyListener() {
            this.body.removeEventListener('keyup', this.bodyListener)
        },
        bodyListener(event) {
            if (event.key === 'Escape') {
                this.hideModal()
            }
        },
        addBodyClasses() {
            this.body.classList.add(...this.classes)
        },
        removeBodyClasses() {
            this.body.classList.remove(...this.classes)
        },
        showModal() {
            this.$emit('update:visible', true)
        },
        hideModal(event) {
            if (event) {
                if (
                    !event.target.classList.contains('modal__close') &&
                    !event.target.classList.contains('modal__background') &&
                    !event.target.classList.contains('modal__overlay')
                ) {
                    return
                }
            }
            this.$emit('close', true)
            this.$emit('update:visible', false)
        },
        getContentClasses() {
            let classes = ['modal__content', 'rounded-2xl', 'p-6']

            if (this.transparent) {
                classes.push('--transparent')
            }

            return classes.concat(this.contentClasses).join(' ')
        },
    },
}
</script>

<template>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform  opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-class="transform opacity-100"
        leave-to-class="transform  opacity-0">
        <div
            v-if="isVisible"
            class="bg-blue-darkest fixed w-full h-full inset-0 bg-opacity-75 z-50 px-4 modal__overlay"
            @keyup.esc="hideModal"
            @click="hideModal">
            <div :class="getContentClasses()">
                <slot />
            </div>
            <button
                class="modal__close button button red"
                @click.prevent="hideModal">
                <!-- <i class="far fa-times fa-2x"></i> -->
            </button>
        </div>
    </transition>
</template>

<style lang="scss">
.modal {
    &.--is-open {
        height: 100vh;
        overflow: hidden;
    }

    &__background {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.75);
        bottom: 0;
        display: flex;
        flex-flow: row wrap;
        height: 100vh;
        justify-content: center;
        left: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 50;
    }

    &__close {
        @apply text-coral-dark text-xl;
        position: absolute;
        right: 0;
        top: 0;

        &::before {
            color: #f3f3f3;
            content: '\f00d';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;
            font-size: 2rem;
        }

        &:hover {
            @apply text-coral-link-hover;
        }
    }

    &__content {
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        max-height: 90%;
        max-width: 90%;
        z-index: 101;

        img {
            object-fit: contain;
            object-position: center;
            min-height: 200px;
            width: 100%;
        }

        &.--transparent {
            background-color: transparent;
        }

        &.--overflow-scroll {
            @apply overflow-y-scroll;
        }

        // &.p-4 {
        //     @apply p-4;
        // }

        // &.text-center {
        //     @apply text-center;
        // }
    }
}
</style>
