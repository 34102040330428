import {
    postComment,
    getWorkspace,
    getTags,
    tagAsset,
    untagAsset,
    updateUsers,
    rename,
    sendContactSheet,
} from 'Composables/useWorkspaces.js'

export default {
    namespaced: true,
    state: {
        workspace: null,
        tags: [],
        comments: [],
        areCommentsLoading: false,
        areTagsLoading: false,
    },
    getters: {},
    mutations: {
        SET_WORKSPACE(state, workspace) {
            state.workspace = workspace
        },
        SET_TAGS(state, tags) {
            state.tags = tags
        },
        SET_COMMENTS(state, comments) {
            state.comments = comments
        },
    },
    actions: {
        init({ dispatch }, { id }) {
            dispatch('loadWorkspace', { id })
            dispatch('loadTags')
            // dispatch('loadComments')
        },
        async loadTags({ commit }, { workspace }) {
            try {
                const response = await getTags(workspace)

                if (!response.data.data) {
                    throw new Error('unable to load tags')
                }

                let tags = response.data.data

                if (
                    !tags.find(
                        (tag) =>
                            tag.name.toLocaleLowerCase() ===
                            'Favorite'.toLocaleLowerCase()
                    )
                ) {
                    tags.unshift({ name: 'Favorite' })
                }

                commit('SET_TAGS', tags)
            } catch (error) {
                console.error(error)
            }
        },
        async createTag({ commit, state }, { workspace, tag }) {
            let tags = state.tags

            const exists = tags.find(
                (existing) =>
                    existing.name.toLocaleLowerCase() ===
                    tag.name.toLocaleLowerCase()
            )

            if (exists) {
                return tag
            }

            tags.push(tag)

            commit('SET_TAGS', tags)

            return tag
        },
        async tagAsset({ commit, dispatch, state }, { workspace, tag, asset }) {
            try {
                await tagAsset({ workspace, tag, asset })

                if (!tag.id) {
                    dispatch('loadTags', { workspace })
                }
            } catch (error) {
                console.error(error)
            }
        },
        async untagAsset({ commit }, { workspace, tag, asset }) {
            try {
                await untagAsset({ workspace, tag, asset })
            } catch (error) {
                console.error(error)
            }
        },
        async loadWorkspace({ commit }, { id, includes }) {
            try {
                const response = await getWorkspace({ id, includes })
                commit('SET_WORKSPACE', response.data.data)
                commit('SET_COMMENTS', response.data.data?.comments?.data)
            } catch (error) {
                console.error(error)
            }
        },
        async postComment({ commit, dispatch }, { workspace, comment }) {
            try {
                const response = await postComment({ workspace, comment })
                commit('SET_COMMENTS', response.data.data)
                return comment
            } catch (error) {
                console.error(error)

                dispatch(
                    'pushErrorAlert',
                    {
                        title: 'Workspace Update',
                        message: 'Comment could not be posted',
                    },
                    { root: true }
                )
                return false
            }
        },
        async rename({ commit }, { workspace, name }) {
            try {
                const response = await rename({ workspace, name })

                if (response.data.success) {
                    commit('SET_WORKSPACE', response.data.data)
                }
            } catch (error) {
                console.error(error)
            }
        },
        async updateUsers({ commit, dispatch }, { workspace, users }) {
            try {
                const response = await updateUsers({ workspace, users })

                if (response.data.success) {
                    commit('SET_WORKSPACE', response.data.data)

                    dispatch(
                        'pushSuccessAlert',
                        {
                            title: 'Workspace Update',
                            message: 'Collaborators successfully updated',
                        },
                        { root: true }
                    )

                    return true
                }
            } catch (error) {
                console.error(error)
                dispatch(
                    'pushErrorAlert',
                    {
                        title: 'Workspace Update',
                        message: 'Collaborators could not be updated',
                    },
                    { root: true }
                )
                return false
            }
        },
        async sendContactSheet({ dispatch }, { workspace }) {
            try {
                const response = await sendContactSheet({ workspace })

                if (response.data.success) {
                    dispatch(
                        'pushSuccessAlert',
                        {
                            title: 'Contact sheet sent',
                            message:
                                'Your contact sheet is being generated and will be sent to your email',
                        },
                        { root: true }
                    )

                    return true
                }
            } catch (error) {
                console.error(error)

                dispatch(
                    'pushErrorAlert',
                    {
                        title: 'Contact sheet error',
                        message: 'Unable to generate contact sheet',
                    },
                    { root: true }
                )

                return false
            }
        },
    },
}
