<template>
    <MultiselectModal
        v-model="tempRegions"
        :is-open="isOpen"
        :options="filtered"
        label="name"
        @search="filter = $event"
        @save="
            $emit('input', tempRegions)
            $emit('close', true)
        "
        @close="$emit('close', true)">
        <template #title>Select Regions</template>

        <template #option="option">
            {{ option }}
        </template>
        <template #currentTitle>Current Regions</template>

        <template #current>
            <div
                class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mt-4 min-h-24">
                <MultiselectModalItem
                    v-for="(region, i) in tempRegions"
                    :key="i"
                    :name="region.name"
                    :title="region.id"
                    :truncate-title="true"
                    :truncate-name="true"
                    :reverseOrder="true"
                    @remove="removeTempRegion(region)">
                    <template #icon>
                        <button class="ml-auto">
                            <IconRemoveUser />
                        </button>
                    </template>
                </MultiselectModalItem>
            </div>
        </template>
    </MultiselectModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import MultiselectModal from 'Components/Redesign/MultiselectModal'
import MultiselectModalItem from 'Components/Redesign/MultiselectModal/MultiselectModalItem'
import IconRemoveUser from 'Components/Redesign/Icons/IconRemoveUser'

export default {
    components: {
        IconRemoveUser,
        MultiselectModal,
        MultiselectModalItem,
    },
    props: {
        value: {
            required: true,
            type: [Array],
            default: () => [],
        },
        isOpen: {
            required: true,
            default: false,
            type: Boolean,
        },
    },
    data() {
        return { filter: null, options: [], tempRegions: [] }
    },
    computed: {
        ...mapState('options', ['regions']),
        filtered() {
            if (!this.filter) {
                return this.regions
            }

            const filter = this.filter.toLowerCase()

            return this.regions.filter(
                (region) =>
                    region.name.toLowerCase().includes(filter) ||
                    region.id.toLowerCase().includes(filter)
            )
        },
    },
    methods: {
        ...mapActions('options', ['loadRegions']),
        removeTempRegion(remove) {
            this.tempRegions = this.tempRegions.filter(
                (region) => region.id !== remove.id
            )
        },
    },
    mounted() {
        this.tempRegions = this.value

        if (!this.regions.length) {
            this.loadRegions()
        }
    },
}
</script>
