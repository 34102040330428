<template>
    <div 
        :class="[backgroundClasses, borderClasses]"
        class="p-6">
        <h4
            :class="headingClasses">
            {{ label }}
        </h4>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BasePanel',
    props: {
        backgroundClasses: {
            type: String,
            default: 'bg-white',
        },
        borderClasses: {
            type: String,
            default: 'rounded-xl',
        },
        label: {
            type: String,
            required: false,
        },
        headingClasses: {
            type: String,
            default: 'font-swiss text-xl font-bold text-gray-darkest leading-tight',
        },
    },
}
</script>