<template>
    <BasePerson
        :name="name"
        :thumbnail="thumbnail"
        :title="title"
        :truncate-title="truncateTitle"
        :truncate-name="truncateName"
        :reverseOrder="reverseOrder"
        name-font-styles="text-base font-normal"
        gap="gap-2"
        class="relative bg-white rounded-lg px-3 py-4 shadow font-swiss not-italic">
        <template #icon>
            <button
                @click="$emit('remove')"
                class="ml-auto">
                <div
                    class="rounded-full bg-[#FFF3F1] text-[#E53411] hover:bg-[#E53411] hover:text-[#FFF3F1] transition-colors p-2 w-full h-full">
                    <IconX />
                </div>
            </button>
        </template>
    </BasePerson>
</template>

<script>

import BasePerson from 'Components/Redesign/Base/BasePerson.vue'
import IconX from 'Components/Redesign/Icons/IconX.vue'

export default {
    name: 'MultiselectModalItem',
    components: {
        BasePerson,
        IconX,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        thumbnail: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        truncateTitle: {
            type: Boolean,
            default: false,
        },
        truncateName: {
            type: Boolean,
            default: false,
        },
        reverseOrder: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
