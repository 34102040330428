<template>
    <div
        class="relative bg-white border border-blue-medium rounded-lg px-4 py-3">
        <label class="absolute top-2 left-4 text-xs text-gray">
            {{ inputLabel }}
            <span
                v-if="required"
                class="text-red-500"
                >*</span
            >
        </label>
        <FormSelect
            :name="name"
            :value="value"
            :disabled="disabled"
            :required="required"
            :options="options"
            :placeholder="placeholder"
            :label="label"
            :reduce="reduce"
            :filter-by="filterBy"
            :hidden-input-label="hiddenInputLabel"
            :searchable="true"
            :multiple="multiple"
            class="userFormSelect"
            select-input-classes="w-full outline-none border-none pt-4 pb-0 px-0 shadow-none font-bold"
            @search="$emit('search', $event)"
            @input="updateValue" />
    </div>
</template>

<script>
import FormSelect from 'Components/Redesign/Form/FormSelect'

export default {
    name: 'AccountFormSelect',
    components: {
        FormSelect,
    },
    props: {
        value: {
            type: [String, Number, Object, Array],
            required: false,
        },
        inputLabel: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        reduce: {
            type: Function,
            required: false,
            default: (option) => option,
        },
        hiddenInputLabel: {
            type: String,
            default: null,
            required: false
        },
        filterBy: {
            type: Function,
            default(option, label, search) {
                return (
                    (label || '')
                        .toLocaleLowerCase()
                        .indexOf(search.toLocaleLowerCase()) > -1
                )
            },
        },
        multiple: {
            required: false,
            default: false,
            type: Boolean,
        }
    },
    watch: {
        value(newVal) {
            this.$emit('input', newVal)
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },
    },
}
</script>

<style>
.userFormSelect .vs__selected-options .vs__search {
    padding-left: 0 !important;
}
</style>
