import {
    SET_REMEDIATIONS_CONTACTS,
    SET_REMEDIATIONS_NOTES,
    SET_REMEDIATIONS_DOCUMENTS,
    SET_REMEDIATIONS_ASSETS,
    SET_REMEDIATIONS_ERRORS,
    SET_REMEDIATIONS_PROPERTY_ID,
    SET_REMEDIATIONS_BRAND_ID,
    SET_REMEDIATIONS_SUBMITTED,
    SET_REMEDIATIONS_NOTIFY_SUBMITTER_BY_EMAIL,
    SET_REMEDIATIONS_NOTIFY_SECONDARY_CONTACT_BY_EMAIL,
} from 'Stores/Frontend/mutation-types';

import {
    REMEDIATIONS_SUBMIT,
} from 'Root/endpoints';

import NotificationModel from 'Components/Redesign/SystemNotifications/NotificationModel.js';

import axios from 'axios';
import {isEmpty} from 'lodash';

const remediations = {
    namespaced: true,
    state: {
        submitted: false,
        contacts: [],
        notes: null,
        documents: [],
        assets: [],
        property_id: null,
        notify_submitter_by_email: true,
        notify_secondary_contact_by_email: true,
        permissions: {
            user_groups: [],
        },
        errors: {}
    },
    actions: {
        setAssets({commit, dispatch}, assets) {
            commit(SET_REMEDIATIONS_ASSETS, assets);
        },
        setPropertyId({commit}, id) {
            commit(SET_REMEDIATIONS_PROPERTY_ID, id);
        },
        setBrandId({commit}, id) {
            commit(SET_REMEDIATIONS_BRAND_ID, id);
        },
        validateWorkOrder({state, commit, dispatch}) {
            let errors = state.errors;
            let assetErrors = {};
            let invalid = false;
            let invalidAssets = false;

            if (!state.notes) {
                errors.notes = 'Notes are required';
                invalid = true;
            } else {
                delete errors.notes;
            }

            if (!state.property_id && !state.brand_id) {
                if (!state.property_id) {
                    errors.property_id = 'A property or brand id is required';
                    invalid = true;
                } else if (!state.brand_id) {
                    errors.brand_id = 'A brand or property id is required';
                    invalid = true;
                }
            }

            state.assets.forEach(asset => {
                let error = {};
                if (!asset.marketing_caption) {
                    error.marketing_caption = 'A marketing caption is required';
                }

                if (!asset.caption) {
                    error.caption = 'A caption is required';
                }

                if (!isEmpty(error)) {
                    assetErrors[asset.asset_id] = error;
                    invalidAssets = true;
                }
            });

            if (invalidAssets) {
                errors.assets = assetErrors;
            } else {
                delete errors.assets;
            }

            commit(SET_REMEDIATIONS_ERRORS, errors);
        },
        submitRemediation({state, commit, dispatch}) {
            dispatch('validateWorkOrder');

            if (!isEmpty(state.errors)) {
                let header = document.querySelector('.remediations__header');

                if (header) {
                    header.scrollIntoView({behavior: 'smooth'});
                }

                return false;
            }

            let workOrder = {
                contacts: state.contacts,
                notes: state.notes,
                assets: state.assets,
                property_id: state.property_id,
                notify_submitter_by_email: state.notify_submitter_by_email,
                notify_secondary_contact_by_email: state.notify_secondary_contact_by_email,
            };

            if (state.property_id) {
                workOrder.brand_id = state.brand_id;
            }

            if (state.brand_id) {
                workOrder.brand_id = state.brand_id;
            }

            axios.post(REMEDIATIONS_SUBMIT, workOrder)
                .then(response => {
                    if (response.data.success) {
                        dispatch('setAssets', []);

                        let notification = new NotificationModel(
                            'Created remediation request!',
                            'primary',
                            30
                        );

                        dispatch('addNotification', notification, {root: true});
                        dispatch('setAssets', []);
                        dispatch('setSubmitted', true);
                        dispatch('setCollectionAction', 'contact_sheet', {root: true});
                    } else {
                        let notification = new NotificationModel(
                            'Unable to create remediation, an unknown error has occurred!',
                            'danger',
                            30
                        );

                        dispatch('addNotification', notification, {root: true});
                    }

                }).catch(error => {
                    dispatch('setErrors', error.response.data.errors);

                    let notification = new NotificationModel(
                        'Unable to create remediation',
                        'danger',
                        30
                    );

                    dispatch('addNotification', notification, {root: true});
                });
        },
        setContacts({commit}, contacts) {
            commit(SET_REMEDIATIONS_CONTACTS, contacts);
        },
        setNotes({commit}, notes) {
            commit(SET_REMEDIATIONS_NOTES, notes);
        },
        setErrors({commit}, errors) {
            commit(SET_REMEDIATIONS_ERRORS, errors);
        },
        setSubmitted({commit}, submitted) {
            commit(SET_REMEDIATIONS_SUBMITTED, submitted);
        },
        setNotifyMe({commit}, notify) {
            commit(SET_REMEDIATIONS_NOTIFY_SUBMITTER_BY_EMAIL, notify);
        },
        setNotifySecondaryContacts({commit}, notify) {
            commit(SET_REMEDIATIONS_NOTIFY_SECONDARY_CONTACT_BY_EMAIL, notify);
        },
        setDocuments({commit}, documents) {
            commit(SET_REMEDIATIONS_DOCUMENTS, documents);
        }
    },
    mutations: {
        [SET_REMEDIATIONS_CONTACTS] (state, contacts) {
            state.contacts = contacts;
        },
        [SET_REMEDIATIONS_NOTES] (state, notes) {
            state.notes = notes;
        },
        [SET_REMEDIATIONS_DOCUMENTS] (state, documents) {
            state.documents = documents;
        },
        [SET_REMEDIATIONS_ASSETS] (state, assets) {
            state.assets = assets;
        },
        [SET_REMEDIATIONS_ERRORS] (state, errors) {
            state.errors = errors;
        },
        [SET_REMEDIATIONS_PROPERTY_ID] (state, id) {
            state.property_id = id;
        },
        [SET_REMEDIATIONS_BRAND_ID] (state, id) {
            state.brand_id = id;
        },
        [SET_REMEDIATIONS_SUBMITTED] (state, submitted) {
            state.submitted = submitted;
        },
        [SET_REMEDIATIONS_NOTIFY_SUBMITTER_BY_EMAIL] (state, notify) {
            state.notify_submitter_by_email = notify;
        },
        [SET_REMEDIATIONS_NOTIFY_SECONDARY_CONTACT_BY_EMAIL] (state, notify) {
            state.notify_secondary_contact_by_email = notify;
        },
    }
};

export default remediations;
