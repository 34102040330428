<template>
    <div>
        <div class="relative">
            <label
                v-if="inputLabel"
                :for="inputId"
                class="block text-sm font-medium text-gray-700"
                >{{ inputLabel }}</label
            >
            <v-select
                v-model="internalValue"
                multiple
                :id="inputId"
                :class="[inputLabel ? 'mt-2' : 'mt-0']"
                :placeholder="placeholder"
                :options="options"
                :reduce="reduce"
                :label="label"
                :clear-search-on-select="clearSearchOnSelect"
                :close-on-select="false"
                :filterable="filterable"
                :dropdown-should-open="dropdownShouldOpen"
                :deselect-from-dropdown="deselectFromDropdown"
                class="baseMultiselect"
                @search="handleSearch"
                @input="setSelected">
                <template #option="option">
                    <div
                        :class="{
                            'is-selected': isSelected(option),
                            'is-hovered': isHovered(option),
                        }"
                        class="h-full"
                        @mouseover="setHover(option, true)"
                        @mouseleave="setHover(option, false)">
                        <div class="flex justify-between items-center py-4">
                            <div class="flex flex-col">
                                <slot
                                    name="option"
                                    :option="option">
                                    <span class="text-base text-blue">
                                        {{ option[label] }}
                                    </span>
                                    <span
                                        v-if="
                                            option.email || option.marsha_code
                                        "
                                        class="text-xs text-gold-light"
                                        >{{ option.email }}
                                        {{ option.marsha_code }}</span
                                    >
                                </slot>
                            </div>
                            <!-- Default Icon -->
                            <svg
                                v-if="!isSelected(option)"
                                class="default-icon"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="#0F358A" />
                                <path
                                    d="M22.6943 15.9999C22.6943 16.5624 22.2255 17.0312 21.6943 17.0312H17.1943V21.5312C17.1943 22.0624 16.7255 22.4999 16.1943 22.4999C15.6318 22.4999 15.1943 22.0624 15.1943 21.5312V17.0312H10.6943C10.1318 17.0312 9.69428 16.5624 9.69428 15.9999C9.69428 15.4687 10.1318 15.0312 10.6943 15.0312H15.1943V10.5312C15.1943 9.96869 15.6318 9.49994 16.1943 9.49994C16.7255 9.49994 17.1943 9.96869 17.1943 10.5312V15.0312H21.6943C22.2255 14.9999 22.6943 15.4687 22.6943 15.9999Z"
                                    fill="white" />
                            </svg>
                            <!-- Hover Icon -->
                            <svg
                                v-if="!isSelected(option) && isHovered(option)"
                                class="hover-icon"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="#003208" />
                                <path
                                    d="M22.8818 11.3124C23.288 11.6874 23.288 12.3437 22.8818 12.7187L14.8818 20.7187C14.5068 21.1249 13.8505 21.1249 13.4755 20.7187L9.47553 16.7187C9.06928 16.3437 9.06928 15.6874 9.47553 15.3124C9.85053 14.9062 10.5068 14.9062 10.8818 15.3124L14.163 18.5937L21.4755 11.3124C21.8505 10.9062 22.5068 10.9062 22.8818 11.3124Z"
                                    fill="#28C642" />
                            </svg>
                            <!-- Active Icon -->
                            <svg
                                v-if="isSelected(option)"
                                class="active-icon"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect
                                    width="32"
                                    height="32"
                                    rx="16"
                                    fill="#003208" />
                                <path
                                    d="M22.8818 11.3124C23.288 11.6874 23.288 12.3437 22.8818 12.7187L14.8818 20.7187C14.5068 21.1249 13.8505 21.1249 13.4755 20.7187L9.47553 16.7187C9.06928 16.3437 9.06928 15.6874 9.47553 15.3124C9.85053 14.9062 10.5068 14.9062 10.8818 15.3124L14.163 18.5937L21.4755 11.3124C21.8505 10.9062 22.5068 10.9062 22.8818 11.3124Z"
                                    fill="#28C642" />
                            </svg>
                        </div>
                    </div>
                </template>
            </v-select>
            <!-- Fake placeholder text -->
            <div
                v-if="internalValue.length && !isTyping"
                class="absolute h-full w-full top-0 left-0 pl-10 pt-4 inline-block pointer-events-none text-gray-500">
                {{ placeholder }}
            </div>
            <hr class="hidden md:block border-t border-blue-medium mt-3" />
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import _ from 'lodash'

export default {
    name: 'BaseMultiselect',
    components: {
        vSelect,
    },
    props: {
        value: {
            required: true,
            type: Array,
            default: () => [],
        },
        multiple: {
            required: false,
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            required: false,
            default: 'title',
        },
        inputLabel: {
            type: String,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            default: 'Type at least three letters...',
        },
        inputId: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            default() {
                return []
            },
            required: true,
        },
        trackBy: {
            required: false,
            type: String,
            default: 'id',
        },
        clearSearchOnSelect: {
            required: false,
            type: Boolean,
            default: false,
        },
        filterable: {
            required: false,
            type: Boolean,
            default: true,
        },
        reduce: {
            type: Function,
            default: function (option) {
                return option[this.trackBy]
            },
        },
        dropdownShouldOpen: {
            type: Function,
            default({ noDrop, open, mutableLoading }) {
                return noDrop ? false : open && !mutableLoading
            },
        },
        deselectFromDropdown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hoverState: {},
            isTyping: false,
        }
    },
    computed: {
        internalValue: {
            get() {
                return this.value
            },
            set(newValue) {
                console.log('internalValue updated:', newValue)
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        handleSearch(searchText) {
            this.isTyping = searchText.length > 0
            this.$emit('search', searchText)
        },
        isSelected(option) {
            return this.internalValue.some((item) => _.isEqual(item, option))
        },
        isHovered(option) {
            return !!this.hoverState[option[this.trackBy]]
        },
        setSelected(value) {
            this.internalValue = value
            this.$emit('input', value)
        },
        setHover(option, state) {
            this.$set(this.hoverState, option[this.trackBy], state)
        },
    },
    watch: {
        internalValue(newVal, oldVal) {
            if (newVal.length === 0) {
                this.isTyping = false
            }
        },
    },
}
</script>

<style>
.baseMultiselect .vs__dropdown-menu {
    @apply bg-blue-lightest overflow-hidden max-h-96 shadow-none border-none translate-y-8;
}

/* Base styling for dropdown options */
.baseMultiselect .vs__dropdown-option {
    @apply mb-2 shadow rounded-xl bg-white transition-colors duration-200 ease-in-out border border-transparent relative py-0;
}

/* Default icon styling */
.baseMultiselect .vs__dropdown-option .default-icon {
    @apply block;
}

/* Hover icon styling */
.baseMultiselect .vs__dropdown-option .hover-icon {
    @apply hidden;
}

/* Default icon styling */
.baseMultiselect .vs__dropdown-option:hover .default-icon {
    @apply hidden;
}

/* Hover icon styling */
.baseMultiselect .vs__dropdown-option:hover .hover-icon {
    @apply block;
}

/* Ensure hover icon does not show for selected items */
.baseMultiselect .vs__dropdown-option.is-selected .hover-icon {
    @apply hidden;
}

/* Ensure icons are properly managed */
.baseMultiselect .vs__dropdown-option:hover .default-icon {
    @apply hidden;
}

.baseMultiselect .vs__selected-options {
    @apply rounded-full border-2 border-blue-darkest px-8 py-4 text-lg text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue focus:border-transparent;
}

/* Highlight styling for selected items */
.baseMultiselect .vs__dropdown-option--highlight {
    @apply bg-blue-lightest shadow-none border border-blue;
}

.baseMultiselect .vs__search {
    @apply border-none mt-0;
}

.baseMultiselect .vs__dropdown-toggle svg,
.baseMultiselect .vs__selected {
    @apply hidden;
}
</style>
