import axios from 'axios'

import {
    ASSET_TYPES_LIST,
    COUNTRIES_LIST,
    LANGUAGES_LIST,
    STATES_LIST,
    TEMPLATE_TYPES_LIST,
    TEMPLATE_TYPE_CATEGORIES_LIST,
    TIME_ZONES_LIST,
    DIVISIONS_LIST,
} from 'Root/endpoints.js'

export async function getAssetTypes() {
    return await axios.get(ASSET_TYPES_LIST)
}

export async function getCountries() {
    return await axios.get(COUNTRIES_LIST)
}

export async function getLanguages() {
    return await axios.get(LANGUAGES_LIST)
}

export async function getStates() {
    return await axios.get(STATES_LIST)
}

export async function getTemplateTypes() {
    return await axios.get(TEMPLATE_TYPES_LIST)
}

export async function getTemplateTypeCategories() {
    return await axios.get(TEMPLATE_TYPE_CATEGORIES_LIST)
}

export async function getTimeZones() {
    return await axios.get(TIME_ZONES_LIST)
}

export async function getDivisions() {
    return await axios.get(DIVISIONS_LIST)
}

export async function getApprovalCategories() {
    return await axios.get('/api/structure/approvals/categories')
}

export async function getRegions() {
    return await axios.get('/api/structure/regions')
}

export default {
    getRegions,
    getAssetTypes,
    getCountries,
    getLanguages,
    getStates,
    getTemplateTypes,
    getTemplateTypeCategories,
    getTimeZones,
}
