<template>
    <multiselect
        class="absolute"
        v-model="data"
        id="entry_brands"
        label="name"
        track-by="brand_id"
        placeholder="Enter brand"
        open-direction="bottom"
        :options="brands"
        :multiple="true"
        :searchable="false"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="8"
        :limit-text="limitText"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
    >
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { getBrands } from 'Composables/useBrands.js'

export default {
    components: {
        Multiselect,
    },
    props: {
        value: {
            required: false,
            type: Array,
            default: [],
        },
    },
    data() {
        return { filter: null, allBrands: [], isLoading: false }
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValues) {
                this.$emit('input', newValues)
            },
        },
        brands() {
            if (!this.filter) {
                return this.allBrands
            }

            return this.allBrands.filter(
                (brand) => brand.name.includes(this.filter) !== -1
            )
        },
    },
    methods: {
        limitText(count) {
            return `${count} addtional Brands`
        },
        async findBrands(filter) {
            this.filter = filter
        },
    },
    async mounted() {
        this.isLoading = true
        try {
            const response = await getBrands()

            if (response.data.data) {
                this.allBrands = response.data.data
            }
        } catch (error) {
            this.allBrands = []
            console.error(error)
        }

        this.isLoading = false
    },
}
</script>
