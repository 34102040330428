var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex gap-4 w-full flex-col"},[(_vm.headingEditor)?_c('div',{staticClass:"relative border rounded-lg px-4 py-3 bg-white border-blue-medium w-full mb-2"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"flex w-full my-4"},[_c('ToolbarButton',{staticClass:"font-aldine",attrs:{"is-active":_vm.headingEditor.isActive('textStyle', {
                        fontFamily: 'Aldine, serif',
                    })},on:{"click":function($event){_vm.headingEditor
                        .chain()
                        .focus()
                        .setFontFamily('Aldine, serif')
                        .run()}}},[_vm._v("\n                Aldine\n            ")]),_vm._v(" "),_c('ToolbarButton',{staticClass:"font-swiss",attrs:{"is-active":_vm.headingEditor.isActive('textStyle', {
                        fontFamily: 'Swiss721, sans-serif',
                    })},on:{"click":function($event){_vm.headingEditor
                        .chain()
                        .focus()
                        .setFontFamily('Swiss721, sans-serif')
                        .run()}}},[_vm._v("\n                Swiss721\n            ")]),_vm._v(" "),_c('ToolbarButton',{attrs:{"disabled":!_vm.headingEditor.can().chain().focus().toggleBold().run(),"is-active":_vm.headingEditor.isActive('bold')},on:{"click":function($event){_vm.headingEditor.chain().focus().toggleBold().run()}}},[_c('strong',[_vm._v("Bold")])]),_vm._v(" "),_c('ToolbarButton',{attrs:{"disabled":!_vm.headingEditor
                        .can()
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run(),"is-active":_vm.headingEditor.isActive('italic')},on:{"click":function($event){_vm.headingEditor.chain().focus().toggleItalic().run()}}},[_c('em',[_vm._v("Italic")])]),_vm._v(" "),_c('ToolbarButton',{staticClass:"border-r border-blue-light",on:{"click":function($event){_vm.headingEditor.chain().focus().setHardBreak().run()}}},[_vm._v("\n                BR\n            ")])],1),_vm._v(" "),_c('div',[_c('EditorContent',{staticClass:"w-full",attrs:{"id":"headingEditor","editor":_vm.headingEditor}})],1)]):_vm._e(),_vm._v(" "),_c('div',[_c('EntryContent',{staticClass:"w-full mb-2",attrs:{"label":"Content","id":"content"},model:{value:(_vm.entry.content),callback:function ($$v) {_vm.$set(_vm.entry, "content", $$v)},expression:"entry.content"}})],1),_vm._v(" "),_c('div',[_vm._m(1),_vm._v(" "),_c('EntryArray',{staticClass:"w-full",attrs:{"id":"topics","new-item-label":"Add Topic","new-item-default":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var data = ref.data;
                        var add = ref.add;
                        var remove = ref.remove;
return [_vm._l((data),function(item,i){return _c('li',{key:i,staticClass:"mb-2"},[_c('div',{staticClass:"flex flex-row gap-4 items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.title),expression:"item.title"}],staticClass:"rounded w-1/2",domProps:{"value":(item.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "title", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"w-1/2"},[_c('EntryUsers',{staticClass:"w-full",model:{value:(item.contacts),callback:function ($$v) {_vm.$set(item, "contacts", $$v)},expression:"item.contacts"}})],1),_vm._v(" "),_c('button',{staticClass:"p-2 text-white bg-red-600 hover:bg-red-800",on:{"click":function($event){return remove(i)}}},[_c('i',{staticClass:"far fa-fw fa-trash"})])])])})]}}]),model:{value:(_vm.entry.extra.topics),callback:function ($$v) {_vm.$set(_vm.entry.extra, "topics", $$v)},expression:"entry.extra.topics"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"absolute top-2 left-4 text-xs text-gray",attrs:{"for":"entryHeading"}},[_vm._v("Heading"),_c('span',{staticClass:"text-danger-dark"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"inline-block font-bold mb-2",attrs:{"for":"topics"}},[_vm._v("\n            Topics"),_c('span',{staticClass:"text-red-600"},[_vm._v("*")])])}]

export { render, staticRenderFns }