<template>
    <FormInput v-model="data" v-bind="{ ...$attrs }"
    />
    <!-- <input -->
    <!--     class="p-2 rounded border-gray w-full shadow mb-4" -->
    <!--     type="text" -->
    <!--     v-bind="{ ...$attrs }" -->
    <!--     v-model="data" -->
    <!-- /> -->
</template>

<script>
import FormInput from 'Components/Redesign/Form/FormInput'

export default {
    inheritAttrs: false,
    components: {FormInput},
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
}
</script>
