<template>
    <div>
        <button
            @click="add"
            class="bg-blue px-4 py-2 text-white rounded-full hover:bg-blue-medium mb-4"
            v-text="newItemLabel"
        />
        <ul>
            <slot
                :data="data"
                :add="add"
                :remove="remove"
            >
                <li
                    v-for="(item, i) in data"
                    :key="i"
                    class="flex flex-row items-baseline"
                >
                    <input
                        v-model="data[i]"
                        class="rounded rounded-r-none w-full mb-2 border-r-0"
                    />
                    <button
                        @click="remove(i)"
                        class="bg-red-600 text-white border-r border-t border-b border-red-800 py-2 px-2 font-medium hover:bg-red-800 rounded-r"
                    >
                        <i class="far fa-fw fa-trash" />
                    </button>
                </li>
            </slot>
        </ul>
    </div>
</template>

<script>
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue'

export default {
    components: {
        ButtonPrimary,
    },
    props: {
        value: {
            required: false,
            type: Array,
            default() {
                return []
            },
        },
        newItemLabel: {
            required: false,
            type: String,
            default: 'Add Item',
        },
        newItemDefault: {
            required: false,
            type: [String, Object],
            default: 'New Item!',
        },
    },
    data() {
        return {}
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        add() {
            let data = this.data

            data.push(this.newItemDefault)

            this.data = data
        },
        remove(index) {
            this.data = this.data.filter((item, i) => index !== i)
            // remove item from array
        },
    },
}
</script>
