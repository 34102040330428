import axios from 'axios'
import { orderBy, uniqBy } from 'lodash'

import { BRANDS_LIST, COLLATERAL_LIST, GUIDELINES_LIST } from 'Root/endpoints'

export async function getBrands(params) {
    return await axios.get(BRANDS_LIST, { params })
}

export async function getCollateral(brandId) {
    return await axios.get(`${COLLATERAL_LIST}/${brandId}`)
}

export async function getGuidelines(brandId) {
    return await axios.get(`${GUIDELINES_LIST}/${brandId}`)
}

export function groupGuidelinesByCategory(guidelines) {
    if (!guidelines) {
        return []
    }

    let categories = guidelines.map((guideline) => {
        let category = {
            id: guideline.guideline_category.data.id,
            name: guideline.guideline_category.data.name,
            description: guideline.guideline_category.data.description,
            order: guideline.guideline_category.data.category_order,
        }

        if (!category.items) {
            category.items = []
        }

        // delete guideline.guideline_category

        category.items.push(structuredClone(guideline))

        return category
    })

    categories = orderBy(
        uniqBy(categories, (category) => category.id),
        (category) => category.category_order
    )

    categories = categories.map((category) => {
        category.items = orderBy(
            category.items,
            (item) => item.guideline_category.display_order
        )

        return category
    })

    return categories
}

export default { getBrands, getCollateral, getGuidelines }
