<template>
    <drawer>
        <template #trigger="{ toggleDrawer }">
            <ButtonStripped
                variant="link"
                class="flex items-center gap-2"
                text-size="text-sm"
                padding="px-0"
                @click="toggleDrawer"
                @keyup.esc="toggleDrawer">
                Downloads
                <svg
                    height="8"
                    viewBox="0 0 14 8"
                    width="14"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m1 1 6 6 6-6"
                        fill="none"
                        stroke="#9DB6EB"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2" />
                </svg>
            </ButtonStripped>
        </template>

        <template #drawer>
            <ul class="flex flex-col">
                <li
                    v-if="!asset.links.data.downloads"
                    class="border-blue-light border-b mb-2 last:mb-0 text-gray-darkest hover:text-blue last:border-b-0 p-4 text-sm">
                    <span class="inline-block text-left"
                        >No downloads available</span
                    >
                </li>
                <li
                    v-for="(link, type) in asset.links.data.downloads"
                    :key="type"
                    class="border-blue-light border-b mb-2 last:mb-0 text-gray-darkest hover:text-blue last:border-b-0 p-4 text-sm"
                    @click.prevent="getConfirmation(link)"
                    v-html="link" />

                <confirm
                    :visible="showConfirm"
                    text=""
                    confirm-text="I agree to these terms"
                    cancel-text="Cancel download"
                    @update:visible="showConfirm = $event"
                    @confirmed="download"
                    @cancelled="showConfirm = false">
                    <template #text>
                        <p>
                            <span class="font-bold">PLEASE NOTE:</span>
                            The Asset you are about to download and use is
                            Rights Managed. Please read the Usage Description
                            and adhere to the specified usage. By down-loading
                            this asset you are agreeing to the usage terms.
                            Please
                            <!-- I have no idea why clicking this link
                        wont fire so I added a method to do it. -->
                            <a
                                class="link"
                                target="_blank"
                                href="/resources/contact"
                                @click="theHeck"
                                >contact us</a
                            >
                            before downloading if you have any questions.
                        </p>

                        <p
                            v-if="asset.usage_description"
                            class="my-2">
                            <span class="font-bold">Usage Description:</span>
                            <span v-text="asset.usage_description" />
                        </p>

                        <p
                            v-if="asset.instructions"
                            class="my-2">
                            <span class="font-bold">Instructions:</span>
                            <span v-text="asset.instructions" />
                        </p>

                        <p
                            v-if="asset.deactivation_date"
                            class="my-2">
                            <span class="font-bold">Expiration Date:</span>
                            <span v-text="asset.deactivation_date" />
                        </p>
                    </template>
                </confirm>
            </ul>
        </template>
    </drawer>
</template>

<script>
import Drawer from 'Components/Redesign/Drawer'
import Confirm from 'Components/Redesign/Confirm'
import ButtonStripped from '../Button/ButtonStripped.vue'

export default {
    name: 'AssetDownloads',
    components: {
        Drawer,
        Confirm,
        ButtonStripped,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        extraDrawerClasses: {
            type: Array,
            default: null,
            required: false,
        },
        iconStyle: {
            type: String,
            default: 'regular',
            required: false,
        },
    },
    data() {
        return {
            showConfirm: false,
            isConfirmed: false,
            link: null,
        }
    },
    methods: {
        // I can't figure out why the link in the confirm text doesn't want to
        // click. What the heck?!
        theHeck(event) {
            window.location = event.target.href
        },
        getConfirmation(link) {
            this.link = link

            if (!this.asset.rights_managed || this.isConfirmed === true) {
                this.download()
                return
            }

            this.showConfirm = true
        },
        download() {
            let div = document.createElement('div')
            div.innerHTML = this.link
            window.location = div.firstChild.href
            this.showConfirm = false
        },
    },
}
</script>

<style></style>
