<template>
    <div
        :class="[
            block,
            isOpen ? bgColorOpened : bgColorClosed,
            'py-2 pl-6 pr-3 rounded',
        ]">
        <header>
            <div
                :id="`${id}-control`"
                :class="`${block}__${headerElement}`"
                :aria-expanded="`${isOpen}`"
                :aria-controls="id"
                tabindex="0"
                role="button"
                @click="open"
                @keyup.enter="open">
                <slot name="heading" />
                <slot name="icon">
                    <!-- <svg
                        :class="[
                            isOpen
                                ? 'rotate-180 text-blue bg-blue-light'
                                : 'text-white bg-blue',
                            'rounded-full',
                        ]"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                    >
                        <polyline points="6 9 12 15 18 9" />
                    </svg> -->
                    <svg
                        :class="[
                            isOpen
                                ? 'rotate-180 text-blue bg-blue-lighter'
                                : 'text-white bg-blue',
                            'rounded-full',
                        ]"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect
                            width="48"
                            height="48"
                            :fill="isOpen ? 'bg-blue-light' : 'bg-blue'" />
                        <path
                            d="M16 22L24 30L32 22"
                            :stroke="isOpen ? 'currentColor' : 'white'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </slot>
            </div>
        </header>
        <transition
            name="accordion__transition"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition">
            <div
                v-if="isOpen"
                :id="id"
                ref="content"
                :class="`${block}__${contentElement}`"
                :aria-labelledby="`${id}-control`">
                <slot />
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        block: {
            type: String,
            default: 'accordion',
        },
        headerElement: {
            type: String,
            default: 'header',
        },
        contentElement: {
            type: String,
            default: 'content',
        },
        bgColorClosed: {
            type: String,
            default: 'bg-blue-lighter',
        },
        bgColorOpened: {
            type: String,
            default: 'bg-white border border-blue-medium',
        },
        id: {
            type: String,
            default: () => Math.random().toString(36).substr(2),
        },
    },
    data: () => ({ isOpen: false }),
    methods: {
        open() {
            this.isOpen = !this.isOpen
            if (this.isOpen) {
                this.$emit('open')
            } else {
                this.$emit('close')
            }
        },

        /**
         * The following methods and transition hooks are necessary to work
         * around the fact that CSS transitions can't navigate to auto values.
         */
        startTransition(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        endTransition(el) {
            el.style.height = ''
        },
    },
}
</script>
