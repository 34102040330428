<template>
<div class="tabs">
    <slot
        name="links"
        :active-tab="activeTab"
        :set-tab="setTab"
    />
    <slot
        name="content"
        :active-tab="activeTab"
    />
</div>
</template>

<script>
export default {
    props: {
        defaultTab: {
            required: false,
            type: String,
            default: null,
        },
        pushState: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            activeTab: null,
        };
    },
    computed: {},
    mounted: function() {
        if (this.defaultTab) {
            this.activeTab = this.defaultTab;
        }

        if (this.pushState) {
            let hash = window.location.hash;

            if (hash) {
                window.location.hash = hash;
                this.activeTab = window.location.hash.substr(1);
            }

            window.addEventListener('hashchange', (event) => {
                this.activeTab = window.location.hash.substr(1);
            });
        }
    },
    methods: {
        setTab(event, tab) {
            if (event) {
                tab = event.target.getAttribute('data-tab')
            }

            this.activeTab = tab;

            if (this.pushState) {
                window.location.hash = tab.toLowerCase();
                window.history.pushState({}, '', window.location);
            }
        },
    }
};
</script>