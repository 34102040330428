import axios from 'axios';
import {
    SET_CSRF_TOKEN,
    SET_LOGO_UPLOAD_SHOW_ASSET_FORM,
    SET_LOGO_UPLOAD_ASSET_STATUSES,
    SET_LOGO_UPLOAD_BRANDS,
    SET_LOGO_UPLOAD_CAN_MANAGE_PERMISSIONS,
    SET_LOGO_UPLOAD_CONFIRMED,
    SET_LOGO_UPLOAD_COUNTRIES,
    SET_LOGO_UPLOAD_DESTINATIONS,
    SET_LOGO_UPLOAD_DIVISIONS,
    SET_LOGO_UPLOAD_PROPERTIES,
    SET_LOGO_UPLOAD_PROPERTY_TYPES,
    SET_LOGO_UPLOAD_SOURCE_TYPES,
    SET_LOGO_UPLOAD_SOURCE_USER,
    SET_LOGO_UPLOAD_USER_GROUPS,
    SET_LOGO_UPLOAD_USER_TYPES,
    SET_LOGO_UPLOAD_VENDOR_TYPES,
    SET_LOGO_UPLOAD_FORM_DATA,
    SET_LOGO_UPLOAD_ERRORS,
    SET_LOGO_UPLOAD_ZIP,
    SUBMIT_LOGO_UPLOAD_ZIP,
    SET_LOGO_UPLOAD_PARSED_FILES,
    SET_LOGO_UPLOAD_ASSETS,
    SET_IS_LOADING
} from 'Stores/Frontend/mutation-types';

import {
    LOGO_UPLOAD_CREATE,
    LOGO_UPLOAD_UPLOAD_ZIP,
    LOGO_UPLOAD_STATUS_CHECK,
    LOGO_UPLOAD_RESET,
    ASSET_STATUSES,
    BRANDS_LIST,
    COUNTRIES_LIST,
    DESTINATION_GROUPS,
    DIVISIONS_LIST,
    PROPERTIES_LIST,
    PROPERTY_TYPES,
    SOURCE_TYPES,
    VENDOR_TYPES,
    USER_GROUPS_LIST,
    USER_LOOKUP
} from 'Root/endpoints';

import NotificationModel from 'Components/Redesign/SystemNotifications/NotificationModel';

const logoUpload = {
    namespaced: true,
    state: {
        assets: [],
        assetStatuses: [],
        brands: [],
        countries: [],
        desinations: [],
        divisions: [],
        sourceTypes: [],
        selectedUser: null,
        vendorTypes: [],
        properties: [],
        propertyTypes: [],
        userGroups: [],
        userTypes: [],
        visibleTo:{
           '1':'Public',
           '0':'Select Users'
        },
        canManagePermissions: false,
        confirmed: false,
        csrfToken: null,
        display: false,
        errors: null,
        formData: {
            preferred: null,
            status: 'active',
            description: '',
            metadata: '',
            source_vendor: null,
            vendor_type: '',
            source_type: '',
            source_asset_name: '',
            deactivation_date: '',
            aged_date: '',
            instructions: '',
            usage_description: '',
            rights_managed: null,
            limited_rights: null,
            marketing_caption: '',
            gds_room_type_code: '',
            ers_room_type_code: '',
            ers_attribute_code: '',
            destination_control: [],
            visible_to_api: true,
            public: 1,
            note: '',
            comment: '',
            permissions: {
                brand_id: [],
                property_id: [],
                user_group_id: [],
                corporate: null,
                property: null,
                regional: null,
                third_party_vendor: null,
                division_id: [],
                country_id: [],
                property_type_id: [],
            },
        },
        zipFile: null,
    },
    getters: {
        assets (state) {
            return state.assets;
        },
        assetStatuses (state) {
            return state.assetStatuses;
        },
        brands (state) {
            return state.brands;
        },
        canManagePermissions (state) {
            return state.canManagePermissions;
        },
        confirmed (state) {
            return state.confirmed;
        },
        countries (state) {
            return state.countries;
        },
        csrfToken (state) {
            return state.csrfToken;
        },
        destinations (state) {
            return state.destinations;
        },
        divisions (state) {
            return state.divisions;
        },
        formData (state) {
            return state.formData;
        },
        properties (state) {
            return state.properties;
        },
        propertyTypes (state) {
            return state.propertyTypes;
        },
        selectedUser (state) {
            return state.selectedUser;
        },
        sourceTypes (state) {
            return state.sourceTypes;
        },
        vendorTypes (state) {
            return state.vendorTypes;
        },
        userGroups (state) {
            return state.userGroups;
        },
        userTypes (state) {
            return state.userTypes;
        },
        visibleTo (state) {
            return state.visibleTo;
        },
        zipFile (state) {
            return state.zipFile;
        },
        parsedFiles (state) {
            if (state.assets.length > 0) {
                let files = [];

                state.assets.forEach(a => {
                    files.push(a.filename);
                });

                return files;
            }
            return null;
        },
        errors (state) {
            return state.errors;
        },
    },
    actions: {
        loadFormOptions({commit, state, dispatch}) {
            let assetStatuses = [];
            let brands = [];
            let countries = [];
            let destinations = [];
            let divisions = [];
            let properties = [];
            let propertyTypes = [];
            let sourceTypes = [];
            let sourceUsers = [];
            let userGroups = [];

            /* ASSET STATUSES */
            axios.get(ASSET_STATUSES).then(response => {
                commit(SET_LOGO_UPLOAD_ASSET_STATUSES, response.data.data);
            });

            /* BRANDS */
            axios.get(BRANDS_LIST).then(response => {
                response.data.data.forEach((option) => {
                    let current = null;
                    if (state.formData.permissions.brand_id.includes(option.brand_id)) {
                        current = option.brand_id;
                    }

                    brands.push({
                        id: 'brand_' + option.brand_id,
                        checkedValue: option.brand_id,
                        currentValue: current,
                        label: option.name,
                        name: null,
                        classes: null
                    });
                });
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_BRANDS, brands);
            });

            /* CAN MANAGE PERMISSIONS - default to true for now, we may need to create an API lookup for this. */
            commit(SET_LOGO_UPLOAD_CAN_MANAGE_PERMISSIONS, true);

            /* COUNTRIES */
            axios.get(COUNTRIES_LIST).then(response => {
                response.data.data.forEach((option) => {
                    countries.push({
                        optValue: option.id,
                        optLabel: option.name
                    });
                });
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_COUNTRIES, countries);
            });

            /* DESTINATIONS */
            axios.get(DESTINATION_GROUPS).then(response => {
                response.data.data.forEach((option) => {
                    let current = null;
                    if (state.formData.destination_control.includes(option.checkedValue)) {
                        current = option.checkedValue;
                    }

                    destinations.push({
                        id: option.id,
                        checkedValue: option.checkedValue,
                        currentValue: current,
                        label: option.name,
                        name: null,
                        classes: null
                    });
                })
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_DESTINATIONS, destinations);
            });

            /* DIVISIONS */
            axios.get(DIVISIONS_LIST).then(response => {
                response.data.data.forEach((option) => {
                    let current = null;
                    if (state.formData.permissions.division_id.includes(option.checkedValue)) {
                        current = option.checkedValue;
                    }

                    divisions.push({
                        id: option.id,
                        checkedValue: option.checkedValue,
                        currentValue: current,
                        label: option.label,
                        name: null,
                        classes: null
                    });
                });
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_DIVISIONS, divisions);
            });

            /* PROPERTIES */
            axios.get(PROPERTIES_LIST).then(response => {
                response.data.data.forEach((option) => {
                    properties.push({
                        optValue: option.property_id,
                        optLabel: option.name,
                        optSearchable: option.property_id + ' | ' + option.name + ' | ' + option.marsha_code
                    });
                });
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_PROPERTIES, properties);
            });

            /* PROPERTY TYPES */
            axios.get(PROPERTY_TYPES).then(response => {
                response.data.data.forEach((option) => {
                    let current = null;
                    if (state.formData.permissions.property_type_id.includes(option.checkedValue)) {
                        current = option.checkedValue;
                    }

                    propertyTypes.push({
                        id: option.id,
                        checkedValue: option.checkedValue,
                        currentValue: current,
                        label: option.label,
                        name: null,
                        classes: null
                    });
                })
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_PROPERTY_TYPES, propertyTypes);
            });

            /* SOURCE TYPES */
            axios.get(SOURCE_TYPES).then(response => {
                commit(SET_LOGO_UPLOAD_SOURCE_TYPES, response.data.data);
            });

            /* SELECTED USERS FOR SOURCE_VENDOR */
            dispatch('setSelectedUser', state.formData.source_vendor);

            /* USER TYPES */
            let userTypes = [
                {
                    id:'permissions_corporate',
                    name:'corporate',
                    label:'Corporate',
                    checkedValue:'1',
                    currentValue:state.formData.permissions.corporate,
                },
                {
                    id:'permissions_property',
                    name:'property',
                    label:'Property',
                    checkedValue:'1',
                    currentValue:state.formData.permissions.property,
                },
                {
                    id:'permissions_regional',
                    name:'regional',
                    label:'Regional',
                    checkedValue:'1',
                    currentValue:state.formData.permissions.regional,
                },
                {
                    id:'permissions_third_party_vendor',
                    name:'third_party_vendor',
                    label:'Third Party Vendors',
                    checkedValue:'1',
                    currentValue:state.formData.permissions.third_party_vendor,
                }
            ];
            commit(SET_LOGO_UPLOAD_USER_TYPES, userTypes);

            /* USER GROUPS */
            axios.get(USER_GROUPS_LIST).then(response => {
                response.data.data.forEach((option) => {
                    userGroups.push({
                        optValue: option.user_group_id,
                        optLabel: option.name
                    });
                });
            })
            .then(() => {
                commit(SET_LOGO_UPLOAD_USER_GROUPS, userGroups);
            });

            /* VENDOR TYPES */
            axios.get(VENDOR_TYPES).then(response => {
                commit(SET_LOGO_UPLOAD_VENDOR_TYPES, response.data.data);
            });
        },
        uploadZip({dispatch, commit}, formElement, zipField = 'zip') {
            let formData = new FormData(formElement);
            commit(SET_LOGO_UPLOAD_ZIP, formData.get(zipField));

            return axios.post(
                LOGO_UPLOAD_UPLOAD_ZIP,
                formData
            ).then(response => {

                let notification = new NotificationModel(
                    response.data.message,
                    'success',
                    30
                );

                dispatch('addNotification', notification, {
                    root: true,
                });

                commit(SET_LOGO_UPLOAD_CONFIRMED, false);
                commit(SET_LOGO_UPLOAD_ERRORS, null);

            }).catch(error => {
                formElement[zipField].value = null;

                let notification = new NotificationModel(
                    error.response.data.message,
                    'danger',
                    30
                );

                dispatch('addNotification', notification, {
                    root: true
                });

                commit(SET_LOGO_UPLOAD_ERRORS, error.response.data.message);

                // if (error.response.data.files) {
                //     commit(
                //         SET_LOGO_UPLOAD_PARSED_FILES,
                //         error.response.data.files.data
                //     );
                // }
            });
        },
        checkStatus({dispatch, commit}) {
            commit(SET_IS_LOADING, true, { root: true });

            return axios.get(LOGO_UPLOAD_STATUS_CHECK)
                .then(response => {
                    if (response.data.assets) {
                        commit(SET_LOGO_UPLOAD_ASSETS, response.data.assets.data);
                        commit(SET_CSRF_TOKEN, response.data.csrfToken)
                    }

                    return {success : response.data.success, stage : response.data.stage};
                }).then(response => {
                    commit(SET_IS_LOADING, false, { root: true });
                    return {success : response.success, stage : response.stage};
                }).catch(error => {
                    dispatch(
                        'addNotification',
                        new NotificationModel('Unable to check status', 'danger', 30),
                        {
                            root: true
                        }
                    );
                    commit(SET_IS_LOADING, false, { root: true });
                });
        },
        setConfirmed({commit}, value) {
            commit(SET_LOGO_UPLOAD_CONFIRMED, value);
        },
        setFormData({commit}, value) {
            commit(SET_LOGO_UPLOAD_FORM_DATA, value);
        },
        setSelectedUser({commit}, userId) {
            let selectedUser = null;

            if (userId) {
                axios.get(USER_LOOKUP, {params:{user_ids:userId}})
                .then(response => {
                    if (response.data.data.length == 1) {
                        selectedUser = response.data.data[0];
                    }

                    commit(SET_LOGO_UPLOAD_SOURCE_USER, selectedUser);
                });
            } else {
                commit(SET_LOGO_UPLOAD_SOURCE_USER, selectedUser);
            }
        },
        createAssets({commit, state, dispatch}) {
            commit(SET_IS_LOADING, true, { root: true });

            let params = state.formData;

            params.csrf_token = state.csrfToken;

            return axios.post(
                LOGO_UPLOAD_CREATE, 
                params
            ).then(response => {
                return axios.get(LOGO_UPLOAD_STATUS_CHECK)
                .then(response => {
                    let notification = new NotificationModel(
                        response.data.message,
                        'success',
                        30
                    );

                    dispatch('addNotification', notification, {
                        root: true,
                    });

                    return {success : response.data.success, message : response.data.message, stage : response.data.stage};
                })
            }).then(response => {
                commit(SET_IS_LOADING, false, { root: true });
                return {success : response.success, message : response.message, stage : response.stage};
            }).catch(error => {
                let notification = new NotificationModel(
                    error.response.data.message,
                    'danger',
                    30
                );

                dispatch('addNotification', notification, {
                    root: true
                });

                commit(SET_IS_LOADING, false, { root: true });

                return {success : false, message : error.response.data.message, stage : 2};
            });
        },
        startOver({commit, state}) {
            commit(SET_IS_LOADING, true, { root: true });

            return axios.post(
                    LOGO_UPLOAD_RESET,
                    {
                        clear: true
                    }
                )
                .then(response => {
                    commit(SET_LOGO_UPLOAD_ASSETS, []);
                    commit(SET_LOGO_UPLOAD_ZIP, null);
                    commit(SET_LOGO_UPLOAD_ERRORS, null);
                    commit(SET_LOGO_UPLOAD_SOURCE_USER, null);

                    let formData = {
                        preferred: null,
                        status: 'active',
                        description: '',
                        metadata: '',
                        source_vendor: null,
                        vendor_type: '',
                        source_type: '',
                        source_asset_name: '',
                        deactivation_date: '',
                        aged_date: '',
                        instructions: '',
                        usage_description: '',
                        rights_managed: null,
                        limited_rights: null,
                        marketing_caption: '',
                        gds_room_type_code: '',
                        ers_room_type_code: '',
                        ers_attribute_code: '',
                        destination_control: [],
                        visible_to_api: true,
                        public: 1,
                        note: '',
                        comment: '',
                        permissions: {
                            brand_id: [],
                            property_id: [],
                            user_group_id: [],
                            corporate: null,
                            property: null,
                            regional: null,
                            third_party_vendor: null,
                            division_id: [],
                            country_id: [],
                            property_type_id: [],
                        },
                    };

                    commit(SET_LOGO_UPLOAD_FORM_DATA, formData);

                    let destinations = [];

                    state.destinations.forEach((d) => {
                        d.currentValue = null;
                        destinations.push(d);
                    });

                    commit(SET_LOGO_UPLOAD_DESTINATIONS, destinations);
                })
                .catch(error => {

                })
                .then(() => {
                    commit(SET_IS_LOADING, false, { root: true });
                });
        },
        setIsLoading({commit}, value) {
            commit(SET_IS_LOADING, value, { root: true });
        },
    },
    mutations: {
        [SET_CSRF_TOKEN] (state, value) {
            state.csrfToken = value;
        },
        [SET_LOGO_UPLOAD_ASSETS] (state, assets) {
            state.assets = assets;
        },
        [SET_LOGO_UPLOAD_ASSET_STATUSES] (state, assetStatuses) {
            state.assetStatuses = assetStatuses;
        },
        [SET_LOGO_UPLOAD_BRANDS] (state, brands) {
            state.brands = brands;
        },
        [SET_LOGO_UPLOAD_CAN_MANAGE_PERMISSIONS] (state, value) {
            state.canManagePermissions = value;
        },
        [SET_LOGO_UPLOAD_CONFIRMED] (state, confirmed) {
            state.confirmed = confirmed;
        },
        [SET_LOGO_UPLOAD_COUNTRIES] (state, countries) {
            state.countries = countries;
        },
        [SET_LOGO_UPLOAD_DESTINATIONS] (state, destinations) {
            state.destinations = destinations;
        },
        [SET_LOGO_UPLOAD_DIVISIONS] (state, divisions) {
            state.divisions = divisions;
        },
        [SET_LOGO_UPLOAD_ERRORS] (state, errors) {
            state.errors = errors;
        },
        [SET_LOGO_UPLOAD_FORM_DATA] (state, formData) {
            state.formData = formData;
        },
        [SET_LOGO_UPLOAD_PROPERTIES] (state, properties) {
            state.properties = properties;
        },
        [SET_LOGO_UPLOAD_PROPERTY_TYPES] (state, propertyTypes) {
            state.propertyTypes = propertyTypes;
        },
        [SET_LOGO_UPLOAD_SOURCE_USER] (state, user) {
            state.selectedUser = user;
        },
        [SET_LOGO_UPLOAD_SOURCE_TYPES] (state, sourceTypes) {
            state.sourceTypes = sourceTypes;
        },
        [SET_LOGO_UPLOAD_USER_GROUPS] (state, userGroups) {
            state.userGroups = userGroups;
        },
        [SET_LOGO_UPLOAD_USER_TYPES] (state, userTypes) {
            state.userTypes = userTypes;
        },
        [SET_LOGO_UPLOAD_VENDOR_TYPES] (state, vendorTypes) {
            state.vendorTypes = vendorTypes;
        },
        [SET_LOGO_UPLOAD_ZIP] (state, zipFile) {
            state.zipFile = zipFile;
        },
    },
};

export default logoUpload;
