<template>
    <div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
            <ButtonPrimary
                text-size="text-sm"
                @click="showContent('open')">
                Open ({{ openCount }})
            </ButtonPrimary>
            <ButtonPrimary
                text-size="text-sm"
                @click="showContent('on hold')">
                On hold ({{ onHoldCount }})
            </ButtonPrimary>
            <ButtonPrimary
                text-size="text-sm"
                @click="showContent('closed')">
                Closed ({{ closedCount }})
            </ButtonPrimary>
            <ButtonPrimary
                text-size="text-sm"
                @click="showContent('pending')">
                Pending ({{ pendingCount }})
            </ButtonPrimary>
        </div>

        <hr class="border border-blue-light mt-8 mb-8" />

        <div v-if="selectedItem !== 'Detail'">
            <WorkOrdersTable 
                v-if="visibleSection === 'open'" 
                :orders="openOrders" 
                status="open"
                status-title="Open Work Orders"
                status-subtitle="An Open work order is one that is currently being worked on."
                @display-details="showDetails"/>
            <WorkOrdersTable 
                v-if="visibleSection === 'on hold'" 
                :orders="onHoldOrders" 
                status="on hold"
                status-title="On Hold Work Orders" 
                status-subtitle="An On Hold work order is one that requires further input or sources before work can continue."
                @display-details="showDetails"/>
            <WorkOrdersTable 
                v-if="visibleSection === 'closed'" 
                :orders="closedOrders" 
                status="closed"
                status-title="Closed Work Orders" 
                status-subtitle="A Closed work order is one that was completed or no further action is required "
                @display-details="showDetails"/>
            <WorkOrdersTable 
                v-if="visibleSection === 'pending'" 
                :orders="pendingOrders" 
                status="pending"
                status-title="Pending Work Orders" 
                status-subtitle="A Pending work order is one that is waiting for approval."
                @display-details="showDetails"/>
            
        </div>
        <div v-if="selectedItem === 'Detail'">
            <div class="flex gap-4 items-center justify-between">
                <h5 class="text-lg text-blue font-swiss font-bold">Work Order Details</h5>
                <ButtonStripped
                    @click="goBack"
                    class="flex gap-4 items-center">
                    <IconArrowRight
                        icon-color="blue"
                        class="transform rotate-180"
                    />
                    Back to Your Work Orders
                </ButtonStripped>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-[max-content_1fr] gap-x-8 gap-y-2 border border-blue-medium py-3 px-6 rounded mt-4">
                <div class="font-bold text-blue-dark">Work Order #</div>
                <div>{{ selectedWorkOrder.id }}</div>
                <div class="font-bold text-blue-dark">Submitted By</div>
                <div>{{ selectedWorkOrder.submittedby.data.full_name }}</div>
                <div class="font-bold text-blue-dark">Brand</div>
                <div>{{ selectedWorkOrder.brand.data.name }}</div>
                <div class="font-bold text-blue-dark">Request Type</div>
                <div>{{ selectedWorkOrder.request_type.data.name }}</div>
                <div class="font-bold text-blue-dark">Urgency</div>
                <div>{{ selectedWorkOrder.urgency }}</div>
                <div class="font-bold text-blue-dark">Status</div>
                <div>{{ selectedWorkOrder.formatted_status }}</div>
                <div class="font-bold text-blue-dark">Status Notifications</div>
                <div>{{ selectedWorkOrder.status_notifications }}</div>
                <div class="font-bold text-blue-dark">Notes</div>
                <div>{{ selectedWorkOrder.notes.data.note }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonPrimary from 'Components/Redesign/Button/ButtonPrimary.vue';
import ButtonStripped from 'Components/Redesign/Button/ButtonStripped.vue';
import IconArrowRight from 'Components/Redesign/Icons/IconArrowRight.vue';
import WorkOrdersTable from './WorkOrdersTable.vue';

export default {
    name: 'WorkOrderStatus',
    components: {
        ButtonPrimary,
        ButtonStripped,
        IconArrowRight,
        WorkOrdersTable,
    },
    props: {
        workOrders: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visibleSection: null, // Track which section is visible
            selectedItem: '',
            selectedWorkOrder: null,
        };
    },
    computed: {
        openCount() {
            return this.workOrders.data.filter(item => item.status === 'open').length;
        },
        onHoldCount() {
            return this.workOrders.data.filter(item => item.status === 'on hold').length;
        },
        closedCount() {
            return this.workOrders.data.filter(item => item.status === 'closed').length;
        },
        pendingCount() {
            return this.workOrders.data.filter(item => item.status === 'pending').length;
        },
        openOrders() {
            return this.workOrders.data.filter(item => item.status === 'open');
        },
        onHoldOrders() {
            return this.workOrders.data.filter(item => item.status === 'on hold');
        },
        pendingOrders() {
            return this.workOrders.data.filter(item => item.status === 'pending');
        },
        closedOrders() {
            return this.workOrders.data.filter(item => item.status === 'closed');
        },
    },
    methods: {
        goBack() {
            this.selectedItem = ''; // Reset to previous view
            this.selectedWorkOrder = null;
        },
        showContent(status) {
            console.log(status);
            this.visibleSection = status;
        },
        showDetails(workOrder) {
            // console.log(`Display details for order ID: ${workOrder.id}`);
            this.selectedWorkOrder = workOrder;
            this.selectedItem = 'Detail';
        },
    },
}
</script>
