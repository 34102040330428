<template>
    <div class="workspaceActions">
        <drawer>
            <template v-slot:trigger="{ toggleDrawer }">
                <ButtonStripped
                    variant="link"
                    class="flex items-center gap-2"
                    text-color="text-blue"
                    text-size="text-sm"
                    @click="toggleDrawer"
                    @keyup.esc="toggleDrawer">
                    Add to Workspace
                    <svg
                        height="8"
                        viewBox="0 0 14 8"
                        width="14"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m1 1 6 6 6-6"
                            fill="none"
                            stroke="#9DB6EB"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2" />
                    </svg>
                </ButtonStripped>
            </template>

            <template v-slot:drawer>
                <div class="mb-2 p-2 border-b last:border-b-0 text-sm">
                    <FormInput
                        v-model="workspaceFilter"
                        input-class="w-full text-sm font-normal text-blue-darkest p-0 outline-none border-none"
                        placeholder="Workspace name" />

                    <div v-if="workspaceFilter">
                        <span>"{{ workspaceFilter }}"</span>
                        <button
                            class="text-coral-dark underline float-right"
                            @click="confirmNewWorkspace()">
                            Create
                        </button>
                    </div>
                </div>

                <ul
                    v-if="filteredWorkspaces.length"
                    class="max-h-52 px-4 overflow-y-scroll">
                    <li
                        v-for="workspace in filteredWorkspaces"
                        :key="workspace.id"
                        class="border-slate-alt border-b flex justify-between gap-2 py-2 last:border-b-0 text-xs">
                        <span>{{ getTruncatedName(workspace) }}</span>
                        <button
                            class="hover:text-blue-dark text-blue underline"
                            @click.prevent="confirmAddToWorkspace(workspace)">
                            Add
                        </button>
                    </li>
                </ul>
            </template>
        </drawer>
        <modal
            :visible="confirmAction"
            :transparent="false">
            <div
                v-if="isNewWorkspace"
                class="p-12">
                <div class="mb-8">
                    Create new workspace titled
                    <span class="font-bold">"{{ workspaceFilter }}"</span>?
                </div>
                <div class="text-center">
                    <button
                        class="button --coral"
                        @click="createNewWorkspace()">
                        Create
                    </button>
                    <button
                        class="button normal-case font-normal"
                        @click="confirmAction = false">
                        Cancel
                    </button>
                </div>
            </div>

            <div
                v-if="!isNewWorkspace && selectedWorkspace"
                class="p-12">
                <div class="mb-8">
                    Add to existing workspace titled
                    <span class="font-bold">"{{ selectedWorkspace.name }}"</span
                    >?
                </div>
                <div class="text-center">
                    <button
                        class="button --coral"
                        @click="addAssetToWorkspace()">
                        Add
                    </button>
                    <button
                        class="button normal-case font-normal"
                        @click="confirmAction = false">
                        Cancel
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import ButtonStripped from '../Button/ButtonStripped'
import Drawer from 'Components/Redesign/Drawer'
import FormInput from 'Components/Redesign/Form/FormInput'
import Modal from 'Components/Modal'

export default {
    components: {
        ButtonStripped,
        Drawer,
        FormInput,
        Modal,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        workspaces: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            workspaceFilter: null,
            confirmAction: false,
            isNewWorkspace: false,
            selectedWorkspace: null,
        }
    },
    computed: {
        filteredWorkspaces() {
            if (this.workspaceFilter) {
                return this.workspaces.filter(
                    (workspace) =>
                        workspace.name.indexOf(this.workspaceFilter) !== -1
                )
            }

            return this.workspaces
        },
    },
    methods: {
        getTruncatedName(workspace) {
            if (workspace.name.length > 15) {
                return workspace.name.substring(0, 15) + '...'
            }

            return workspace.name
        },
        confirmNewWorkspace() {
            this.isNewWorkspace = true
            this.confirmAction = true
        },
        confirmAddToWorkspace(workspace) {
            this.selectedWorkspace = workspace
            this.isNewWorkspace = false
            this.confirmAction = true
        },
        createNewWorkspace(name) {
            this.$store
                .dispatch('user/createWorkspace', {
                    name: this.workspaceFilter,
                    asset_ids: [this.asset.asset_id],
                })
                .finally(() => {
                    this.$store.dispatch('user/getWorkspaces')
                    this.workspaceFilter = null
                    this.confirmAction = false
                })
        },
        addAssetToWorkspace(workspace) {
            this.isNewWorkspace = false
            this.confirmAction = true

            this.$store
                .dispatch('user/addAssetsToWorkspace', {
                    assets: [this.asset],
                    workspace: this.selectedWorkspace,
                })
                .then((response) => {
                    console.log(response)
                })
                .finally(() => {
                    this.selectedWorkspace = null
                    this.confirmAction = false
                    this.workspaceFilter = null
                })
        },
        //
    },
}
</script>

<style></style>
