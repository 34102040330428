<template>
    <multiselect
        class="absolute"
        v-model="data"
        id="entry_users"
        label="full_name"
        track-by="user_id"
        placeholder="Enter name"
        open-direction="bottom"
        :options="users"
        :multiple="multiple"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="8"
        :limit-text="limitText"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="findUsers"
    />
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import debounce from 'lodash/debounce'

import { getUsers } from 'Composables/useUsers.js'

export default {
    components: {
        Multiselect,
    },
    props: {
        value: {
            required: false,
            type: [Array, Object],
            default: () => [],
        },
        multiple: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return { filter: null, users: [], isLoading: false }
    },
    computed: {
        data: {
            get() {
                return this.value
            },
            set(newValues) {
                this.$emit('input', newValues)
            },
        },
    },
    methods: {
        limitText(count) {
            return `${count} addtional users`
        },
        findUsers: debounce(async function (filter) {
            if (filter.length < 4) {
                return
            }

            this.filter = filter
            this.isLoading = true

            try {
                const response = await getUsers({ keywords: filter })

                if (response.data.data) {
                    this.users = response.data.data
                }
            } catch (error) {
                this.users = []
                console.error(error)
            }

            this.isLoading = false
        }, 300),
    },
    async mounted() {},
}
</script>
