import { uniqBy } from 'lodash'
import axios from 'axios'

import {
    USER_SELECTED_ASSETS,
    USER_WORKSPACES_LIST,
    USER_WORKSPACES_CREATE,
    USER_WORKSPACES_UPDATE,
} from 'Root/endpoints'

import {
    SET_SEARCH_IS_LOADING,
    SET_USER,
    SET_USER_ASSOCIATIONS,
    SET_USER_GROUPS,
    SET_USER_HAS_CHANGED,
    SET_USER_IS_LOADING,
    SET_USER_SELECTED_ASSETS,
    SET_USER_SETTINGS,
    SET_USER_VALID_AUTHORIZATION_LEVELS,
    SET_USER_VALID_STATUSES,
    SET_USER_WORKSPACES,
    SET_USER_WORK_ORDERS,
} from 'Stores/Frontend/mutation-types.js'

import Notification from 'Components/Redesign/SystemNotifications/NotificationModel'

import {
    getSettings,
    getAssociations,
    getAccount,
} from 'Composables/useAccount.js'

const user = {
    namespaced: true,
    state: {
        user: null,
        settings: null,
        selectedAssets: [],
        associations: [],
        workspaces: [],
        groups: [],
        workOrders: [],
        validAuthorizationLevels: [],
        validStatuses: [],
        isLoading: false,
        hasChanged: false,
    },
    mutations: {
        [SET_USER_IS_LOADING](state, isLoading) {
            state.isLoading = isLoading
        },
        [SET_USER](state, user) {
            state.user = user
        },
        [SET_USER_ASSOCIATIONS](state, associations) {
            state.associations = associations
        },
        [SET_USER_GROUPS](state, groups) {
            state.groups = groups
        },
        [SET_USER_WORKSPACES](state, workspaces) {
            state.workspaces = workspaces
        },
        [SET_USER_WORK_ORDERS](state, workOrders) {
            state.workOrders = workOrders
        },
        [SET_USER_SELECTED_ASSETS](state, selected) {
            state.selectedAssets = uniqBy(selected, 'asset_id')
        },
        [SET_USER_SETTINGS](state, settings) {
            state.settings = settings
        },
        [SET_USER_VALID_AUTHORIZATION_LEVELS](state, levels) {
            state.validAuthorizationLevels = levels
        },
        [SET_USER_VALID_STATUSES](state, statuses) {
            state.validStatuses = statuses
        },
        [SET_USER_HAS_CHANGED](state, changed) {
            state.hasChanged = changed
        },
    },
    getters: {
        brands: (state) => {
            return state.user?.brands.data ?? []
        },
        properties: (state) => {
            return state.user?.properties.data ?? []
        },
        isPropertyUser: (state) => {
            if (!state.user) {
                return null
            }

            return (
                state.user?.is_property_user ||
                state.user?.is_third_party_vendor
            )
        },
    },
    actions: {
        async init({ dispatch, commit }) {
            commit(SET_USER_IS_LOADING, true)

            dispatch('loadAccount')
            dispatch('loadSettings')
            dispatch('getWorkspaces')
            // await dispatch('loadAssociations', true)

            commit(SET_USER_IS_LOADING, false)
        },
        async loadAccount({ commit }) {
            try {
                const response = await getAccount()

                if (!response.data.data) {
                    throw new Error('Unable to load account')
                }

                commit(SET_USER, response.data.data)
                commit(
                    SET_USER_VALID_AUTHORIZATION_LEVELS,
                    response.data.meta.valid_authorization_levels
                )
                commit(
                    SET_USER_VALID_STATUSES,
                    response.data.meta.valid_statuses
                )
            } catch (error) {
                console.error(error)
            }
        },
        async loadAssociations({ commit }, force = false) {
            try {
                const associations = await getAssociations(force)
                commit(SET_USER_ASSOCIATIONS, associations.data?.data)
            } catch (error) {
                console.error(error)
            }
        },
        async loadSettings({ commit }) {
            try {
                const settings = await getSettings()

                commit(SET_USER_SETTINGS, settings.data.data)
            } catch (error) {
                console.log(error)
            }
        },
        getSelectedAssets({ commit, dispatch }) {
            return axios
                .get(
                    USER_SELECTED_ASSETS +
                        '?includes[]=links&includes[]=brand&includes[]=property&includes[]=property.brand&includes[]=files&includes[]=thumbnails&includes[]=asset_type'
                )
                .then((response) => {
                    commit(SET_USER_SELECTED_ASSETS, response.data.data)
                    if (response.data.data.length) {
                        // Commenting out this notification right now. It's annoying.
                        // dispatch(
                        //     'addNotification',
                        //     new Notification(`Loaded ${response.data.data.length} previously selected assets`), {
                        //         root: true
                        //     }
                        // );
                    }

                    return response.data.data
                })
                .catch((error) => {
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    )
                })
                .finally(() => {
                    //
                })
        },
        removeSelectedAssets({ commit, dispatch, state }) {
            let total = state.selectedAssets.length

            commit(SET_SEARCH_IS_LOADING, true, { root: true })

            return axios
                .post(
                    USER_SELECTED_ASSETS +
                        '?includes[]=links&includes[]=brand&includes[]=property&includes[]=property.brand&includes[]=files&includes[]=thumbnails&includes[]=asset_type',
                    {
                        asset_ids: [],
                    }
                )
                .then((res) => {
                    commit(SET_USER_SELECTED_ASSETS, [])
                    return []
                })
                .finally(() => {
                    let notification = new Notification(
                        `${total} Assets have been deselected`
                    )
                    dispatch('addNotification', notification, { root: true })
                    commit(SET_SEARCH_IS_LOADING, false, { root: true })
                })
        },
        async setSelectedAssets({ commit, state, context }, assets) {
            commit(SET_SEARCH_IS_LOADING, true, { root: true })

            try {
                return axios
                    .post(
                        USER_SELECTED_ASSETS +
                            '?includes[]=links&includes[]=brand&includes[]=property&includes[]=property.brand&includes[]=files&includes[]=thumbnails&includes[]=asset_type',
                        {
                            asset_ids: assets.map((asset) => asset.asset_id),
                        }
                    )
                    .then((res) => {
                        commit(SET_USER_SELECTED_ASSETS, assets)
                        return assets
                    })
                    .finally(() => {
                        commit(SET_SEARCH_IS_LOADING, false, { root: true })
                    })
            } catch (error) {
                dispatch(
                    'addNotification',
                    new Notification('An unknown error has occurred', 'danger'),
                    { root: true }
                )
            }
        },
        async getWorkspaces(
            { commit, state, dispatch },
            {
                includes = ['thumbnail', 'links', 'events', 'notes'],
                limit = null,
            } = {}
        ) {
            dispatch('setIsLoading', true)
            try {
                const response = await axios.get(USER_WORKSPACES_LIST, {
                    params: { includes, limit },
                })
                commit(SET_USER_WORKSPACES, response.data.data)
                return response.data.data
            } catch (error) {
                console.log(error)
            }
            dispatch('setIsLoading', false)
        },
        async createWorkspace(
            { commit, state, dispatch },
            { name, asset_ids }
        ) {
            return axios
                .post(USER_WORKSPACES_CREATE, { name, asset_ids })
                .then((response) => {
                    return response.data.data
                })
                .catch((error) => {
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    )
                })
        },
        async addAssetsToWorkspace(
            { commit, state, dispatch },
            { assets, workspace, refresh = true }
        ) {
            return axios
                .put(`${USER_WORKSPACES_UPDATE}/${workspace.workspace_id}`, {
                    asset_ids: assets.map((asset) => asset.asset_id),
                })
                .then((response) => {
                    if (refresh) {
                        dispatch('getWorkspaces')
                    }

                    dispatch(
                        'pushSuccessAlert',
                        {
                            title: 'Workspace Updated',
                            message: `Successfully added ${assets.length} assets to ${workspace.name}`,
                        },
                        { root: true }
                    )

                    return response.data.data
                })
                .catch((error) => {
                    console.error(error)
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    )
                })
        },
        updateAccount({ commit, dispatch }, user) {
            // update the user
            dispatch('setHasChanged', true)
            commit(SET_USER, user)
        },
        setHasChanged({ commit }, hasChanged) {
            commit(SET_USER_HAS_CHANGED, hasChanged)
        },
        setIsLoading({ commit }, isLoading) {
            commit(SET_USER_IS_LOADING, isLoading)
        },
    },
}

export default user
