<template>
<section class="assetSearch--filters">
    <div class="assetSearch--filterGroup">
        <span class="assetSearch--filterTitle">Sort Results By</span>
        <div class="flex flex-col items-start gap-2">
            <radio
                v-model="order_by"
                name="order_by"
                label="Relevance"
                content="association"
                @input="search"
            />

            <radio
                v-model="order_by"
                name="order_by"
                label="Date Created (Newest First)"
                content="date_created_desc"
                @input="search"
            />

            <radio
                v-model="order_by"
                name="order_by"
                label="Date Created (Oldest First)"
                content="date_created_asc"
                @input="search"
            />

            <radio
                v-model="order_by"
                name="order_by"
                label="Date Updated (Newest First)"
                content="last_modified_desc"
                @input="search"
            />

            <radio
                v-model="order_by"
                name="order_by"
                label="Date Updated (Oldest First)"
                content="last_modified_asc"
                @input="search"
            />
        </div>
    </div>

    <div class="assetSearch--filterGroup">
        <span class="assetSearch--filterTitle">Locations</span>
        <toggle-box
            title="Division"
            :meta="getSelectedCountText(division_ids)"
            :open="isOpen(division_ids)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="division_ids"
                    label="Division"
                    label-plural="Divisions"
                    :search="false"
                    :show-label="false"
                    :options="divisions"
                    filter-by="name"
                    display-key="name"
                    placeholder="Search for Division"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Region"
            :meta="getSelectedCountText(region_ids)"
            :open="isOpen(region_ids)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="region_ids"
                    label="Region"
                    label-plural="Regions"
                    :search="false"
                    :show-label="false"
                    :options="regions"
                    filter-by="name"
                    display-key="name"
                    placeholder="Search for Region"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="State"
            :meta="getSelectedCountText(states_provinces)"
            :open="isOpen(states_provinces)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="states_provinces"
                    label="State"
                    label-plural="States"
                    :search="false"
                    :show-label="false"
                    :options="states"
                    filter-by="name"
                    display-key="name"
                    placeholder="Search for State"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="City"
            :meta="getSelectedCountText(cities)"
            :open="isOpen(cities)"
        >
            <template #content>
                <search-input-text
                    id="city"
                    v-model="cities"
                    label="City"
                    :show-label="false"
                    placeholder="Enter city"
                    @input="search"
                />
            </template>
        </toggle-box>
    </div>

    <div class="assetSearch--filterGroup">
        <span class="assetSearch--filterTitle">Collections & Groups</span>

        <toggle-box
            title="My Groups"
            :meta="getSelectedCountText(group_ids)"
            :open="isOpen(group_ids)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="group_ids"
                    label="Group"
                    label-plural="Groups"
                    :search="false"
                    :show-label="false"
                    :options="groups"
                    filter-by="user_group_id"
                    display-key="name"
                    placeholder="Search for Group"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Photography Subject"
            :meta="getSelectedCountText(subject_ids)"
            :open="isOpen(subject_ids)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="subject_ids"
                    label="Subject"
                    label-plural="Subjects"
                    :search="false"
                    :show-label="false"
                    :options="subjects"
                    filter-by="subject_id"
                    display-key="name"
                    placeholder="Search for Subject"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Collections"
            :meta="getSelectedCountText(collection_ids)"
            :open="isOpen(collection_ids)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="collection_ids"
                    label="Collection"
                    label-plural="Collections"
                    :search="false"
                    :show-label="false"
                    :options="collections"
                    filter-by="collection_id"
                    display-key="name"
                    placeholder="Search for Collection"
                    @input="search"
                />
            </template>
        </toggle-box>
    </div>

    <div class="assetSearch--filterGroup">
        <toggle-box
            title="Date Created"
            :open="date_created_start !== null || date_created_end !== null"
        >
            <template #content>
                <span class="bold">From</span>

                <date-picker
                    v-model="date_created_start"
                    name="date_created_start"
                    format="yyyy-MM-dd"
                    @input="updateDateField('date_created_start', $event)"
                />

                <span class="bold">To</span>
                <date-picker
                    v-model="date_created_end"
                    name="date_created_end"
                    format="yyyy-MM-dd"
                    @input="updateDateField('date_created_end', $event)"
                />
            </template>
        </toggle-box>
        <toggle-box
            title="Date Updated"
            :open="
                last_modified_start !== null || last_modified_end !== null
            "
        >
            <template #content>
                <span class="bold">From</span>
                <date-picker
                    v-model="last_modified_start"
                    name="date_created_end"
                    format="yyyy-MM-dd"
                    @input="updateDateField('last_modified_start', $event)"
                />

                <span class="bold">To</span>
                <date-picker
                    v-model="last_modified_end"
                    name="date_created_end"
                    format="yyyy-MM-dd"
                    @input="updateDateField('last_modified_end', $event)"
                />
            </template>
        </toggle-box>
    </div>

    <div class="assetSearch--filterGroup">
        <span class="assetSearch--filterTitle">Asset Details</span>

        <div class="flex flex-col items-start gap-2 pt-2 pb-4">
            <toggle-switch
                id="rights_docs_only"
                v-model="usage_rights"
                name="rights_docs_only"
                input-value="has_documents"
                text="Has Rights Documents"
                @input="search"
            />

            <toggle-switch
                id="brand_only"
                v-model="brand_only"
                name="brand_only"
                input-value="true"
                text="Brand Only"
                @input="search"
            />

            <toggle-switch
                id="property_only"
                v-model="property_only"
                name="property_only"
                input-value="true"
                text="Property Only"
                @input="search"
            />

            <toggle-switch
                id="has_thumbnails"
                v-model="has_thumbnails"
                name="has_thumbnails"
                input-value="true"
                text="Has Thumbnails"
                @input="search"
            />
        </div>

        <toggle-box
            title="Template Type"
            :meta="getSelectedCountText(template_types)"
            :open="isOpen(template_types)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="template_types"
                    label="Template Types"
                    label-plural="Template Types"
                    :search="false"
                    :show-label="false"
                    :options="templateTypes"
                    filter-by="name"
                    display-key="name"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Colors"
            :meta="getSelectedCountText(selected_colors)"
            :open="isOpen(selected_colors)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="selected_colors"
                    label="Color"
                    label-plural="Colors"
                    :search="false"
                    :show-label="false"
                    :options="colors"
                    filter-by="name"
                    display-key="name"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Destination"
            :meta="getSelectedCountText(destination_controls)"
            :open="isOpen(destination_controls)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="destination_controls"
                    label="Destination"
                    label-plural="Destinations"
                    :search="false"
                    :show-label="false"
                    :options="destinations"
                    filter-by="id"
                    display-key="name"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="External"
            :open="is_stock || is_offer || from_mdam || hidden_from_search || from_dac"
        >
            <template #content>
                <div class="flex flex-col items-start gap-2 pt-2 pb-4">
                    <toggle-switch
                        id="is_stock"
                        v-model="is_stock"
                        name="is_stock"
                        :input-value="true"
                        text="Is Stock"
                        @input="search"
                    />

                    <toggle-switch
                        id="is_offer"
                        v-model="is_offer"
                        name="is_offer"
                        :input-value="true"
                        text="Is Offered"
                        @input="search"
                    />

                    <toggle-switch
                        v-if="isSuperAdmin"
                        id="from_mdam"
                        v-model="from_mdam"
                        name="from_mdam"
                        :input-value="true"
                        text="Imported From MDAM"
                        @input="search"
                    />

                    <toggle-switch
                        id="from_dac"
                        v-model="from_dac"
                        name="from_dac"
                        :input-value="true"
                        text="Imported From DAC"
                        @input="search"
                    />

                    <toggle-switch
                        v-if="isSuperAdmin"
                        id="hidden_from_search"
                        v-model="hidden_from_search"
                        name="hidden_from_search"
                        :input-value="true"
                        text="Hidden from Search"
                        @input="search"
                    />
                </div>
            </template>
        </toggle-box>
    </div>

    <div class="assetSearch--filterGroup">
        <span class="assetSearch--filterTitle">Asset Status</span>

        <div class="flex flex-col items-start gap-2 pt-2">
            <div
                v-for="stat in statuses"
                :key="stat.id"
            >
                <radio
                    v-model="status"
                    name="status"
                    :label="stat.name"
                    :content="stat"
                    compare="value"
                    placeholder="Search for Asset Status"
                    @input="search"
                />
            </div>
        </div>
    </div>

    <div
        v-if="isSuperAdmin"
        class="assetSearch--filterGroup"
    >
        <span class="assetSearch--filterTitle">MDAM</span>

        <toggle-box
            title="IDs"
            :open="isOpen(mdam_ids)"
        >
            <template #content>
                <search-input-text
                    v-model="mdam_ids"
                    label="MDAM Id"
                    :show-label="false"
                    placeholder="Enter Id"
                    @input="search"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Containers"
            :open="isOpen(containers)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="containers"
                    label="Container"
                    label-plural="Containers"
                    :search="true"
                    :show-label="false"
                    :options="availableContainers"
                    filter-by="name"
                    display-key="name"
                    placeholder="Enter container name"
                    :filter-results="false"
                    @input="search"
                    @update:filter="fetchContainers"
                />
            </template>
        </toggle-box>

        <toggle-box
            title="Datapoints"
            :open="isOpen(datapoints)"
        >
            <template #content>
                <search-input-checkbox
                    v-model="datapoints"
                    label="Datapoint"
                    label-plural="Datapoints"
                    :search="true"
                    :show-label="false"
                    :options="availableDatapoints"
                    filter-by="name"
                    display-key="name"
                    placeholder="Enter datapoint name"
                    :filter-results="false"
                    @input="search"
                    @update:filter="fetchDatapoints"
                />
            </template>
        </toggle-box>
    </div>
</section>
</template>

<script>
import Radio from 'Components/Redesign/Radio';
import ToggleBox from 'Components/Redesign/ToggleBox';
import SearchInputCheckbox from 'Components/Redesign/Search/SearchInputCheckbox';
import SearchInputText from 'Components/Redesign/Search/SearchInputText';
import DatePicker from 'Components/Redesign/DatePicker';
import ToggleSwitch from 'Components/Redesign/ToggleSwitch';
import mutations from 'Stores/Frontend/mutation-types';
import endpoints from 'Root/endpoints';
import axios from 'axios';
import { debounce, find } from 'lodash';

export default {
    components: {
        Radio,
        ToggleBox,
        ToggleSwitch,
        SearchInputCheckbox,
        SearchInputText,
        DatePicker,
    },
    props: {
        divisions: {
            required: true,
            default: () => [],
            type: Array
        },
        regions: {
            required: true,
            default: () => [],
            type: Array
        },
        states: {
            required: true,
            default: () => [],
            type: Array
        },
        countries: {
            required: true,
            default: () => [],
            type: Array
        },
        destinations: {
            required: true,
            default: () => [],
            type: Array
        },
        groups: {
            required: true,
            default: () => [],
            type: Array
        },
        subjects: {
            required: true,
            default: () => [],
            type: Array
        },
        collections: {
            required: true,
            default: () => [],
            type: Array
        },
        statuses: {
            required: true,
            default: () => [],
            type: Array
        },
        templateTypes: {
            required: true,
            default: () => [],
            type: Array
        },
        templateTypeCategories: {
            required: true,
            default: () => [],
            type: Array
        },
        colors: {
            required: true,
            default: () => [],
            type: Array
        },
        fileTypes: {
            required: true,
            default: () => [],
            type: Array
        },
        isAdmin: {
            required: false,
            default: false,
            type: Boolean
        },
        isSuperAdmin: {
            required: false,
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            containersFilter: null,
            availableContainers: [],
            datapointsFilter: null,
            availableDatapoints: [],
            invalidDateFields: {}
        };
    },
    computed: {
        order_by: {
            get() {
                return this.$store.state.search.query.order_by;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_ORDER_BY, value);
            }
        },
        division_ids: {
            get() {
                return this.$store.state.search.query.divisions;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_DIVISIONS, value);
            }
        },
        region_ids: {
            get() {
                return this.$store.state.search.query.regions;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_REGIONS, value);
            }
        },
        states_provinces: {
            get() {
                return this.$store.state.search.query.states;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_STATES, value);
            }
        },
        cities: {
            get() {
                return this.$store.state.search.query.cities;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_CITIES, value);
            }
        },
        group_ids: {
            get() {
                return this.$store.state.search.query.groups;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_GROUPS, value);
            }
        },
        subject_ids: {
            get() {
                return this.$store.state.search.query.subjects;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_SUBJECTS, value);
            }
        },
        collection_ids: {
            get() {
                return this.$store.state.search.query.collections;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_COLLECTIONS, value);
            }
        },
        date_created_start: {
            get() {
                return this.$store.state.search.query.date_created_start;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_DATE_CREATED_START,
                    value
                );
            }
        },
        date_created_end: {
            get() {
                return this.$store.state.search.query.date_created_end;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_DATE_CREATED_END,
                    value
                );
            }
        },
        last_modified_start: {
            get() {
                return this.$store.state.search.query.last_modified_start;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_LAST_MODIFIED_START,
                    value
                );
            }
        },
        last_modified_end: {
            get() {
                return this.$store.state.search.query.last_modified_end;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_LAST_MODIFIED_END,
                    value
                );
            }
        },
        usage_rights: {
            get() {
                return this.$store.state.search.query.usage_rights;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_USAGE_RIGHTS, value);
            }
        },
        from_mdam: {
            get() {
                return this.$store.state.search.query.from_mdam;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_FROM_MDAM, value);
            }
        },
        hidden_from_search: {
            get() {
                return this.$store.state.search.query.hidden_from_search;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_HIDDEN_FROM_SEARCH,
                    value
                );
            }
        },
        destination_controls: {
            get() {
                return this.$store.state.search.query.destinations;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_DESTINATIONS, value);
            }
        },
        is_stock: {
            get() {
                return this.$store.state.search.query.is_stock;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_IS_STOCK, value);
            }
        },
        is_offer: {
            get() {
                return this.$store.state.search.query.is_offer;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_IS_OFFER, value);
            }
        },
        status: {
            get() {
                return this.$store.state.search.query.status;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_STATUS, value);
            }
        },
        asset_ids: {
            get() {
                return this.$store.state.search.query.assets;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_ASSET_IDS);
            }
        },
        has_thumbnails: {
            get() {
                return this.$store.state.search.query.has_thumbnails;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_HAS_THUMBNAILS, value);
            }
        },
        brand_only: {
            get() {
                return this.$store.state.search.query.brand_only;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_BRAND_ONLY, value);
            }
        },
        property_only: {
            get() {
                return this.$store.state.search.query.property_only;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_PROPERTY_ONLY, value);
            }
        },
        template_types: {
            get() {
                return this.$store.state.search.query.template_types;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_TEMPLATE_TYPES, value);
            }
        },
        template_type_categories: {
            get() {
                return this.$store.state.search.query.template_type_categories;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_TEMPLATE_TYPE_CATEGORIES,
                    value
                );
            }
        },
        selected_colors: {
            get() {
                return this.$store.state.search.query.colors;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_COLORS, value);
            }
        },
        file_types: {
            get() {
                return this.$store.state.search.query.file_types;
            },
            set(value) {
                this.$store.commit(mutations.SET_SEARCH_FILE_TYPES, value);
            }
        },
        datapoints: {
            get() {
                return this.$store.state.search.query.datapoints.map(datapoint => {
                    return {
                        name: datapoint,
                    };
                });
            },
            set(datapoints) {
                this.$store.commit(
                    mutations.SET_SEARCH_DATAPOINTS,
                    datapoints.map(datapoint => {
                        return datapoint.name;
                    })
                );
            },
        },
        containers: {
            get() {
                return this.$store.state.search.query.containers.map(container => {
                    return {
                        name: container,
                    };
                });
            },
            set(containers) {
                this.$store.commit(
                    mutations.SET_SEARCH_CONTAINERS,
                    containers.map(container => {
                        return container.name;
                    })
                );
            }
        },
        mdam_ids: {
            get() {
                return this.$store.state.search.query.mdam_ids;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_MDAM_IDS,
                    value
                );
            }
        },
        from_dac: {
            get() {
                return this.$store.state.search.query.from_dac;
            },
            set(value) {
                this.$store.commit(
                    mutations.SET_SEARCH_FROM_DAC,
                    value
                );
            }
        },
        today() {
            return new Date().toLocaleDateString('en-CA');
        }
    },
    mounted() {
        if (window.loggedInUser) {
            this.$store.commit('user/SET_USER', JSON.parse(window.loggedInUser));
        }

        this.$store.commit(mutations.SET_SEARCH_STATUS, this.getDefaultStatus());
    },
    methods: {
        getDefaultStatus() {
            let user = this.$store.state.user.user;

            if (user) {
                if (user.is_admin || user.is_super_admin) {
                    return find(this.statuses, {value: 'all'});
                }

                if (find(user.permissions.data, {name: 'view_revisions_needed'})) {
                    return find(this.statuses, {value: 'active_&_revisions_needed'});
                }
            }

            return find(this.statuses, {value: 'active'});

        },
        getSelectedCountText(array) {
            if (Array.isArray(array)) {
                return array.length > 0 ? `${array.length} selected` : '';
            }

            return '';
        },
        isOpen(value) {
            if (value && value.length > 0) {
                return true;
            }

            return false;
        },
        fetchContainers: debounce(
            function (filter) {
                this.containersFilter = filter;

                axios.get(endpoints.CONTAINERS_LIST, {
                    params: {
                        filter: this.containersFilter,
                    }
                }).then(response => {
                    this.availableContainers = response.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            300
        ),
        fetchDatapoints: debounce(
            function (filter) {
                this.datapointsFilter = filter;

                axios.get(endpoints.DATAPOINTS_LIST, {
                    params: {
                        filter: this.datapointsFilter,
                    }
                }).then(response => {
                    this.availableDatapoints = response.data.data;
                }).catch(error => {
                    console.log(error);
                });
            },
            300
        ),
        isValidDate(field) {
            if (this.invalidDateFields[field]) {
                return true;
            }

            return false;
        },
        updateDateField(field, value) {
            this.search();
        },
        search() {
            this.$store.dispatch('search');
        }
    }
};
</script>

<style></style>
