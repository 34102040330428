<template>
    <div
        class="relative bg-white border border-blue-medium rounded-lg px-4 py-3">
        <label
            :for="inputId"
            class="absolute top-2 left-4 text-xs text-gray">
            {{ label }}
            <span
                v-if="optionalText"
                class="font-normal">
                {{ optionalText }}
            </span>
            <span
                v-if="required"
                class="text-danger-dark"
                >*</span
            >
        </label>
        <FormTextArea
            :id="inputId"
            :name="name"
            :placeholder="placeholder"
            :value="value"
            :classes="classes"
            :required="required"
            @input="updateValue" />
    </div>
</template>

<script>
import FormTextArea from 'Components/Redesign/Form/FormTextArea'

export default {
    name: 'AccountFormTextArea',
    components: {
        FormTextArea,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Object],
            default: '',
        },
        inputId: {
            type: String,
            default: null,
        },
        optionalText: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        classes: {
            type: String,
            default:
                'border-none pt-4 shadow-none rounded-md w-full min-h-40 mt-2',
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },
    },
}
</script>
