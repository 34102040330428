<template>
    <footer
        class="bg-gradient-to-b from-blue-lightest to-blue-light text-blue text-center w-full relative py-4">
        <!-- Background Img -->
        <svg
            class="absolute top-1/2 -translate-y-1/2 -left-1/4 w-full h-full z-5 mix-blend-screen"
            width="923"
            height="449"
            viewBox="0 0 923 449"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g
                style="mix-blend-mode: screen"
                clip-path="url(#clip0_2415_5105)">
                <path
                    d="M814.384 510.225C654.788 334.807 953.485 373.66 731.38 324.288C545.697 283.013 619.829 359.179 439.334 304.71C209.943 235.485 383.013 146.705 84.3579 119.82C-64.9699 106.377 -83.3049 147.392 -85.7721 201.67C-88.2392 255.949 -74.8385 323.491 -160.695 363.103C-246.551 402.715 -134.44 486.404 18.5809 550.528C171.602 614.651 365.534 659.21 443.322 620.561C598.958 543.232 973.98 685.643 814.384 510.225Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M800.434 509.722C646.915 340.403 933.27 376.914 719.416 328.898C540.528 288.655 610.823 361.326 436.287 308.796C214.74 242.088 380.197 156.909 93.0059 131.365C-50.6575 118.631 -68.4821 158.265 -71.1017 210.7C-72.3427 235.948 -70.0482 264.165 -76.6056 290.92C-83.6851 319.711 -100.908 346.773 -143.641 366.617C-226.242 405.263 -117.883 485.784 29.8858 547.498C177.862 609.249 365.32 652.181 440.008 615.989C497.419 588.124 587.113 591.113 666.767 595.581C793.763 602.754 895.09 614.343 800.434 509.722Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M786.481 509.22C639.039 345.999 913.053 380.168 707.45 333.508C535.356 294.298 601.815 363.473 433.237 312.883C219.534 248.691 377.378 167.113 101.652 142.911C-36.3477 130.885 -53.6619 169.139 -56.4339 219.729C-57.7264 244.06 -55.6354 271.247 -62.0392 297.042C-68.988 324.845 -85.7193 350.871 -126.589 370.131C-206.185 407.64 -101.328 485.164 41.1882 544.469C184.119 603.847 365.103 645.153 436.692 611.417C491.72 585.397 578.932 588.649 656.44 592.91C779.318 599.763 877.513 610.438 786.481 509.22Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M772.532 508.717C631.167 351.595 892.838 383.421 695.487 338.118C530.188 299.94 592.809 365.62 430.19 316.969C224.332 255.294 374.562 177.317 110.3 154.457C-22.0346 143.14 -38.8386 180.013 -41.7631 228.759C-43.1072 252.172 -41.2194 278.328 -47.4697 303.163C-54.2876 329.979 -70.4628 355.015 -109.534 373.645C-186 410.104 -84.77 484.544 52.494 541.439C190.38 598.445 364.89 638.125 433.379 606.846C486.024 582.669 570.754 586.186 646.116 590.238C764.875 596.771 859.939 606.532 772.532 508.717Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M758.581 508.214C623.836 357.088 872.623 386.675 683.523 342.728C525.018 305.582 583.803 367.766 427.143 321.055C229.128 261.896 371.745 187.52 118.948 166.002C-7.72296 155.394 -24.0166 190.886 -27.0935 237.788C-28.4892 260.283 -26.805 285.409 -32.9018 309.284C-39.5888 335.113 -55.2069 359.159 -92.4807 377.158C-165.817 412.57 -68.2133 483.924 63.7982 538.409C196.639 593.043 364.675 631.096 430.065 602.273C480.327 579.941 562.574 583.721 635.79 587.566C750.431 593.779 842.703 602.562 758.581 508.214Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M744.632 507.711C616.093 362.661 852.359 390.146 671.56 347.338C519.807 311.407 574.798 369.913 424.096 325.142C233.926 268.499 368.93 197.723 127.597 177.548C6.59044 167.648 -9.19282 201.76 -12.4221 246.818C-13.8694 268.395 -12.3886 292.491 -18.3319 315.406C-24.8881 340.247 -39.9483 363.306 -75.4255 380.671C-145.632 415.037 -51.655 483.304 75.1041 535.38C202.9 587.641 364.461 624.068 426.752 597.702C474.632 577.213 554.397 581.258 625.467 584.895C735.989 590.787 825.212 598.642 744.632 507.711Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M730.681 507.208C608.345 368.235 832.13 393.441 659.595 351.948C514.626 317.084 565.79 372.06 421.047 329.228C238.722 275.102 366.112 207.927 136.243 189.093C20.9011 179.902 5.62823 212.634 2.24647 255.847C0.747606 276.507 2.02498 299.572 -3.76484 321.527C-10.1902 345.381 -24.6913 367.453 -58.3731 384.185C-125.449 417.506 -35.0992 482.684 86.4074 532.35C209.158 582.239 364.394 617.151 423.437 593.129C469.049 574.572 546.188 578.702 615.14 582.223C721.5 587.654 807.718 594.722 730.681 507.208Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M716.73 506.706C600.594 373.812 811.901 396.735 647.631 356.558C509.445 322.761 556.784 374.207 418 333.315C243.518 281.706 363.223 217.825 144.891 200.639C35.1765 192.003 20.4506 223.508 16.9165 264.877C15.366 284.62 16.4399 306.655 10.8037 327.649C4.50922 350.515 -9.43147 371.603 -41.3193 387.699C-105.265 419.978 -18.6425 482.187 97.7121 529.321C215.317 576.962 364.204 610.146 420.123 588.559C463.371 571.862 538.004 576.226 604.815 579.553C707.05 584.644 790.224 590.804 716.73 506.706Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M702.781 506.204C592.84 379.389 791.672 400.028 635.667 361.168C504.263 328.436 547.779 376.354 414.953 337.402C248.316 288.309 360.395 227.987 153.54 212.185C49.4829 204.236 35.115 234.351 31.5873 273.907C29.9097 292.717 30.9309 313.755 25.3728 333.771C19.2915 355.67 5.83061 375.754 -24.2647 391.213C-85.0804 422.452 -2.09675 481.584 109.017 526.292C221.564 571.577 364.014 603.142 416.81 583.987C457.694 569.153 529.821 573.749 594.491 576.881C692.601 581.634 772.728 586.887 702.781 506.204Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M688.83 505.7C585.08 384.968 771.441 403.319 623.703 365.778C499.079 334.11 538.772 378.5 411.905 341.487C253.112 294.911 357.566 238.148 162.187 223.73C63.788 216.468 49.9192 245.221 46.2568 282.936C44.5194 300.827 45.3537 320.838 39.941 339.892C33.9997 360.806 21.0934 379.907 -7.21118 394.726C-64.8961 424.929 14.4489 480.979 120.322 523.262C227.812 566.19 363.822 596.139 413.496 579.414C452.015 566.445 521.637 571.272 584.166 574.209C678.151 578.624 755.231 582.969 688.83 505.7Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M674.879 505.198C577.314 390.55 751.207 406.609 611.738 370.388C493.893 339.784 529.765 380.648 408.857 345.575C257.908 301.515 354.735 248.312 170.834 235.276C78.0919 228.703 64.7238 256.091 60.9259 291.966C59.1291 308.938 59.7751 327.923 54.5084 346.014C48.7066 365.943 36.3576 384.063 9.84155 398.241C-44.7119 427.409 30.9947 480.375 131.625 520.233C234.06 560.805 363.628 589.14 410.181 574.843C446.334 563.74 513.453 568.797 573.84 571.538C663.7 575.618 737.73 579.055 674.879 505.198Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M660.929 504.695C569.543 396.133 730.973 409.896 599.774 374.998C488.707 345.455 520.759 382.794 405.809 349.661C262.704 308.117 351.904 258.475 179.482 246.822C92.3963 240.936 79.5302 266.961 75.596 300.995C73.7403 317.048 74.197 335.006 69.0767 352.135C63.4139 371.079 51.6253 388.221 26.8954 401.754C-24.5256 429.893 47.5426 479.77 142.93 517.203C240.31 555.419 363.432 582.143 406.867 570.271C440.653 561.037 505.27 566.322 563.515 568.867C649.252 572.612 720.228 575.14 660.929 504.695Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M646.977 504.193C561.763 401.719 710.736 413.181 587.809 379.608C483.517 351.125 511.752 384.941 402.761 353.747C267.5 314.72 349.071 268.64 188.129 258.368C106.699 253.17 94.3365 277.831 90.2648 310.025C88.3508 325.158 88.6173 342.089 83.6441 358.257C78.1198 376.216 66.8952 392.383 43.9483 405.268C-4.33873 432.382 64.0908 479.164 154.234 514.174C246.561 550.032 363.23 575.149 403.552 565.699C434.967 558.337 497.086 563.849 553.189 566.195C634.802 569.609 702.722 571.228 646.977 504.193Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M633.027 503.69C553.976 407.308 690.498 416.463 575.846 384.218C478.327 356.792 502.699 387.209 399.714 357.834C272.238 321.473 346.238 278.806 196.776 269.913C121.002 265.405 109.145 288.701 104.935 319.054C102.963 333.269 103.038 349.173 98.2127 364.378C92.8261 381.352 82.1696 396.548 61.0022 408.782C15.8513 434.876 80.6415 478.557 165.538 511.144C252.813 544.644 363.023 568.16 400.238 561.128C429.278 555.64 488.903 561.376 542.864 563.524C620.354 566.608 685.214 567.317 633.027 503.69Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M619.076 503.187C546.178 412.901 670.256 419.742 563.881 388.829C473.134 362.457 493.691 389.363 396.666 361.92C277.033 328.084 343.403 288.974 205.424 281.459C135.304 277.64 123.955 299.571 119.604 328.084C117.576 341.379 117.457 356.256 112.781 370.5C107.531 386.488 97.4478 400.718 78.0553 412.296C36.0437 437.377 97.1931 477.949 176.842 508.115C259.067 539.256 362.802 561.176 396.924 556.556C423.578 552.947 480.719 558.905 532.538 560.853C605.907 563.61 667.7 563.409 619.076 503.187Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M605.127 502.684C538.368 418.497 650.014 423.016 551.918 393.438C467.939 368.117 484.684 391.515 393.619 366.007C281.829 334.693 340.568 299.143 214.072 293.004C149.606 289.876 138.767 310.441 134.275 337.113C132.19 349.489 131.877 363.339 127.35 376.621C122.236 391.624 112.733 404.893 95.1099 415.809C56.2421 439.885 113.748 477.34 188.148 505.085C265.324 533.866 362.562 554.195 393.611 551.984C417.865 550.257 472.538 556.436 522.214 558.181C591.461 560.614 650.182 559.503 605.127 502.684Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M591.175 502.182C530.541 424.098 629.767 426.285 539.953 398.048C462.74 373.774 475.676 393.667 390.57 370.093C286.624 341.301 337.728 309.315 222.719 304.55C163.904 302.113 153.58 321.311 148.944 346.143C146.804 357.6 146.293 370.422 141.917 382.743C136.938 396.76 128.023 409.074 112.162 419.323C76.4449 442.404 130.303 476.729 199.451 502.056C271.581 528.475 362.283 547.215 390.296 547.412C412.123 547.566 464.355 553.968 511.888 555.51C577.014 557.622 632.655 555.599 591.175 502.182Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M577.226 501.679C522.697 429.704 609.52 429.546 527.99 402.659C457.54 379.425 466.672 395.818 387.524 374.18C291.423 347.907 334.889 319.492 231.368 316.096C178.204 314.352 168.398 332.182 163.615 355.172C161.422 365.711 160.71 377.505 156.486 388.865C151.641 401.895 143.324 413.262 129.217 422.837C96.6597 444.934 146.864 476.116 210.757 499.027C277.843 523.082 361.952 540.222 386.983 542.841C406.341 544.866 456.175 551.503 501.564 552.838C562.571 554.633 615.121 551.699 577.226 501.679Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M563.275 501.176C514.828 435.316 589.267 432.798 516.025 407.268C452.335 385.068 457.666 397.966 384.475 378.266C296.22 354.511 332.045 329.673 240.015 327.641C192.499 326.593 183.217 343.053 178.284 364.202C176.04 373.822 175.124 384.587 171.054 394.986C166.725 406.882 158.315 417.525 146.27 426.35C116.887 447.48 163.426 475.5 222.061 495.997C284.107 517.686 361.548 533.191 383.668 538.269C400.504 542.133 447.994 549.04 491.238 550.167C548.127 551.649 597.573 547.802 563.275 501.176Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M549.323 500.674C506.928 440.934 569.013 436.039 504.06 411.878C447.128 390.702 448.661 400.113 381.426 382.353C301.019 361.113 329.197 339.861 248.661 339.187C206.792 338.836 198.041 353.925 192.953 373.231C190.659 381.933 189.535 391.668 185.621 401.107C181.41 411.871 173.869 421.596 163.323 429.864C137.135 450.046 179.991 474.88 233.364 492.967C290.374 512.287 361.104 526.087 380.353 533.697C394.639 539.345 439.814 546.581 480.912 547.495C533.684 548.669 580.007 543.91 549.323 500.674Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M535.373 500.171C498.99 446.559 548.761 439.263 492.096 416.488C441.921 396.322 439.659 402.257 378.379 386.439C305.821 367.71 326.347 350.061 257.309 350.733C221.084 351.085 212.872 364.797 207.623 382.261C205.283 390.045 203.944 398.749 200.19 407.229C196.102 416.864 189.426 425.675 180.377 433.378C157.42 452.638 196.562 474.255 244.669 489.938C296.647 506.883 360.731 518.914 377.039 529.125C388.825 536.505 431.637 544.127 470.587 544.824C519.245 545.695 562.418 540.023 535.373 500.171Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M521.423 499.668C491 452.192 528.514 442.466 480.132 421.099C436.718 401.925 430.658 404.397 375.332 390.526C310.625 374.303 323.493 360.278 265.957 362.278C235.373 363.342 227.711 375.672 222.293 391.29C219.91 398.158 218.35 405.829 214.758 413.351C210.803 421.86 204.986 429.763 197.431 436.892C177.759 455.264 213.139 473.623 255.974 486.909C302.926 501.471 360.512 511.739 373.726 524.553C383.121 533.666 423.462 541.678 460.262 542.153C504.809 542.728 544.794 536.141 521.423 499.668Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M507.474 499.165C482.94 457.831 508.285 445.641 468.169 425.708C431.53 407.503 421.66 406.53 372.285 394.612C315.431 380.888 320.642 370.523 274.606 373.824C249.662 375.612 242.563 386.549 236.964 400.32C234.544 406.272 232.752 412.907 229.328 419.472C225.514 426.86 220.549 433.863 214.486 440.405C198.19 457.938 229.723 472.98 267.279 483.879C309.213 496.048 360.41 504.617 370.413 519.981C377.5 530.868 415.291 539.235 449.938 539.481C490.377 539.768 527.119 532.264 507.474 499.165Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M493.523 498.663C474.779 463.474 488.096 448.783 456.205 430.319C426.38 413.051 412.663 408.655 369.237 398.699C320.239 387.464 317.804 380.823 283.253 385.37C263.956 387.91 257.43 397.433 251.633 409.35C249.183 414.389 247.147 419.984 243.896 425.594C239.784 432.691 235.545 438.658 231.539 443.919C218.782 460.677 246.311 472.321 278.584 480.85C315.505 490.607 360.341 497.551 367.099 515.41C371.904 528.111 407.123 536.803 439.613 536.81C475.948 536.818 509.358 528.39 493.523 498.663Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M479.572 498.161C466.473 469.111 468.001 451.893 444.241 434.929C421.321 418.566 403.667 410.766 366.19 402.785C325.048 394.024 315.042 391.223 291.901 396.916C278.292 400.263 272.321 408.325 266.303 418.379C263.832 422.509 261.532 427.057 258.464 431.716C254.448 437.814 251.079 442.953 248.593 447.433C239.674 463.503 262.902 471.636 289.888 477.821C321.801 485.134 360.263 490.518 363.784 510.839C366.304 525.379 398.963 534.383 429.288 534.139C461.526 533.879 491.455 524.512 479.572 498.161Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
                <path
                    d="M465.622 497.658C473.291 520.605 447.113 530.953 418.963 531.467C390.813 531.981 360.69 522.66 360.47 506.267C360.165 483.501 328.086 479.603 301.193 474.791C274.3 469.979 252.593 464.254 273.032 437.837C294.531 410.051 300.086 394.914 363.142 406.872C394.67 412.851 416.456 424.074 432.277 439.539C448.097 455.004 457.953 474.711 465.622 497.658Z"
                    stroke="#928D82"
                    stroke-miterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_2415_5105">
                    <rect
                        width="460.953"
                        height="1059.26"
                        fill="white"
                        transform="matrix(0.233055 -0.972464 -0.972464 -0.233055 815.088 695.125)" />
                </clipPath>
            </defs>
        </svg>

        <!-- Content -->
        <div
            class="max-w-container px-container mx-auto py-12 flex items-end flex-wrap z-10 relative">
            <div class="w-full text-left">
                <ContactForm
                    v-if="minimal === false"
                    v-observe-visibility="{
                        callback: handleContactVisibility,
                        throttle: 500,
                        intersection: { threshold: 0.1, rootMargin: '5%' },
                        once: true,
                    }"
                    :is-loading="isLoading"
                    :topics="topics"
                    :contacts="contacts">
                    <div
                        v-if="heading"
                        slot="heading"
                        v-html="heading" />

                    <div
                        v-if="content"
                        v-html="content" />
                </ContactForm>
                <div class="flex justify-between items-end">
                    <!-- Logo -->
                    <svg
                        width="128"
                        height="138"
                        viewBox="0 0 128 138"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2415_5150)">
                            <path
                                d="M69.3306 122.569C68.7194 122.569 67.9043 121.757 67.9043 121.148L67.9043 116.275C67.9043 115.665 68.7194 114.853 69.3306 114.853L126.574 114.853C127.185 114.853 128 115.665 128 116.275L128 121.148C128 121.757 127.185 122.569 126.574 122.569L69.3306 122.569Z"
                                fill="url(#paint0_linear_2415_5150)" />
                            <path
                                d="M69.3306 138C68.7194 138 67.9043 137.188 67.9043 136.579L67.9043 131.706C67.9043 131.097 68.7194 130.284 69.3306 130.284L126.574 130.284C127.185 130.284 128 131.097 128 131.706L128 136.579C128 137.188 127.185 138 126.574 138L69.3306 138Z"
                                fill="url(#paint1_linear_2415_5150)" />
                            <path
                                d="M106.27 65.2089L89.5655 63.9906C82.3337 63.4828 79.8889 60.3359 79.8889 56.6809C79.8889 50.1836 86.3061 45.2093 96.7972 45.2093C108.715 45.2093 112.993 50.59 113.502 55.869C113.604 57.2901 114.011 57.5947 115.03 57.5947L121.039 57.5947C122.16 57.5947 122.567 57.0873 122.567 56.5795C122.567 46.1231 115.743 37.4937 96.7972 37.4937C78.9722 37.4937 70.9258 47.4428 70.9258 56.6809C70.9258 65.2089 76.4256 70.6911 88.7504 71.7062L105.76 72.9241C114.011 73.5333 116.15 77.3911 116.15 81.0461C116.15 87.4425 111.668 92.7214 99.0381 92.7214C85.1854 92.7214 79.8889 87.848 79.8889 80.3351C79.8889 79.4214 79.5833 78.914 78.4631 78.914L72.1477 78.914C71.2314 78.914 70.9258 79.3204 70.9258 80.0305L70.9258 81.0461C70.9258 91.7058 80.3985 100.437 99.0381 100.437C118.187 100.437 125.113 89.2691 125.113 81.0461C125.113 73.1273 120.224 66.3254 106.27 65.2089Z"
                                fill="url(#paint2_linear_2415_5150)" />
                            <path
                                d="M58.7369 37.9041L1.49367 37.9041C0.882469 37.9041 0.0673828 38.7164 0.0673828 39.3256L0.0673828 44.1986C0.0673828 44.8078 0.882469 45.6197 1.49367 45.6197L26.2447 45.6197L26.1774 77.6234C26.1774 78.2326 26.9921 79.045 27.6032 79.045L32.4924 79.045C33.1036 79.045 33.9187 78.2326 33.9187 77.6234L33.9859 45.6197L58.7369 45.6197C59.3481 45.6197 60.1632 44.8078 60.1632 44.1986L60.1632 39.3256C60.1632 38.7164 59.3481 37.9041 58.7369 37.9041Z"
                                fill="url(#paint3_linear_2415_5150)" />
                            <path
                                d="M1.42628 23.1468C0.815086 23.1468 -4.13991e-09 22.3349 -3.77461e-09 21.7257L-8.52465e-10 16.8527C-4.87162e-10 16.2435 0.815086 15.4312 1.42628 15.4312L58.6695 15.4312C59.2807 15.4312 60.0958 16.2435 60.0958 16.8527L60.0958 21.7257C60.0958 22.3349 59.2807 23.1468 58.6695 23.1468L1.42628 23.1468Z"
                                fill="url(#paint4_linear_2415_5150)" />
                            <path
                                d="M1.42628 7.71563C0.815086 7.71563 -4.13991e-09 6.9037 -3.77461e-09 6.29452L-8.52465e-10 1.42157C-4.87162e-10 0.812391 0.815086 4.88779e-10 1.42628 8.55293e-10L58.6695 3.5182e-08C59.2807 3.55486e-08 60.0958 0.812391 60.0958 1.42157L60.0958 6.29452C60.0958 6.9037 59.2807 7.71563 58.6695 7.71563L1.42628 7.71563Z"
                                fill="url(#paint5_linear_2415_5150)" />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_2415_5150"
                                x1="86.7796"
                                y1="107.745"
                                x2="-8.26422"
                                y2="13.8419"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00358E" />
                                <stop
                                    offset="0.99871"
                                    stop-color="#2C2E35" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_2415_5150"
                                x1="79.0393"
                                y1="115.58"
                                x2="-16.0049"
                                y2="21.6761"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00358E" />
                                <stop
                                    offset="0.99871"
                                    stop-color="#2C2E35" />
                            </linearGradient>
                            <linearGradient
                                id="paint2_linear_2415_5150"
                                x1="111.426"
                                y1="82.7999"
                                x2="16.382"
                                y2="-11.1034"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00358E" />
                                <stop
                                    offset="0.99871"
                                    stop-color="#2C2E35" />
                            </linearGradient>
                            <linearGradient
                                id="paint3_linear_2415_5150"
                                x1="90.0247"
                                y1="104.461"
                                x2="-5.01933"
                                y2="10.5577"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00358E" />
                                <stop
                                    offset="0.99871"
                                    stop-color="#2C2E35" />
                            </linearGradient>
                            <linearGradient
                                id="paint4_linear_2415_5150"
                                x1="103.22"
                                y1="91.1055"
                                x2="8.17569"
                                y2="-2.79792"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00358E" />
                                <stop
                                    offset="0.99871"
                                    stop-color="#2C2E35" />
                            </linearGradient>
                            <linearGradient
                                id="paint5_linear_2415_5150"
                                x1="110.96"
                                y1="83.2713"
                                x2="15.9159"
                                y2="-10.6321"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00358E" />
                                <stop
                                    offset="0.99871"
                                    stop-color="#2C2E35" />
                            </linearGradient>
                            <clipPath id="clip0_2415_5150">
                                <rect
                                    width="128"
                                    height="138"
                                    fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div class="tracking-wide">
                        <a :href="urls.tou"> Terms of Use </a>
                        <div v-if="!minimal" class="inline">
                            <span class="text-blue-medium mx-4">|</span>
                            <a :href="urls.sitemanager"> Admin </a>
                            <span class="text-blue-medium mx-4">|</span>
                            <a :href="urls.logout"> Log Out </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SystemNotifications />
    </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ContactForm from 'Components/Redesign/Homepage/ContactForm'
import SystemNotifications from 'Components/Redesign/SystemNotifications/NotificationsModal'

export default {
    name: 'BaseFooter',
    components: {
        ContactForm,
        SystemNotifications,
    },
    props: {
        minimal: {
            default: false,
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapState(['urls']),
        ...mapState('contactForm', [
            'isLoading',
            'topics',
            'contacts',
            'content',
            'heading',
        ]),
    },
    methods: {
        ...mapActions('contactForm', ['loadSection']),
        handleContactVisibility(isVisible) {
            if (isVisible) {
                this.loadSection()
            }
        },
    },
}
</script>
