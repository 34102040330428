<template>
    <div
        class="bg-white flex flex-col items-stretch gap-y-1 h-full rounded-lg shadow-card">
        <div class="assetCard__body">
            <div class="flex justify-between items-center p-4">
                <div>
                    <input
                        v-if="isSelectable"
                        :id="`select-${asset.asset_id}`"
                        v-model="model"
                        :name="`selected-${asset.asset_id}`"
                        type="checkbox"
                        class="assetCard--select !bg-blue-lightest checked:!bg-blue-dark h-6 w-6"
                        @click="updateSelected($event)" />

                    <label
                        v-if="isSelectable && selectLabel"
                        class="text-xs cursor-pointer ml-1"
                        :for="`select-${asset.asset_id}`"
                        v-text="selectLabel" />
                </div>

                <span
                    :class="getStatusClasses()"
                    v-text="statusText" />
            </div>

            <div class="px-4 text-center">
                <AssetThumbnail :asset="asset" />
            </div>

            <slot
                name="thumbnailFooter"
                v-bind="{ asset }" />

            <div class="assetCard__content p-4">
                <div class="assetCard__heading">
                    <span
                        v-if="asset.association === 'brand'"
                        class="assetCard__brandName">
                        {{ brandOrPropertyName }}
                    </span>
                    <span
                        v-if="asset.association === 'property'"
                        class="font-swiss font-[14px] text-[#222] leading-5">
                        {{ brandOrPropertyName }}
                    </span>

                    <span
                        v-if="asset.caption && !brandOrPropertyName"
                        class="font-swiss font-[14px] text-[#222] leading-5">
                        {{ asset.caption }}
                    </span>

                    <div class="flex gap-2 items-center text-sm">
                        <span>
                            {{ asset.filename }}
                        </span>
                        <span class="text-blue-medium">|</span>
                        <span v-if="asset.links.data.edit">
                            <ButtonStripped
                                :href="asset.links.data.edit"
                                text-size="text-sm"
                                padding="px-0"
                                class="font-bold text-blue hover:text-blue-darker">
                                edit
                            </ButtonStripped>
                        </span>
                    </div>

                    <span
                        v-if="assetType == 'Logo'"
                        class="assetCard__details">
                        {{ color }}
                    </span>

                    <span
                        v-if="assetType === 'Template'"
                        class="assetCard__details">
                        {{ templateType }}
                    </span>

                    <span
                        v-if="asset.subject"
                        class="font-swiss font-[14px] text-[#222] leading-5">
                        {{ asset.subject.data.name }}
                    </span>
                    <span
                        v-if="dacFileName"
                        class="text-2xs">
                        DAC File: {{ dacFileName }}
                    </span>

                    <span v-if="asset.studio_link">
                        <ButtonStripped
                            :href="asset.studio_link"
                            padding="px-0"
                            :has-arrow="true"
                            arrow-color="mediumblue"
                            text-size="text-sm"
                            text-color="text-blue"
                            class="font-bold hover:text-blue-darker mt-2">
                            <span v-text="studioLinkText" />
                        </ButtonStripped>
                    </span>
                </div>

                <div class="assetCard__footer">
                    <dl class="text-slate-light text-xs">
                        <div class="assetCard__date">
                            <dt>Date Created</dt>
                            <dd>{{ formattedDateCreated }}</dd>
                        </div>
                        <div class="assetCard__date">
                            <dt>Date Updated</dt>
                            <dd>{{ formattedDateUpdated }}</dd>
                        </div>
                        <div
                            v-if="isAdmin"
                            class="assetCard__toggle">
                            <dt>Visible to API</dt>
                            <dd>
                                <toggle-switch
                                    :id="`visible_to_api_${asset.asset_id}`"
                                    v-model="visibleToApi"
                                    name="visible_to_api"
                                    :input-value="true"
                                    :unchecked-value="false"
                                    @input="toggleApiVisibility()" />
                            </dd>
                        </div>
                    </dl>

                    <slot name="footer-extras" />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 border-t-2 border-blue-lighter">
            <div
                v-if="showViewLink"
                class="flex items-center justify-center border-r-2 border-blue-lighter p-4">
                <ButtonStripped
                    :href="asset.links.data.view"
                    :has-arrow="true"
                    text-size="text-sm"
                    arrow-color="mediumblue"
                    text-color="text-blue"
                    padding="px-0"
                    class="font-bold">
                    View
                </ButtonStripped>
            </div>
            <div class="flex items-center justify-center p-2">
                <AssetDownloads
                    :asset="asset"
                    :extra-drawer-classes="['py-4']"
                    icon-style="light" />
            </div>
        </div>
    </div>
</template>

<script>
import Drawer from 'Components/Redesign/Drawer'
import AssetThumbnail from 'Components/Redesign/Asset/AssetThumbnail'
import AssetDownloads from 'Components/Redesign/Asset/AssetDownloads'
import AssetMixins from 'Components/Redesign/Mixins/AssetMixins'
import ToggleSwitch from 'Components/Redesign/ToggleSwitch'
import ButtonStripped from 'Components/Redesign/Button/ButtonStripped'

const DATE_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}

export default {
    name: 'AssetCard',
    components: {
        Drawer,
        AssetThumbnail,
        AssetDownloads,
        ToggleSwitch,
        ButtonStripped,
    },
    mixins: [AssetMixins],
    props: {
        asset: {
            required: true,
            type: Object,
        },
        selected: {
            required: false,
            type: Boolean,
        },
        isSelectable: {
            required: false,
            type: Boolean,
            default: true,
        },
        selectLabel: {
            required: false,
            type: String,
            default: '',
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
        showViewLink: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isSelected: false,
        }
    },
    computed: {
        model: {
            get() {
                return this.selected
            },
            set(isSelected) {
                this.isSelected = isSelected
            },
        },
        brandOrPropertyName() {
            if (this.asset.association === 'brand') {
                return this.asset.property?.data.brand?.name
            }

            return this.asset.property?.data.name
        },
        studioLinkText() {
            if (this.asset.studio_link_text) {
                return this.asset.studio_link_text
            }

            return 'Create on MI Studio'
        },
    },
    methods: {
        updateSelected(event) {
            this.$emit('input', event.target.checked)
        },
    },
}
</script>
