<template>
    <BaseButton
        class="rounded-full font-bold md:w-auto leading-tight cursor-pointer transition-colors"
        :class="buttonClasses"
        :href="href"
        @click="$emit('click', $event)"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave">
        <slot />
        <IconArrowRight
            v-if="hasArrow"
            :icon-color="arrowColor"
            :icon-size="arrowSize"
            :class="hoverIconClass" />
    </BaseButton>
</template>

<script>
import BaseButton from '../Base/BaseButton.vue'
import IconArrowRight from '../Icons/IconArrowRight.vue'

export default {
    name: 'ButtonStripped',
    components: {
        BaseButton,
        IconArrowRight,
    },
    props: {
        href: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: null,
            validator: (val) =>
                ['_blank', '_parent', '_self', '_top'].includes(val),
        },
        textSize: {
            type: String,
            default: 'text-base',
        },
        padding: {
            type: String,
            default: 'px-6 py-2',
        },
        textColor: {
            type: String,
            default: 'text-blue',
        },
        arrowColor: {
            type: String,
            default: 'blue',
            validator: (val) =>
                [
                    'white',
                    'blue',
                    'lightblue',
                    'mediumblue',
                    'gray',
                    'darkblue',
                ].includes(val),
        },
        arrowSize: {
            type: String,
            default: 'medium',
            validator: (val) => ['small', 'medium', 'large'].includes(val),
        },
        hasArrow: {
            type: Boolean,
            default: false,
        },
        underline: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClasses() {
            return [
                this.textSize,
                this.padding,
                this.underline
                    ? 'underline decoration-blue-medium transition-colors decoration-2'
                    : 'no-underline',
                this.hasArrow ? 'flex gap-2 items-center group' : '',
                this.textColor,
                this.hoverClass,
            ]
        },
        hoverClass() {
            return this.textColor === 'text-white'
                ? 'hover:text-blue-light'
                : 'hover:text-blue-darker'
        },
        hoverIconClass() {
            return this.arrowColor === 'white' ||
                this.arrowColor === 'lightblue'
                ? 'transition-colors group-hover:text-blue-light'
                : 'transition-colors group-hover:text-blue-darker'
        },
    },
    methods: {
        mouseEnter() {
            this.$emit('mouseenter')
        },
        mouseLeave() {
            this.$emit('mouseleave')
        },
    },
}
</script>
