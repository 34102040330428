<template>
    <section class="bg-blue-lighter py-16">
        <div
            v-if="!associations.length"
            class="h-96 w-full animate-pulse" />
        <div
            v-if="associations"
            class="max-w-container px-container mx-auto">
            <h2 class="text-6xl text-gray font-aldine italic">
                Assets from Your Brands and Properties
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-9 mt-12">
                <div
                    class="col-span-3 self-start h-auto bg-blue-lightest rounded-tl-xl rounded-bl-xl p-4 mt-8">
                    <BaseSidebar
                        title="Brands"
                        :items="brands"
                        :selected-item="selectedItem"
                        @item-selected="selectItem" />

                    <BaseSidebar
                        title="Properties"
                        :items="properties"
                        class="mt-8"
                        :selected-item="selectedItem"
                        @item-selected="selectItem" />
                </div>

                <div
                    class="md:col-start-4 col-span-full grid gap-4"
                    v-if="selectedItem">
                    <BasePanel class="flex flex-col gap-8">
                        <img
                            v-if="logoUrl"
                            :src="logoUrl"
                            :alt="selectedItem.name"
                            class="h-20 w-80 object-contain object-left" />

                        <div class="flex gap-4 items-center flex-wrap">
                            <ButtonPrimarySmall
                                text-size="text-xs"
                                class="flex gap-4 items-center"
                                v-for="(link, i) in selectedItem.links.data"
                                :key="i"
                                :href="link">
                                {{ i }}
                                <IconArrowRight icon-color="white" />
                            </ButtonPrimarySmall>
                        </div>

                        <BpAccordion
                            bg-color-closed="bg-white border border-blue"
                            v-if="selectedItem.brand_id">
                            <template #heading>
                                <h5
                                    class="text-2xl text-blue font-swiss font-bold">
                                    Collateral
                                </h5>
                            </template>
                            <div class="flex flex-wrap gap-3 pb-3">
                                <div
                                    v-for="(item, i) in collateralLinks"
                                    :key="i">
                                    <ButtonOutline
                                        text-color="text-black"
                                        text-size="text-xs"
                                        border-color="border-yellow hover:border-yellow-500"
                                        :href="item.url"
                                        class="inline-block">
                                        {{ item.name }}
                                    </ButtonOutline>
                                </div>
                            </div>
                        </BpAccordion>
                        <BpAccordion
                            bg-color-closed="bg-white border border-blue"
                            v-if="selectedItem.brand_id">
                            <template #heading>
                                <h5
                                    class="text-2xl text-blue font-swiss font-bold">
                                    Guidelines
                                </h5>
                            </template>
                            <div
                                class="grid grid-cols-1 md:grid-cols-2 gap-3 py-3">
                                <span v-if="!guidelines"
                                    >Sorry, no Guidelines were found</span
                                >
                                <div
                                    v-for="(
                                        category, i
                                    ) in groupedByCategoryGuidelines"
                                    :key="i">
                                    <!-- <strong v-text="category.name" />
                                    <p v-text="category.description" />
                                    <pre v-text="category.items" /> -->
                                    <ToolkitTiles
                                        v-for="(item, index) in category.items"
                                        :key="'item_' + index"
                                        :toolkit="item" />
                                </div>
                            </div>
                        </BpAccordion>
                        <BpAccordion
                            bg-color-closed="bg-white border border-blue"
                            v-if="collections">
                            <template #heading>
                                <h5
                                    class="text-2xl text-blue font-swiss font-bold">
                                    Collections
                                </h5>
                            </template>
                            <div
                                class="grid grid-cols-1 md:grid-cols-2 gap-3 py-3">
                                <CollectionTilesLight
                                    v-for="(collection, index) in collections"
                                    :key="'collection_' + index"
                                    :collection="collection" />
                            </div>
                        </BpAccordion>
                    </BasePanel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { orderBy, groupBy, uniqBy } from 'lodash'

import { getCollateral, getGuidelines } from 'Composables/useBrands.js'

import BasePanel from '../Base/BasePanel.vue'
import BaseSidebar from '../Base/BaseSidebar.vue'
import BpAccordion from '../BpAccordion/index.vue'
import ButtonOutline from '../Button/ButtonOutline.vue'
import ButtonPrimarySmall from '../Button/ButtonPrimarySmall.vue'
import CollectionTilesLight from '../Tiles/CollectionTilesLight.vue'
import IconArrowRight from '../Icons/IconArrowRight.vue'
import ToolkitTiles from '../Tiles/ToolkitTiles.vue'

export default {
    name: 'Assets',
    components: {
        BasePanel,
        BaseSidebar,
        BpAccordion,
        ButtonOutline,
        ButtonPrimarySmall,
        CollectionTilesLight,
        IconArrowRight,
        ToolkitTiles,
    },
    data() {
        return {
            selectedItem: null,
            collateral: null,
            guidelines: null,
            cache: {
                guidelines: {},
                collateral: {},
            },
        }
    },
    mounted() {
        // Initialize with default brand data
    },
    computed: {
        ...mapState('user', ['associations', 'user']),
        ...mapState('options', ['templateTypeCategories']),
        brands() {
            return this.associations.filter(
                (association) => association.brand_id
            )
        },
        properties() {
            return this.associations.filter(
                (association) => association.property_id
            )
        },
        logoUrl() {
            return this.selectedItem?.logo?.data?.path
        },
        collections() {
            return this.selectedItem.collections?.data
        },
        collateralLinks() {
            return this.templateTypeCategories.map((category) => {
                return {
                    name: category.name,
                    url: `/assets/collateral/${this.selectedItem.brand_id}#${category.slug}`,
                }
            })
        },
        groupedByCategoryGuidelines() {
            if (!this.guidelines) {
                return []
            }

            let categories = this.guidelines.map((guideline) => {
                let category = {
                    id: guideline.guideline_category.data.id,
                    name: guideline.guideline_category.data.name,
                    description: guideline.guideline_category.data.description,
                    order: guideline.guideline_category.data.category_order,
                }

                if (!category.items) {
                    category.items = []
                }

                // delete guideline.guideline_category

                category.items.push(structuredClone(guideline))

                return category
            })

            categories = orderBy(
                uniqBy(categories, (category) => category.id),
                (category) => category.category_order
            )

            categories = categories.map((category) => {
                category.items = orderBy(
                    category.items,
                    (item) => item.guideline_category.display_order
                )

                return category
            })

            return categories
        },
    },
    methods: {
        ...mapActions('user', ['loadAssociations']),
        ...mapActions('options', [
            'loadAssetTypes',
            'loadTemplateTypeCategories',
        ]),
        selectItem(item) {
            this.selectedItem = item
        },
        async loadGuidelines(brandId) {
            if (this.cache.guidelines[brandId]) {
                this.guidelines = this.cache.guidelines[brandId]
                return
            }

            try {
                const response = await getGuidelines(brandId)

                if (!response.data.data) {
                    this.cache.guidelines[brandId] = null
                }

                this.cache.guidelines[brandId] = response.data.data

                this.guidelines = this.cache.guidelines[brandId]
            } catch (error) {
                console.error(error)
            }
        },
        fetchCollections(association) {},
    },
    mounted() {
        this.loadTemplateTypeCategories()
        this.loadAssociations()
    },
    watch: {
        associations(newAssociations) {
            if (newAssociations) {
                this.selectItem(newAssociations[0])
            }
        },
        selectedItem(newItem) {
            if (newItem.brand_id) {
                this.loadGuidelines(newItem.brand_id)
            }
        },
    },
}
</script>
