<script>
import axios from 'axios'

import Spinner from 'Components/Redesign/Spinner'
import RadioTab from 'Components/Redesign/RadioTab'
import ButtonPrimarySmall from 'Components/Redesign/Button/ButtonPrimarySmall'

export default {
    components: {
        Spinner,
        RadioTab,
        ButtonPrimarySmall,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            pausing: false,
            deleting: false,
            updating: false,
            settingSchedule: false,
            selectedSchedule: null,
            schedules: [
                { label: 'Daily Email', value: 'daily' },
                { label: 'Weekly Email', value: 'weekly' },
                { label: 'Biweekly', value: 'semi_monthly' },
                { label: 'Monthly Email', value: 'monthly' },
            ],
        }
    },
    computed: {
        notificationIsPaused() {
            return !this.notification.schedule.length
        },
        showPills() {
            if (this.notification.regions.length) {
                return true
            }
            if (this.notification.asset_types.length) {
                return true
            }
            return false
        },
        showPillSeparator() {
            if (
                this.notification.regions.length &&
                this.notification.asset_types.length
            ) {
                return true
            }
            return false
        },
    },
    methods: {
        truncate(text, length) {
            if (text.length > length) {
                text = text.slice(0, length) + '...'
            }

            return text
        },
        async deleteItem() {
            this.deleting = true
            await this.$store.dispatch(
                'accountNotifications/deleteNotification',
                this.notification.id
            )
            await this.$store.dispatch(
                'accountNotifications/fetchNotifications'
            )
            this.deleting = false
        },
        async pauseItem() {
            this.pausing = true
            await this.$store.dispatch(
                'accountNotifications/updateNotification',
                {
                    notification: {
                        schedule: null,
                    },
                    id: this.notification.id,
                }
            )
            await this.$store.dispatch(
                'accountNotifications/fetchNotifications'
            )
            this.pausing = false
        },
        async updateSchedule() {
            this.updating = true
            await this.$store.dispatch(
                'accountNotifications/updateNotification',
                {
                    notification: {
                        schedule: this.selectedSchedule,
                    },
                    id: this.notification.id,
                }
            )
            await this.$store.dispatch(
                'accountNotifications/fetchNotifications'
            )
            this.updating = false
            this.closeScheduleDialog()
        },
        editNotification() {
            this.$store.dispatch(
                'accountNotifications/setNotificationToUpdate',
                this.notification
            )
            const form = document.querySelector('h1')

            form.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        },
        openScheduleDialog() {
            this.$refs.modal.showModal()
        },
        closeScheduleDialog() {
            this.$refs.modal.close()
        },
    },
}
</script>

<template>
    <div class="accountNotification border-b-2 border-blue-medium/20 py-8">
        <div
            class="py-2 px-6 mb-6 bg-blue-medium/50 rounded text-gray text-sm inline-flex gap-6">
            <strong class="item">
                {{ notification.filterType }} Notification
            </strong>
            <span
                v-if="notification.schedule == 'daily'"
                class="item"
                ><i class="far fa-calendar" /> Daily email sent each day after
                5pm</span
            >
            <span
                v-if="notification.schedule == 'weekly'"
                class="item"
                ><i class="far fa-calendar" /> Weekly email sent on Sundays
                after 5pm</span
            >
            <span
                v-if="notification.schedule == 'semi_monthly'"
                class="item"
                ><i class="far fa-calendar" /> Bi-weekly email sent on 1st and
                15th of the month</span
            >
            <span
                v-if="notification.schedule == 'monthly'"
                class="item"
                ><i class="far fa-calendar" /> Monthly email sent on the first
                day of the month</span
            >
        </div>
        <h4 class="mb-4 text-3xl text-blue-darkest font-swiss">
            {{ notification.name }}
        </h4>
        <div class="groups pillList --primary mb-4">
            <span
                v-for="brand in notification.brands"
                :key="brand.id"
                class="text-blue-darkest bg-coral-medium rounded-full text-base px-6 py-2">
                {{ truncate(brand.name, 23) }}
            </span>
            <span
                v-for="property in notification.properties"
                :key="property.id"
                class="text-blue-darkest bg-green-light rounded-full text-base px-6 py-2">
                {{ truncate(property.name, 23) }}
            </span>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
                <div
                    v-if="showPills"
                    class="regions pillList">
                    <span
                        v-for="region in notification.regions"
                        :key="region.id"
                        class="text-blue-darkest border-2 border-yellow rounded-full text-xs px-6 py-2">
                        {{ region.name }}
                    </span>
                    <span
                        v-if="showPillSeparator"
                        class="text-blue-medium">
                        ●
                    </span>
                    <span
                        v-for="type in notification.asset_types"
                        :key="type.id"
                        class="text-blue-darkest border-2 border-yellow rounded-full text-xs px-6 py-2">
                        {{ type.name }}
                    </span>
                </div>
            </div>
            <div class="actions justify-self-end">
                <div
                    v-if="notificationIsPaused"
                    class="flex gap-4">
                    <button
                        class="bg-red-100 whitespace-nowrap rounded-full py-1 px-4 text-sm text-red-900 font-bold"
                        @click="deleteItem">
                        <i class="far fa-trash mr-1" />
                        Delete
                        <Spinner
                            v-if="deleting"
                            class="ml-2 inline-block w-4 h-4" />
                    </button>
                    <button
                        class="bg-green-100 whitespace-nowrap rounded-full py-1 px-4 text-sm text-green-900 font-bold"
                        @click="openScheduleDialog">
                        <i class="far fa-play mr-1" />
                        Resume
                    </button>
                </div>
                <div
                    v-else
                    class="flex gap-2">
                    <button
                        class="bg-white whitespace-nowrap rounded-full py-1 px-4 text-[13px] text-gray font-bold"
                        @click="pauseItem">
                        <i class="far fa-pause mr-1" />
                        <span>Pause</span>
                        <Spinner
                            v-if="pausing"
                            class="ml-2 inline-block w-4 h-4" />
                    </button>
                    <button
                        class="bg-white whitespace-nowrap rounded-full py-1 px-4 text-[13px] text-danger font-bold"
                        @click="deleteItem">
                        <i class="far fa-trash mr-1" />
                        Delete
                        <Spinner
                            v-if="deleting"
                            class="ml-2 inline-block w-4 h-4" />
                    </button>
                    <button
                        class="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 flex gap-4 items-center rounded-full bg-blue text-white font-medium w-full md:w-auto text-center no-underline hover:text-white hover:bg-blue-medium transition-colors text-[13px] px-6 py-2"
                        @click="editNotification">
                        Edit This Notification
                        <svg
                            class="w-4 h-4 inline-block ml-2"
                            viewBox="0 0 512 512">
                            <path
                                fill="currentColor"
                                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <dialog ref="modal">
            <h4 class="text-center mb-4">Resume {{ notification.name }}</h4>
            <RadioTab
                v-model="selectedSchedule"
                :options="schedules"
                name="schedule"
                class="mb-4" />
            <div class="flex justify-center gap-2">
                <button
                    class="bg-red-100 text-red-900 whitespace-nowrap rounded-full py-1 px-4 text-sm flex items-center ml-2 font-bold"
                    @click="closeScheduleDialog">
                    Cancel
                </button>
                <button
                    class="bg-blue-900 text-white whitespace-nowrap rounded-full py-1 px-4 text-sm flex items-center ml-2 font-bold"
                    :disabled="!selectedSchedule"
                    @click="updateSchedule">
                    Save
                    <Spinner
                        v-if="updating"
                        class="ml-2 inline-block w-4 h-4 text-white" />
                </button>
            </div>
        </dialog>
    </div>
</template>
