<template>
    <div>
        <div
            v-if="getActive.length"
            id="manage"
            class="max-w-container px-container mx-auto py-16">
            <div>
                <div class="fullWidth__heading pb-4">
                    <h3
                        class="text-blue-darkest font-swiss font-bold text-5xl leading-tight">
                        Active notifications
                    </h3>
                </div>
                <hr class="hidden md:block border-t-2 border-blue-medium" />
                <div>
                    <transition-group name="list">
                        <AccountNotificationsNotification
                            v-for="notification in getActive"
                            :key="notification.id"
                            :notification="notification" />
                    </transition-group>
                </div>
            </div>
        </div>
        <div
            v-if="getPaused.length"
            class="fullWidth pageSection">
            <div>
                <div class="fullWidth__heading">
                    <h3
                        class="text-blue-darkest font-swiss font-bold text-5xl leading-tight">
                        Paused notifications
                    </h3>
                </div>
                <hr class="hidden md:block border-t-2 border-blue-medium" />
                <div>
                    <transition-group name="list">
                        <AccountNotificationsNotification
                            v-for="notification in getPaused"
                            :key="notification.id"
                            :notification="notification" />
                    </transition-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AccountNotificationsNotification from './AccountNotificationsNotification.vue'

export default {
    components: {
        AccountNotificationsNotification,
    },
    props: {
        initial: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapState('accountNotifications', ['notifications']),
        getActive() {
            if (!this.notifications) {
                return []
            }

            return this.notifications.filter((item) => {
                return item.schedule.length
            })
        },
        getPaused() {
            if (!this.notifications) {
                return []
            }

            return this.notifications.filter((item) => {
                return !item.schedule.length
            })
        },
    },
    mounted() {
        this.$store.dispatch('accountNotifications/fetchNotifications')
    },
}
</script>
