import axios from 'axios'
import moment from 'moment'

import {
    ACCOUNT_LIST_UPDATE_NOTIFICATIONS,
    ACCOUNT_LIST_ASSET_NOTIFICATIONS,
    ACCOUNT_LIST_DEACTIVATIONS,
    ACCOUNT_LIST_DOWNLOADS,
    ACCOUNT_UPDATE_ASSET_NOTIFICATIONS,
} from 'Root/endpoints'

export const DATE_FORMAT = moment.ISO_8601

export async function getAssetNotifications({ start, end, page }) {
    return axios.get(ACCOUNT_LIST_ASSET_NOTIFICATIONS, {
        params: { start, end, page },
    })
}

export async function getUpdateNotifications(from = '1 week ago') {
    return axios.get(ACCOUNT_LIST_UPDATE_NOTIFICATIONS, { params: { from } })
}

export async function getDeactivations(month = null) {
    return await axios.get(ACCOUNT_LIST_DEACTIVATIONS, { params: { month } })
}

export async function getDownloads() {
    return await axios.get(ACCOUNT_LIST_DOWNLOADS)
}

export async function markSeen(notification) {
    notification.seen = true

    return axios.put(
        `${ACCOUNT_UPDATE_ASSET_NOTIFICATIONS}/${notification.id}`,
        {
            notification: {
                id: notification.id,
                seen_at: moment().format('YYYY-MM-DD HH:mm'),
            },
        }
    )
}

export async function getUnreadAssetNotifications({ page = null } = {}) {
    return axios.get('/api/account/notifications/assets/unread', {
        params: { page },
    })
}

export async function getReadAssetNotifications({ page = 1 }) {
    return axios.get('/api/account/notifications/assets/read', {
        params: { page },
    })
}

export function formatNotificationDate(date) {
    const now = moment()

    date = moment(date, DATE_FORMAT)

    if (now.isSame(date, 'day')) {
        return 'Today'
    }

    if (date.isAfter(moment().subtract(2, 'day'))) {
        return 'Yesterday'
    }

    if (date.isAfter(moment().subtract(1, 'week'))) {
        return date.fromNow()
    }

    return date.format('MMMM Do, YYYY')
}

export default {
    formatNotificationDate,
    getAssetNotifications,
    getDeactivations,
    getDownloads,
    getReadAssetNotifications,
    getUnreadAssetNotifications,
    getUpdateNotifications,
    markSeen,
}
