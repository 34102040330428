<template>
    <div
        v-if="stacked"
        class="flex items-center space-x-4">
        <!-- Render stacked thumbnails -->
        <div class="flex relative">
            <!-- eslint-disable-next-line -->
            <slot name="stacked-thumbnails">
                <img
                    v-for="(thumb, index) in cappedThumbnails"
                    :key="index"
                    :src="thumb"
                    alt=""
                    :style="{ transform: `translateX(${index * -1}rem)` }"
                    class="w-12 h-12 rounded-full" />
            </slot>
            <div
                v-if="remainingThumbnailsCount > 0"
                class="absolute top-0 right-0 bg-blue-medium text-blue font-bold rounded-full w-12 h-12 text-xl flex items-center justify-center"
                :class="separation">
                +{{ remainingThumbnailsCount }}
            </div>
        </div>
    </div>
    <div
        v-else
        class="flex gap-4 items-center">
        <div
            v-if="titleType === 'added'"
            class="bg-coral w-12 h-12 rounded-full flex items-center justify-center translate-x-8">
            <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none">
                <path
                    d="M4.64844 6.47656L2.77344 4.60156C2.38281 4.25 2.38281 3.66406 2.77344 3.3125C3.125 2.92188 3.71094 2.92188 4.0625 3.3125L5.9375 5.1875C6.32812 5.53906 6.32812 6.125 5.9375 6.47656C5.78125 6.67188 5.54688 6.75 5.3125 6.75C5.03906 6.75 4.80469 6.67188 4.64844 6.47656ZM14.6875 6.75C14.4141 6.75 14.1797 6.67188 14.0234 6.47656C13.6328 6.125 13.6328 5.53906 14.0234 5.1875L15.8984 3.3125C16.25 2.92188 16.8359 2.92188 17.1875 3.3125C17.5781 3.66406 17.5781 4.25 17.1875 4.60156L15.3125 6.47656C15.1562 6.67188 14.9219 6.75 14.6875 6.75ZM15.3125 14.5234L17.1875 16.4375C17.5781 16.7891 17.5781 17.375 17.1875 17.7266C16.8359 18.1172 16.25 18.1172 15.8984 17.7266L14.0234 15.8516C13.6328 15.5 13.6328 14.9141 14.0234 14.5625C14.1797 14.3672 14.4141 14.25 14.6875 14.25C14.9219 14.25 15.1562 14.3672 15.3125 14.5234ZM5.3125 14.25C5.54688 14.25 5.78125 14.3672 5.9375 14.5625C6.32812 14.9141 6.32812 15.5 5.9375 15.8516L4.0625 17.7266C3.71094 18.1172 3.125 18.1172 2.77344 17.7266C2.38281 17.375 2.38281 16.7891 2.77344 16.4375L4.64844 14.5625C4.80469 14.3672 5.03906 14.25 5.3125 14.25ZM19.2578 9.60156C19.6875 9.71875 20 10.0703 20 10.5C20 10.9688 19.6875 11.3203 19.2578 11.4375L12.5781 13.1172L10.8984 19.7969C10.7812 20.2266 10.4297 20.5 9.96094 20.5C9.53125 20.5 9.17969 20.2266 9.0625 19.7578L7.38281 13.0781L0.703125 11.3984C0.273438 11.2812 0 10.9297 0 10.5C0 10.0703 0.273438 9.71875 0.703125 9.60156L7.38281 7.92188L9.0625 1.24219C9.17969 0.8125 9.53125 0.5 10 0.5C10.4297 0.5 10.7812 0.8125 10.8984 1.24219L12.5781 7.92188L19.2578 9.60156Z"
                    fill="#FAF7F5" />
            </svg>
        </div>
        <slot name="thumbnail">
            <img
                v-if="thumbnail"
                :src="thumbnail"
                alt=""
                class="w-12 h-12 rounded-full bg-white relative" />
        </slot>

        <div
            class="leading-tight min-w-0 flex-1"
            :class="[gap, reverseOrder ? 'flex flex-col-reverse' : '']">
            <div class="text-xs text-blue-darkest">
                <slot name="title">
                    <div v-if="titleType === 'created'">
                        Created on {{ message }} {{ formatDate(title) }} by
                    </div>
                    <div v-else-if="titleType === 'comments'">
                        {{ message }} {{ formatDate(title, true) }}
                    </div>
                    <div v-else-if="titleType === 'added'">
                        {{ message }} items added on {{ formatDate(title) }} by
                    </div>
                    <div
                        v-else
                        class="text-gray-dark"
                        :class="truncateTitle ? 'truncate' : ''">
                        {{ title }}
                    </div>
                </slot>
            </div>
            <div
                class="font-bold capitalize"
                :class="[
                    colorClasses,
                    nameFontStyles,
                    truncateTitle ? 'truncate' : '',
                ]">
                <slot name="name">{{ name }}</slot>
            </div>
        </div>

        <slot name="icon"></slot>
    </div>
</template>

<script>
export default {
    name: 'BasePerson',
    props: {
        thumbnail: {
            type: String,
            default: null,
        },
        thumbnails: {
            type: Array,
            default: null,
        },
        title: {
            type: [String, Date],
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        truncateName: {
            type: Boolean,
            default: false,
        },
        truncateTitle: {
            type: Boolean,
            default: false,
        },
        textColor: {
            type: String,
            default: 'blue',
            // Add more if needed
            validator: (val) =>
                ['white', 'blue', 'lightblue', 'gray', 'darkblue'].includes(
                    val
                ),
        },
        nameFontStyles: {
            type: String,
            default: 'text-xs font-bold', // Default classes for font size and weight
        },
        titleType: {
            type: String,
            default: 'normal', // 'created' / 'added' / 'comments' / 'normal'. Default to 'normal' if not specified
        },
        message: {
            type: String,
            default: null,
        },
        stacked: {
            type: Boolean,
            default: false,
        },
        stackedPersonCount: {
            type: Number,
            default: 0,
        },
        reverseOrder: {
            type: Boolean,
            default: false,
        },
        gap: {
            type: String,
            default: 'gap-0',
        },
    },
    computed: {
        colorClasses() {
            if (this.textColor === 'white') {
                return 'text-white'
            } else if (this.textColor === 'blue') {
                return 'text-blue'
            } else if (this.textColor === 'lightblue') {
                return 'text-blue-light'
            } else if (this.textColor === 'darkblue') {
                return 'text-blue-darkest'
            } else if (this.textColor === 'gray') {
                return 'text-gray'
            } else {
                return 'text-blue'
            }
        },
        separation() {
            switch (this.thumbnails.length) {
                case 1:
                    return 'translate-x-12'
                case 2:
                    return 'translate-x-8'
                case 3:
                    return 'translate-x-4'
                default:
                    return 'translate-x-4'
            }
        },
        cappedThumbnails() {
            return this.thumbnails.slice(0, 3)
        },
        remainingThumbnailsCount() {
            const remainingCount = this.thumbnails.length - 3
            return remainingCount > 0 ? remainingCount : 0
        },
    },
    methods: {
        isValidDate(value) {
            return value instanceof Date && !isNaN(value)
        },
        formatDate(dateString, includeTime = false) {
            const date = new Date(dateString)
            if (!this.isValidDate(date)) {
                return ''
            }

            const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            const day = date.getDate()
            const month = months[date.getMonth()]
            const year = date.getFullYear()

            // Add ordinal suffix to the day (e.g., 1st, 2nd, 3rd, ...)
            let daySuffix
            if (day % 10 === 1 && day !== 11) {
                daySuffix = 'st'
            } else if (day % 10 === 2 && day !== 12) {
                daySuffix = 'nd'
            } else if (day % 10 === 3 && day !== 13) {
                daySuffix = 'rd'
            } else {
                daySuffix = 'th'
            }

            let formattedTime = ''
            if (includeTime) {
                let hours = date.getHours()
                const minutes = ('0' + date.getMinutes()).slice(-2)

                // Convert hours to 12-hour format to determine am/pm
                const period = hours >= 12 ? 'pm' : 'am'
                hours = hours % 12 || 12 // Handle midnight (0) and noon (12)

                formattedTime = ` ${hours}:${minutes} ${period} -`
            }

            return `${formattedTime} ${month} ${day}${daySuffix}, ${year}`
        },
    },
}
</script>
