import { get, set, upperCase } from 'lodash'
import asset from '../../../Stores/Frontend/Modules/asset'

let AssetMixins = {
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            newComment: null,
        }
    },
    methods: {
        getStatusClasses() {
            let classes = [
                'text-2xs',
                'py-0.5',
                'px-2',
                'rounded-full',
                'font-bold',
            ]

            if (this.asset.status === 'active') {
                classes.push(
                    'bg-success-light border border-success text-success-dark'
                )
            }

            if (this.asset.status === 'revisions_needed') {
                classes.push('bg-gold-lighter')
            }

            if (this.asset.status === 'pending') {
                classes.push('bg-coral-medium text-black')
            }

            if (this.asset.status === 'deactivated') {
                classes.push('bg-[#f7f7f7] text-gray-dark')
            }

            return classes.join(' ')
        },
        toggleApiVisibility() {
            this.$store.dispatch('asset/setApiVisibility', {
                asset_id: this.asset.asset_id,
                visible_to_api: this.asset.visible_to_api,
            })
        },
    },
    computed: {
        statusText() {
            return upperCase(this.asset.status)
        },
        formattedDateCreated() {
            const date = new Date(this.asset.date_created.replace(/-/g, '/'))
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' })
                .format(date)
                .slice(0, 3)
            const day = date.getDate()
            const year = date.getFullYear()

            return `${month}. ${day}, ${year}`
        },
        formattedDateUpdated() {
            const date = new Date(this.asset.last_modified.replace(/-/g, '/'))
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' })
                .format(date)
                .slice(0, 3)
            const day = date.getDate()
            const year = date.getFullYear()

            return `${month}. ${day}, ${year}`
        },
        formattedExpirationDate() {
            if (!this.asset.deactivation_date) {
                return '';
            }
            const date = new Date(this.asset.deactivation_date.replace(/-/g, '/'))
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' })
                .format(date)
                .slice(0, 3)
            const day = date.getDate()
            const year = date.getFullYear()

            return `${month}. ${day}, ${year}`
        },
        getAssetType() {
            return this.asset.asset_type.data
                ? this.asset.asset_type.data.name
                : null
        },
        assetType() {
            return this.getAssetType
        },
        assetTypeId: {
            set(id) {
                this.asset.asset_type_id = id
            },
            get() {
                return this.asset.asset_type_id
            },
        },
        brand() {
            return this.asset.brand ? this.asset.brand.data.name : null
        },
        color() {
            return this.asset.color_id
        },
        subject() {
            return this.asset.subject ? this.asset.subject.data.name : 'N/A'
        },
        subjectId: {
            set(id) {
                this.asset.subject_id = id
            },
            get() {
                return this.asset.subject_id
            },
        },
        caption() {
            return this.asset.caption ? this.asset.caption : 'N/A'
        },
        description: {
            set(description) {
                this.asset.description = description
            },
            get() {
                return this.asset.description ? this.asset.description : 'N/A'
            },
        },
        extension() {
            return this.asset.extension
                ? upperCase(this.asset.extension)
                : 'N/A'
        },
        name() {
            return this.asset.name ? this.asset.name : 'N/A'
        },
        filename() {
            return this.asset.filename ? this.asset.filename : 'N/A'
        },
        dacFileName() {
            return this.asset.dac_file_name ? this.asset.dac_file_name : 'N/A'
        },
        fileSize() {
            return this.asset.high_file_size ? this.asset.high_file_size : 'N/A'
        },
        width() {
            let width = null

            if (this.asset.files.data) {
                this.asset.files.data.forEach((file) => {
                    if (file.type === 'high') {
                        width = file.width
                    }
                })
            }

            return width
        },
        height() {
            let height = null

            if (this.asset.files.data) {
                this.asset.files.data.forEach((file) => {
                    if (file.type === 'high') {
                        height = file.height
                    }
                })
            }

            return height
        },
        dimensions() {
            if (this.width && this.height) {
                return `${this.width}x${this.height}`
            }

            return 'N/A'
        },
        createdBy() {
            return this.asset.created_by
        },
        collections() {
            return this.asset.collections ? this.asset.collections.data : []
        },
        fromMvwal() {
            return this.asset.vistana_asset_id ? true : false
        },
        mvwalLink() {
            if (!this.asset.links) {
                return null
            }

            if (this.fromMvwal && this.asset.links.data) {
                if (this.asset.links.data.mvwal) {
                    return this.asset.links.data.mvwal
                }
            }

            return null
        },
        fromMdam() {
            return this.asset.mdam_id ? true : false
        },
        mdamLink() {
            if (!this.asset.links) {
                return null
            }

            if (this.fromMdam && this.asset.links.data) {
                if (this.asset.links.data.mdam) {
                    return this.asset.links.data.mdam
                }
            }

            return null
        },
        usageDescription() {
            return this.asset.usage_description
        },
        rightsDocs() {
            if (this.asset['rights-documents']) {
                return this.asset['rights-documents'].data
            }

            return []
        },
        templateType() {
            return this.asset.template_type
                ? this.asset.template_type.data.name
                : 'N/A'
        },
        templateNumber() {
            return this.asset.template_number
        },
        keywords: {
            get() {
                return this.asset.keywords
                    ? this.asset.keywords.join(', ')
                    : null
            },
            set(keywords) {
                this.asset.keywords = keywords.split(', ')
            },
        },
        reportCardGrade: {
            get() {
                return this.asset.report_card_grade
            },
            set(grade) {
                this.asset.report_card_grade = grade
            },
        },
        reportCardNotes() {
            return this.asset.report_card_notes
        },
        metadata: {
            get() {
                return this.asset.metadata
            },
            set(metadata) {
                this.asset.metadata = metadata
            },
        },
        marketingCaption: {
            set(caption) {
                this.asset.marketing_caption = caption
            },
            get() {
                return this.asset.marketing_caption
            },
        },
        instructions: {
            set(instructions) {
                this.asset.instructions = instructions
            },
            get() {
                return this.asset.instructions
            },
        },
        comments: {
            get() {
                return this.asset.comments ? this.asset.comments.data : null
            },
            set(comment) {
                if (this.asset.comments) {
                    //
                }
            },
        },
        historyNotes() {
            return this.asset.asset_history_notes
                ? this.asset.asset_history_notes.data
                : null
        },
        elevateInGallery: {
            get() {
                return this.asset.elevate_in_gallery
            },
            set(value) {
                this.asset.elevate_in_gallery = value
            },
        },
        travelAdvisorOnly: {
            get() {
                return this.asset.travel_advisor_only
            },
            set(value) {
                this.asset.travel_advisor_only = value
            },
        },
        hwsImageName: {
            get() {
                return this.asset.hws_image_name
            },
            set(value) {
                this.asset.hws_image_name = value
            },
        },
        isPrimaryPropertyImage: {
            get() {
                return this.asset.is_primary_property_image
            },
            set(value) {
                this.asset.is_primary_property_image = value
            },
        },
        visibleToApi: {
            get() {
                return this.asset.visible_to_api
            },
            set(value) {
                this.asset.visible_to_api = value
            },
        },
        gdsRoomTypeCodes: {
            set(codes) {
                this.asset.gds_room_type_codes = codes
            },
            get() {
                return this.asset.gds_room_type_codes
            },
        },
        ersRoomTypeCodes: {
            set(codes) {
                this.asset.ers_room_type_codes = codes
            },
            get() {
                return this.asset.ers_room_type_codes
            },
        },
        poolCodes: {
            get() {
                return this.asset.pool_codes
            },
        },
        deactivationDate: {
            set(date) {
                this.asset.deactivation_date = date
            },
            get() {
                return this.asset.deactivation_date
            },
        },
        documents: {
            get() {
                if (this.asset['rights-documents']) {
                    return this.asset['rights-documents'].data
                }

                return []
            },
        },
    },
    mounted() {
        if (!this.$store.state.asset.asset) {
            if (window.assetId) {
                this.$store.dispatch('asset/getAsset', window.assetId)
            }
        }
    },
}

export default AssetMixins
