import axios from 'axios'
import { Editor } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'

const includes = [
    'properties',
    'brands',
    'collections',
    'collections.thumbnails',
]

import {
    ENTRIES_CREATE,
    ENTRIES_DELETE,
    ENTRIES_LIST,
    ENTRIES_UPDATE,
} from 'Root/endpoints'

export async function getEntries(params) {
    params = { ...params, includes }
    return axios.get(ENTRIES_LIST, { params })
}

export async function getEntry(id) {
    return axios.get(`${ENTRIES_LIST}/${id}`, { params: { includes } })
}

export async function getEntryBySlug(slug) {
    const params = { slug, includes }
    return axios.get(ENTRIES_LIST, { params })
}

export async function createEntry(entry) {
    return axios.post(ENTRIES_CREATE, { entry })
}

export async function updateEntry(entry) {
    entry.type = entry.type.data.slug
    entry.status = entry.status.data.slug
    // entry.category = entry.category.data.slug

    return axios.post(`${ENTRIES_UPDATE}/${entry.id}`, { entry, includes })
}

export async function deleteEntry(entry) {
    return axios.delete(`${ENTRIES_DELETE}/${entry.id}`)
}

export async function updateFeaturedImage(entry, file) {
    const form = new FormData()

    form.append('file', file)
    return axios.post(`${ENTRIES_CREATE}/${entry.id}/image`, form)
}

export function renderContent(content) {
    const editor = new Editor({
        extensions: [StarterKit, FontFamily, TextStyle],
        readOnly: true,
        content,
    })

    return editor.getHTML()
}

export async function createCategory(category) {
    return axios.post('/api/content/entry-categories', { category })
}

export async function updateCategory(category) {
    return axios.put(`/api/content/entry-categories/${category.id}`, {
        category,
    })
}

export async function deleteCategory(category) {
    return axios.delete(`/api/content/entry-categories/${category.id}`, {
        category,
    })
}

export default {
    createCategory,
    createEntry,
    deleteCategory,
    deleteEntry,
    getEntries,
    getEntry,
    renderContent,
    updateCategory,
    updateEntry,
    updateFeaturedImage,
}
