<template>
    <div class="flex flex-wrap">

        <EntryTitle
            id="title"
            v-model="entry.title"
            class="w-full mb-4"
        />

        <EntryContent
            label="Content"
            class="w-full"
            id="content"
            v-model="entry.content"
        />
    </div>
</template>

<script>
import EntryContent from 'Components/Entries/EntryFields/EntryContent'
import EntryTitle from 'Components/Entries/EntryFields/EntryTitle'

export default {
    components: {
        EntryContent,
        EntryTitle,
    },
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {}
    },
    computed: {
        entry: {
            get() {
                return this.value
            },
            set(newEntry) {
                this.$emit('input', newEntry)
            },
        },
    },

    created() {},
}
</script>
