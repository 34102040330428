import axios from 'axios'
import { USER_SEARCH } from 'Root/endpoints'

export async function getUsers(params) {
    return axios.get(USER_SEARCH, { params })
}


export default {
    getUsers,
}
