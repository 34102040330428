<template>
<div class="overflow-hidden">
    <div class="mb-2 border-b flex justify-between">
        <span
            :class="tabClasses('recent')"
            @click="showRecent()"
        >
            Recent
        </span>

        <span
            v-if="isAdmin"
            :class="tabClasses('stored')"
            @click="showStored()"
        >
            Saved
        </span>
    </div>

    <ul
        v-if="viewRecent"
        class=""
    >
        <li
            v-for="recentSearch in recentSearches"
            :key="recentSearch.recent_search_id"
            class="inline-block w-full mb-2"
        >
            <a
                :href="recentSearch.link"
                class="link mb-1"
                v-text="recentSearch.name"
            />
        </li>
    </ul>
    <ul
        v-if="isAdmin && viewStored"
    >
        <li>
            <input
                v-model="storedSearchName"
                type="text"
                class="inline-block border rounded mb-4 p-2 w-full text-sm"
                @input="fetchStoredSearches"
            >
        </li>

        <li v-if="!storedSearches.length">
            No stored searches found
        </li>

        <li
            v-for="storedSearch in storedSearches"
            :key="storedSearch.stored_search_id"
            class="inline-block w-full mb-2"
        >
            <a
                :href="storedSearch.link"
                class="link"
                v-text="storedSearch.name"
            />
        </li>

        <li
            v-if="storedSearchMeta.pagination"
            class="flex justify-between mt-2"
        >
            <span
                v-if="storedSearchMeta.pagination.links.previous"
                class="link cursor-pointer inline-block py-2"
                @click="fetchPreviousStoredSearches()"
            >
                <i class="far fa-chevron-left" /> Prev
            </span>

            <span
                v-if="storedSearchMeta.pagination.links.next"
                class="link cursor-pointer inline-block py-2 ml-auto"
                @click="fetchNextStoredSearches()"
            >
                Next <i class="far fa-chevron-right" />
            </span>
        </li>
    </ul>
</div>
</template>

<script>
import axios from 'axios';
import endpoints from 'Root/endpoints';
import { debounce } from 'lodash';

export default {
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            viewRecent: true,
            viewStored: false,
            recentSearches: [],
            storedSearches: [],
            storedSearchMeta: {},
            storedSearchName: null,
        };
    },
    mounted() {
        this.fetchRecentSearches();
    },
    methods: {

        showRecent() {
            this.viewStored = false;
            this.viewRecent = true;
        },
        showStored() {
            if (!this.storedSearches.length) {
                this.fetchStoredSearches();
            }

            this.viewRecent = false;
            this.viewStored = true;
        },
        tabClasses(type) {
            let classes = [
                'p-1',
                'cursor-pointer',
            ];

            if (type === 'recent' && this.viewRecent) {
                classes.push('font-bold', 'text-coral-dark');
            }

            if (type === 'stored' && this.viewStored) {
                classes.push('font-bold', 'text-coral-dark');
            }

            return classes.join(' ');
        },

        fetchRecentSearches() {
            axios.get(endpoints.SEARCHES_RECENT_LIST, { params: { limit: 10 } })
                .then(response => {
                    this.recentSearches = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
        },
        fetchStoredSearches: debounce(
            function () {
                let params = {
                    page: 1,
                    limit: 10,
                };

                if (this.storedSearchName) {
                    params.name = this.storedSearchName;
                }
                axios.get(endpoints.SEARCHES_STORED_LIST, { params })
                    .then(response => {
                        this.storedSearches = response.data.data;
                        this.storedSearchMeta = response.data.meta;
                    }).catch(error => {
                        console.error(error);
                    });
            },
            300
        ),
        fetchNextStoredSearches() {
            if (!this.storedSearchMeta.pagination) {
                return false;
            }

            axios.get(this.storedSearchMeta.pagination.links.next)
                .then(response => {
                    this.storedSearches = response.data.data;
                    this.storedSearchMeta = response.data.meta;
                }).catch(error => {
                    console.error(error);
                });
        },
        fetchPreviousStoredSearches() {
            if (!this.storedSearchMeta.pagination) {
                return false;
            }

            axios.get(this.storedSearchMeta.pagination.links.previous)
                .then(response => {
                    this.storedSearches = response.data.data;
                    this.storedSearchMeta = response.data.meta;
                }).catch(error => {
                    console.error(error);
                });
        }
    },
};
</script>

<style>

</style>
