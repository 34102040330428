<template>
    <div >
        <div class="mt-6 mb-8">
            <h3 class="text-xl text-blue font-swiss font-bold">{{ statusTitle }}</h3>
            <p>{{ statusSubtitle }}</p>
        </div>
        <table v-if="orders.length > 0" class="table">
            <thead>
                <tr>
                    <th class="table--head">ID</th>
                    <th class="table--head">Association</th>
                    <th class="table--head">Request Type</th>
                    <th class="table--head">Options</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in orders" :key="i" class="table--row">
                    <td class="table--cell">{{ item.id }}</td>
                    <td class="table--cell">{{ item.brand.data.name }}</td>
                    <td class="table--cell">
                        <span v-if="item.request_type.data">
                            {{ item.request_type.data.name }}
                        </span>
                    </td>
                    <td class="table--cell">
                        <a href="#" class="link" @click="displayDetails(item)">Details</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else>
            None of your work orders are {{ status }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'WorkOrdersTable',
    props: {
        orders: {
            type: Array,
            required: true,
        },
        status: {
            type: String,
            required: false,
        },
        statusTitle: {
            type: String,
            required: false,
        },
        statusSubtitle: {
            type: String,
            default: '',
        },
    },
    methods: {
        displayDetails(item) {
            // Emit an event to notify the parent component
            this.$emit('display-details', item);
        },
    },
}
</script>