<template>
    <svg 
        viewBox="0 0 14 14" 
        class="transition-transform transform translate-x-0 group-hover:translate-x-1"
        :class="iconClasses"
        fill="none"
    >
        <path
            d="M8.25 1.21875L13.75 6.46875C13.9062 6.625 14 6.8125 14 7.03125C14 7.21875 13.9062 7.40625 13.75 7.5625L8.25 12.8125C7.96875 13.0938 7.46875 13.0938 7.1875 12.7812C6.90625 12.5 6.90625 12 7.21875 11.7188L11.375 7.78125H0.75C0.3125 7.78125 0 7.4375 0 7.03125C0 6.59375 0.3125 6.28125 0.75 6.28125H11.375L7.21875 2.3125C6.90625 2.03125 6.90625 1.53125 7.1875 1.25C7.46875 0.9375 7.9375 0.9375 8.25 1.21875Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconArrowRight',
    props: {
        iconColor: {
            type: String,
            default: 'blue',
            // Add more if needed
            validator: (val) =>
                ['white', 'blue', 'lightblue', 'mediumblue', 'gray', 'darkblue'].includes(val),
        },
        iconSize: {
            type: String,
            default: 'medium',
            // Add more if needed
            validator: (val) =>
                ['small', 'medium', 'large'].includes(val),
        },
    },
    computed: {
        iconClasses() {
            let classes = [];

            if (this.iconColor === 'white') {
                classes.push('text-white');
            } else if (this.iconColor === 'blue') {
                classes.push('text-blue');
            } else if (this.iconColor === 'lightblue') {
                classes.push('text-blue-light');
            } else if (this.iconColor === 'darkblue') {
                classes.push('text-blue-darkest');
            } else if (this.iconColor === 'gray') {
                classes.push('text-gray');
            } else if (this.iconColor === 'mediumblue') {
                classes.push('text-blue-medium');
            } else {
                classes.push('text-blue');
            }

            // width and height
            classes.push({
                small: 'h-2.5 w-2.5',
                medium: 'h-3.5 w-3.5',
                large: 'h-5 w-5',
            }[this.iconSize]);

            return classes;
        },
    }
};
</script>