<template>
<div class="assetThumbnail">
    <template
        v-if="asset.thumbnail_type === 'Slideshow' && thumbnails"
    >
        <div :class="`${type}--slidesWrapper`">
            <vue-glide
                v-model="activeSlide"
                :per-view="1"
                :class="`${type}--slides`"
            >
                <vue-glide-slide
                    v-for="thumbnail in thumbnails"
                    :key="thumbnail.thumbnail_id"
                >
                    <div v-if="slidesModal">
                        <a
                            class="assetThumbnail--link"
                            :href="asset.links.data.view"
                            @click.prevent="showModal = true"
                        >
                            <span :class="backgroundClasses">
                                <img v-lazy="thumbnail.url">
                            </span>
                        </a>
                        <modal
                            :visible.sync="showModal"
                            :content-classes="['w-2/3', 'text-center']"
                            :transparent="true"
                            :overflow-scroll="false"
                        >
                            <img :src="thumbnail">
                        </modal>
                    </div>
                    <a
                        v-else
                        class="assetThumbnail--link"
                        :href="asset.links.data.view"
                    >
                        <img v-lazy="thumbnail.url">
                    </a>
                </vue-glide-slide>
                <template slot="control">
                    <button
                        :class="`${type}--slideControl`"
                        data-glide-dir="<"
                    >
                        <i class="far fa-chevron-left fa-fw" />
                    </button>
                    <button
                        :class="`${type}--slideControl`"
                        data-glide-dir=">"
                    >
                        <i class="far fa-chevron-right fa-fw" />
                    </button>
                </template>
            </vue-glide>
        </div>
    </template>

    <template v-else>
        <a
            v-if="type === 'assetCard'"
            :href="asset.links.data.view"
        >
            <span :class="backgroundClasses">
                 <img
                    v-lazy="thumbnail"
                    :class="thumbnailClasses"
                >
            </span>
        </a>
        <span v-else :class="backgroundClasses">
            <img
                v-lazy="thumbnail"
                :class="thumbnailClasses"
                @click="showModal = true"
            >
        </span>
    </template>
    <modal
        :visible.sync="showModal"
        :content-classes="['w-2/3', 'text-center']"
        :transparent="true"
        :overflow-scroll="false"
    >
        <img :src="thumbnail">
    </modal>
</div>
</template>


<script>
import { Glide, GlideSlide } from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';
import Modal from 'Components/Modal';

export default {
    name: 'AssetThumbnail',
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
        Modal,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        type: {
            required: false,
            type: String,
            default: 'assetCard',
        },
        slidesModal: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeSlide: 0,
            showModal: false,
        };
    },
    computed: {
        thumbnail() {
            let url = this.asset.asset_thumbnail_url;

            if (this.type === 'assetDetails') {
                if (this.asset.files.data) {
                    this.asset.files.data.forEach(file => {
                        if (file.type === 'low') {
                            url = file.url;
                        }

                        if (file.type === 'video_preview') {
                            url = file.url;
                        }
                    });
                }
            }

            return url;
        },
        thumbnails() {
            if (this.asset.thumbnails) {
                return this.asset.thumbnails.data;
            }

            return null;
        },
        thumbnailClasses() {
            let classes = [];

            if (this.type === 'assetCard') {
                classes.push('assetThumbnail__img', 'inline-block', 'max-h-48', 'object-cover', 'bg-blue-lightest');
            }

            if (this.type === 'assetDetails') {
                classes.push(
                    'assetThumbnail__img',
                    '--full-height',
                    'cursor-pointer'
                );
            }

            return classes.join(' ');
        },
        assetType() {
            if (this.type === 'assetCard') {
                return this.asset.asset_type.data.name;
            }
            if (this.type === 'assetDetails') {
                return this.asset.asset_type.data.name;
            }

            return '';
        },
        backgroundClasses() {
            if (this.assetType === 'Logo') {
                return 'bg-transparency';
            }

            return 'bg-white';
        }
    },
    methods: {
        changeSlide(direction) {
            if (this.asset.thumbnails.data.length === 1) {
                return;
            }

            if (direction === '<') {
                if (this.activeSlide === 0) {
                    this.activeSlide = this.asset.thumbnails.data.length - 1;
                } else {
                    this.activeSlide--;
                }
            }

            if (direction === '>') {
                if (this.activeSlide === this.asset.thumbnails.data.length - 1) {
                    this.activeSlide = 0;
                } else {
                    this.activeSlide++;
                }
            }
        }
    }
};
</script>

<style>

    .glide {
        position: relative;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    div[data-glide-el="controls"] {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        display: flex;
        justify-content: space-between;
    }
</style>
